import React from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";

const DataPolicy = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item className={classes.main}>
        <Typography variant="h4">Datenschutzhinweise </Typography>

        <Typography variant="body1">Gültig ab dem 01. Juni 2023. </Typography>
        <br />
        <Typography variant="body1">
          Verantwortlicher und Ihre Rechte als Betroffener*
          <br />
          (*Genderhinweis: Aus Gründen der besseren Lesbarkeit wird in der Folge
          auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich
          und divers (m/w/d) verzichtet. Sämtliche nachfolgenden
          Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.)
        </Typography>
        <Typography variant="h6">1. Verantwortlicher</Typography>

        <br />
        <Typography variant="body1">
          Verantwortliche Stelle für die in diesen Datenschutzhinweisen
          geregelte Datenverarbeitung ist die Smart Agent GmbH (nachfolgend
          „wir“ oder „uns“). Unsere Kontaktdaten lauten:
        </Typography>
        <br />
        <Typography variant="body1">
          Smart Agent GmbH <br />
          Perhamerstraße 37 <br />
          D-80687 München <br />
          E-Mail: info@smart-agent.eu
        </Typography>
        <br />
        <Typography variant="h6">2. Ihre Rechte als Betroffener</Typography>

        <br />
        <Typography variant="body1">
          Sie haben ein Recht auf Auskunft über die Verarbeitung Ihrer
          personenbezogenen Daten (beispielsweise den Ursprung dieser Daten, den
          Verarbeitungszweck, die Modalitäten der Datenverarbeitung). Ferner
          sind Sie unter bestimmten Voraussetzungen berechtigt, der
          Datenverarbeitung für die Zukunft zu widersprechen, diese
          einzuschränken oder die Löschung der Daten zu verlangen. Schließlich
          können sie jederzeit den Versand von Werbematerial oder die
          Durchführung von Marktforschungen oder kommerziellen Mitteilungen
          unterbinden.
        </Typography>

        <br />
        <Typography variant="body1">
          Sie haben zusammengefasst ein Recht auf
        </Typography>
        <Typography component="li">Auskunft,</Typography>
        <Typography component="li">Berichtigung,</Typography>
        <Typography component="li">
          Löschung (bzw. auf Vergessenwerden),{" "}
        </Typography>
        <Typography component="li">Einschränkung der Verarbeitung,</Typography>
        <Typography component="li">Datenübertragbarkeit,</Typography>
        <Typography component="li">
          Widerspruch gegen die Verarbeitung ihrer personenbezogenen Daten,
        </Typography>
        <Typography component="li">
          Beschwerde gegenüber Aufsichtsbehörden.
        </Typography>

        <br />
        <Typography variant="body1">
          Darüber hinaus haben Sie das Recht, jederzeit der Verarbeitung Ihrer
          personenbezogenen Daten für Zwecke der Direktwerbung zu widersprechen.
          Wenn Sie der Verarbeitung zu Zwecken des Direktmarketings
          widersprechen, werden wir Ihre personenbezogenen Daten nicht mehr für
          diese Zwecke verarbeiten.
        </Typography>

        <br />
        <Typography variant="body1">
          Gemäß Art. 7 III der EU (Datenschutz-Grundverordnung (nachfolgend
          „DSGVO“) können Sie Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns widerrufen. Dies hat zur Folge, dass wir die auf dieser
          Einwilligung beruhende Datenverarbeitung für die Zukunft nicht mehr
          fortführen dürfen.
        </Typography>

        <br />
        <Typography variant="body1">
          Bitte beachten Sie, dass Ihr Löschungsrecht Einschränkungen
          unterliegt. Zum Beispiel müssen bzw. dürfen wir keine Daten löschen,
          die wir aufgrund gesetzlicher Aufbewahrungsfristen noch weiter
          vorhalten müssen. Auch Daten, die wir zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen benötigen sind von Ihrem
          Löschungsrecht ausgenommen
        </Typography>

        <br />
        <Typography variant="body1">
          Wenn Sie eine Beschwerde über die Art und Weise haben, wie wir Ihre
          Daten verarbeiten, haben Sie die Möglichkeit, Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
          Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, geltend zu machen.
        </Typography>
        <br />
        <Typography variant="body1">
          Wir behalten uns im Falle einer gesetzlichen Verpflichtung vor,
          Informationen über Sie offen zu legen, wenn die Herausgabe von
          rechtmäßig handelnden Behörden oder Strafverfolgungsorganen von uns
          verlangt wird. Rechtsgrundlage: Art. 6 I Satz 1 lit. c DSGVO
          (gesetzliche Verpflichtung).
        </Typography>

        <br />
        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>
        <br />

        <Typography variant="h6">
          Überblick über unsere Datenverarbeitungsprozesse
        </Typography>

        <br />

        <Typography variant="body1">
          Unsere nachfolgenden Datenschutzhinweise sind sodann wie folgt
          gegliedert und geben Ihnen einen Überblick über die folgenden
          Datenverarbeitungsprozesse:
        </Typography>

        <br />

        <Typography variant="body1">
          I. Sie besuchen uns auf unserer Unternehmensseite im Internet <br />
          II. Sie schließen mit uns einen Such- oder Vermarktungsvertrag,
          fordern von uns ein Exposé an oder beauftragen uns mit einer
          Immobilieneinwertung <br />
          III. Wir suchen gemeinsam mit einem Lizenzpartner eine Immobilie für
          Sie (Zuführung) <br />
          IV. Sie bewerben sich initiativ oder auf eine Stellenanzeige <br />
          V. Speicherung und Löschung Ihrer Daten
        </Typography>

        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>
        <Typography variant="h6">
          I. Erhebung und Verarbeitung personenbezogener Daten bei dem Besuch
          unserer Unternehmensseite im Internet
        </Typography>

        <br />

        <Typography variant="body1">
          Wir verarbeiten personenbezogene Daten grundsätzlich nur, soweit Sie
          uns diese über die auf unserer Unternehmensseite befindlichen
          Kontaktformulare übermitteln. Die Verarbeitung personenbezogener Daten
          erfolgt dabei nur, wenn Sie uns hierfür Ihre Einwilligung erteilt
          haben oder die Verarbeitung durch gesetzliche Vorschriften gestattet
          ist. Für bestimmte Leistungen, die wir auf unserer Unternehmensseite
          anbieten, benötigen wir ein Mindestmaß an Daten. Diese Pflichtangaben
          haben wir gekennzeichnet. Ohne die Pflichtangaben können wir unsere so
          gekennzeichneten Leistungen nicht erbringen.
        </Typography>

        <br />

        <Typography variant="h6">
          1. Welche Daten verarbeiten wir von Ihnen (nachfolgend „Sie“ oder auch
          „Nutzer“)
        </Typography>
        <br />

        <Typography variant="body1">
          Im Rahmen Ihrer Nutzung unserer Unternehmensseite verarbeiten wir
          personenbezogene Daten, und zwar:
        </Typography>

        <br />

        <Typography variant="body1">
          E-Mail-Adresse, Vorname, Nachname, Titel, Adresse, Telefonnummer,
          Objektdaten – im Zusammenhang mit dieser Datenschutzerklärung als
          "Daten" oder "personenbezogene Daten" bezeichnet), die von Ihnen im
          Falle der angebotenen Dienstleistungen auf unserer Unternehmensseite
          zur Verfügung gestellt werden (z.B. im Rahmen von Kontaktanfragen und
          Newsletter-Mailings sowie der Marktpreisermittlung („Einwertung“) von
          Immobilien).
        </Typography>

        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>

        <br />
        <Typography variant="h6">
          Bitte entnehmen Sie die Beschreibung der nutzungsbedingten
          Datenverarbeitung im Zusammenhang mit dem Einsatz von Cookies und
          anderen Trackingtechnologien den „Cookie Hinweisen“ der
        </Typography>

        <br />

        <Typography variant="body1">
          Smart Agent GmbH <br />
          Perhamerstraße 37 <br />
          D80687 München <br />
          E-Mail: info@smart-agent.eu <br />
          Deutschland
        </Typography>
        <br />

        <Typography variant="h6">
          Sie finden die Cookie Hinweise im unteren Teil unserer
          Unternehmensseite im Internet.
        </Typography>
        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>

        <br />
        <Typography variant="h6">
          2. Zweck der Verarbeitung und Rechtsgrundlage
        </Typography>

        <br />
        <Typography variant="body1">
          Wir verarbeiten Ihre Daten zu folgenden Zwecken:
        </Typography>
        <Typography component="li">
          Erfüllung von Kontaktanfragen (Rechtsgrundlage ist die
          Vertragserfüllung sowie - im Falle der Weitergabe der Nutzerdaten an
          andere Lizenzpartner der Smart Agent Gruppe (für die Definition sehen
          Sie bitte unten Ziffer 3) Ihre Einwilligung.)
        </Typography>
        <Typography component="li">
          Ggf. Erfüllung der Anfrage nach einer dem Marktpreis entsprechenden
          Einwertung der vom Nutzer beschriebenen Immobilie sowie des damit
          übermittelten Vermiet- oder Verkaufsinteresses (Rechtsgrundlage ist
          die Vertragserfüllung sowie im Falle der Eingabe von Kontaktdaten
          durch den Nutzer und ihrer Weitergabe an einen sachlich oder
          geographisch zuständigen Lizenzpartner der Smart Agent Gruppe (für die
          Definition sehen Sie bitte unten Ziffer 3) bzw. an das für die
          Einwertung technisch umsetzende Unternehmen Ihre Einwilligung sowie im
          Falle der Nutzung der Kontaktdaten für Direktmarketing das berechtigte
          Interesse an der Vermarktung unser eigenen Dienstleistungen oder Ihre
          Einwilligung);
        </Typography>
        <Typography component="li">
          Kontaktaufnahme per E-Mail oder Telefon durch uns (Rechtsgrundlage ist
          die Vertragserfüllung bzw. im Falle der Verwendung der Telefonnummer
          für werbliche Zwecke Ihre Einwilligung);
        </Typography>
        <Typography component="li">
          Versand eines E-Mail-Newsletters zu eigenen Angeboten sowie Eigen- und
          Fremdwerbung im gesetzlich zulässigen Umfang bzw. einwilligungsbasiert
          (Rechtsgrundlage ist entweder Ihre Einwilligung oder das berechtigte
          Interesse von uns an Direktmarketing, solange das Marketing unter
          Beachtung datenschutz- und wettbewerbsrechtlicher Vorgaben erfolgt);
        </Typography>
        <br />
        <Typography variant="body1">In der Zusammenfassung:</Typography>
        <br />
        <Typography variant="body1">
          Rechtsgrundlage der Verarbeitung der personenbezogenen Daten ist Art.
          6 I Satz 1 lit. a DSGVO, sofern die Verarbeitung auf einer
          Einwilligung basiert, Art. 6 I Satz 1 lit. b DSGVO, sofern Grundlage
          der Verarbeitung ein (ggf. vorvertragliches) Vertragsverhältnis ist
          und Art. 6 I Satz 1 lit. f DSGVO, sofern Grundlage das berechtigte
          Interesse von uns ist. Soweit eine Verarbeitung personenbezogener
          Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
          der wir unterliegen, dient Art. 6 I Satz 1 lit. c DSGVO als
          Rechtsgrundlage.
        </Typography>
        <br />
        <Typography variant="body1">
          Sofern Rechtsgrundlage Ihre Einwilligung ist, sind Sie berechtigt,
          Ihre Einwilligung jederzeit zu widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung ihrer personenbezogenen berührt wird. Ist
          Rechtsgrundlage das berechtigte Interesse, so sind Sie ebenfalls
          grundsätzlich berechtigt, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten Widerspruch einzulegen. Es gilt insofern Art.
          21 DSGVO.
        </Typography>
        <br />

        <Typography variant="h6">
          3. Weitergabe Ihrer personenbezogenen Daten
        </Typography>
        <br />
        <Typography variant="body1">
          Ihre personenbezogenen Daten werden von uns grundsätzlich nur dann an
          Dritte gegeben, soweit dies zur Vertragserfüllung erforderlich ist,
          wir oder der Dritte ein berechtigtes Interesse an der Weitergabe
          haben, eine gesetzliche Verpflichtung besteht oder Ihre Einwilligung
          hierfür vorliegt. Sofern Daten an Dritte auf Basis eines berechtigten
          Interesses übermittelt werden, wird dies in diesen
          Datenschutzbestimmungen erläutert.{" "}
        </Typography>
        <br />
        <Typography variant="body1">
          Sprechen wir von der „Smart Agent Gruppe“ innerhalb dieser
          Datenschutzerklärung, so meinen wir die zur Smart Agent
          Unternehmensgruppe gehörenden Gesellschaften i.S.d. §§ 15ff. AktG
          sowie die an diese Unternehmensgruppe vertraglich direkt oder indirekt
          angeschlossenen (Master-) Lizenzpartner.
        </Typography>

        <br />
        <Typography variant="h6">
          4. Weitergabe von Daten in Nicht-EWR Länder
        </Typography>
        <br />
        <Typography variant="body1">
          Zusätzlich zu dem obigen Absatz "Weitergabe Ihrer personenbezogenen
          Daten" gilt Folgendes:{" "}
        </Typography>
        <br />
        <Typography variant="body1">
          Die Empfänger von personenbezogenen Daten können sich außerhalb des
          EWR/UK befinden. Wenn personenbezogene Daten an Standorte außerhalb
          des EWR/UK übermittelt werden, werden wir, wie gesetzlich
          vorgeschrieben, sicherstellen, dass Ihre Datenschutzrechte angemessen
          geschützt sind, entweder weil die Europäische Kommission entschieden
          hat, dass das Land, in das personenbezogene Daten übermittelt werden,
          ein angemessenes Schutzniveau gewährleistet (Art. 45 DSGVO) oder die
          Übermittlung unterliegt angemessenen Garantien (z. B.
          Standardvertragsklauseln) der Europäischen Union, die mit dem
          Empfänger vereinbart wurden (Art. 46 DSGVO), sofern nicht die DSGVO
          eine Ausnahme vorsieht (Art. 49 DSGVO). Darüber hinaus beabsichtigen
          wir, soweit erforderlich, zusätzliche Maßnahmen mit den Empfängern zu
          vereinbaren, um ein angemessenes Datenschutzniveau zu gewährleisten.
          Kopien der angemessenen Schutzmaßnahmen (soweit wir uns auf diese
          verlassen) und eine Liste der Empfänger außerhalb des EWR/des
          Vereinigten Königreichs können angefordert werden. Bitte beachten Sie,
          dass diese Kopien in dem Maße geschwärzt werden können, wie es zum
          Schutz von Geschäftsgeheimnissen oder anderen vertraulichen
          Informationen erforderlich ist.
        </Typography>

        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>

        <br />
        <Typography variant="h6">
          II. Sie schließen mit uns einen Such- oder Vermarktungsvertrag,
          fordern von uns ein Exposé an oder beauftragen uns mit einer
          Immobilieneinwertung{" "}
        </Typography>
        <br />
        <Typography variant="h6">
          1. Welche Daten verarbeiten wir von Ihnen
        </Typography>

        <br />

        <Typography variant="body1">
          Bei der Suche, dem Verkauf bzw. der Vermietung einer Immobilie, der
          Exposéanfrage sowie bei der Immobilieneinwertung werden regelmäßig
          folgende personenbezogene Daten von Ihnen verarbeitet:
        </Typography>
        <br />

        <Typography component="li">
          Vorname, Nachname, Titel, Adresse, Telefonnummer, Angaben über den
          Immobilienwunsch (im Falle der Immobiliensuche) bzw. (im Falle der
          Immobilienvermarktung oder der Immobilieneinwertung) Angaben über die
          Immobilie;{" "}
        </Typography>
        <Typography component="li">
          E-Mail-Adresse, sofern Sie sich mit dem Empfang von werblichen
          Informationen, insbesondere dem Angebot oder der Einwertung von
          Immobilien, einverstanden erklärt haben oder eine Kommunikation per
          E-Mail wünschen;{" "}
        </Typography>
        <Typography component="li">
          sowie (sofern es zum Kauf / Verkauf oder der Anmietung von Immobilien
          kommt) Bankverbindung, Angaben zur Identität, Ausweiskopie (bei Kauf /
          Verkauf einer Immobilie) sowie Grundbuch, Strom-, Öl- und/oder
          Gasabrechnungen, allgemeine Einzelkostenabrechnungen,
          Teilungserklärungen, WEG -protokolle (-beschlüsse, Kaufvertrag (bei
          Verkauf einer Immobilie) beziehungsweise (sofern es zur Anmietung
          einer Immobilie kommt) Bankverbindung, Angaben zur Identität, ggf.
          Lebenslauf, Gehaltsnachweis, Bonitätsnachweis, Vermieterbestätigung,
          Mietvertrag, Bonitätsprüfungen (SCHUFA und andere Anbieter)
          Bestätigung von Ämtern zur Kostenübernahme, Steuerbescheide zur
          Bonitätsprüfung.
        </Typography>

        <br />
        <Typography variant="h6">
          2. Zweck der Verarbeitung und Rechtsgrundlage
        </Typography>

        <br />

        <Typography variant="body1">
          Wir verarbeiten diese personenbezogenen Daten zu folgenden Zwecken:{" "}
        </Typography>
        <br />

        <Typography component="li">
          Erfüllung des Vertragsverhältnisses oder eines vorvertraglichen
          Rechtsverhältnisses - auch durch mit uns kooperierende selbständige
          Immobilienberater - einschließlich Abrechnung und Bonitätsprüfung
          (Rechtsgrundlage ist Art. 6 I lit b. DSGVO sowie Art. 6 I lit. f
          DSGVO; das berechtigte Interesse von uns im Zusammenhang mit der
          Weitergabe der Daten an selbständige Immobilienberater folgt aus der
          Wahlfreiheit, auch mit selbständigen Maklern bzw. Submaklern
          Vertragsverhältnisse zu erfüllen. Das berechtigte Interesse an der
          Durchführung von Bonitätsprüfungen folgt aus dem berechtigten
          Interesse zum Schutz vor Forderungsausfällen.);
        </Typography>
        <Typography component="li">
          Erfüllung einer rechtlichen Verpflichtung insbesondere im Zusammenhang
          mit dem Geldwäschegesetz; (Rechtsgrundlage ist Art. 6 I lit. c DSGVO);
        </Typography>
        <Typography component="li">
          Werbliche Informationen über Immobilien, die wir oder andere
          Lizenzpartner der Smart Agent Gruppe vermarkten (Rechtsgrundlage ist
          Art. 6 I lit. f DSGVO; aus der Verarbeitung personenbezogener Daten
          zum Zwecke der Direktwerbung ergibt sich das berechtigte Interesse von
          uns. Rechtsgrundlage ist ebenfalls Ihre Einwilligung nach Art. 6 I a.
          DSGVO, sofern Ihnen die werblichen Informationen per Email, Telefax
          oder Telefon übermittelt werden.){" "}
        </Typography>

        <br />
        <Typography variant="body1">
          Sofern Rechtsgrundlage Ihre Einwilligung ist, sind Sie berechtigt,
          Ihre Einwilligung jederzeit zu widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung berührt wird. Ist Rechtsgrundlage das
          berechtigte Interesse, so sind Sie ebenfalls grundsätzlich berechtigt,
          aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
          Daten Widerspruch einzulegen. Es gilt insofern Art. 21 DSGVO.
        </Typography>
        <br />

        <Typography variant="h6">3. Weitergabe von Daten </Typography>

        <br />

        <Typography variant="body1">
          Sofern Ihre Anfrage (z.B. anlässlich einer Einwertungsanfrage)
          Angebote anderer Lizenzpartner der Smart Agent Gruppe betrifft,
          übermittelt Smart Agent die von Ihnen in diesem Fall eingegebenen
          personenbezogenen Daten an das jeweilige Unternehmen aus der Smart
          Agent Gruppe nach Ihrer vorherigen Einwilligung.{" "}
        </Typography>
        <br />

        <Typography variant="body1">
          Bitte informieren Sie sich in diesem Zusammenhang auch über unsere
          Angaben zur Zuführung im <strong>Abschnitt III</strong>.
        </Typography>
        <br />

        <Typography variant="body1">
          Ferner übermitteln wir im Rahmen der Abwicklung eines
          Vertragsverhältnisses personenbezogene Daten an Vertragspartner
          (Eigentümer, Vermieter, Suchkunden) und unterstützende Dienstleister
          wie Notare, Rechtsanwälte, Hausverwalter, Bauträger sowie im Rahmen
          von Bonitätsauskünften oder an Aufsichtsbehörden (wie z.B. bei den
          Geldwäschedokumentationen). Auch kann es sein, dass wir Ihre Daten im
          Rahmen des rechtlich zulässigen an Dritte übermitteln. Zu Dritten, an
          die wir Ihre personenbezogenen Daten, unabhängig von unserer
          Leistungserbringung, weitergeben gehören externe Berater (z.B. Anwälte
          und Wirtschaftsprüfer), Behörden im Rahmen ihrer Zuständigkeit um
          gesetzlichen Verpflichtungen nachzukommen und/oder unsere Rechte zu
          wahren (z.B. Finanzamt, Polizei, Staatsanwaltschaft, Gerichte),
          potenzielle Käufer oder Erwerber unserer gesamten oder eines Teils
          unserer Vermögenswerte und/oder Aktivitäten sowie sonstige Dritte,
          soweit Sie uns anweisen, Daten weiterzugeben oder Ihre Einwilligung
          erteilen.
        </Typography>
        <br />

        <Typography variant="body1">
          Darüber hinaus werden Ihre personenbezogenen Daten von uns nur an
          Mitarbeiter (feste Mitarbeiter wie Arbeitnehmer, freie Mitarbeiter und
          freie Immobilienberater) und Drittanbieter-Unternehmen (z. B.
          IT-Dienstleister, Hosting-Provider usw.) weitergegeben, auf die wir
          Dienstleistungen mit Verarbeitungsvorgängen im Rahmen einer
          Auftragsverarbeitung ausgelagert hat.
        </Typography>

        <br />

        <Typography variant="h6">
          4. Weitergabe von Daten in Nicht-EWR Länder
        </Typography>

        <br />

        <Typography variant="body1">
          In Ergänzung zu dem vorstehenden Absatz „Weitergabe Ihrer
          personenbezogenen Daten“ gilt Folgendes:
        </Typography>

        <br />

        <Typography variant="body1">
          Die Empfänger von personenbezogenen Daten können sich außerhalb des
          EWR/UK befinden. Wenn personenbezogene Daten an Standorte außerhalb
          des EWR/UK übermittelt werden, werden wir, wie gesetzlich
          vorgeschrieben, sicherstellen, dass Ihre Datenschutzrechte angemessen
          geschützt sind, entweder weil die Europäische Kommission entschieden
          hat, dass das Land, in das personenbezogene Daten übermittelt werden,
          ein angemessenes Schutzniveau gewährleistet (Art. 45 DSGVO) oder die
          Übermittlung unterliegt angemessenen Garantien (z. B.
          Standardvertragsklauseln) der Europäischen Union, die mit dem
          Empfänger vereinbart wurden (Art. 46 DSGVO), sofern nicht die DSGVO
          eine Ausnahme vorsieht (Art. 49 DSGVO). Darüber hinaus beabsichtigen
          wir, soweit erforderlich, zusätzliche Maßnahmen mit den Empfängern zu
          vereinbaren, um ein angemessenes Datenschutzniveau zu gewährleisten.
          Kopien der angemessenen Schutzmaßnahmen (soweit wir uns auf diese
          verlassen) und eine Liste der Empfänger außerhalb des EWR/des
          Vereinigten Königreichs können angefordert werden. Bitte beachten Sie,
          dass diese Kopien in dem Maße geschwärzt werden können, wie es zum
          Schutz von Geschäftsgeheimnissen oder anderen vertraulichen
          Informationen erforderlich ist.
        </Typography>

        <br />

        <Typography variant="body1">
          Sofern sich Ihre Anfrage auf Lizenzpartner der Smart Agent Gruppe
          außerhalb der Europäischen Union bezieht, übermitteln wir Ihre Daten
          nach Ihrer Einwilligung an den örtlich / sachlich zuständigen
          Lizenzpartner.
        </Typography>

        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>

        <br />
        <Typography variant="h6">
          III.Wir suchen gemeinsam mit einem anderen Smart Agent Lizenzpartner
          eine Immobilie für Sie („Zuführung“)
        </Typography>

        <br />

        <Typography variant="body1">
          Suchen wir gemeinsam mit einem anderen Lizenzpartner eine Immobilie
          für Sie, so werden Ihre Daten nur auf Basis einer zuvor erteilten
          Einwilligung durch Sie übermittelt. Bitte beachten Sie, dass sowohl
          wir als Ihr Erstkontakt, als auch der Lizenzpartner der Smart Agent
          Gruppe, dem Ihre Daten zugeführt werden, Ihre personenbezogenen Daten
          verarbeitet. Beide – wir und dieser Lizenzpartner - sind unabhängig
          voneinander verantwortlich für die jeweilige Datenverarbeitung. Wir
          und dieser andere Lizenzpartner haben wechselseitig keinen Zugriff auf
          die Daten des jeweils anderen und verarbeiten Ihre Daten auch nicht
          gemeinsam. Möchten Sie Betroffenenrechte geltend machen, so prüfen Sie
          bitte im Vorwege, ob Sie diese uns gegenüber oder dem anderen
          Lizenzpartner der Smart Agent Gruppe gegenüber geltend machen möchten,
          der ebenfalls Ihre Daten verarbeitet.
        </Typography>
        <br />

        <Typography variant="body1">
          Wie wir Ihre Daten im Zusammenhang mit der Vermarktung Ihrer Immobilie
          verarbeiten, welche Rechte Sie in diesem Zusammenhang haben und wer
          Ihre Ansprechpartner sind, sollten Sie Betroffenenrechte geltend
          machen wollen, entnehmen Sie bitte den obigen Ausführungen.
        </Typography>
        <br />

        <Typography variant="body1">
          ------------------------------------------------------------------------------
        </Typography>

        <br />
        <Typography variant="h6">
          IV. Sie bewerben sich initiativ oder auf eine Stellenanzeige{" "}
        </Typography>
        <br />
        <Typography variant="h6">
          1. Welche Daten verarbeiten wir von Ihnen
        </Typography>
        <br />
        <Typography variant="body1">
          Wenn Sie sich über unsere Webseite oder über eine Stellenanzeige bei
          uns bewerben, verarbeiten wir Ihren Namen sowie Ihre Adress-,
          Telekommunikations- und Bewerbungsdaten. Die Angabe Ihrer als
          Pflichtangaben ausgeschriebenen Adress- und Telekommunikationsdaten
          ist erforderlich, um mit Ihnen zu Ihrer Bewerbung Kontakt aufnehmen
          und die Bewerbung zuordnen zu können.
        </Typography>
        <br />

        <Typography variant="h6">
          2. Zweck der Verarbeitung und Rechtsgrundlage
        </Typography>
        <br />
        <Typography variant="body1">
          Wir verarbeiten Ihre personenbezogenen Daten dabei zu folgenden
          Zwecken:
        </Typography>
        <br />

        <Typography component="li">
          Prüfung der Bewerbung für ein Beschäftigungsverhältnis innerhalb
          unseres Unternehmens (Rechtsgrundlage ist Art. 6 I lit. b DSGVO ggf.
          i.V.m. § 26 BDSG (für den Anwendungsbereich der Bundesrepublik
          Deutschland).).{" "}
        </Typography>
        <Typography component="li">
          Abwehr von ggfs. geltend gemachten Rechtsansprüchen aus dem
          Bewerbungsverfahren gegen uns, sofern dies erforderlich ist
          (Rechtsgrundlage ist dabei das berechtigte Interesse von uns, ihrer
          Beweispflicht in einem Verfahren nach dem Allgemeinen
          Gleichbehandlungsgesetz (AGG) nachzukommen; Art. 6 I lit. f DSGVO.).
        </Typography>
        <Typography component="li">
          Ferner verarbeiten wir Ihre personenbezogenen Daten auf der Grundlage
          einer zuvor erteilten Einwilligung, um Sie über interessante
          Stellenangebote zu informieren (Rechtsgrundlage ist Art. 6 I lit. a
          DSGVO).{" "}
        </Typography>

        <br />

        <Typography variant="body1">
          Sofern Rechtsgrundlage Ihre Einwilligung ist, sind Sie berechtigt,
          Ihre Einwilligung jederzeit zu widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung berührt wird. Ist Rechtsgrundlage das
          berechtigte Interesse, so sind Sie ebenfalls grundsätzlich berechtigt,
          aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
          Daten Widerspruch einzulegen. Es gilt insofern Art. 21 DSGVO.
        </Typography>
        <Typography variant="body1">
          insofern Art. 21 DSGVO. Wir benötigen Ihre personenbezogenen Daten, um
          die oben aufgeführten Verarbeitungszwecke erfüllen zu können. Es steht
          Ihnen frei, uns keinerlei personenbezogene Daten zur Verfügung zu
          stellen. In diesem Fall können wir Ihnen die Teilnahme an dem
          Bewerbungsprozess auf offene Stellenangebote nicht ermöglichen.
        </Typography>
        <br />

        <Typography variant="h6">3. Weitergabe von Daten</Typography>

        <br />
        <Typography variant="body1">
          Ihre personenbezogenen Daten werden von uns grundsätzlich nur dann an
          Dritte gegeben, soweit dies zur Vertragserfüllung erforderlich ist,
          wir oder der Dritte ein berechtigtes Interesse an der Weitergabe
          haben, eine gesetzliche Verpflichtung besteht oder Ihre Einwilligung
          hierfür vorliegt. Sofern Daten an Dritte auf Basis eines berechtigten
          Interesses übermittelt werden, wird dies in diesen
          Datenschutzbestimmungen erläutert.
        </Typography>
        <br />

        <Typography variant="h6">
          4. Weitergabe von Daten in Nicht-EWR Länder
        </Typography>
        <br />
        <Typography variant="body1">
          In Ergänzung zu dem vorstehenden Absatz „Weitergabe Ihrer
          personenbezogenen Daten“ gilt Folgendes:{" "}
        </Typography>
        <br />
        <Typography variant="body1">
          Die Empfänger von personenbezogenen Daten können sich außerhalb des
          EWR/UK befinden. Wenn personenbezogene Daten an Standorte außerhalb
          des EWR/UK übermittelt werden, werden wir, wie gesetzlich
          vorgeschrieben, sicherstellen, dass Ihre Datenschutzrechte angemessen
          geschützt sind, entweder weil die Europäische Kommission entschieden
          hat, dass das Land, in das personenbezogene Daten übermittelt werden,
          ein angemessenes Schutzniveau gewährleistet (Art. 45 DSGVO) oder die
          Übermittlung unterliegt angemessenen Garantien (z. B.
          Standardvertragsklauseln) der Europäischen Union, die mit dem
          Empfänger vereinbart wurden (Art. 46 DSGVO), sofern nicht die DSGVO
          eine Ausnahme vorsieht (Art. 49 DSGVO). Darüber hinaus beabsichtigen
          wir, soweit erforderlich, zusätzliche Maßnahmen mit den Empfängern zu
          vereinbaren, um ein angemessenes Datenschutzniveau zu gewährleisten.
          Kopien der angemessenen Schutzmaßnahmen (soweit wir uns auf diese
          verlassen) und eine Liste der Empfänger außerhalb des EWR/des
          Vereinigten Königreichs können angefordert werden. Bitte beachten Sie,
          dass diese Kopien in dem Maße geschwärzt werden können, wie es zum
          Schutz von Geschäftsgeheimnissen oder anderen vertraulichen
          Informationen erforderlich ist.
        </Typography>

        <br />
        <Typography variant="h6">
          5. Speicherung und Löschung Ihrer Daten
        </Typography>
        <br />
        <Typography variant="body1">
          Wir speichern Ihre personenbezogenen Daten für die Zeit, die
          erforderlich ist, um die Zwecke zu erlangen, für die Daten erhoben und
          weiterverarbeitet werden, einschließlich jeglicher Aufbewahrungsfrist,
          die nach den geltenden Gesetzen erforderlich ist (z. B. Beibehaltung
          der Buchhaltungsunterlagen).
        </Typography>
        <br />
        <Typography variant="body1">
          Im Rahmen von Bewerbungsverfahren, die ohne Anstellung / Beschäftigung
          enden, werden Ihre Daten regelmäßig noch sechs Monate zu
          Dokumentationszwecken gespeichert und danach, ebenso wie die uns
          übersandten Unterlagen, gelöscht bzw. vernichtet. Mit Ihrer
          Einwilligung zur Verarbeitung der Daten für künftige
          Bewerbungsverfahren bewahren wir diese ebenso entsprechend länger auf.
        </Typography>
        <br />
      </Grid>
    </Grid>
  );
};

export default DataPolicy;
