import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton } from "@mui/material";
import { Language } from "@mui/icons-material";
import de from "../../../assets/img/de.png";
import en from "../../../assets/img/en.png";
import { DE, EN, Lookup_Query_String } from "../../../i18n/CONSTANTS";
import * as colors from "../../../style/colors";
import i18n from "i18next";

export const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = (lang: string) => {
    localStorage.setItem(Lookup_Query_String, lang);
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ paddingLeft: 2 }}>
      <IconButton size="medium" onClick={handleClick} sx={{bgcolor: colors.smartAgentGreen20pct}}>
        <Language />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleLanguage(DE)}>
          <img src={de} alt="de" />
        </MenuItem>
        <MenuItem onClick={() => handleLanguage(EN)}>
          <img src={en} alt="en" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageMenu;
