import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface InfoTextProps {
  labelText: string;
  bodyText: string;
}

const InfoText = ({ labelText, bodyText }: InfoTextProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography className={classes.head}>
        {t(labelText)}
      </Typography>
      <Typography className={classes.body}>
        {t(bodyText)}
      </Typography>
    </Grid>
  );
};

export default InfoText;
