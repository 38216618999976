import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from "i18next-http-backend";
import translationEN from '../i18n/translations/en.json';
import translationDE from '../i18n/translations/de.json';
import {DE, EN, Lookup_Query_String} from "./CONSTANTS";

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: Lookup_Query_String
}
const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        fallbackLng: DE,
        supportedLngs: [DE, EN],
        debug: false,
        keySeparator: false
    });

