import {
  CardMedia,
  Grid,
  Button,
  Box,
  Badge,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Alert from "react-s-alert";
import planzen from "../../../assets/img/planzen.png";
import enpal from "../../../assets/img/enpal.png";
import gray from "../../../assets/img/gray.png";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

export interface SpecialOffersProps {
  title: string;
  body: string;
  image: string;
  badge: string;
}

export const SpecialOffersCard = (props: SpecialOffersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let imageSource;

  if (props.image === "planzen") {
    imageSource = planzen;
  } else if (props.image === "gray") {
    imageSource = gray;
  } else if (props.image === "enpal") {
    imageSource = enpal;
  }

  const handleClick = () => {
    Alert.info( `${t("error.notYetAvailable")}` );
  };
  return (
    <Box>
      <Badge badgeContent={props.badge} color="error" invisible={props.badge === ""} classes={{ badge: classes.badge }}>
        <Grid container sx={{ position: "relative !important" }} className={classes.card}>
          <Grid item xs={4} display="flex">
            <CardMedia component="img" image={imageSource} className={classes.logoImage} />
          </Grid>
          <Grid item container xs={8} sx={{ padding: "16px" }}>
            <Grid item xs={12} className={classes.title}>
              {props.title}
            </Grid>
            <Grid item xs={12} className={classes.offer}>
              {props.body}
            </Grid>
            <Grid item xs={12} className={classes.buttonBox}>
              <Button className={classes.button} onClick={handleClick}>
                { t("main.dashboard.offer.view") }
                <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Badge>
    </Box>
  );
};
