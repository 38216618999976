import React from "react";
import { FooterHeight, useStyles } from "./styles";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  LOGIN,
} from "../../routes/ROUTES";
import homeImg from "../../assets/img/home_logo.png";

export const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
  fontSize: 15,
  background: theme.palette.secondary.main,
  boxShadow:
    "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
  padding: "8px 22px",
  borderRadius: 0,
  transition: "background-color 0.2s ease",
  "&:hover": {
    background: "rgba(154, 244, 48, 0.8)",
  },
}));

export const Footer = () => {
  const { t } = useTranslation();

  const navigationItems = [
    { key: "contact", text: t("main.contact"), url: "https://www.smart-agent.eu/contact/" },
    { key: "data_policy", text: t("main.privacyPolicy"), url: "https://www.smart-agent.eu/datapolicy/" },
    { key: "disclaimer", text: t("main.disclaimer"), url: "https://www.smart-agent.eu/disclaimer/" },
    { key: "cookies", text: t("main.cookiePolicy"), url: "https://www.smart-agent.eu/cookies/" },
    { key: "imprint", text: t("main.imprint"), url: "https://www.smart-agent.eu/imprint/" },
  ];

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: FooterHeight,
      }}
      component="footer"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {navigationItems.map((item) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              onClick={() => window.open(item.url, "_blank")}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <ListItemText>
                <Typography variant="body2" color="white">
                  {item.text}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
      <Typography variant="body2" color="#687298" align="center">
        {" © "}
        {new Date().getFullYear()}
        {" Smart Agent GmbH"}
      </Typography>
    </Box>
  );
};

const HomePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const email = "info@smart-agent.eu";
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");

  const HomeImgContainer = () => {
    return (
      <Paper className={classes.card}>
        <CardMedia
          sx={{ maxWidth: isLargeScreen ? "100%" : "400px" }}
          component="img"
          alt="Home Image"
          image={homeImg}
        />
      </Paper>
    );
  };

  const MainContent = () => {
    return (
      <>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Typography
            variant="homeH1"
            sx={{ fontSize: isLargeScreen ? "36px" : "20px" }}
          >
            {t("landing.page.title")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mb: 4 }}>
          <Typography
            variant="homeBody1"
            sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
          >
            {t("landing.page.description 1")}
          </Typography>
        </Box>
        <Typography
          variant="homeBody1"
          sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
        >
          {t("landing.page.description 2")}
        </Typography>
        <Box sx={{ ml: 2 }}>
          <li>
            <Typography
              variant="homeBody2"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 3")}
            </Typography>
            &nbsp;
            <Typography
              variant="homeBody1"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 4")}
            </Typography>
          </li>
          <li>
            <Typography
              variant="homeBody2"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 5")}
            </Typography>
            &nbsp;
            <Typography
              variant="homeBody1"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 6")}
            </Typography>
          </li>
          <li>
            <Typography
              variant="homeBody2"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 7")}
            </Typography>
            &nbsp;
            <Typography
              variant="homeBody1"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 8")}
            </Typography>
          </li>
          <li>
            <Typography
              variant="homeBody2"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 9")}
            </Typography>
          </li>
        </Box>
      </>
    );
  };

  const ContactDetails = () => {
    return (
      <>
        <Grid item xs={10}>
          <Typography
            variant="homeBody3"
            sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
          >
            {t("landing.page.description 10")}
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Typography
            variant="homeBody1"
            sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
          >
            {t("landing.page.description 11")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="homeBody1"
              sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
            >
              {t("landing.page.description 12")}
            </Typography>
            &nbsp;
            <Link href={`mailto:${email}`} color="primary">
              <Typography
                variant="homeBody2"
                sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
              >
                {email}
              </Typography>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={10} sx={{ mt: 4 }}>
          <Typography
            variant="homeBody2"
            sx={{ fontSize: isLargeScreen ? "20px" : "16px" }}
          >
            {t("landing.page.description 13")}
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ pt: 2, pb: 2 }}>
          <PrimaryButton onClick={() => navigate(LOGIN)}>
            {t("landing.page.description.login")}
          </PrimaryButton>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.mainHomeContainer}>
      {/* Main Content */}
      <Grid item xs={12} style={{ flex: 1 }}>
        {isLargeScreen ? (
          <Grid container>
            <Grid item xs={6} className={classes.container}>
              <MainContent />
            </Grid>
            <Grid item xs={6} sx={{ pr: 4 }}>
              <HomeImgContainer />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ pr: 4, pl: 4, justifyContent: "center", display: "flex" }}
            >
              <HomeImgContainer />
            </Grid>
            <Grid item xs={12} sx={{ pl: "16px", pr: "16px" }}>
              <MainContent />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              pl: isLargeScreen ? "92px" : "16px",
              mt: 4,
            }}
          >
            <ContactDetails />
          </Grid>
        </Grid>
      </Grid>

      {/* Fixed Footer */}
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </div>
  );
};
export default HomePage;
