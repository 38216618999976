import { Card, CardMedia, CardContent, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import placeholder from "../../../../assets/img/placeholder-image.jpeg";
import { RelativeObject } from "../../../../context/ObjectContext";
import { useTranslation } from "react-i18next";
import { numericFormatter } from "react-number-format";

const ObjectCard = ({ relativeObject }: { relativeObject: RelativeObject }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rent, address, distance, size, rooms, tintColor } = relativeObject;
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia} image={placeholder} sx={{ backgroundColor: tintColor }} />
      <CardContent sx={{ padding: `${0} !important` }}>
        <Grid
          item
          sx={{
            backgroundColor: "#171E3B29",
            width: "100%",
            paddingLeft: "16px",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {numericFormatter(
              rent.toString(), {
                thousandSeparator: ".",
                suffix: " €",
                decimalScale: 0,
              }
            )}
            {t("object.rent")}
          </Typography>
        </Grid>
        <Grid item container spacing={2} sx={{ padding: "16px" }}>
          <Grid container item>
            <Grid item>
              <Typography
                variant="h6"
                className={classes.locationLabelDistance}
                gutterBottom
              >
                {address} {t("object.distance")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body" className={classes.locationLabelAddress}>
                {distance}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.rowLabelContainer}>
            <Grid item>
              <Typography variant="h6">{size}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {rooms}
                {t("object.room")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObjectCard;
