import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import InfoText from "../../Customs/InfoText";
import { useState } from "react";
import { numericFormatter } from "react-number-format";

interface Props {
  rent: number;
  isRentControl: boolean;
}

export const RecommendedRent: React.FC<Props> = ({ rent, isRentControl }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  return (
    <Grid container direction="column">
      <Grid item>
        <InfoText
          labelText={t("info.label.rent")}
          bodyText={t("info.label.rent.body")}
        />
      </Grid>

      <Grid item container className={classes.gridContainer} mt={4} mb={4}>
        <Box className={classes.boxContainer}>
          <Typography variant="h4" className={classes.fontStyle}>
            {numericFormatter(
                  rent.toString(), {
                    thousandSeparator: ".",
                    suffix: " €",
                    decimalScale: 0,
                  }
                )}
            {t("recommended.cold.rent")}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box className={classes.isRentControlContainer}>
          <Alert severity="info" variant="standard" className={classes.alert}>
            {isRentControl ? (
              <>
                <AlertTitle>
                  <strong>
                    {t("recommended.rental.price.break.apply")}{" "}
                    <Typography
                      component="span"
                      className={classes.clickableText}
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? t("recommended.rental.price.less") : t("recommended.rental.price.more")}
                    </Typography>
                  </strong>
                </AlertTitle>

                {showMore ? (
                  <>
                    <Typography gutterBottom className={classes.textItem} pt={2}>
                      <b>{t("mietpreisbremse.was.bedeuet")}</b>
                      <br />
                      {t("mietpreisbremse.explanation")}
                    </Typography>

                    <Typography className={classes.textItem}>
                      {t("mietpreisbremse.was.follow.up")}
                    </Typography>
                    <ListItem className={classes.listItem} disablePadding>
                      {t("mietpreisbremse.list.item.a")}
                    </ListItem>
                    <ListItem className={classes.listItem} disablePadding>
                      {t("mietpreisbremse.list.item.b")}
                    </ListItem>
                    <ListItem className={classes.listItem} disablePadding>
                      {t("mietpreisbremse.list.item.c")}
                    </ListItem>
                    <ListItem className={classes.listItem} disablePadding>
                      {t("mietpreisbremse.list.item.d")}
                    </ListItem>
                    <ListItem className={classes.listItem} disablePadding>
                      {t("mietpreisbremse.list.item.e")}
                    </ListItem>
                    <Typography gutterBottom />

                    <Typography className={classes.textItem} gutterBottom>
                      {t("mietpreisbremse.final.text")}
                    </Typography>
                  </>
                ) : null}
              </>
            ) : (
              <AlertTitle>
                <strong>{t("recommended.rental.price.break.not.apply")}</strong>
              </AlertTitle>
            )}
          </Alert>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecommendedRent;
