import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";
import {
  getAllObjects,
  updateRentalStatus,
} from "../../services/rentalHandler";
import { CustomerDashboardContext } from "../../context/CustomerDashboardContext";
import Alert from "react-s-alert";
import { useTranslation } from "react-i18next";
import { RentalObject, RentalStatus } from "../../types/RentalObject";
import {
  CALENDER,
  CONTRACT_DETAILS,
  DASHBOARD,
  NEWS,
  OBJECT_DASHBOARD,
  OBJECTS_PAGE,
  PROFILE,
  SPECIAL_OFFERS,
} from "../../routes/ROUTES";

import DateRangeIcon from "@mui/icons-material/DateRange";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeIcon from "@mui/icons-material/Home";
import GridViewIcon from '@mui/icons-material/GridView';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HouseIcon from '@mui/icons-material/House';
import AddBoxIcon from '@mui/icons-material/AddBox';

import * as colors from "../../style/colors";

export const SideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [objects, setObjects] = useState<RentalObject[]>([] as any);

  useEffect(() => {
    getAllObjects()
      .then((res) => {
        let arr = res?.slice(0, 5);
        setObjects(arr);
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
      });
  }, [t]);

  return (
    <Box className={classes.sideBar}>
      <List disablePadding>
        <ListItem 
          key="start_page" 
          disablePadding 
          sx={{
            bgcolor: colors.smartAgentGreen,
            padding: "0",
          }}
        >
          <ListItemButton onClick={() => navigate(DASHBOARD)} sx={{ padding: "16px" }}>
            <ListItemIcon className={classes.iconSpacer}>
              <HomeIcon className={classes.largeIcon} />
            </ListItemIcon>
            <ListItemText
              primary={t("main.home")}
              className={classes.primaryNav}
              disableTypography
            />
          </ListItemButton>
        </ListItem>


        <ListItem 
          key="objects" 
          className={classes.primaryNavWithSubtitle}
          sx={{paddingTop: "16px"}}
        >
          <ListItemText
            primary={t("main.propertyDashboards")}
            className={[classes.primaryNav, classes.primaryNavWithSubtitle].join(" ")}
            disableTypography
          />
        </ListItem>
        <ListItem key="object_subtitle" className={classes.noVerticalPadding}>
          <ListItemText
            primary={
              Object.keys(objects).length > 0 ? 
                (
                  Object.keys(objects).length === 1 ?
                    "1 " + t("main.menu.Property")
                  :
                    Math.min(Object.keys(objects).length, 5) + t("main.menu.ofProperties") + Object.keys(objects).length + t("main.menu.Properties")
                )
                : 
                t("main.menu.noProperties")
              }
            className={classes.subtitle}
            disableTypography
          />
        </ListItem>

        <ListItem key="spacer1" />

        {Object.keys(objects).length > 0 && objects.map((property) => (
          <List key={property.id} disablePadding>
            <ListItem key={property.id + "_property"} disablePadding>
              <ListItemButton 
                onClick={() => navigate(`${OBJECT_DASHBOARD}/${property.id}`)}
                className={classes.objectButton}
              >
                <ListItemIcon className={classes.iconSpacer}>
                  {property.estateType === "644a4d95d411b0cdfb777942" ?
                      <HouseIcon className={classes.largeIcon} />
                    :
                      <ApartmentIcon className={classes.largeIcon} />
                  }
                </ListItemIcon>
                <ListItemText
                  primary={property.name}
                  className={[classes.primaryNav, classes.objectButton].join(" ")}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>
            <ListItem 
              key={property.id + "_status"}
              className={classes.noVerticalPadding} 
              sx={{
                marginTop: "-4px",
                paddingLeft: "29px",
              }}
            >
              <ListItemButton 
                onClick={() => navigate(`${OBJECT_DASHBOARD}/${property.id}`)}
                className={classes.objectButton}
                
              >
                <ListItemText
                  primary={t("main.menu.propertyStatus." + property.status)}
                  className={classes.subtitle}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>
          </List>
        ))}

        {Object.keys(objects).length < 1 && 
          <ListItem key="add_object" disablePadding>
            <ListItemButton onClick={() => navigate(OBJECTS_PAGE)}>
              <ListItemIcon className={classes.iconSpacer}>
                <AddBoxIcon className={classes.largeIcon} />
              </ListItemIcon>
              <ListItemText
                primary={t("main.menu.createProperty")}
                className={classes.primaryNav}
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        }

        <ListItem key="spacer2" />

        <ListItem key="all_objects" disablePadding>
          <ListItemButton onClick={() => navigate(OBJECTS_PAGE)}>
            <ListItemIcon className={classes.iconSpacer}>
              <GridViewIcon className={classes.largeIcon} />
            </ListItemIcon>
            <ListItemText
              primary={t("main.propertyList")}
              className={classes.primaryNav}
              disableTypography
            />
          </ListItemButton>
        </ListItem>

        <Divider key="divider1" className={classes.whiteDivider}/>

        {[
            { label: "main.calendar", icon: <DateRangeIcon className={classes.largeIcon} />, route: CALENDER },
            { label: "main.documents", icon: <ContentCopyOutlinedIcon className={classes.largeIcon} />, route: CALENDER },
            { label: "main.newsGuides", icon: <PercentOutlinedIcon className={classes.largeIcon} />, route: NEWS },
            { label: "main.specialOffers", icon: <FormatAlignLeftOutlinedIcon className={classes.largeIcon} />, route: SPECIAL_OFFERS },
          ].map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton onClick={() => navigate(item.route)}>
              <ListItemIcon className={classes.iconSpacer}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(item.label)}
                className={classes.primaryNav}
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider key="divider2" className={classes.whiteDivider}/>

        <ListItem key="main_settings">
          <ListItemText
            primary={t("main.settings")}
            className={classes.primaryNav}
            disableTypography
          />
        </ListItem>
        {[
          { label: "main.profile", icon: <PersonIcon className={classes.smallIcon} />, route: PROFILE },
          { label: "main.contractDetails", icon: <DescriptionOutlinedIcon className={classes.smallIcon} />, route: CONTRACT_DETAILS },
        ].map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.route)}
              className={classes.noVerticalPadding}
            >
              <ListItemIcon className={classes.iconSpacer}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(item.label)}
                className={classes.secondaryNav}
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider key="divider3" className={classes.whiteDivider}/>


        <ListItem key="main_about">
          <ListItemText
            primary={t("main.about")}
            className={classes.primaryNav}
            disableTypography
          />
        </ListItem>
        {[
          { label: "main.contact", link: "https://www.smart-agent.eu/contact/" },
          { label: "main.privacyPolicy", link: "https://www.smart-agent.eu/datapolicy/" },
          { label: "main.imprint", link: "https://www.smart-agent.eu/imprint/" },
          { label: "main.cookiePolicy", link: "https://www.smart-agent.eu/cookies/" },
          { label: "main.disclaimer", link: "https://www.smart-agent.eu/disclaimer/" },
        ].map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              onClick={() => window.open(item.link, "_blank")}
              className={classes.noVerticalPadding}
            >
              <ListItemText
                primary={t(item.label)}
                className={classes.secondaryNav}
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        ))}

      </List>
    </Box>
  );
};

interface SecondaryButtonProps {
  label: string;
  onClick: () => void;
}
export const SecondaryButton = ({ label, onClick }: SecondaryButtonProps) => {
  const classes = useStyles();
  const handleOnClick = () => {
    onClick();
  };
  return (
    <Button className={classes.secondaryButton} onClick={handleOnClick}>
      {label}
    </Button>
  );
};

interface DeactivateButtonProps {}

// eslint-disable-next-line no-empty-pattern
export const DeactivateButton = ({}: DeactivateButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rentalObject, setRentalObject } = useContext(
    CustomerDashboardContext
  );
  const [status, setStatus] = useState<RentalStatus>(rentalObject.status);
  const [currentStatus, setCurrentStatus] = useState<RentalStatus>(
    rentalObject.status
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeactivate = (status: RentalStatus) => {
    setCurrentStatus(status);
    setOpenDialog(true);
    handleClose();
  };

  const handleCancel = () => {
    setCurrentStatus(status);
    setOpenDialog(false);
  };

  const handleConfirmDeactivate = () => {
    setLoading(true);
    updateRentalStatus(rentalObject.id, currentStatus)
      .then(() => {
        setStatus(currentStatus);
        setRentalObject({ ...rentalObject, status: currentStatus });
        setLoading(false);
        setOpenDialog(false);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error((err && err.message) || t("error"));
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="contained"
        endIcon={<ArrowDropDown />}
        sx={{
          justifyContent: "space-between",
          "&:hover": {
            color: "#FFFFFF",
          },
        }}
        onClick={handleClick}
        className={classes.button}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "0" }}>
            {status === "publish_expose"
              ? t("customer.dashboard.active.button.created")
              : t("customer.dashboard.active.button.disabled")}
          </span>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            paddingTop: "0",
            paddingBottom: "0",
            //mt: 1,
            //minWidth: 120,
            /*"&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              zIndex: 0,
            },*/
          },
        }}
      >
        {status !== "publish_expose" ? (
          <MenuItem onClick={() => handleDeactivate("publish_expose")}>
            {t("customer.dashboard.active.button.enable")}
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleDeactivate("create_expose")}>
            {t("customer.dashboard.active.button.disable")}
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleCancel}
        className={classes.paper}
      >
        <DialogTitle>
          <Typography variant="body" sx={{fontSize: "20px"}}>
            {currentStatus === "publish_expose"
              ? t("customer.dashboard.active.object.dialog.title")
              : t("customer.dashboard.inactive.object.dialog.title")}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="body">
            {currentStatus === "publish_expose"
              ? t("customer.dashboard.active.object.dialog.subTitle")
              : t("customer.dashboard.inactive.object.dialog.subTitle")}
          </Typography>
        </DialogContent>
        <DialogActions sx={{paddingRight: "16px", paddingBottom: "16px"}}>
          <Button
            onClick={handleCancel}
            color="primary"
            sx={{ textTransform: "none", boxShadow: "none !important", marginRight: "8px" }}
            className={[classes.button, classes.buttonVariant].join(" ")}
          >
            {t("customer.dashboard.inactive.object.dialog.cancel")}
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleConfirmDeactivate}
              color="primary"
              variant="contained"
              sx={{ textTransform: "none" }}
              className={classes.button}
            >
              {t("customer.dashboard.inactive.object.dialog.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
