import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const FooterHeight = "100px";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    minHeight: "100%",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E7E7E7B2",
  },
  container: {
    backgroundColor: theme.palette.primary.light,
    backgroundBlendMode: "multiply",
    minHeight: "100%",
  },
  signUpContainer: {
    backgroundColor: "#F5F7FA",
    opacity: 0.98,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: "30%",
    display: "flex",
    alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  redirectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  loginButton: {
    paddingBottom: theme.spacing(3),
  },
  passwordSpacer: {
    paddingBottom: theme.spacing(3),
  }
}));
