import {
  Grid,
  Box,
  Typography,
  Rating,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  InputLabel,
} from "@mui/material";
//import Alert from "react-s-alert";
import { useStyles } from "./styles";
import React, { useState, useEffect } from "react";
import {
  getIncomeCategories,
  getBooleanCategories,
} from "../../../services/entitiyHandler";
import { Entities } from "../../Expose/Form/types";
import { getApplicationStatuses } from "../../../services/entitiyHandler";
import i18next from "i18next";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_APPLICATION_PREVIEW } from "../../../routes/ROUTES";
import MoneyIcon from '@mui/icons-material/Money';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';


interface RatingProps {
  customerId: string;
  rating: string;
  manipulateRating: (newValue: string) => void;
}

const RatingController = ({ rating, manipulateRating }: RatingProps) => {
  const classes = useStyles();
  const numberRating = parseFloat(rating);
  const [value, setValue] = useState<number | null>(numberRating);
  return (
        <Rating
          className={classes.starColor}
          value={Number(value)}
          onChange={(event, newValue) => {
            setValue(newValue);
            manipulateRating(newValue !== null ? newValue.toString() : "0");
          }}
          size="medium"
        />
  );
};

interface NotesProps {
  customerId: string;
  notes: string;
  manipulateNotes: (newValue: string) => void;
}

const NotesController = ({ notes, manipulateNotes }: NotesProps) => {
  const [value, setValue] = useState<string>(notes);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    manipulateNotes(newValue);
  };

  return (
    <Grid display="flex">
      <TextField
        type="text"
        variant="outlined"
        multiline
        fullWidth
        size="small"
        value={value}
        onChange={handleChange}
        label={`${t("customer.dashboard.notes")}`}
        minRows={2}
        sx={{
          "& textarea": {
            fontSize: "14px",
          },
          "& label:not(.MuiInputLabel-shrink)": {
            fontSize: "14px",
          },
        }}
      />
    </Grid>
  );
};

interface CheckboxProps {
  customerId: string;
  hasChecked: boolean;
  manipulateCheckbox: (newValue: boolean) => void;
}

const CheckboxController = ({
  hasChecked,
  manipulateCheckbox,
}: CheckboxProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<boolean>(hasChecked);

  const handleChange = () => {
    const newValue = !value;
    setValue(newValue);
    manipulateCheckbox(newValue);
  };

  return (
    <Grid display="flex" className={classes.textColorWhite}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            className={classes.textColorWhite}
            checked={value}
            onChange={() => handleChange()}
          />
        }
        label={
          <Typography className={[classes.textColorWhite, classes.actionStuff].join(",")} sx={{fontSize: "14px !important"}}>
            {t("customer.dashboard.appointment.made.for.viewing")}
          </Typography>
        }
      />
    </Grid>
  );
};

interface DropdownProps {
  selection: string;
}

const DropdownController = ({ selection }: DropdownProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>("");
  const lang = i18next.language;

  const [applicationStatuses, setApplicationStatuses] = useState<Entities[]>(
    []
  );
  useEffect(() => {
    getApplicationStatuses().then((response) => {
      setApplicationStatuses(response);
    });
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };
  return (
    <Grid container display="flex" flexDirection="column" spacing={0.5}>
      <Grid item>
        <FormControl
          className={classes.select}
          fullWidth size="small"
          sx={{
            "& label:not(.MuiInputLabel-shrink)": {
              fontSize: "14px",
            },
          }}
        >
          <InputLabel>{t("customer.dashboard.next.step")}</InputLabel>
          <Select
            value={value}
            onChange={handleChange}
            className={classes.select}
            displayEmpty
            label={t("customer.dashboard.next.step")}
            sx={{height: "38px"}}
          >
            {applicationStatuses.map((item, index) => {
              return (
                <MenuItem disabled value={item.id} key={index} className={[classes.actionStuff].join(",")}>
                  {lang === "en"
                    ? item.name_en
                    : lang === "de"
                    ? item.name_de
                    : ""}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};


interface CardProps {
  id: string;
  photo: File | null;
  firstName: string;
  lastName: string;
  adultCount: string;
  childCount: string;
  smoker: string;
  netHouseHoldIncome: string;
  phoneNumber: string;
  email: string;
  rating: string;
  notes: string;
  hasChecked: boolean;
  selection: string;
  manipulateRating: (newValue: string) => void;
  manipulateNotes: (newValue: string) => void;
  manipulateCheckbox: (newValue: boolean) => void;
}

const CustomerCard = (props: CardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [photoURL, setPhotoURL] = useState<string | null>();
  const [incomeCategories, setIncomeCategories] = useState<Entities[]>([]);
  const [isSmoking, setIsSmoking] = useState<Entities[]>([]);


  useEffect(() => {
    if (props.photo) {
      const photo = props.photo as any;
      setPhotoURL(`data:image/jpeg;base64,${photo["image"]["data"]}`);
    } else {
      setPhotoURL(null);
    }
  }, [props.photo]);

  const handleClick = () => {
    navigate(`${CUSTOMER_APPLICATION_PREVIEW}/${props.id}`);
  };

  useEffect(() => {
    getIncomeCategories().then((response) => {
      setIncomeCategories(response);
    });
    getBooleanCategories().then((response) => {
      setIsSmoking(response);
    });
  }, []);

  const getIncomeCategoryValue = (categoryId: string) => {
    const category = incomeCategories.find(
      (category) => category.id === categoryId
    );
    return category ? category.value_1 : "";
  };

  const getSmokerValue = (categoryId: string) => {
    const category = isSmoking.find((category) => category.id === categoryId);
    return category ? category.value_1 : "";
  };

  return (
    <TableRow>
      <TableCell>
        <Grid container sx={{alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
          <Avatar
            src={photoURL ? photoURL?.toString() : ""}
            sx={{ width: "96px", height: "96px", border: "1px solid rgba(91, 164, 58, 1.0)" }}
          />
          <RatingController
            customerId={props.id}
            rating={props.rating}
            manipulateRating={props.manipulateRating}
          />
        </Grid>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <Box className={classes.title}>{props.firstName} {props.lastName}</Box>
          <Box className={classes.subTitle}>
            {props.adultCount}&nbsp;{parseInt(props.adultCount) === 1 ? t("customer.dashboard.adult") : t("customer.dashboard.adults")}
            {parseInt(props.childCount) > 0 && (
              <>
                {" / "}
                {props.childCount}&nbsp;{parseInt(props.childCount) === 1 ? t("customer.dashboard.child") : t("customer.dashboard.children")}
              </>
            )}
          </Box>
          <Box>
            <Chip
              sx={{
                backgroundColor: "rgba(91, 164, 58, 1.0)",
                color: "#ffffff",
                marginTop: "16px",
              }}
              className={classes.statusChip}
              label={t("customer.dashboard.view.application.form")}
              onClick={handleClick}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          {props.netHouseHoldIncome && (
            <Box className={classes.detailInfos}><MoneyIcon /> {getIncomeCategoryValue(props.netHouseHoldIncome)}</Box>
          )}
          {props.smoker && (
            <Box className={classes.detailInfos}>
              {getSmokerValue(props.smoker) === "yes" ? (
                <>
                  <SmokingRoomsIcon /> {t("customer.dashboard.smoker")}
                </>
              ) : (
                <>
                  <SmokeFreeIcon /> {t("customer.dashboard.non.smoker")}
                </>
              )}
            </Box>
          )}
          <Box className={classes.detailInfos}><AlternateEmailIcon /> {props.email}</Box>
          {props.phoneNumber && (
            <Box className={classes.detailInfos}><PhoneIcon /> {props.phoneNumber}</Box>
          )}
        </Box>
      </TableCell>
      <TableCell>
            <NotesController
              customerId={props.id}
              notes={props.notes}
              manipulateNotes={props.manipulateNotes}
            />
            <CheckboxController
              customerId={props.id}
              hasChecked={props.hasChecked}
              manipulateCheckbox={props.manipulateCheckbox}
            />
            <DropdownController selection={props.selection} />
      </TableCell>
    </TableRow>
  );
};

export default CustomerCard;
