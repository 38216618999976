import Menu from "@mui/material/Menu";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { ESTATE } from "../../../routes/ROUTES";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { RentalObject } from "../../../types/RentalObject";
import { useTranslation } from "react-i18next";
import { deleteRental } from "../../../services/rentalHandler";
import Alert from "react-s-alert";

interface Props {
  object: RentalObject;
  reload: () => void;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}
const ObjectActions = ({ object, reload, anchorEl, setAnchorEl }: Props) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenuItem = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDeleting(false);
  };

  const handleObjectDelete = () => {
    setDeleting(true);
    deleteRental(object.id)
      .then(() => {
        setDeleting(false);
        setOpenDialog(false);
        reload();
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
        setDeleting(false);
        setOpenDialog(false);
        reload();
      });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() =>
            navigate(`${ESTATE}/${object.id}`, {
              state: { object, step: 2 },
            })
          }
        >
          {t("customer.dashboard.object.dropdown.change.data")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`${ESTATE}/${object.id}`, {
              state: { object, step: 0 },
            })
          }
        >
          {t("customer.dashboard.object.dropdown.calculate.rent")}
        </MenuItem>
        <MenuItem onClick={handleDeleteMenuItem}>
          {t("customer.dashboard.object.dropdown.delete.object")}
        </MenuItem>
      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {t("customer.dashboard.object.dropdown.delete.object")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${t(
              "customer.dashboard.object.dropdown.delete.dialog.confirmation"
            )}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="outlined">
            {t(
              "customer.dashboard.object.dropdown.delete.dialog.decline.button"
            )}
          </Button>
          {deleting ? (
            <CircularProgress sx={{ marginLeft: 4 }} />
          ) : (
            <Button onClick={handleObjectDelete} autoFocus variant="contained">
              {t(
                "customer.dashboard.object.dropdown.delete.dialog.confirmation.button"
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ObjectActions;
