import {
  Grid,
  Button,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useStyles } from "./styles";
import { activeStatuses, RentalObject } from "../../../types/RentalObject";
import React from "react";
import { ESTATE, OBJECT_DASHBOARD } from "../../../routes/ROUTES";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { statuses } from "./ObjectCard";
import { MoreVert } from "@mui/icons-material";
import ObjectActions from "./ObjectActions";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { numericFormatter } from "react-number-format";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import * as colors from "../../../style/colors";

interface Props {
  objects: RentalObject[];
  reload: () => void;
}

export const ObjectTable = ({ objects, reload }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const goToNextStep = (object: RentalObject) => {
    switch (object.status) {
      case "create_object":
        navigate(`${ESTATE}/${object.id}`, {
          state: { object, step: 2 },
        });
        break;
      case "create_expose":
        navigate(`${ESTATE}/${object.id}`, {
          state: { object, step: 3 },
        });
        break;
    }
  };

  const handleCardClick = (object: RentalObject) => {
    navigate(`${OBJECT_DASHBOARD}/${object.id}`);
  };

  const getNextStatus = (object: RentalObject): string => {
    if (object?.status) {
      const currentIndex = statuses.findIndex(
        (item) => item.status === object?.status
      );
      const nextIndex = (currentIndex + 1) % statuses.length;
      return t(statuses[nextIndex]?.label);
    }
    return "";
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box className={classes.card} sx={{ width: "100%" }}>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableBody>
              {objects.map((object) => (
                <TableRow
                  key={object.id}
                  className={classes.tableRow}
                >
                  <TableCell onClick={() => handleCardClick(object)}>
                    <Box display="flex" flexDirection="column">
                      <Box className={classes.title}>{object.name}</Box>
                      <Box className={classes.subTitle}>{object.address}</Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => handleCardClick(object)}
                  >
                    <Box
                      className={classes.priceFont}
                    >{
                      numericFormatter(
                        object.totalRent.toString(), {
                          thousandSeparator: ".",
                          suffix: " €",
                          decimalScale: 0,
                        }
                      )
                    }</Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => handleCardClick(object)}
                  >
                    <Box
                      className={classes.priceFont}
                    >{
                      numericFormatter(
                        object.rooms.toString(), {
                          thousandSeparator: `${t("numbers.thousandSeparator")}`,
                          decimalSeparator: `${t("numbers.decimalSeparator")}`,
                        }
                      ) + 
                      " " + (object.rooms === 1 ? t("customer.dashboard.room") : t("customer.dashboard.rooms")) }</Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => handleCardClick(object)}
                  >
                    <Box className={classes.priceFont}>{
                      numericFormatter(
                        object.area.toString(), {
                          thousandSeparator: `${t("numbers.thousandSeparator")}`,
                          decimalScale: 0,
                        }
                      ) + " " + t("customer.dashboard.area")
                    }</Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleCardClick(object)}
                  >
                    <Chip
                      sx={{
                        backgroundColor: activeStatuses.includes(object.status)
                          ? colors.smartAgentGreen
                          : "#E0E0E0",
                        color: activeStatuses.includes(object.status)
                          ? colors.lightTextPrimary
                          : colors.darkTextPrimary,
                      }}
                      className={classes.statusChip}
                      label={activeStatuses.includes(object.status) ? t("customer.dashboard.object.active.expose") : t("customer.dashboard.object.inactive.expose") } 
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.currentStatusCell}
                    onClick={() => goToNextStep(object)}
                  >
                    <Box>
                      <Box className={classes.nextStatusFont}>
                        {t("customer.dashboard.object.next.step")}
                      </Box>
                      <Box className={classes.statusFont}>
                        {getNextStatus(object)}
                        <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.actionCell}
                    onClick={handleMenuOpen}
                  >
                    <MoreVert />
                  </TableCell>
                  <ObjectActions
                    object={object}
                    reload={reload}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container mt={3} sx={{ justifyContent: "flex-end" }}>
        <Button className={classes.button} onClick={() => navigate(ESTATE)}>
          <AddBoxIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
          {t("customer.dashboard.object.create.new.rental")}
        </Button>
      </Grid>
    </>
  );
};
