import React, { useContext, useEffect } from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";
import { SideBarContext } from "../../context/SideBarContext";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";

const NotImplemented = () => {
  const classes = useStyles();
  const { setVisibility } = useContext(SideBarContext);
  useEffect(() => {
    setVisibility(true);
  }, [setVisibility]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
          className={classes.main}
        >
          <Grid item>
            <EngineeringOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h2" style={{ fontSize: 32 }}>
              This page is still being developed
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotImplemented;
