export const lightNavy = "#4f5b76";
export const navy = "#0a1929";
export const darkNavy = "#000a17";

export const lightGreen = "#ffff62";
export const green = "#9af430";
export const darkGreen = "#6ba100";

export const lightGray = "#5a6d9d";
export const gray = "#213d6f";
export const darkGray = "#001541";
export const lightGrayBackground = "#e7e7e7";

export const darkRed = "#AB003C";
export const darkRed10pct = "#e6cfd7";

export const white = "#fff";

export const black = "#000";

export const smartAgentGreen = "rgba(91, 164, 58, 1.0)";
export const smartAgentGreen20pct = "rgba(91, 164, 58, 0.2)";
export const smartAgentNeonGreen = "#9af430";
export const smartAgentLightGreen = "#96BB2C";
export const smartAgentBlue = "rgb(34, 49, 107, 1.0)";
export const smartAgentBlue20pct = "rgb(34, 49, 107, 0.2)";
export const smartAgentBlue20pctNoAlpha = "#d2d6e2";
export const smartAgentDarkBlue = "#0A1929";
export const smartAgentChatbotBlue = "#454B62";

export const lightTextPrimary = "rgba(255, 255, 255, 1.0)";
export const lightTextSecondary = "rgba(255, 255, 255, 0.6)";
export const lightLine = "rgba(255, 255, 255, 0.15)";

export const darkTextPrimary = "rgba(16, 21, 41, 1.0)";
export const darkTextSecondary = "rgba(16, 21, 41, 0.6)";
export const darkLine = "rgba(16, 21, 41, 0.15)";

export const cardBorderRadius = "8px";

export const fontSizeBig = 24;
export const fontSizeBigger = 18;
export const fontSizeRegular = 16;
export const fontSizeIntermediate = 14;
export const fontSizeSmall = 13;
export const fontWeightRegular = 400;
export const fontWeightBold = 700;

export const cardShadow = "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)";