import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    background: colors.smartAgentDarkBlue,
    transition: "background-color 0.2s ease",
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    "&.active": {
      background: colors.smartAgentGreen,
      color: colors.white,
    },
  },
}));
