import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { RentalObject } from "../../../types/RentalObject";
import ObjectCard from "./ObjectCard";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardContext } from "../../../context/DashboardContext";
import data from "./data.json";
import { getAllObjects } from "../../../services/rentalHandler";
import Alert from "react-s-alert";
import NewObjectCard from "./NewObjectCard";
import { ObjectTable } from "./ObjectTable";
import { ViewHeadline, ViewModule } from "@mui/icons-material";
import { useStyles } from "./styles";

type ViewType = "LIST" | "GRID";
const ObjectCardContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleView = (type: ViewType) => {
    setViewType(type);
  };

  const [objects, setObjects] = useState<RentalObject[]>(data as any);
  const [loading, setLoading] = useState<boolean>(true);
  const [viewType, setViewType] = useState<ViewType>("LIST");

  const reload = () => {
    setLoading(true);
    getAllObjects()
      .then((res) => {
        res?.length > 5 ? setViewType("LIST") : setViewType("GRID");
        setObjects(res);
        setLoading(false);
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllObjects()
      .then((res) => {
        res?.length > 5 ? setViewType("LIST") : setViewType("GRID");
        setObjects(res);
        setLoading(false);
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
        setLoading(false);
      });
  }, [t]);

  const contextValues = useMemo(() => {
    return { objects, setObjects };
  }, [objects, setObjects]);

  return (
    <Grid item container display="flex" flexDirection="row">
      {loading ? (
        <CircularProgress />
      ) : (
        <DashboardContext.Provider value={contextValues}>
          <Grid container flexDirection="row">
            <Grid item xs={12}>
              <Box className={classes.objectAction}>
                <Typography variant="h4">
                  {t("customer.dashboard.object.title")}
                </Typography>
                <Box sx={{ marginLeft: "auto" }}>
                  <Typography variant="body">
                    {t("customer.dashboard.object.view.change")}
                  </Typography>
                  <IconButton onClick={() => handleView("GRID")}>
                    <ViewModule />
                  </IconButton>
                  <IconButton onClick={() => handleView("LIST")}>
                    <ViewHeadline />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {viewType === "GRID" ? (
                <Grid item container spacing={3}>
                  {objects.map((object, index) => (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <ObjectCard object={object} reload={reload} />
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={6} xl={4}>
                    <NewObjectCard objectCount={objects.length} />
                  </Grid>
                </Grid>
              ) : (
                <ObjectTable objects={objects} reload={reload} />
              )}
            </Grid>
          </Grid>
        </DashboardContext.Provider>
      )}
    </Grid>
  );
};

export default ObjectCardContainer;
