import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useStyles, FooterHeight } from "./styles";
import { signup } from "../../../services/authHandler";
import Alert from "react-s-alert";
import { LOGIN } from "../../../routes/ROUTES";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SideBarContext } from "../../../context/SideBarContext";
import logoImg from "../../../assets/img/logo-graphic-only.png";

interface FormData {
  name: string;
  email: string;
  password: string;
  signupcode: string;
  lang: string;
}
export const Footer = () => {
  const { t } = useTranslation();

  const navigationItems = [
    { key: "contact", text: t("main.contact"), url: "https://www.smart-agent.eu/contact/" },
    { key: "data_policy", text: t("main.privacyPolicy"), url: "https://www.smart-agent.eu/datapolicy/" },
    { key: "disclaimer", text: t("main.disclaimer"), url: "https://www.smart-agent.eu/disclaimer/" },
    { key: "cookies", text: t("main.cookiePolicy"), url: "https://www.smart-agent.eu/cookies/" },
    { key: "imprint", text: t("main.imprint"), url: "https://www.smart-agent.eu/imprint/" },
  ];


  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: FooterHeight,
      }}
      component="footer"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {navigationItems.map((item) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              onClick={() => window.open(item.url, "_blank")}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <ListItemText>
                <Typography variant="body2" color="white">
                  {item.text}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
      <Typography variant="body2" color="#687298" align="center">
        {" © "}
        {new Date().getFullYear()}
        {" Smart Agent GmbH"}
      </Typography>
    </Box>
  );
};

const SignUp = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, isLoading] = useState(false);

  const { setVisibility } = useContext(SideBarContext);
  useEffect(() => {
    setVisibility(false);
  }, [setVisibility]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    password: "",
    signupcode: "",
    lang: "",
  });
  const [emailError, setEmailError] = useState("");
  const validateEmail = (email: string) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      setEmailError(`${t("signup.email.required.error")}`);
    } else {
      setEmailError("");
    }
  };
  const [passwordError, setPasswordError] = useState("");
  const validatePassword = (email: string) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(email)) {
      setPasswordError(`${t("signup.password.valid.msg")}`);
    } else {
      setPasswordError("");
    }
  };
  const [codeError, setCodeError] = useState("");
  const validateCode = async (code: string) => {
    code = code.substring(0, 11);
    code = code.toLowerCase();
    const textEncoder = new TextEncoder();
    const codeHashBuffer = await crypto.subtle.digest('SHA-256', textEncoder.encode(code));
    const codeHashString = Array.from(new Uint8Array(codeHashBuffer)).map(v => v.toString(16).padStart(2, '0')).join('');
    // Yeah, not really secure, but good enough to keep curious people out of the demo prototype.
    // And yes, it's not good practice to commit the hash to the repository but as this is not really a security feature...
    // This is *really* easy to circumvent but good enough to prevent accidental signups.
    if (codeHashString !== "128afd6612a9b7f1b528cacefdcacf4af699f41c2c0baecebc0d7cb9e5bf78e6") {
      setCodeError(`${t("signup.code.validation.error")}`);
    } else {
      setCodeError("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "email") {
      validateEmail(value);
    }
    if (name === "password") {
      validatePassword(value);
    }
    if (name === "signupcode") {
      validateCode(value);
    }
  };

  const onsSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (emailError) {
      alert(t("signup.email.validation.error"));
      return;
    }
    if (codeError) {
      alert(t("signup.code.validation.error"));
      return;
    }
    isLoading(true);
    const signUpRequest = Object.assign({}, formData, {"lang": i18n.language});
    signup(signUpRequest)
      .then(() => {
        isLoading(false);
        Alert.success(`${t("signup.success.message")}`);
        navigate(LOGIN);
      })
      .catch((error) => {
        isLoading(false);
        Alert.error((error && error.message) || t("error"));
      });
  };

  return (
    <div className={classes.mainContainer}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
        style={{ flex: 1 }}
      >
        <Grid item className={classes.signUpContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.itemContainer}>
              <img src={logoImg} alt="Logo" width="64" />
            </Grid>
            <Grid item xs={12} className={classes.itemContainer}>
              <Typography variant="subtitle1">{t("signup.title")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.itemContainer}>
              <form onSubmit={onsSubmit}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t("signup.name")}
                        defaultValue=""
                        variant="outlined"
                        name="name"
                        onChange={handleChange}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t("signup.email")}
                        defaultValue=""
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        required={true}
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.passwordSpacer}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="password">
                          {t("signup.password")}
                        </InputLabel>
                        <OutlinedInput
                          id="password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          required={true}
                          error={!!passwordError}
                        />
                        {passwordError && (
                          <FormHelperText error>{passwordError}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.signupCode}>
                      <TextField
                        fullWidth
                        label={t("signup.code")}
                        defaultValue=""
                        variant="outlined"
                        name="signupcode"
                        onChange={handleChange}
                        required={true}
                        error={!!codeError}
                      />
                      {codeError && (
                        <FormHelperText error>{codeError}</FormHelperText>
                      )}
                      <FormHelperText id="component-helper-text">
                        {t("signup.code.info")}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} className={classes.registerButton}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button type="submit" variant="contained" fullWidth>
                          {t("home.signup")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.itemContainer}>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label={t("login.or")} />
                      </Divider>
                    </Grid>
                    <Grid item xs={12} className={classes.redirectContainer}>
                      <Button onClick={() => navigate(LOGIN)} variant="outlined">
                        {t("home.gotologin")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Fixed Footer */}
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </div>
  );
};

export default SignUp;
