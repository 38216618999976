import {
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export const DocumentsCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "32px 0 16px 0" }}>
        { t("main.dashboard.documents") }
      </Typography>
      <Grid container sx={{ position: "relative !important" }} className={classes.card}>
        <Grid item xs={12} className={classes.icon}>
          <ContentCopyOutlinedIcon sx={{ fontSize: 50 }} />
        </Grid>
        <Grid item xs={12} className={ classes.subTitle }>
          { t("main.dashboard.find.relevant.documents") }
        </Grid>
        <Grid item xs={12} className={ classes.additionalInfo }>
          { t("main.dashboard.legally.approved.templates") }
        </Grid>
      </Grid>
    </Box>
  );
};
