import React from "react";
export interface RelativeObject {
  rent: number;
  address: string;
  distance: string;
  rooms: number;
  size: string;
  tintColor: string;
}

export interface ObjectContext {
  relativeObjects: RelativeObject[];
  updateRelativeObjects: (props: RelativeObject[]) => void;
}

// Seriously? Pleeeeease choose unique names. It's absulutly not confusing that way…
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObjectContext = React.createContext<ObjectContext>({
  relativeObjects: [],
  updateRelativeObjects: () => {},
});
