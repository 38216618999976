import {
  Typography,
  Grid,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Alert from "react-s-alert";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

export interface ContractProps {
  status: string;
  info: string;
  advantages: string[];
}

export const ContractCard = ({ status, info, advantages }: ContractProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = () => {
    Alert.info( `${t("error.notYetAvailable")}` );
  };
  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "32px 0 16px 0" }}>
        { t("main.dashboard.contract.status") }
      </Typography>
      <Grid container sx={{ position: "relative !important" }} className={classes.card}>
        <Grid item xs={12} display="flex">
          <Grid item xs={12} className={ classes.titleContainer }>
            <Box className={classes.title}>
              { t("main.dashboard.current.status") }
              <span className={classes.status}>
                {status}
              </span>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={ classes.additionalInfo }>
          {info}
        </Grid>
        <Grid item xs={12} sx={{ padding: "8px 16px" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={ classes.upsell }>
          { t("main.dashboard.satisfied.with.tariff") }
        </Grid>
        <Grid item xs={12} className={ classes.upsell }>
          { t("main.dashboard.more.than.5.objects") }
          { " " }
          { t("main.dashboard.property.management.support") }
        </Grid>
        <Grid item xs={12} className={ classes.upsell }>
          { t("main.dashboard.matching.tariff") }
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="right" sx={{ padding: "16px" }}>
          <Button className={classes.button} onClick={handleClick}>
            { t("main.dashboard.view.options") }
            <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
