import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    width: "100%",
    background: colors.white,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    borderRadius: colors.cardBorderRadius,
  },
  accordionSummary: {
    //color: theme.palette.primary.contrastText,
  },
  expandMoreIcon: {
    //color: theme.palette.primary.contrastText,
  },
  accordionHead: {
    fontWeight: colors.fontWeightBold,
  },
}));
