import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid } from "@mui/material";
import ImageEditDialog from "./ImageEditDialog";
import {
  UPLOAD_ENERGY_CERTIFICATES,
  UPLOAD_FLOOR_PLAN,
  UPLOAD_PHOTOS,
} from "../../../services/CONSTANTS";
import { getRental } from "../../../services/rentalHandler";
import Alert from "react-s-alert";
import ImagePreviewReadOnly from "./ImagePreviewReadOnly";
import UploadIcon from '@mui/icons-material/Upload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export type LocalFile = {
  id: string;
  src: string;
  file: File | null;
};

export type ImageType = "ENERGY" | "PHOTOS" | "FLOOR";

const customizeImageUploader = (
  type: ImageType,
  objectId: string
): {
  buttonLabel: string;
  dialogTitle: string;
  endPoint: string;
  attribute: string;
} => {
  switch (type) {
    case "ENERGY":
      return {
        buttonLabel: "accordion.pictures.upload.energy.certificate",
        dialogTitle: "accordion.pictures.upload.energy.certificate.dialog.title",
        endPoint: `${UPLOAD_ENERGY_CERTIFICATES}/${objectId}`,
        attribute: "energyCertificates",
      };

    case "PHOTOS":
      return {
        buttonLabel: "accordion.pictures.upload.pictures",
        dialogTitle: "accordion.pictures.upload.pictures.dialog.title",
        endPoint: `${UPLOAD_PHOTOS}/${objectId}`,
        attribute: "pictures",
      };

    case "FLOOR":
      return {
        buttonLabel: "accordion.pictures.upload.floor.plan",
        dialogTitle: "accordion.pictures.upload.floor.plan.dialog.title",
        endPoint: `${UPLOAD_FLOOR_PLAN}/${objectId}`,
        attribute: "floorPlan",
      };

    default:
      return {
        buttonLabel: "",
        dialogTitle: "",
        endPoint: "",
        attribute: "",
      };
  }
};

interface Props {
  type: ImageType;
  objectId: string;
}

const ImageUploader = ({ type, objectId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buttonLabel, dialogTitle, endPoint, attribute } =
    customizeImageUploader(type, objectId);

  const [currentImages, setCurrentImages] = useState<Array<LocalFile>>([]);
  const [previewImages, setPreviewImages] = useState<
    { title: string; image: string }[]
  >([]);
  const [selectedImages, setSelectedImages] = useState<Array<LocalFile>>([]);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(!!objectId);
  const [callApi, setCallApi] = React.useState(true);

  useEffect(() => {
    if (callApi && !open && objectId) {
      getRental(objectId)
        .then(async (response) => {
          const photos = response[attribute];
          if (photos) {
            setPreviewImages(
              photos.map(
                (item: {
                  title: string;
                  image: { data: string; type: number };
                }) => {
                  return {
                    image: item["image"]["data"],
                    title: item["title"],
                  };
                }
              )
            );
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          Alert.error((err && err.message) || t("error"));
        });
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previewImages.length > 0) {
      setLoading(false);
    }
  }, [previewImages]);

  useEffect(() => {
    if (!callApi) {
      setPreviewImages([]);
    }
  }, [callApi]);

  const handleClose = () => {
    setSelectedImages(currentImages);
    setOpen(false);
  };

  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = e.target.files;
    if (fileList?.length) {
      const newSelectedImagesArr: Array<LocalFile> = Array.from(fileList).map(
        (currentFile, index) => {
          const newSource = URL.createObjectURL(currentFile);
          const formData = new FormData();
          const currentImage = currentFile as File;
          formData.append("fileName", currentImage.name);
          return {
            id: (index + 1).toString(),
            src: newSource,
            file: currentFile,
            title: currentFile.name,
            formData: formData,
          };
        }
      );
      setSelectedImages(newSelectedImagesArr);
      setOpen(true);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setCallApi(false);
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  return (
    <Grid item container sx={{ width: "100%" }} xs={12}>
      {isLoading ? (
        <CircularProgress color="success" />
      ) : (
        <Grid sx={{ width: "100%" }} item xs={12}>
          {!open && previewImages.length > 0 && !isLoading && (
            <ImagePreviewReadOnly images={previewImages} type={type} />
          )}
          <Button
            variant="outlined"
            component="label"
            className={classes.fileButton}
            sx={{ width: "50%" }}
          >
            {previewImages.length > 0 && (
              <RestartAltIcon className={classes.icon} />
            )}
            {previewImages.length < 1 && (
              <UploadIcon className={classes.icon} />
            )}
            {previewImages.length > 0 ? t(buttonLabel + ".again") : t(buttonLabel)}
            <input
              type="file"
              name="file"
              multiple={true}
              hidden
              accept="image/*"
              onChange={onSelectImage}
              onClick={onInputClick}
            />
          </Button>
        </Grid>
      )}
      <ImageEditDialog
        open={open}
        handleClose={handleClose}
        title={dialogTitle}
        images={selectedImages}
        setSelectedImages={setSelectedImages}
        endPoint={endPoint}
        setCurrentImages={setCurrentImages}
      />
    </Grid>
  );
};

export default ImageUploader;
