import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import {
  ContractCard,
  ContractProps,
} from "../../components/Dashboard/ContractStatus/ContractStatus";
import { SmartAssistant } from "../../components/Dashboard/SmartAssistant/SmartAssistant";
import { DocumentsCard } from "../../components/Dashboard/DocumentsCard/DocumentsCard";
import { Calendar } from "../../components/Dashboard/Calendar/Calendar";
import {
  SpecialOffersCard,
  SpecialOffersProps,
} from "../../components/Dashboard/SpecialOffers/SpecialOffers";
import ObjectCardContainer from "../../components/Dashboard/Objects/ObjectCardContainer";

import { useContext, useEffect } from "react";
import { SideBar } from "../../components/CustomerDashboard/Components";
import { SideBarContext } from "../../context/SideBarContext";

const Dashboard = () => {

  const { t } = useTranslation();

  const specialOffers: SpecialOffersProps[] = [
    {
      title: t("main.dashboard.50.percent.off.gardening"),
      body: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      image: "planzen",
      badge: t("main.dashboard.new"),
    },
    {
      title: t("main.dashboard.2.hours.free.caretaking"),
      body: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      image: "gray",
      badge: "",
    },
    {
      title: t("main.dashboard.0.percent.down.payment.solar.panels"),
      body: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      image: "enpal",
      badge: "",
    },
  ];
  const contractStatus: ContractProps = {
    status: t("main.dashboard.rental"),
    info: t("main.dashboard.rental.info"),
    advantages: [
      "Maximale Anzahl von Objekten für Verwaltun erhöhen",
      "Vermietungsdienste hinzufügen: ",
      "Premium-Kombi buchen",
    ],
  };

  const classes = useStyles();
  const { setVisibility, setContent } = useContext(SideBarContext);

  useEffect(() => {
    setContent(<SideBar />);
    setVisibility(true);
  }, [setVisibility, setContent]);

  return (
    <>
      <Grid container className={classes.container}>

        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12}>
            <ObjectCardContainer />
          </Grid>
        </Grid>

        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Grid container display="flex" flexDirection="row">
              <Grid container flexDirection="row">
                <Grid item xs={12}>
                  <Grid item container spacing={3}>
                    <Grid item xs={12} lg={6} xl={4}>
                      <SmartAssistant />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={4}>
                      <DocumentsCard />
                      <ContractCard status={contractStatus.status} info={contractStatus.info} advantages={contractStatus.advantages} />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={4}>
                      <Calendar />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Grid container display="flex" flexDirection="row">
              <Grid container flexDirection="row">
                <Grid item xs={12}>
                  <Grid item container spacing={3}>
                    <Grid item xs={12} sx={{ marginBottom: "-24px" }}>
                      <Box>
                        <Typography variant="h4" sx={{ margin: "32px 0 16px 0" }}>
                          { t("main.dashboard.special.offers") }
                        </Typography>
                      </Box>
                    </Grid>
                    {specialOffers.map((offer, index) => (
                      <Grid item xs={12} lg={6} xl={4} key={"offer_" + index}>
                        <SpecialOffersCard title={offer.title} body={offer.body} image={offer.image} badge={offer.badge} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
};

export default Dashboard;
