const convertJavaBinaryToBase64 = (javaBinary: string): string => {
  return javaBinary.replace(/^data:image\/jpeg;base64,/, "");
};

const b64toBlob = (base64Data: string, contentType: string = ""): Blob => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

const generateImgUrl = (javaBinaryData: string) => {
  if (javaBinaryData) {
    const base64Data = convertJavaBinaryToBase64(javaBinaryData);
    const blob = b64toBlob(base64Data, "image/jpeg");
    return URL.createObjectURL(blob);
  }
};

export default generateImgUrl;
