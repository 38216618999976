export interface FormData {
  id?: string;
  name: string;
  address: string;
  state: string;
  zip: string;
  placeId: string;
  latitude: string;
  longitude: string;
  estateType: string;
  estateTypeDetailName: string;
  area: string;
  plotArea: string;
  constructionYear: string;
  lastRefurbished: string;
  rooms: string;
  bedRooms: string;
  bathRooms: string;
  floor: string;
  numberOfFloors: string;
  kitchen: boolean;
  balconyTerrace: boolean;
  garden: boolean;
  guestWashRoom: boolean;
  parking: boolean;
  basementLoft: boolean;
  canBeWg: boolean;
  lift: boolean;
  stepLessAccess: boolean;
  subsidized: boolean;
  energyUsage: string;
  preservation: boolean;
  commonCharge: string;
  firstTimeUse: boolean;
  parkingOutside: boolean;
  parkingInside: boolean;
  garage: boolean;
  priceParking: string;
  furnished: boolean;
  barrierFree: boolean;
  temporary: boolean;

  additionalCosts: string;
  estimatedRent: number;
  suggestionRentals: string;
  rentControl: boolean;
  rent: string;
  sideCost: string;
  heatingCosts: string;
  totalRent: string;
  deposit: string;
  availableFrom: string;
  availableTo: string;
  quality: string;
  isPetAllowed: string;
  typeOfHeating: string;
  essentialEnergySource: string;
  energyClass: string;

  areaCharacteristics: null | string[];
  reachability: null | string[];
  rentalPropertyCharacters: null | string[];
  specialFeaturesAndServices: null | string[];
  floorCovering: string;
  conditionOfTheObject: string;

  hasSelfDisclosure: boolean;
  proofOfIdentity: boolean;
  proofOfCreditCheck: boolean;
  previousRentPaymentProof: boolean;
  incomeConfirmation: boolean;
  jobCenter: boolean;
  rentGuarantee: boolean;

  titleSuggestion: string;
  titleSuggestionAttempts: string;
  descriptionSuggestion: string;
  descriptionSuggestionAttempts: string;
  equipmentsSuggestion: string;
  equipmentsSuggestionAttempts: string;
  areaDescriptionSuggestion: string;
  areaDescriptionSuggestionAttempts: string;
  miscellaneous: string;
}

export interface Entities {
  id: string;
  value_1: string;
  name_de: string;
  name_en: string;
  type: string;
}

export const defaultFormState = {
  name: "",
  address: "",
  state: "",
  zip: "",
  placeId: "",
  latitude: "",
  longitude: "",
  estateType: "",
  estateTypeDetailName: "",
  area: "",
  plotArea: "",
  constructionYear: "",
  lastRefurbished: "",
  rooms: "",
  bedRooms: "",
  bathRooms: "",
  floor: "",
  numberOfFloors: "",
  kitchen: false,
  balconyTerrace: false,
  garden: false,
  guestWashRoom: false,
  parking: false,
  basementLoft: false,
  canBeWg: false,
  lift: false,
  stepLessAccess: false,
  subsidized: false,
  energyUsage: "",
  preservation: false,
  commonCharge: "",
  firstTimeUse: false,
  parkingOutside: false,
  parkingInside: false,
  garage: false,
  priceParking: "",
  furnished: false,
  barrierFree: false,
  temporary: false,

  additionalCosts: "",
  estimatedRent: 0,
  suggestionRentals: "",
  rentControl: false,
  rent: "",
  sideCost: "",
  heatingCosts: "",
  totalRent: "",
  deposit: "",
  availableFrom: "",
  availableTo: "",
  quality: "",
  isPetAllowed: "",
  typeOfHeating: "",
  essentialEnergySource: "",
  energyClass: "",

  areaCharacteristics: [],
  reachability: [],
  rentalPropertyCharacters: [],
  specialFeaturesAndServices: [],
  floorCovering: "",
  conditionOfTheObject: "",

  hasSelfDisclosure: false,
  proofOfIdentity: false,
  proofOfCreditCheck: false,
  previousRentPaymentProof: false,
  incomeConfirmation: false,
  jobCenter: false,
  rentGuarantee: false,

  titleSuggestion: "",
  titleSuggestionAttempts: "",
  descriptionSuggestion: "",
  descriptionSuggestionAttempts: "",
  equipmentsSuggestion: "",
  equipmentsSuggestionAttempts: "",
  areaDescriptionSuggestion: "",
  areaDescriptionSuggestionAttempts: "",
  miscellaneous: "",
};
