import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  sideBar: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.smartAgentDarkBlue,
    width: 248,
  },
  secondaryButton: {
    backgroundColor: "#E0E0E0",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 15,
    textTransform: "none",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  primaryButton: {
    backgroundColor: "#9AF430",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 15,
    textTransform: "none",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    color: "#101529",
  },
  paper: {
    "& .MuiDialog-paper": {
      minWidth: 450,
    },
  },
  primaryNav: {
    color: colors.lightTextPrimary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
  },
  primaryNavWithSubtitle: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  subtitle: {
    color: colors.lightTextSecondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 13,
    marginTop: 0,
  },
  secondaryNav: {
    color: colors.lightTextSecondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 13,
  },
  objectButton: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  iconSpacer: {
    minWidth: 30,
  },
  smallIcon: {
    color: colors.lightTextSecondary,
    fontSize: 15,
  },
  largeIcon: {
    color: colors.lightTextPrimary,
    fontSize: 18,
  },
  whiteColor: {
    color: "#FFFFFF",
  },
  whiteDivider: {
    borderColor: colors.lightLine,
    paddingTop: "16px",
    marginBottom: "16px",
  },
  noVerticalPadding: {
    paddingTop: 0,
    marginBottom: "-6px",
  },
  legalTypo: {
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
  },
  noVerticalMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  objectHeader: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    fontSize: 17,
    textTransform: "none",
  },
  objectName: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 14,
    textTransform: "none",
    marginBottom: 8,
    cursor: "pointer",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    paddingRight: "12px",
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
  buttonVariantDark: {
    backgroundColor: colors.smartAgentDarkBlue,
    color: colors.lightTextPrimary,
  },
}));
