import React from "react";

export interface Adult {
  firstName: string;
  lastName: string;
  birthday: string;
  nationality: string;
  profession: string;
  employmentType: string;
  employer: string;
  maritalStatus: string;
  relationship: string;
}

export interface CustomerState {
  id?: string;
  customer: {
    id: string;
    object: { displayId: string };
    firstName: string;
    lastName: string;
    street: string;
    postalCode: string;
    city: string;
    email: string;
    phoneNumber: string;
    adultCount: string;
    childCount: string;
    adultsData: Adult[];
    childData: string[];
    smoker: string;
    pets: string;
    petTypes: string;
    hobbies: string;
    messages: string;
    photo: File | null;
    netHouseHoldIncome: string;
    canProvideProofOfIncome: boolean;
    proofOfIncome: FileList | null;
    canProvideIncomeTaxStatement: boolean;
    incomeTaxStatement: FileList | null;
    canProvideRentalCostTakeOver: boolean;
    rentalCostTakeOver: FileList | null;
    canProvideRentalGuarantee: boolean;
    rentalGuarantee: FileList | null;
    canProvideSCHUFA: boolean;
    schufa: FileList | null;
    canProvideRentPaymentConfirmations: boolean;
    rentPaymentConfirmations: FileList | null;
    nonEvictionForLastYears: boolean;
    nonRentClaimProcess: boolean;
    nonSalaryGarnishment: boolean;
    nonAffidavit: boolean;
    nonBankruptcy: boolean;
    notes: string;
    rating: string;
    hasChecked: boolean;

    [key: string]:
      | {
          displayId: string;
        }
      | string
      | string[]
      | boolean
      | Adult[]
      | File
      | FileList
      | null;
  };
}

export const defaultCustomerState = {
  customer: {
    id: "",
    object: { displayId: "" },
    firstName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    adultCount: "",
    childCount: "",
    adultsData: [],
    childData: [],
    smoker: "",
    pets: "",
    petTypes: "",
    hobbies: "",
    messages: "",
    photo: null,
    netHouseHoldIncome: "",
    canProvideProofOfIncome: false,
    proofOfIncome: null,
    canProvideIncomeTaxStatement: false,
    incomeTaxStatement: null,
    canProvideRentalCostTakeOver: false,
    rentalCostTakeOver: null,
    canProvideRentalGuarantee: false,
    rentalGuarantee: null,
    canProvideSCHUFA: false,
    schufa: null,
    canProvideRentPaymentConfirmations: false,
    rentPaymentConfirmations: null,
    nonEvictionForLastYears: false,
    nonRentClaimProcess: false,
    nonSalaryGarnishment: false,
    nonAffidavit: false,
    nonBankruptcy: false,
    notes: "",
    rating: "",
    hasChecked: false,
  },
};

export interface ErrorState {
  displayId: string;
  firstName: string;
  lastName: string;
  street: string;
  postalCode: string;
  city: string;
  email: string;
}

export const InitialErrorsState = {
  displayId: "",
  firstName: "",
  lastName: "",
  street: "",
  postalCode: "",
  city: "",
  email: "",
};

export interface CustomerContextType {
  customerState: CustomerState;
  updateCustomerState: (props: CustomerState) => void;
  errors: ErrorState;
  updateErrors: (props: ErrorState) => void;
}

export const CustomerContext = React.createContext<CustomerContextType>({
  customerState: defaultCustomerState,
  updateCustomerState: () => {},
  errors: InitialErrorsState,
  updateErrors: () => {},
});
