import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    "& .MuiStepIcon-root": {
      color: colors.white,
      fontWeight: colors.fontWeightBold,
      "& .MuiStepIcon-text": {
        fill: colors.smartAgentGreen
      },
    },
    "& .Mui-disabled": { 
      color: colors.white + " !important",
      "& .MuiStepIcon-text": {
        fill: colors.smartAgentGreen + " !important",
      },
    },
    "& .Mui-active": {
      color: colors.smartAgentGreen + " !important",
      "& .MuiStepIcon-text": {
        fill: colors.white + " !important",
      },
    },
    "& .Mui-completed": {
      color: colors.smartAgentGreen + " !important",
      "& .MuiStepIcon-text": {
        fill: colors.white + " !important",
      },
    },
    "& .MuiStepLabel-label": {
      fontSize: colors.fontSizeRegular,
      fontWeight: colors.fontWeightBold,
    },
  },
  stepperContainer: {
    display: "flex",
    height: "77px",
    justifyContent: "flex-start",
  },
}));
