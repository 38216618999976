import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  head: {
    fontFamily: theme.typography.fontFamily,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    color: colors.lightTextSecondary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
  },
}));
