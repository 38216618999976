import React, { useEffect, useMemo, useState } from "react";
import AppRoutes from "./routes/AppRoutes";
import { theme } from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { getCurrentUser } from "./services/authHandler";
import { AuthContext, AuthStatus } from "./context/AuthContext";
import { 
  CircularProgress,
  Grid,
} from "@mui/material";
import "./i18n/i18n";

const App = () => {
  const [authStatus, setAuthStatus] = useState<AuthStatus>({
    authenticated: false,
    loading: true,
  });
  const contextValues = useMemo(() => {
    const updateAuthStatus = (props: AuthStatus) => {
      setAuthStatus(props);
    };

    return { authStatus, updateAuthStatus };
  }, [authStatus]);

  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setAuthStatus({
          currentUser: response,
          authenticated: true,
          loading: false,
        });
      })
      .catch(() => {
        setAuthStatus({
          loading: false,
          authenticated: false,
        });
      });
  };

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={contextValues}>
        {authStatus.loading ? 
            <Grid container sx={{height: "100vh", justifyContent: "center", alignContent: "center"}}>
                <CircularProgress /> 
            </Grid>
          : 
            <AppRoutes />
        }
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
