import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    //borderRadius: 0,
    boxShadow: colors.cardShadow,
    minHeight: "100%",
  },
  img: {
    maxHeight: 456,
    objectFit: "cover",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    paddingRight: "12px",
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
  buttonVariantDark: {
    backgroundColor: colors.smartAgentDarkBlue,
    color: colors.lightTextPrimary,
  },
}));
