import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    borderRadius: colors.cardBorderRadius,
    boxShadow:
      "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
    minHeight: "100%",
    backgroundColor: colors.white,
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  logoImage: {
    borderTopLeftRadius: colors.cardBorderRadius,
    borderBottomLeftRadius: colors.cardBorderRadius,
    width: 140,
    height: 191,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightRegular,
  },
  offer: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
  },
  badge: {
    fontWeight: colors.fontWeightBold + " !important",
    backgroundColor: "#ed2102",
  },
  buttonBox: {
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "right",
  },
}));
