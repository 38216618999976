import React, { ReactNode, useMemo, useState } from "react";
import Header from "../../components/Header/Header";
import { useStyles } from "./styles";
import SideBar from "../../components/SideBar/SideBar";
import { Outlet } from "react-router-dom";
import Alert from "react-s-alert";
import { Box } from "@mui/material";
import { SideBarContext } from "../../context/SideBarContext";

const Layout = () => {
  const classes = useStyles();

  const [isShowing, setShowing] = useState<boolean>(false);
  const [content, setSideBarContent] = useState<ReactNode>();

  const contextValues = useMemo(() => {
    const setContent = (param: ReactNode) => {
      setSideBarContent(param);
    };
    const setVisibility = (param: boolean) => {
      setShowing(param);
    };
    return { setVisibility, setContent };
  }, []);

  return (
    <Box className={classes.root}>
      <Header />
      <SideBarContext.Provider value={contextValues}>
        <Box className={classes.mainContentContainer}>
          <aside className={classes.nav}>
            {isShowing && <SideBar isShowing={isShowing} content={content} />}
            <main className={classes.main}>
              <Outlet />
            </main>
          </aside>
        </Box>
      </SideBarContext.Provider>
      <Alert
        stack={{ limit: 3 }}
        timeout={3000}
        position="top-right"
        effect="slide"
        offset={65}
      />
    </Box>
  );
};

export default Layout;
