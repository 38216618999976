import { Alert, AlertTitle } from "@mui/material";
import { useStyles } from "./styles";
import { SxProps } from '@mui/system';

interface Props {
  children?: React.ReactNode;
  title?: string;
  sx?: SxProps;
}

const InfoAlert = (props: Props) => {
  const classes = useStyles();
  return (
    <Alert severity="info" variant="standard" className={classes.infoAlert} sx={props.sx}>
      {props.title && (
        <AlertTitle>
          <strong>{props.title}</strong>
        </AlertTitle>
      )}

      {props.children}
    </Alert>
  );
};

export default InfoAlert;
