import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

interface CheckboxGroupProps {
  onChange: (checked: boolean[]) => void;
}

const CheckBoxGroup: React.FC<CheckboxGroupProps> = ({ onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState([false, false, false]);

  const handleCheckboxChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = [...checked];
      newChecked[index] = event.target.checked;
      setChecked(newChecked);
      onChange(newChecked);
    };

  return (
    <Box sx={{ mt: 2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked[0]}
              className={classes.checkboxDark}
              onChange={handleCheckboxChange(0)}
            />
          }
          className={classes.checkboxLabel}
          label={t("customer.application.preview.data.agreement")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked[1]}
              className={classes.checkboxDark}
              onChange={handleCheckboxChange(1)}
            />
          }
          className={classes.checkboxLabel}
          label={t("customer.application.preview.data.store.agreement")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked[2]}
              className={classes.checkboxDark}
              onChange={handleCheckboxChange(2)}
            />
          }
          className={classes.checkboxLabel}
          label={
            <Typography>
              {t("customer.application.preview.data.privacy.agreement.1")}
              &nbsp;
              <Link target="_blank" href="https://www.smart-agent.eu/datapolicy/" sx={{color:"#fff", fontWeight: "700"}}>
                {"https://www.smart-agent.eu/datapolicy/"}
              </Link>
              &nbsp;
              {t("customer.application.preview.data.privacy.agreement.2")}
              &nbsp;
              <Link target="_blank" href="https://www.smart-agent.eu/disclaimer/" sx={{color:"#fff", fontWeight: "700"}}>
                {"https://www.smart-agent.eu/disclaimer/"}
              </Link>
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  );
};

export default CheckBoxGroup;
