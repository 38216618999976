import React from "react";
import { styled } from "@mui/material/styles";
import * as colors from "../../../style/colors";

const CloseButtonContainer = styled("button")(() => ({
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
  position: "absolute",
  zIndex: "1",
  right: "8px",
  top: "8px",
  height: "24px",
  width: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: colors.darkRed10pct,
  borderRadius: "50%",
  border: "none",
  "&::before, &::after ": {
    position: "absolute",
    content: '""',
    width: "50%",
    height: "3px",
    backgroundColor: colors.darkRed,
  },
  "&:hover::before, &:hover::after ": {
    backgroundColor: "#ffffff",
  },
  "&::before": {
    transform: `rotate(45deg)`,
  },
  "&::after": {
    transform: `rotate(-45deg)`,
  },
  "&:hover": {
    cursor: "pointer",
    background: colors.darkRed,
  },
}));

export const CloseButton = (props: any) => {
  return (
    <>
      <CloseButtonContainer {...props} />
    </>
  );
};
