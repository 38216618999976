import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  columnContainer: {
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  select: {
    background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",
  },
}));
