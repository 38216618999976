import React from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";

const Imprint = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" className={classes.root}>
      <Grid item className={classes.main}>
        <Typography variant="h4">Impressum</Typography>
        <br />
        <Typography variant="body1">
          Smart Agent GmbH <br />
          Perhamerstraße 37D- <br />
          80687 München <br />
          E-Mail: info@smart-agent.eu
        </Typography>
        <br />
        <Typography variant="body1">Geschäftsführer: Anselm Weier</Typography>
        <br />
        <Typography variant="body1">
          Handelsregister: HRB 283567 - Amtsgericht München <br />
          Steuernummer: 143/181/14375 <br />
          Umsatzsteuer-ID: DE360871509
        </Typography>
        <br />
        <Typography variant="body1">
          Aufsichtsbehörde gemäß § 34C GewO: <br />
          IHK für München und Oberbayern <br />
          Max-Joseph-Straße 2, <br />
          80333 München <br />
          Tel: +49 (0) 89 5116 0 <br />
          Fax: +49 (0) 89 5116 1306
        </Typography>
        <br />
      </Grid>
    </Grid>
  );
};

export default Imprint;
