import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: colors.lightGrayBackground,
    padding: "24px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,
    marginTop: 0,
    overflowY: "scroll",
  },
  card: {
    width: "100%",
    borderRadius: colors.cardBorderRadius,
    boxShadow:
      "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
  },
  secondaryButton: {
    backgroundColor: "#E0E0E0",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightRegular,
    fontSize: colors.fontSizeRegular,
    textTransform: "none",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 0,
    margin: 20,
  },
}));
