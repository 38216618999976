import { ACCESS_TOKEN, API_BASE_URL, SEARCH_AUTO_COMPLETE } from "./CONSTANTS";
import { request } from "./authHandler";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { debounceTime, filter, map, switchMap } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { LocalFile } from "../components/Expose/ImageHandler/ImageUploader";
import axios from "axios";
import { RentalStatus } from "../types/RentalObject";

export interface rentalRequest {
  name: string;
  address: string;
  state: string;
  zip: string;
  placeId: string;
  latitude: string;
  longitude: string;
  estateType: string;
  estateTypeDetailName: string;
  area: string;
  plotArea: string;
  constructionYear: string;
  lastRefurbished: string;
  rooms: string;
  bedRooms: string;
  bathRooms: string;
  floor: string;
  numberOfFloors: string;
  kitchen: boolean;
  balconyTerrace: boolean;
  garden: boolean;
  guestWashRoom: boolean;
  parking: boolean;
  basementLoft: boolean;
  canBeWg: boolean;
  lift: boolean;
  stepLessAccess: boolean;
  subsidized: boolean;
  energyUsage: string;
  preservation: boolean;
  commonCharge: string;
  firstTimeUse: boolean;
  parkingOutside: boolean;
  parkingInside: boolean;
  garage: boolean;
  priceParking: string;
  furnished: boolean;
  barrierFree: boolean;
  temporary: boolean;

  additionalCosts: string;
  estimatedRent: number;
  suggestionRentals: string;
  rentControl: boolean;
  rent: string;
  sideCost: string;
  heatingCosts: string;
  totalRent: string;
  deposit: string;
  availableFrom: string;
  availableTo: string;
  quality: string;
  isPetAllowed: string;
  typeOfHeating: string;
  essentialEnergySource: string;
  energyClass: string;

  areaCharacteristics: null | string[];
  reachability: null | string[];
  rentalPropertyCharacters: null | string[];
  specialFeaturesAndServices: null | string[];
  floorCovering: string;
  conditionOfTheObject: string;

  hasSelfDisclosure: boolean;
  proofOfIdentity: boolean;
  proofOfCreditCheck: boolean;
  previousRentPaymentProof: boolean;
  incomeConfirmation: boolean;
  jobCenter: boolean;
  rentGuarantee: boolean;
  titleSuggestion: string;
  titleSuggestionAttempts: string;
  descriptionSuggestion: string;
  descriptionSuggestionAttempts: string;
  equipmentsSuggestion: string;
  equipmentsSuggestionAttempts: string;
  areaDescriptionSuggestion: string;
  areaDescriptionSuggestionAttempts: string;
  miscellaneous: string;

  applications?: any[];
}

export function getRental(id: string) {
  return request({
    url: `${API_BASE_URL}/rental/${id}`,
    method: "GET",
  });
}

export const getEntity = async (id: string) => {
  return await request({
    url: `${API_BASE_URL}/entity/${id}`,
    method: "GET",
  });
};

export const deleteRental = async (id: string) => {
  return await request({
    url: `${API_BASE_URL}/rental/${id}`,
    method: "DELETE",
  });
};

export function createRental(rentalRequest: rentalRequest) {
  return request({
    url: API_BASE_URL + "/rental/",
    method: "POST",
    body: JSON.stringify(rentalRequest),
  });
}

export function updateRental(rentalRequest: rentalRequest) {
  return request({
    url: API_BASE_URL + "/rental/",
    method: "PUT",
    body: JSON.stringify(rentalRequest),
  });
}

const removeImageBinary = (request: any) => {
  delete request?.pictures;
  delete request?.energyCertificates;
  delete request?.floorPlan;
};

export function saveExpose(rentalRequest: rentalRequest) {
  delete rentalRequest?.applications;
  removeImageBinary(rentalRequest);
  return request({
    url: API_BASE_URL + "/rental/expose",
    method: "PUT",
    body: JSON.stringify(rentalRequest),
  });
}

export function updateRentalStatus(id: string, status: RentalStatus) {
  return request({
    url: `${API_BASE_URL}/rental/${id}/${status}`,
    method: "PUT",
  });
}

interface ResponseType {
  predictions: Place[];
}

export interface Place {
  description: string;
  place_id: string;
}

const getApiUrl = (value: string) =>
  `${SEARCH_AUTO_COMPLETE}/de?input=${value}`;

const transformResponse = ({ response }: AjaxResponse<any>) => {
  const res = response as ResponseType;
  return res?.predictions.map((item) => {
    return item;
  });
};

export const getAddressSuggestions = (
  subject: BehaviorSubject<string>
): Observable<Place[]> => {
  return subject.pipe(
    debounceTime(500),
    filter((v) => v.length > 2),
    map(getApiUrl),
    switchMap((url) =>
      ajax({
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      })
    ),
    map(transformResponse)
  );
};

export const savePhotos = (files: LocalFile[], endpoint: string) => {
  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    let localFile: LocalFile = files[i];
    const currentImage = localFile.file as File;
    data.append("pictures", currentImage);
  }

  const config = {
    method: "put",
    url: endpoint,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
    data: data,
  };

  return axios(config);
};

export const getAllApplications = async (id: string) => {
  return await request({
    url: `${API_BASE_URL}/rental/applications/${id}`,
    method: "GET",
  });
};

export const getAllObjects = async () => {
  return await request({
    url: `${API_BASE_URL}/rental/objects`,
    method: "GET",
  });
};
