import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";
import { CustomerContext } from "../../../context/CustomerContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  getFamilyCategories,
  getEmploymentCategories,
} from "../../../services/entitiyHandler";
import { Entities } from "../../Expose/Form/types";
import i18next from "i18next";

interface Props {
  id: number;
  firstName: string;
  lastName: string;
  birthday: string;
  nationality: string;
  profession: string;
  employmentType: string;
  employer: string;
  maritalStatus: string;
  relationship: string;
}
const AdultDetails = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const { customerState, updateCustomerState } = useContext(CustomerContext);

  const [familyCategories, setFamilyCategories] = useState<Entities[]>([]);
  const [employmentCategories, setEmploymentCategories] = useState<Entities[]>(
    []
  );

  useEffect(() => {
    getFamilyCategories().then((response) => {
      setFamilyCategories(response);
    });
    getEmploymentCategories().then((response) => {
      setEmploymentCategories(response);
    });
  }, []);

  const [birthdayDate, setBirthdayDate] = useState<Dayjs | null>(
    props.birthday === "" ? null : dayjs(props.birthday)
  );

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = event.target;

    const updatedAdultsData = [...customerState.customer.adultsData];
    updatedAdultsData[props.id] = {
      ...updatedAdultsData[props.id],
      [name]: value,
    };

    updateCustomerState({
      ...customerState,
      customer: {
        ...customerState.customer,
        adultsData: updatedAdultsData,
      },
    });
  };

  const handleBirthdayChange = (date: Dayjs | null) => {
    if (date) {
      setBirthdayDate(date);
      const formattedDate = date.format("YYYY-MM-DD");

      const updatedAdultsData = [...customerState.customer.adultsData];
      updatedAdultsData[props.id] = {
        ...updatedAdultsData[props.id],
        birthday: formattedDate,
      };

      updateCustomerState({
        ...customerState,
        customer: {
          ...customerState.customer,
          adultsData: updatedAdultsData,
        },
      });
    }
  };

  return (
    <Grid container spacing={1} className={classes.adultBlock}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.bodyText} sx={{ fontWeight: "700", color: "rgba(16, 21, 41, 0.6)" }}>
          {t("customer.application.form.personal.data.detailed.information.adult")}
          {props.id + 1}
          {props.id === 0 ? " - " + t("customer.application.form.personal.data.main.tenant") + "" : ""}
          {":"}
        </Typography>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        xs={12}
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid item md={3}>
          <TextField
            label={t("customer.application.form.personal.data.first.name")}
            value={props.firstName}
            name="firstName"
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label={t("customer.application.form.personal.data.last.name")}
            value={props.lastName}
            name="lastName"
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item md={3}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={`${t("calendar.locale")}`}
          >
            <DatePicker
              className={classes.datePicker}
              label={t("customer.application.form.personal.data.date.of.birth")}
              value={birthdayDate}
              onChange={handleBirthdayChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={3}>
          <TextField
            label={t("customer.application.form.personal.data.nationality")}
            value={props.nationality}
            name="nationality"
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Grid item md={6}>
          <TextField
            label={t("customer.application.form.personal.data.occupation")}
            value={props.profession}
            name="profession"
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item md={6}>
          <FormControl fullWidth size="small" className={classes.select}>
            <InputLabel>{t("customer.application.form.personal.data.employment.status")}</InputLabel>
            <Select
              value={props.employmentType}
              name="employmentType"
              onChange={handleChange}
              displayEmpty
              label={t("customer.application.form.personal.data.employment.status")}
            >
              {employmentCategories.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {lang === "en"
                      ? item.name_en
                      : lang === "de"
                      ? item.name_de
                      : ""}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Grid item md={6}>
          <TextField
            label={t("customer.application.form.personal.data.employer")}
            value={props.employer}
            name="employer"
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Grid>

        {/**TODO: Familienstand */}
        <Grid item md={6}>
          <FormControl fullWidth size="small" className={classes.select}>
            <InputLabel>{t("customer.application.form.personal.data.family.status")}</InputLabel>
            <Select
              value={props.maritalStatus}
              name="maritalStatus"
              onChange={handleChange}
              displayEmpty
              label={t("customer.application.form.personal.data.family.status")}
            >
              {familyCategories.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {lang === "en"
                      ? item.name_en
                      : lang === "de"
                      ? item.name_de
                      : ""}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {props.id > 0 && (
        <Grid
          item
          container
          spacing={2}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={6}>
            <TextField
              label={t("customer.application.form.personal.data.relationship.to.main.tenant")}
              value={props.relationship}
              name="relationship"
              onChange={handleChange}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AdultDetails;
