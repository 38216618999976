import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useState } from "react";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import { 
  Grid, 
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FormData } from "../Form/types";

interface DesiredDocumentsProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

const DesiredDocuments = (props: DesiredDocumentsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialState = [
    {
      id: "hasSelfDisclosure",
      label: "disclosure",
      checked: props.formData.hasSelfDisclosure,
      info: "Info about self-disclosure",
    },
    {
      id: "proofOfIdentity",
      label: "identity",
      checked: props.formData.proofOfIdentity,
      info: "Info about proof of identity",
    },
    {
      id: "proofOfCreditCheck",
      label: "credit",
      checked: props.formData.proofOfCreditCheck,
      info: "Info about credit check",
    },
    {
      id: "previousRentPaymentProof",
      label: "payment",
      checked: props.formData.previousRentPaymentProof,
      info: "Info about rent payment confirmation",
    },
    {
      id: "incomeConfirmation",
      label: "income",
      checked: props.formData.incomeConfirmation,
      info: "Info about proof of income",
    },
    {
      id: "jobCenter",
      label: "job.center",
      checked: props.formData.jobCenter,
      info: "Info about confirmation from employment agency",
    },
    {
      id: "rentGuarantee",
      label: "guarantee",
      checked: props.formData.rentGuarantee,
      info: "Info about rent guarantee",
    },
  ];

  const [items, setItems] = useState(initialState);

  const handleToggle = (index: any) => {
    const itemId = items[index].id as keyof FormData;
    const updatedFormData = {
      ...props.formData,
      [itemId]: !items[index].checked,
    };
    props.setFormData(updatedFormData);
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      )
    );
  };

  return (
    <Grid className={classes.accordionContainer}>
      <VerticalAccordion label={"7. " + t("extra.info.documents.label")}>
        <Grid container className={classes.rowContainer}>
          <Grid
            item
            container
            className={classes.columnContainer}
            xs={6}
          >
            {items.map((item, index) => {
              return (
                <Grid item container key={index}>
                  <Grid item container className={classes.rowContainer} xs={12}>
                    <FormControlLabel 
                      control={<Checkbox checked={item.checked} onChange={() => handleToggle(index)} />}
                      label={t(`extra.info.documents.${item.label}`)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={6} className={classes.infoContainer}>
            <div className={classes.info}>
              <InfoAlert>{t("extra.info.documents.notification")}</InfoAlert>
            </div>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default DesiredDocuments;
