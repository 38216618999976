import ObjectCardContainer from "../../components/Dashboard/Objects/ObjectCardContainer";
import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { SideBar } from "../../components/CustomerDashboard/Components";
import { SideBarContext } from "../../context/SideBarContext";
import { useStyles } from "./styles";

const ObjectsDashboard = () => {
  const { setVisibility, setContent } = useContext(SideBarContext);
  const classes = useStyles();
  useEffect(() => {
    setContent(<SideBar />);
    setVisibility(true);
  }, [setVisibility, setContent]);

  return (
    <Grid container className={classes.container}>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={12}>
          <ObjectCardContainer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ObjectsDashboard;
