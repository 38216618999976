import React, { Dispatch, SetStateAction, useState } from "react";
import { CloseButton } from "./DeleteButton";
import { styled } from "@mui/material/styles";
import { LocalFile } from "./ImageUploader";

const ImagePreviewContainer = styled("div")(() => ({
  display: "flex",
  gap: "1rem",
  marginTop: "16px",
  flexWrap: "wrap",
  paddingBottom: "8px",
}));

type ImageProps = {
  src: string;
  alt: string;
  onClickCloseButton: (e: number) => void;
  showDeleteBtn: boolean;
};

type ImagePreviewProps = {
  images: Array<LocalFile>;
  title?: string;
  setSelectedImages: Dispatch<SetStateAction<LocalFile[]>>;
  showDeleteBtn: boolean;
};

const ImageContainer = styled("div")(() => ({
  position: "relative",
  img: {
    maxWidth: "150px",
    maxHeight: "350px",
    "&:hover ": {
      cursor: "grab",
    },
  },
}));

export const Image = (props: ImageProps) => {
  return (
    <>
      <ImageContainer>
        {props.showDeleteBtn && (
          <CloseButton onClick={props.onClickCloseButton} />
        )}
        <img src={props.src} alt={props.alt} style={{ borderRadius: "4px", border: "1px solid #e7e7e7", verticalAlign: "bottom" }}/>
      </ImageContainer>
    </>
  );
};

export const ImagePreview = (props: ImagePreviewProps) => {
  const { images, setSelectedImages, showDeleteBtn } = props;
  const [dragId, setDragId] = useState<String>();
  const [previewImages, setPreviewImages] = useState<LocalFile[]>(images);

  const removeImage = (i: number) => {
    let deleteImageIndex = -1;
    for (let j = 0; j < previewImages.length; j++) {
      const file = previewImages[j];
      if (file.id === i.toString()) {
        deleteImageIndex = j;
      }
    }
    if (deleteImageIndex >= 0) {
      const newSelectedImages = [...previewImages];
      newSelectedImages.splice(deleteImageIndex, 1);
      setPreviewImages(newSelectedImages);
      setSelectedImages(newSelectedImages);
    }
  };

  const handleOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    setDragId(e.currentTarget.id);
  };

  const moveItem = (from: number, to: number) => {
    const arr = [...previewImages];
    const f = arr.splice(from, 1)[0];
    arr.splice(to, 0, f);
    return arr;
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const dragImage = previewImages.findIndex((image) => image.id === dragId);
    const dropImage = previewImages.findIndex(
      (image) => image.id === e.currentTarget.id
    );
    setPreviewImages(moveItem(dragImage, dropImage));
    setSelectedImages(moveItem(dragImage, dropImage));
  };
  return (
    <>
      <ImagePreviewContainer>
        {previewImages &&
          previewImages.map((image) => {
            const { id, src } = image;
            return (
              <div
                key={id}
                id={id}
                draggable={true}
                onDragOver={(e) => handleOver(e)}
                onDragStart={(e) => handleDrag(e)}
                onDrop={(e) => handleDrop(e)}
              >
                <Image
                  onClickCloseButton={() => removeImage(parseInt(id))}
                  src={src}
                  alt={id}
                  showDeleteBtn={showDeleteBtn}
                />
              </div>
            );
          })}
      </ImagePreviewContainer>
    </>
  );
};
