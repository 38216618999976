import { 
  Divider,
  Grid,
} from "@mui/material";
import { useStyles } from "./styles";
import { t } from "i18next";

export interface DateNotificationProps {
  date: string;
  month: string;
  time: string;
  name: string;
  ymd: string;
}
export const DateNotification = ({
  date,
  time,
  month,
  name,
  ymd,
}: DateNotificationProps) => {
  const classes = useStyles();
  return (
    <Grid item container xs={12} className={classes.mainDate} mt={1} mb={1}>
      <Grid item container xs={12} sx={{ padding: "0 16px" }}>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <div className={classes.mainDay}>
            {date}
          </div>
          <div className={classes.mainMonth}>
            {month}
          </div>
        </Grid>
        <Grid item xs={1} sx={{ padding: "4px" }}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={5} sx={{ padding: "4px 0" }}>
          <div className={classes.dateType}>
            {t("main.dashboard.viewing.appointment")}
          </div>
          <div className={classes.dateName}>
            {name}
          </div>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "center", padding: "4px 0" }}>
          <div className={classes.dateType}>
            { " " }
          </div>
          <div className={classes.dateTime}>
            {time}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ padding: "4px 16px" }} mt={1}>
        <Divider />
      </Grid>
    </Grid>
  );
};
