import {
  Typography,
  Grid,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Alert from "react-s-alert";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import { styled } from "@mui/material/styles";


export const Calendar = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // long way to get two dates that are always respectivley one and two months in the future
  const upcomingEvents = [
    {
      date: "11",
      month: t("months.short." + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString()),
      time: "10:00 - 11:00",
      name: "Max Mustermann",
      ymd: (new Date(Date.now() + 30 * 24 * 3600 * 1000).getFullYear()).toString() + "-" + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString().padStart(2, "0") + "-11",
    },
    { 
      date: "23",
      month: t("months.short." + (new Date(Date.now() + 60 * 24 * 3600 * 1000).getMonth() + 1).toString()),
      time: "14:00 - 15:00",
      name: "John Mann",
      ymd: (new Date(Date.now() + 30 * 24 * 3600 * 1000).getFullYear()).toString() + "-" + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString().padStart(2, "0") + "-23",
    },
  ];

  const HighlightedDay = styled(PickersDay)(() => ({
    "&.Mui-selected": {
      backgroundColor: "rgba(91, 164, 58, 1.0)",
      color: "#ffffff",
      fontWeight: "700",
    },
  }));

  const highlightDay = (props: any) => {
    const { upcomingEvents = [], day, outsideCurrentMonth, ...other } = props;

    const dates = upcomingEvents.map(({ ymd }: any) => ymd);
    const isSelected = !props.outsideCurrentMonth && dates.includes(day.format("YYYY-MM-DD"));
  
    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };

  const handleClick = () => {
    Alert.info( `${t("error.notYetAvailable")}` );
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "32px 0 16px 0" }}>
        { t("main.dashboard.calendar") }
      </Typography>
      <Grid container sx={{ position: "relative !important" }} className={classes.card}>
        <Grid item xs={12} className={classes.calendar}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={`${t("calendar.locale")}`}
          >
            <DateCalendar
              slots={{
                day: highlightDay,
              }}
              slotProps={{
                day: {
                  upcomingEvents,
                } as any,
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sx={{ padding: "0 16px 4px 16px" }}>
          <Divider />
        </Grid>
        {upcomingEvents.map((event, index) =>
        <Grid item container xs={12} key={index}>
          <Grid item container xs={12} sx={{ padding: "0 16px" }}>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <div className={classes.mainDay}>
                {event.date}
              </div>
              <div className={classes.mainMonth}>
                {event.month}
              </div>
            </Grid>
            <Grid item xs={1} sx={{ padding: "4px" }}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={5} sx={{ padding: "4px 0" }}>
              <div className={classes.dateType}>
                {t("main.dashboard.viewing.appointment")}
              </div>
              <div className={classes.dateName}>
                {event.name}
              </div>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "center", padding: "4px 0" }}>
              <div className={classes.dateType}>
                { " " }
              </div>
              <div className={classes.dateTime}>
                {event.time}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ padding: "4px 16px" }}>
            <Divider />
          </Grid>
        </Grid>
        )}
        <Grid item xs={12} display="flex" justifyContent="right" sx={{ padding: "16px" }}>
          <Button className={classes.button} onClick={handleClick}>
            { t("main.dashboard.view.all.appointments") }
            <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
