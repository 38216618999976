export const ROOT = "/";
export const LOGIN = "/login";
export const SIGN_UP = "/signup";
export const DASHBOARD = "/dashboard";
export const OAUTH2_REDIRECT = "/oauth2/redirect";
export const CURRENT_USER = "/user/me";
export const PROFILE = "/profile";
export const ESTATE = "/estate";
export const CUSTOMER_APPLICATION = "/application";
export const CUSTOMER_APPLICATION_PREVIEW = "/application/preview";
export const OBJECT_DASHBOARD = "/object";
export const OBJECTS_PAGE = "/objects";
export const CALENDER = "/calender";
export const DOCUMENTS = "/documents";
export const NEWS = "/news";
export const SPECIAL_OFFERS = "/offers";
export const CONTACT = "/contact";
export const CONTRACT_DETAILS = "/contract";
export const DATA_POLICY = "/dataPolicy";
export const IMPRINT = "/imprint";
export const COOKIES = "/cookies";
export const DISCLAIMER = "/disclaimer";
