import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: { display: "flex", flexDirection: "column" },
  columnContainer: {
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  infoContainer: {
    position: "relative", // Set position to relative
    flexGrow: 1,
  },
  info: {
    position: "absolute", // Set position to absolute
    flexGrow: 1,
  },
  typography: {
    //color: theme.palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
}));
