import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const appBarHeight = 64;
export const appBarPadding = 8;

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    backgroundColor: theme.palette.common.white,
    justifyContent: "space-between",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    height: appBarHeight,
    padding: appBarPadding,
  },
  headerContainer: {
    padding: 8,
  },
  left: {
    display: "flex",
    alignItems: "center",
    minWidth: 0,
    cursor: "pointer",
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    height: "auto",
    width: 250,
    marginLeft: 64,
  },
  logoImg: {
    height: "100%",
    width: "100%",
  },
}));
