import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import CustomTextField from "../../Customs/CustomTextField/CustomTextField";
import { Grid } from "@mui/material";
import {
  CustomerContext,
  CustomerState,
} from "../../../context/CustomerContext";

interface Props {
  isDefaultExpand?: boolean;
}
const ContactData = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { customerState, updateCustomerState, errors, updateErrors } = useContext(CustomerContext);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const updatedCustomerState: CustomerState = {
      ...customerState,
      customer: {
        ...customerState.customer,
      },
    };

    if (name === "displayId") {
      updatedCustomerState.customer = {
        ...updatedCustomerState.customer,
        object: {
          ...updatedCustomerState.customer?.object,
          displayId: value,
        },
      };
    } else {
      updatedCustomerState.customer = {
        ...updatedCustomerState.customer,
        [name]: value,
      };
    }

    updateCustomerState(updatedCustomerState);

    const newErrors = { ...errors };
    if (name === "displayId") {
      newErrors.displayId = "";
    }

    if (name === "firstName") {
      newErrors.firstName = "";
    }

    if (name === "lastName") {
      newErrors.lastName = "";
    }

    if (name === "street") {
      newErrors.street = "";
    }

    if (name === "postalCode") {
      newErrors.postalCode = "";
    }

    if (name === "city") {
      newErrors.city = "";
    }

    if (name === "email") {
      newErrors.email = "";
    }

    updateErrors(newErrors);
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion
        label={t("customer.application.form.personal.data.contact.data")}
        isExpand={props.isDefaultExpand}
      >
        <Grid container className={classes.columnContainer} spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              label={t("customer.application.form.personal.data.object.id")}
              value={customerState.customer.object.displayId}
              name="displayId"
              onChange={handleChange}
              err={errors.displayId}
              disabled
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} md={6}>
              <CustomTextField
                label={t("customer.application.form.personal.data.first.name.required")}
                value={customerState.customer.firstName}
                name="firstName"
                onChange={handleChange}
                err={errors.firstName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomTextField
                label={t("customer.application.form.personal.data.last.name.required")}
                value={customerState.customer.lastName}
                name="lastName"
                onChange={handleChange}
                err={errors.lastName}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              label={t("customer.application.form.personal.data.street.house.number")}
              value={customerState.customer.street}
              name="street"
              onChange={handleChange}
              err={errors.street}
            />
          </Grid>

          <Grid
            item
            container
            spacing={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} md={3}>
              <CustomTextField
                label={t("customer.application.form.personal.data.zip.code")}
                value={customerState.customer.postalCode}
                name="postalCode"
                onChange={handleChange}
                err={errors.postalCode}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <CustomTextField
                label={t("customer.application.form.personal.data.city")}
                value={customerState.customer.city}
                name="city"
                onChange={handleChange}
                err={errors.city}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} md={6}>
              <CustomTextField
                label={t("customer.application.form.personal.data.email")}
                value={customerState.customer.email}
                name="email"
                onChange={handleChange}
                err={errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomTextField
                label={t("customer.application.form.personal.data.telefon")}
                value={customerState.customer.phoneNumber}
                name="phoneNumber"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default ContactData;

//vertical accordion name
