import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useStyles, FooterHeight } from "./styles";
import { getCurrentUser, login } from "../../../services/authHandler";
import Alert from "react-s-alert";
import { DASHBOARD, SIGN_UP } from "../../../routes/ROUTES";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  ACCESS_TOKEN,
} from "../../../services/CONSTANTS";
import { AuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { SideBarContext } from "../../../context/SideBarContext";
import logoImg from "../../../assets/img/logo-graphic-only.png";

interface FormData {
  email: string;
  password: string;
}

export const Footer = () => {
  const { t } = useTranslation();

  const navigationItems = [
    { key: "contact", text: t("main.contact"), url: "https://www.smart-agent.eu/contact/" },
    { key: "data_policy", text: t("main.privacyPolicy"), url: "https://www.smart-agent.eu/datapolicy/" },
    { key: "disclaimer", text: t("main.disclaimer"), url: "https://www.smart-agent.eu/disclaimer/" },
    { key: "cookies", text: t("main.cookiePolicy"), url: "https://www.smart-agent.eu/cookies/" },
    { key: "imprint", text: t("main.imprint"), url: "https://www.smart-agent.eu/imprint/" },
  ];


  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: FooterHeight,
      }}
      component="footer"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {navigationItems.map((item) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              onClick={() => window.open(item.url, "_blank")}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <ListItemText>
                <Typography variant="body2" color="white">
                  {item.text}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
      <Typography variant="body2" color="#687298" align="center">
        {" © "}
        {new Date().getFullYear()}
        {" Smart Agent GmbH"}
      </Typography>
    </Box>
  );
};

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { updateAuthStatus } = useContext(AuthContext);
  const [loading, isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { setVisibility } = useContext(SideBarContext);
  useEffect(() => {
    setVisibility(false);
  }, [setVisibility]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  let searchParamEmail = searchParams.get("email");
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (location.state && location.state.error) {
      setTimeout(() => {
        Alert.error(location.state.error, {
          timeout: 5000,
        });
        navigate(location.pathname, { state: {} });
      }, 100);
    }
  }, [location.pathname, location.state, navigate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onsSubmit = (event: FormEvent<HTMLFormElement>) => {
    isLoading(true);
    event.preventDefault();
    const loginRequest = Object.assign({}, formData);
    login(loginRequest)
      .then((response) => {
        isLoading(false);
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        Alert.success(`${t("login.success.message")}`);
        getCurrentUser()
          .then((user) => {
            updateAuthStatus({
              loading: false,
              authenticated: true,
              currentUser: user,
            });
            navigate(DASHBOARD);
          })
          .catch((err) => {
            updateAuthStatus({ loading: false, authenticated: true });
            navigate(DASHBOARD);
            Alert.error((err && err.message) || t("error"));
          });
      })
      .catch((error) => {
        Alert.error(
          (error && error.message) ||
            (error && error.status === 401 && "Invalid Login credentials.") ||
            t("error")
        );
        isLoading(false);
      });
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const isConfirmation = queryParameters.get("confirmed");
//  console.log(isConfirmation)

  return (
    <div className={classes.mainContainer}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
        style={{ flex: 1 }}
      >
        <Grid item className={classes.signUpContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.itemContainer}>
              <img src={logoImg} alt="Logo" width="64" />
            </Grid>
            <Grid item xs={12} className={classes.itemContainer}>
              <Typography variant="subtitle1">{t("login.title")}</Typography>
            </Grid>
            {isConfirmation === "true" && (
              <Grid item xs={12} className={classes.itemContainer} p={3}>
                <Typography variant="body1">
                  {t("login.emailConfirmed")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.itemContainer}>
              <form onSubmit={onsSubmit}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t("login.email")}
                        defaultValue={searchParamEmail}
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.passwordSpacer}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="password">
                          {t("login.password")}
                        </InputLabel>
                        <OutlinedInput
                          id="password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          required={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} alignSelf="center" className={classes.loginButton}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button type="submit" variant="contained" fullWidth>
                          {t("home.login")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.itemContainer}>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label={t("login.or")} />
                      </Divider>
                    </Grid>
                    <Grid item xs={12} className={classes.redirectContainer}>
                      <Button onClick={() => navigate(SIGN_UP)} variant="outlined">
                        {t("login.signup.button")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Fixed Footer */}
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </div>
  );
};

export default Login;
