import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    //borderRadius: 0,
    boxShadow: colors.cardShadow,
    borderBottom: "1px solid black",
  },
  mainDay: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
  },
  mainMonth: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightRegular,
  },
  dateType: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextSecondary,
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightRegular,
  },
  dateName: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightBold,
  },
  dateTime: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightBold,
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "100%",
    width: "2px",
  },
  mainDate: {
    "&:last-child > div:last-child": {
      display: "none",
    },
  },
}));
