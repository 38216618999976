import ObjectCard from "../ObjectCard/ObjectCard";
import { ObjectContext } from "../../../../context/ObjectContext";
import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
};

const CardDeck = () => {
  const classes = useStyles();
  const { relativeObjects } = useContext(ObjectContext);

  return (
    <Grid container className={classes.deckContainer} pb={2}>
      <Slider {...settings}>
        {relativeObjects.map((relativeObject, index) => (
          <Grid item key={index}>
            <ObjectCard relativeObject={relativeObject} />
          </Grid>
        ))}
      </Slider>
    </Grid>
  );
};
export default CardDeck;
