import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  infoAlert: {
    background: colors.smartAgentBlue20pct,
    fontSize: colors.fontSizeSmall,
    "& .MuiAlert-icon": {
      color: colors.smartAgentBlue,
    },
    padding: "0 12px",
  },
}));
