import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  stepperContainer: {
    margin: "16px 0",
    padding: "0 16px !important",
  },
  card: {
    padding: "16px",
    borderRadius: colors.cardBorderRadius,
    backgroundColor: colors.smartAgentDarkBlue,
    boxShadow: colors.cardShadow,
  },
  topCard: {
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  bottomCard: {
    borderTopLeftRadius: "0 !important",
    borderTopRightRadius: "0 !important",
  },
  middleCard: {
    borderRadius: "0",
    paddingBottom: "0",
  },
  cardVariant: {
    backgroundColor: colors.smartAgentChatbotBlue,
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
}));
