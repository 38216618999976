import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  columnContainer: {
    flexDirection: "column",
  },
  bodyText: { },
  select: {
    /*background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",*/
  },
  datePicker: {
    /*background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",*/
    width: "100%",
    "& input": {
      padding: "8.5px 14px",
    },
    "& label[data-shrink=\"false\"]": {
      marginTop: "-7.5px",
    },
  },
  info: {
    fontWeight: colors.fontWeightBold,
    color: colors.smartAgentGreen,
  },
  adultBlock: {
    borderRadius: colors.cardBorderRadius,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    margin: "0px",
    width: "100%",
    padding: "0 8px 8px 0",
  },
  childBox: {
    borderRadius: colors.cardBorderRadius,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    padding: "8px !important",
    margin: "0px 0px 0px 16px",
  },
  textField: {
    /*background: "#FFFFFF",

    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",*/
  },
  imageContainer: {
    display: "flex",
    marginTop: 8,
  },
  fileButton: {
    display: "flex",
    //color: theme.palette.common.white,
    justifyContent: "flex-start",
    textTransform: "none",
    //fontSize: "16px",
    height: "56px",
    //border: "1px white solid",
    //borderRadius: 0,
    "&:hover": {
      //backgroundColor: theme.palette.common.black,
      //color: theme.palette.common.white,
    },
  },
  icon: {
    marginRight: "8px",
  },
  previewImage: {
    maxWidth: "250px",
    margin: "16px 0",
    borderRadius: "4px",
    border: "1px solid #e7e7e7",
  },
  removeButton: {
    position: "absolute",
    top: 16,
    right: 0,
    zIndex: 2,
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
}));
