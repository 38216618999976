import { CircularProgress, Grid } from "@mui/material";
import React from "react";

export const Loader = () => {
  return (
    <Grid
      container={true}
      flexDirection="row"
      display="flex"
      sx={{
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12} display="flex" justifyContent="center">
        <CircularProgress color="success" size={100} />
      </Grid>
    </Grid>
  );
};
