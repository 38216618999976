import React from "react";
export interface AuthStatus {
    currentUser?: {
        email: '',
        emailVerified: boolean,
        id:string
        imageUrl: string
        name: string
        password: string
        provider: string
        providerId: string
    },
    authenticated: boolean,
    loading: boolean,
}

export interface AuthContext {
    authStatus: AuthStatus,
    updateAuthStatus: (props: AuthStatus) => void,
}

// Seriously? Pleeeeease choose unique names. It's absulutly not confusing that way…
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthContext = React.createContext<AuthContext>({
    authStatus: {
        authenticated: false,
        loading: true,
    },
    updateAuthStatus: () => {}
});
