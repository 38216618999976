import { API_BASE_URL } from "../CONSTANTS";
import { request } from "../authHandler";
import {
  titleSuggestionRequest,
  descriptionSuggestionRequest,
  areaSuggestionRequest,
  equipmentsSuggestionRequest,
  messageSuggestionRequest,
} from "./types";

export function suggestTitle(titleRequest: titleSuggestionRequest) {
  return request({
    url: API_BASE_URL + "/suggestion/title",
    method: "POST",
    body: JSON.stringify(titleRequest),
  });
}

export function suggestDescription(
  descriptionRequest: descriptionSuggestionRequest
) {
  return request({
    url: API_BASE_URL + "/suggestion/description",
    method: "POST",
    body: JSON.stringify(descriptionRequest),
  });
}

export function suggestArea(areaRequest: areaSuggestionRequest) {
  return request({
    url: API_BASE_URL + "/suggestion/area",
    method: "POST",
    body: JSON.stringify(areaRequest),
  });
}

export function suggestEquipment(
  equipmentRequest: equipmentsSuggestionRequest
) {
  return request({
    url: API_BASE_URL + "/suggestion/equipments",
    method: "POST",
    body: JSON.stringify(equipmentRequest),
  });
}

export function suggestMessage(messageRequest: messageSuggestionRequest) {
  return request({
    url: API_BASE_URL + "/customer/suggestion/message",
    method: "POST",
    body: JSON.stringify(messageRequest),
  });
}
