import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: { display: "flex", flexDirection: "column" },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
  },
  contentContainerCol: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    flexDirection: "column",
  },
  typography: {
    //color: theme.palette.common.white,
    //fontSize: "18px",
    //lineHeight: "24px",
  },
  helperText: {
    //color: theme.palette.common.white,
    fontSize: "12px",
    lineHeight: "18px",
  },
  divider: {
    color: colors.darkLine,
    border: "1px solid",
  },
  contentContainerWithSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  typographyCosts: {
    //color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 800,
  },
  infoContainer: {
    position: "relative", // Set position to relative
    flexGrow: 1,
  },
  info: {
    position: "absolute", // Set position to absolute
    flexGrow: 1,
  },
  datePicker: {
    background: "#FFFFFF",
    height: "56px",
    padding: "0px, 12px, 0px, 12px",
    width: "100%",
  },
}));
