import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import { Grid, Typography } from "@mui/material";
import ToggleButton from "../../Customs/ToggleButton/ToggleButton";
import { FormData } from "../Form/types";
import FlooringAndCondition from "../FlooringAndCondition/FlooringAndCondition";

import {
  Characteristics,
  Environment,
  Services,
  WalkingDistance,
} from "./types";

interface DetailedInformationProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

const DetailedInformation = (props: DetailedInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [environmentState, setEnvironmentState] = useState<Environment>({
    quiet: false,
    natural: false,
    greenStreet: false,
    convenient: false,
    centralLocation: false,
    livelyArea: false,
    barsAndRestaurants: false,
    parking: false,
  });

  const [walkingDistanceState, setWalkingDistanceState] =
    useState<WalkingDistance>({
      cityCenter: false,
      restaurant: false,
      cafe: false,
      bar: false,
      playground: false,
      supermarket: false,
      bakery: false,
      butcher: false,
      market: false,
      greenGrocer: false,
    });

  const [characteristicsState, setCharacteristicsState] =
    useState<Characteristics>({
      oldBuilding: false,
      facade: false,
      bright: false,
      spacious: false,
      stylish: false,
      extravagant: false,
      traditional: false,
      natural: false,
      modern: false,
      classic: false,
      functional: false,
      inexpensive: false,
      cute: false,
    });

  const [servicesState, setServicesState] = useState<Services>({
    bicycleParking: false,
    strollerParking: false,
    bathtub: false,
    window: false,
    stove: false,
    commonCleaning: false,
  });

  const environmentOptions = [
    { key: "quiet", label: t("extra.info.environment.quite") },
    { key: "natural", label: t("extra.info.environment.natural") },
    { key: "greenStreet", label: t("extra.info.environment.green") },
    { key: "convenient", label: t("extra.info.environment.convenient") },
    { key: "centralLocation", label: t("extra.info.environment.central") },
    { key: "livelyArea", label: t("extra.info.environment.lively") },
    {
      key: "barsAndRestaurants",
      label: t("extra.info.environment.trendy"),
    },
    { key: "parking", label: t("extra.info.environment.parking") },
  ];

  const walkingDistanceOptions = [
    { key: "cityCenter", label: t("extra.info.walking.center") },
    { key: "restaurant", label: t("extra.info.walking.restaurant") },
    { key: "cafe", label: t("extra.info.walking.cafe") },
    { key: "bar", label: t("extra.info.walking.bar") },
    { key: "playground", label: t("extra.info.walking.playground") },
    { key: "supermarket", label: t("extra.info.walking.supermarket") },
    { key: "bakery", label: t("extra.info.walking.bakery") },
    { key: "butcher", label: t("extra.info.walking.butcher") },
    { key: "market", label: t("extra.info.walking.market") },
    { key: "greenGrocer", label: t("extra.info.walking.green.grocer") },
  ];

  const characteristicsOptions = [
    { key: "oldBuilding", label: t("extra.info.character.old") },
    { key: "facade", label: t("extra.info.character.facade") },
    { key: "bright", label: t("extra.info.character.bright") },
    { key: "spacious", label: t("extra.info.character.spacious") },
    { key: "stylish", label: t("extra.info.character.stylish") },
    { key: "extravagant", label: t("extra.info.character.extravagant") },
    { key: "modern", label: t("extra.info.character.modern") },
    { key: "classic", label: t("extra.info.character.classic") },
    { key: "traditional", label: t("extra.info.character.traditional") },
    { key: "natural", label: t("extra.info.character.natural") },
    { key: "functional", label: t("extra.info.character.functional") },
    { key: "inexpensive", label: t("extra.info.character.cheap") },
    { key: "cute", label: t("extra.info.character.cute") },
  ];

  const servicesOptions = [
    { key: "bicycleParking", label: t("extra.info.services.bike") },
    { key: "strollerParking", label: t("extra.info.services.stroller") },
    { key: "bathtub", label: t("extra.info.services.bathttub") },
    { key: "window", label: t("extra.info.services.windows") },
    { key: "stove", label: t("extra.info.services.stove") },
    {
      key: "commonCleaning",
      label: t("extra.info.services.cleaning"),
    },
  ];

  const handleEnvironmentButtonClick = (key: keyof Environment) => {
    setEnvironmentState((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: !prevState[key],
      };

      return updatedState;
    });
  };

  useEffect(() => {
    const selectedEnvironmentKeys = Object.keys(environmentState).filter(
      (k) => environmentState[k as keyof Environment]
    );

    const selecteWalkingDistancedKeys = Object.keys(
      walkingDistanceState
    ).filter((k) => walkingDistanceState[k as keyof WalkingDistance]);

    const selectedCharacteristicsKeys = Object.keys(
      characteristicsState
    ).filter((k) => characteristicsState[k as keyof Characteristics]);
    const selectedServicesKeys = Object.keys(servicesState).filter(
      (k) => servicesState[k as keyof Services]
    );

    props.setFormData((prevData: FormData) => ({
      ...prevData,
      areaCharacteristics: selectedEnvironmentKeys,
      reachability: selecteWalkingDistancedKeys,
      rentalPropertyCharacters: selectedCharacteristicsKeys,
      specialFeaturesAndServices: selectedServicesKeys,
    }));
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    environmentState,
    walkingDistanceState,
    characteristicsState,
    servicesState,
  ]);

  const handleWalkingDistanceButtonClick = (key: keyof WalkingDistance) => {
    setWalkingDistanceState((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: !prevState[key],
      };

      return updatedState;
    });
  };

  const handleCharacteristicsButtonClick = (key: keyof Characteristics) => {
    setCharacteristicsState((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: !prevState[key],
      };

      return updatedState;
    });
  };

  const handleServicesButtonClick = (key: keyof Services) => {
    setServicesState((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: !prevState[key],
      };

      return updatedState;
    });
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion label={"4. " + t("extra.info.environment.accordion.label")}>
        <Grid item container className={classes.columnContainer}>
          <Grid item mb={1}>
            <Typography variant="body1" className={classes.bodyText}>
              {t("extra.info.environment.label")}
            </Typography>
          </Grid>

          <Grid item container className={classes.rowContainer} mb={2} spacing={1}>
            {environmentOptions.map((option) => (
              <ToggleButton
                key={option.key}
                onClick={() =>
                  handleEnvironmentButtonClick(option.key as keyof Environment)
                }
                active={environmentState[option.key as keyof Environment]}
              >
                {option.label}
              </ToggleButton>
            ))}
          </Grid>

          <Grid item container className={classes.columnContainer}>
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("extra.info.services.label")}
              </Typography>
            </Grid>
            <Grid item container className={classes.rowContainer} mb={2} spacing={1}>
              {walkingDistanceOptions.map((option) => (
                <ToggleButton
                  key={option.key}
                  onClick={() =>
                    handleWalkingDistanceButtonClick(
                      option.key as keyof WalkingDistance
                    )
                  }
                  active={
                    walkingDistanceState[option.key as keyof WalkingDistance]
                  }
                >
                  {option.label}
                </ToggleButton>
              ))}
            </Grid>
          </Grid>

          <Grid item container className={classes.columnContainer}>
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("extra.info.character.label")}
              </Typography>
            </Grid>
            <Grid item container className={classes.rowContainer} mb={2} spacing={1}>
              {characteristicsOptions.map((option) => (
                <ToggleButton
                  key={option.key}
                  onClick={() =>
                    handleCharacteristicsButtonClick(
                      option.key as keyof Characteristics
                    )
                  }
                  active={
                    characteristicsState[option.key as keyof Characteristics]
                  }
                >
                  {option.label}
                </ToggleButton>
              ))}
            </Grid>
          </Grid>

          <Grid item container className={classes.columnContainer}>
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("extra.info.environment.accordion.label")}
              </Typography>
            </Grid>
            <Grid item container className={classes.rowContainer} mb={4} spacing={1}>
              {servicesOptions.map((option) => (
                <ToggleButton
                  key={option.key}
                  onClick={() =>
                    handleServicesButtonClick(option.key as keyof Services)
                  }
                  active={servicesState[option.key as keyof Services]}
                >
                  {option.label}
                </ToggleButton>
              ))}
            </Grid>
          </Grid>

          <Grid item container className={classes.columnContainer}>
            <FlooringAndCondition
              formData={props.formData}
              setFormData={props.setFormData}
            />
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default DetailedInformation;
