import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//import CustomButton from "../../Customs/CustomButton";
import ToggleButton from "../../Customs/ToggleButton/ToggleButton";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import ErrorText from "../../Common/ErrorText/ErrorText";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Entities, FormData } from "./types";

import {
  NumericFormat,
  PatternFormat,
  SourceInfo,
  NumberFormatValues,
 } from 'react-number-format';

import {
  getEstateType,
  getEstateTypeDetailName,
  getGermanState,
} from "../../../services/entitiyHandler";
import i18next from "i18next";
import PlaceDropDown from "../../Customs/PlaceDropDown/PlaceDropDown";
import { EstateType } from "../ExposeRenderer";

export interface ErrorState {
  name: string;
  state: string;
  address: string;
  zip: string;
  estateType: string;
  estateTypeDetailName: string;
  area: string;
  rooms: string;
}

interface Props {
  activeStep: number;
  formData: FormData;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleSelectChange: (event: SelectChangeEvent) => void;
  handleButtonClick: (key: keyof FormData) => void;
  handleRental: (setLoading: Dispatch<React.SetStateAction<boolean>>) => void;
  submitText: string;
  setFormData: Dispatch<SetStateAction<any>>;
  errors: ErrorState;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
  estateType: EstateType;
  realTimeZip: (zip: string) => void;
}

const PropertyDetails: React.FC<Props> = ({
  activeStep,
  formData,
  handleChange,
  handleSelectChange,
  handleButtonClick,
  handleRental,
  submitText,
  setFormData,
  errors,
  setErrors,
  estateType,
  realTimeZip,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const [estateTypes, setEstateTypes] = useState<Entities[]>([]);
  const [estateTypeDetailName, setEstateTypeDetailName] = useState<Entities[]>(
    []
  );
  const [germanState, setGermanState] = useState<Entities[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getEstateType().then((response) => {
      setEstateTypes(response);
    });
    getEstateTypeDetailName().then((response) => {
      setEstateTypeDetailName(response);
    });
    getGermanState().then((response) => {
      setGermanState(response);
    });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let hasError = false;
    if (
        formData.name === "" ||
        formData.zip === "" ||
        formData.state === "" ||
        formData.address === "" ||
        formData.estateType === "" ||
        formData.estateTypeDetailName === "" ||
        formData.area === "" ||
        formData.rooms === ""
      ) {
      hasError = true;
    }

    setErrors({
      ...errors,
      name: formData.name === "" ? t("estate.estateName.validation") : "",
      zip: formData.zip === "" ? t("estate.zip.validation") : "",
      state: formData.state === "" ? t("estate.state.validation") : "",
      address: formData.address === "" ? t("estate.address.validation") : "",
      estateType: formData.estateType === "" ? t("estate.estateType.validation") : "",
      estateTypeDetailName: formData.estateTypeDetailName === "" ? t("estate.estateTypeDetailName.validation") : "",
      area: formData.area === "" ? t("estate.area.validation") : "",
      rooms: formData.rooms === "" ? t("estate.rooms.validation") : "",
    });

    if (!hasError) {
      handleRental(setLoading);
    }
  };

  const [hoveredObject, setHoveredObject] = useState("");

  const handleMouseOver = (obj: string) => {
    setHoveredObject(obj);
  };

  const handleMouseOut = () => {
    setHoveredObject("");
  };

  const numericValueChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (sourceInfo.event) {
      const changeEvent = {
        target: {
          name: (sourceInfo.event.target as HTMLInputElement).name,
          value: values.value,
        }
      } as React.ChangeEvent<HTMLInputElement>;
      handleChange(changeEvent);
    }
  };

  const apparmentTypeIds = [
    "644a5b8ed411b0cdfb777946",
    "644a5be4d411b0cdfb777948",
    "644a5bfbd411b0cdfb777949",
    "644a5c0bd411b0cdfb77794a",
    "644a5c1ad411b0cdfb77794b",
    "645ba67888c6e97d1bf0c983",
    "645ba75ce128f377194444ce",
    "645ba780e128f377194444cf",
    "645ba79fe128f377194444d0",
    "646b8ab680563ab72867e8a0",
  ];

  const houseTypeIds = [
    "644a5c2bd411b0cdfb77794c",
    "644a5c55d411b0cdfb77794f",
    "644a5c65d411b0cdfb777950",
    "644a5cd6d411b0cdfb777951",
    "644a5ce4d411b0cdfb777952",
    "645ba7ebe128f377194444d1",
    "645ba83be128f377194444d3",
    "646b8ab680563ab72867e8a0",
  ];

  const langCompare = (a: any, b: any, lang: string) => {
    const aValue = lang === "en" ? a.name_en : a.name_de;
    const bValue = lang === "en" ? b.name_en : b.name_de;
    if (aValue.charAt(0) === ".") {
      return 1;
    } else if (bValue.charAt(0) === ".") {
      return -1;
    } else {
      return aValue.localeCompare(bValue);
    }
  };

  const langSlice = (item: any, lang: string) => {
    let itemValue = lang === "en" ? item.name_en : item.name_de;
    if (itemValue.charAt(0) === ".") {
      itemValue = itemValue.substring(1);
    }
    return itemValue;
  };

  return (
    <form onSubmit={handleSubmit}>
      {(activeStep === 0 || activeStep === 1 || activeStep === 2) && (
        <Grid
          container
          spacing={2}
          direction="column"
          className={classes.mainGrid}
        >
          {/* First accordion */}
          <Grid item container>
            <Accordion
              square
              className={classes.accordion}
              defaultExpanded={activeStep === 0}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className={classes.expandMoreIcon} />
                }
                id="accordion1"
                className={classes.expandMoreIcon}
              >
                <Typography className={classes.accordionHead}>
                  { "1. " + t("accordion.label.name")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} direction="column">
                  <Grid
                    item
                    container
                    spacing={2}
                    direction="row"
                    onMouseOver={() => handleMouseOver("estateName")}
                    onMouseOut={handleMouseOut}
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t("estate.estateName")}
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        error={!!errors.name}
                      />
                      {errors.name && <ErrorText>{errors.name}</ErrorText>}
                    </Grid>
                    {hoveredObject === "estateName" && (
                      <Grid item xs={6} className={classes.container}>
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.estateName.info")}</InfoAlert>
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    spacing={2}
                    direction="row"
                    onMouseOver={() => handleMouseOver("address")}
                    onMouseOut={handleMouseOut}
                  >
                    <Grid item xs={6}>
                      <PlaceDropDown
                        address={formData.address}
                        setAddress={setFormData}
                        errors={errors}
                        setErrors={setErrors}
                        realTimeZip={realTimeZip}
                      />
                      {errors.address && (
                        <ErrorText>{errors.address}</ErrorText>
                      )}
                    </Grid>
                    {hoveredObject === "address" && (
                      <Grid item xs={6} className={classes.container}>
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.address.info")}</InfoAlert>
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    spacing={2}
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                  >
                    <Grid item xs={12} md={6}>
                      <PatternFormat
                        format="#####"
                        customInput={TextField}
                        fullWidth
                        label={t("estate.zip")}
                        value={formData.zip}
                        name="zip"
                        onValueChange={numericValueChange}
                        error={!!errors.zip}
                      />
                      {errors.zip && <ErrorText>{errors.zip}</ErrorText>}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.state}
                        className={classes.select}
                      >
                        <InputLabel>
                          {t("estate.state")}
                        </InputLabel>
                        <Select
                          label={t("estate.state")}
                          id="state"
                          value={germanState.length ? formData.state : ""}
                          name="state"
                          onChange={handleSelectChange}
                          displayEmpty
                        >
                          {germanState.sort((a, b) => langCompare(a, b, lang)).map((item) => {
                            return (
                              <MenuItem value={item.id} key={item.id}>
                                {langSlice(item, lang)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {errors.state && <ErrorText>{errors.state}</ErrorText>}
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Second accordion */}
          <Grid item container>
            <Accordion square className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className={classes.expandMoreIcon} />
                }
                id="accordion2"
                className={classes.expandMoreIcon}
              >
                <Typography className={classes.accordionHead}>
                  {"2. " + t("accordion.label.basic.data")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} direction="column">
                  <Grid
                    item
                    container
                    spacing={2}
                    direction="row"
                    onMouseOver={() => handleMouseOver("estateType")}
                    onMouseOut={handleMouseOut}
                  >
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        className={classes.select}
                        error={!!errors.estateType}
                      >
                        <InputLabel>
                          {t("estate.estateType")}
                        </InputLabel>
                        <Select
                          id="estate-type"
                          value={estateTypes.length ? formData.estateType : ""}
                          name="estateType"
                          onChange={handleSelectChange}
                          label={t("estate.estateType")}
                        >
                          {estateTypes.sort((a, b) => langCompare(a, b, lang)).map((item) => {
                            return (
                              <MenuItem value={item.id} key={item.id}>
                                {langSlice(item, lang)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {errors.estateType && (
                        <ErrorText>{errors.estateType}</ErrorText>
                      )}
                    </Grid>
                    {hoveredObject === "estateType" && (
                      <Grid item xs={6} className={classes.container}>
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.estateType.info")}</InfoAlert>
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  {estateType === EstateType.NotSelected ? null : ( //render notSeledted
                    //render else
                    <Grid
                      item
                      container
                      spacing={2}
                      direction="row"
                      onMouseOver={() =>
                        handleMouseOver("estateTypeDetailName")
                      }
                      onMouseOut={handleMouseOut}
                    >
                      <Grid item xs={6}>
                        {" "}
                        <FormControl
                          fullWidth
                          className={classes.select}
                          error={!!errors.estateTypeDetailName}
                        >
                          <InputLabel>
                            {t("estate.estateTypeDetailName")}
                          </InputLabel>
                          <Select
                            id="estateTypeDetailName"
                            value={estateTypeDetailName.length ? formData.estateTypeDetailName : ""}
                            name="estateTypeDetailName"
                            onChange={handleSelectChange}
                            label={t("estate.estateTypeDetailName")}
                          >
                            {estateTypeDetailName.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                              if (
                                (estateType === EstateType.Apartment && apparmentTypeIds.includes(item.id)) ||
                                (estateType === EstateType.House && houseTypeIds.includes(item.id))
                              ) {
                                return (
                                  <MenuItem value={item.id} key={index}>
                                    {langSlice(item, lang)}
                                  </MenuItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Select>
                        </FormControl>
                        {errors.estateTypeDetailName && (
                          <ErrorText>{errors.estateTypeDetailName}</ErrorText>
                        )}
                      </Grid>
                      {hoveredObject === "estateTypeDetailName" && (
                        <Grid item xs={6} className={classes.container}>
                          <div className={classes.info}>
                            <InfoAlert>
                              {t("estate.estateTypeDetailName.info")}
                            </InfoAlert>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {estateType === EstateType.House ? (
                    // Render components for house
                    <Grid item container spacing={2} direction="row">
                      <Grid item container xs={6} direction="row" spacing={1}>
                        <Grid
                          xs={6}
                          item
                          onMouseOver={() => handleMouseOver("area")}
                          onMouseOut={handleMouseOut}
                        >
                          <NumericFormat
                            decimalSeparator={`${t("numbers.decimalSeparator")}`}
                            allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                            thousandSeparator={`${t("numbers.thousandSeparator")}`}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            valueIsNumericString={true}
                            customInput={TextField}
                            fullWidth
                            label={t("estate.area")}
                            value={formData.area}
                            name="area"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">{t("form.adornment.area") ?? ""}</InputAdornment>
                              ),
                            }}
                            onValueChange={numericValueChange}
                            error={!!errors.area}
                          />
                          {errors.area && <ErrorText>{errors.area}</ErrorText>}

                        </Grid>
                        <Grid
                          xs={6}
                          item
                          onMouseOver={() => handleMouseOver("plotArea")}
                          onMouseOut={handleMouseOut}
                        >
                          <NumericFormat
                            decimalSeparator={`${t("numbers.decimalSeparator")}`}
                            allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                            thousandSeparator={`${t("numbers.thousandSeparator")}`}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            valueIsNumericString={true}
                            customInput={TextField}
                            fullWidth
                            label={t("estate.plotArea")}
                            value={formData.plotArea}
                            name="plotArea"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">{t("form.adornment.area") ?? ""}</InputAdornment>
                              ),
                            }}
                            onValueChange={numericValueChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className={classes.container}>
                        {hoveredObject === "area" && (
                          <div className={classes.info}>
                            <InfoAlert>{t("estate.area.info")}</InfoAlert>
                          </div>
                        )}
                        {hoveredObject === "plotArea" && (
                          <div className={classes.info}>
                            <InfoAlert>{t("estate.plotArea.info")}</InfoAlert>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    // Render components for non-house
                    <Grid
                      item
                      container
                      spacing={2}
                      direction="row"
                      onMouseOver={() => handleMouseOver("area")}
                      onMouseOut={handleMouseOut}
                    >
                      <Grid item xs={6}>
                        <NumericFormat
                          decimalSeparator={`${t("numbers.decimalSeparator")}`}
                          allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                          thousandSeparator={`${t("numbers.thousandSeparator")}`}
                          thousandsGroupStyle="thousand"
                          allowNegative={false}
                          decimalScale={0}
                          valueIsNumericString={true}
                          customInput={TextField}
                          fullWidth
                          label={t("estate.area")}
                          value={formData.area}
                          name="area"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">{t("form.adornment.area") ?? ""}</InputAdornment>
                            ),
                          }}
                          onValueChange={numericValueChange}
                          error={!!errors.area}
                        />
                        {errors.area && <ErrorText>{errors.area}</ErrorText>}
                      </Grid>

                      {hoveredObject === "area" && (
                        <Grid item xs={6} className={classes.container}>
                          <div className={classes.info}>
                            <InfoAlert>{t("estate.area.info")}</InfoAlert>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item container direction="row" spacing={2}>
                    <Grid item xs={6} className={classes.container}>
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          onMouseOver={() =>
                            handleMouseOver("constructionYear")
                          }
                          onMouseOut={handleMouseOut}
                        >
                          <PatternFormat
                            format="####"
                            customInput={TextField}
                            fullWidth
                            label={t("estate.constructionYear")}
                            value={formData.constructionYear}
                            name="constructionYear"
                            onValueChange={numericValueChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          onMouseOver={() => handleMouseOver("lastRefurbished")}
                          onMouseOut={handleMouseOut}
                        >
                          <PatternFormat
                            format="####"
                            customInput={TextField}
                            fullWidth
                            label={t("estate.lastRefurbished")}
                            value={formData.lastRefurbished}
                            name="lastRefurbished"
                            onValueChange={numericValueChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} className={classes.container}>
                      {hoveredObject === "constructionYear" && (
                        <div className={classes.info}>
                          <InfoAlert>
                            {t("estate.constructionYear.info")}
                          </InfoAlert>
                        </div>
                      )}
                      {hoveredObject === "lastRefurbished" && (
                        <div className={classes.info}>
                          <InfoAlert>
                            {t("estate.lastRefurbished.info")}
                          </InfoAlert>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    spacing={2}
                    direction="row"
                    onMouseOver={() => handleMouseOver("rooms")}
                    onMouseOut={handleMouseOut}
                  >
                    <Grid item xs={6}>
                      {" "}
                      <NumericFormat
                        decimalSeparator={`${t("numbers.decimalSeparator")}`}
                        allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                        thousandSeparator={`${t("numbers.thousandSeparator")}`}
                        thousandsGroupStyle="thousand"
                        allowNegative={false}
                        decimalScale={1}
                        valueIsNumericString={true}
                        customInput={TextField}
                        fullWidth
                        label={t("estate.rooms")}
                        value={formData.rooms}
                        name="rooms"
                        onValueChange={numericValueChange}
                        error={!!errors.rooms}
                      />
                      {errors.rooms && <ErrorText>{errors.rooms}</ErrorText>}
                    </Grid>

                    {hoveredObject === "rooms" && (
                      <Grid item xs={6} className={classes.container}>
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.room.info")}</InfoAlert>
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item container direction="row" spacing={2}>
                    <Grid item xs={6} className={classes.container}>
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          onMouseOver={() => handleMouseOver("bedRooms")}
                          onMouseOut={handleMouseOut}
                        >
                          <NumericFormat
                            decimalSeparator={`${t("numbers.decimalSeparator")}`}
                            allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                            thousandSeparator={`${t("numbers.thousandSeparator")}`}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={1}
                            valueIsNumericString={true}
                            customInput={TextField}
                            fullWidth
                            label={t("estate.bedRooms")}
                            value={formData.bedRooms}
                            name="bedRooms"
                            onValueChange={numericValueChange}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          onMouseOver={() => handleMouseOver("bathRooms")}
                          onMouseOut={handleMouseOut}
                        >
                          <NumericFormat
                            decimalSeparator={`${t("numbers.decimalSeparator")}`}
                            allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                            thousandSeparator={`${t("numbers.thousandSeparator")}`}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={1}
                            valueIsNumericString={true}
                            customInput={TextField}
                            fullWidth
                            label={t("estate.bathRooms")}
                            value={formData.bathRooms}
                            name="bathRooms"
                            onValueChange={numericValueChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.container}>
                      {hoveredObject === "bedRooms" && (
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.bedRooms.info")}</InfoAlert>
                        </div>
                      )}
                      {hoveredObject === "bathRooms" && (
                        <div className={classes.info}>
                          <InfoAlert>{t("estate.bathRooms.info")}</InfoAlert>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  {estateType === EstateType.Apartment ? (
                    // Render components for apartments

                    <Grid item container direction="row" spacing={2}>
                      <Grid item xs={6} className={classes.container}>
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{ flexDirection: { xs: "column", md: "row" } }}
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            md={6}
                            onMouseOver={() => handleMouseOver("floor")}
                            onMouseOut={handleMouseOut}
                          >
                            <NumericFormat
                              decimalSeparator={`${t("numbers.decimalSeparator")}`}
                              allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                              thousandSeparator={`${t("numbers.thousandSeparator")}`}
                              thousandsGroupStyle="thousand"
                              allowNegative={false}
                              decimalScale={0}
                              valueIsNumericString={true}
                              customInput={TextField}
                              fullWidth
                              label={t("estate.floor")}
                              value={formData.floor}
                              name="floor"
                              onValueChange={numericValueChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            onMouseOver={() =>
                              handleMouseOver("numberOfFloors")
                            }
                            onMouseOut={handleMouseOut}
                          >
                            <NumericFormat
                              decimalSeparator={`${t("numbers.decimalSeparator")}`}
                              allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                              thousandSeparator={`${t("numbers.thousandSeparator")}`}
                              thousandsGroupStyle="thousand"
                              allowNegative={false}
                              decimalScale={0}
                              valueIsNumericString={true}
                              customInput={TextField}
                              fullWidth
                              label={t("estate.numberOfFloors")}
                              value={formData.numberOfFloors}
                              name="numberOfFloors"
                              onValueChange={numericValueChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {hoveredObject === "floor" && (
                          <InfoAlert>{t("estate.floor.info")}</InfoAlert>
                        )}
                        {hoveredObject === "numberOfFloors" && (
                          <InfoAlert>
                            {t("estate.numberOfFloors.info")}
                          </InfoAlert>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    // Render components for non-apartments
                    <Grid
                      item
                      container
                      spacing={2}
                      direction="row"
                      onMouseOver={() => handleMouseOver("numberOfFloors")}
                      onMouseOut={handleMouseOut}
                    >
                      <Grid item xs={6}>
                        <NumericFormat
                          decimalSeparator={`${t("numbers.decimalSeparator")}`}
                          allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                          thousandSeparator={`${t("numbers.thousandSeparator")}`}
                          thousandsGroupStyle="thousand"
                          allowNegative={false}
                          decimalScale={0}
                          valueIsNumericString={true}
                          customInput={TextField}
                          fullWidth
                          label={t("estate.numberOfFloors")}
                          value={formData.numberOfFloors}
                          name="numberOfFloors"
                          onValueChange={numericValueChange}
                        />
                      </Grid>
                      {hoveredObject === "numberOfFloors" && (
                        <Grid item xs={6}>
                          <InfoAlert>
                            {t("estate.numberOfFloors.info")}
                          </InfoAlert>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Third accordion */}
          <Grid item container>
            <Accordion square className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className={classes.expandMoreIcon} />
                }
                //aria-controls="panel3-content"
                id="accordion3"
                className={classes.expandMoreIcon}
              >
                <Typography className={classes.accordionHead}>
                  {"3. " + t("accordion.label.facilities")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} direction="column">
                  <Grid item container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="body1" className={classes.bodyText}>
                        {t("accordion.label.facilities.multi")}
                      </Typography>
                    </Grid>
                    <Grid item container spacing={1} direction="row">
                      <ToggleButton
                        onClick={() => handleButtonClick("kitchen")}
                        active={formData.kitchen}
                      >
                        {t("estate.kitchen")}
                      </ToggleButton>
                      <ToggleButton
                        onClick={() => handleButtonClick("balconyTerrace")}
                        active={formData.balconyTerrace}
                      >
                        {t("estate.balconyTerrace")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("garden")}
                        active={formData.garden}
                      >
                        {t("estate.garden")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("guestWashRoom")}
                        active={formData.guestWashRoom}
                      >
                        {t("estate.guestWashRoom")}
                      </ToggleButton>
                      <ToggleButton
                        onClick={() => handleButtonClick("parking")}
                        active={formData.parking}
                      >
                        {t("estate.parking")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("basementLoft")}
                        active={formData.basementLoft}
                      >
                        {t("estate.basementLoft")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("firstTimeUse")}
                        active={formData.subsidized}
                      >
                        {t("estate.firstTimeUse")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("canBeWg")}
                        active={formData.canBeWg}
                      >
                        {t("estate.canBeWg")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("lift")}
                        active={formData.lift}
                      >
                        {t("estate.lift")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("stepLessAccess")}
                        active={formData.stepLessAccess}
                      >
                        {t("estate.stepLessAccess")}
                      </ToggleButton>

                      <ToggleButton
                        onClick={() => handleButtonClick("furnished")}
                        active={formData.subsidized}
                      >
                        {t("estate.furnished")}
                      </ToggleButton>
                      <ToggleButton
                        onClick={() => handleButtonClick("subsidized")}
                        active={formData.subsidized}
                      >
                        {t("estate.subsidized")}
                      </ToggleButton>
                      <ToggleButton
                        onClick={() => handleButtonClick("garage")}
                        active={formData.garage}
                      >
                        {t("estate.garage")}
                      </ToggleButton>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1} direction="column"></Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Forth accordion */}
          <Grid item container>
            <Accordion square className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className={classes.expandMoreIcon} />
                }
                //aria-controls="panel1-content"
                id="accordion4"
                className={classes.expandMoreIcon}
              >
                <Typography className={classes.accordionHead}>
                  {"4. " + t("accordion.label.energy")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} direction="column">
                  <Grid
                    item
                    container
                    spacing={2}
                    direction="row"
                    onMouseOver={() => handleMouseOver("energyUsage")}
                    onMouseOut={handleMouseOut}
                  >
                    <Grid item xs={6} sx={{ height: "150px !important" }}>
                      <NumericFormat
                        decimalSeparator={`${t("numbers.decimalSeparator")}`}
                        allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                        thousandSeparator={`${t("numbers.thousandSeparator")}`}
                        thousandsGroupStyle="thousand"
                        allowNegative={false}
                        decimalScale={1}
                        valueIsNumericString={true}
                        customInput={TextField}
                        fullWidth
                        label={t("estate.energyUsage")}
                        value={formData.energyUsage}
                        name="energyUsage"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{t("form.adornment.energy") ?? ""}</InputAdornment>
                          ),
                        }}
                        onValueChange={numericValueChange}
                      />
                    </Grid>

                    {hoveredObject === "energyUsage" && (
                      <Grid item xs={6}>
                        <InfoAlert>{t("estate.energyUsage.info")}</InfoAlert>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {(activeStep === 0 || activeStep === 1) && (
            <Grid item container direction="row" sx={{justifyContent: "space-between"}}>
              {isLoading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress color="success" />
                  <Typography variant="info" className={classes.loaddingText}>
                    {t("please.wait.message")}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Grid item>
                    {(
                      errors.name ||
                      errors.state ||
                      errors.zip ||
                      errors.address ||
                      errors.estateType ||
                      errors.estateTypeDetailName ||
                      errors.area ||
                      errors.rooms
                    ) && 
                      <ErrorText>
                        {t("error.fillout.all")}
                        <ul className={classes.errorBelow}>
                          {(errors.name) && <li>{errors.name}</li>}
                          {(errors.state) && <li>{errors.state}</li>}
                          {(errors.zip) && <li>{errors.zip}</li>}
                          {(errors.address) && <li>{errors.address}</li>}
                          {(errors.estateType) && <li>{errors.estateType}</li>}
                          {(errors.estateTypeDetailName) && <li>{errors.estateTypeDetailName}</li>}
                          {(errors.area) && <li>{errors.area}</li>}
                          {(errors.rooms) && <li>{errors.rooms}</li>}
                        </ul>
                      </ErrorText>
                    }
                  </Grid>
                  <Grid item>
                    <Button className={classes.button} type="submit">
                      {submitText}
                      <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

export default PropertyDetails;
