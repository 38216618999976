import React, { Dispatch, useContext, useEffect, useState } from "react";
import Alert from "react-s-alert";
import { useTranslation } from "react-i18next";
import { defaultFormState, FormData } from "./Form/types";
import { 
  CircularProgress, 
  Grid, 
  SelectChangeEvent,
  Button,
} from "@mui/material";
import HorizontalStepper from "./HorizontalStepper/HorizontalStepper";
import PropertyDetails from "./Form/FormComponent";
import RecommendedRent from "./RecommendedRent/RecommendedRent";
import InfoText from "../Customs/InfoText";
import {
  createRental,
  saveExpose,
  updateRental,
} from "../../services/rentalHandler";
import ImageAccordion from "./ImageHandler/ImageAccordion";

import CostCalculator from "./CostCalculator/CostCalculator";
import Heating from "./Heating/Heating";
import DetailedInformation from "./DetailedInformations/DetailedInformations";
import DesiredDocuments from "./DesiredDocuments/DesiredDocuments";
import Description from "./Description/Description";
import QualityAndPets from "./QualityAndPets/QualityAndPets";
import ExposePreview from "./ExposePreview/ExposePreview";
import ObjectsRenderer from "./RelativeObjects/ObjectsRenderer";
import { 
  useLocation,
} from "react-router-dom";
import { SideBarContext } from "../../context/SideBarContext";
import { useStyles } from "./styles";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

export enum EstateType {
  House = "house",
  Apartment = "apartment",
  NotSelected = "notSelected",
}

const ExposeRenderer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
//  const { id } = useParams();
  const location = useLocation();
  const state = location.state;
  const { setVisibility } = useContext(SideBarContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isApiChangesLoading, setIsApiChangesLoading] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>(defaultFormState);
  const [rentalId, setRentalId] = useState("");
  const [estimatedRent, setEstimatedRent] = useState(
    formData?.estimatedRent ? formData.estimatedRent : 0
  );
  const [isRentControl, setIsRentControl] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rent, setRent] = useState(formData?.rent ? formData.rent : "");
  const [estateType, setEstateType] = useState<EstateType>(
    EstateType.NotSelected
  );

  useEffect(() => {
    setEstimatedRent(formData?.estimatedRent ? formData.estimatedRent : 0);
    setRentalId(formData?.id ? formData.id : "");
  }, [formData]);

  useEffect(() => {
    setVisibility(false);
  }, [setVisibility]);

  useEffect(() => {
    if (state) {
      const { object, step } = state;
      setActiveStep(step);
      setFormData(object);
    }
  // if the dependecy array were to be used correctly, it would create an infinite loop. So leave it as is :-(
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useState({
    name: "",
    state: "",
    zip: "",
    address: "",
    estateType: "",
    estateTypeDetailName: "",
    area: "",
    rooms: "",
  });

  // you want to see an ugly hack? look no further
  // quick and dirty solution to set ZIP and state without another server call
  const realTimeZip = (zip: string) => {
    const plz = parseInt(zip);
    let cleanStateError = false;
    if (!isNaN(plz) && plz > 1000 && plz < 99999) {
      // some ZIPs are in multiple states and therefore can't be set without exact geolocation data
      const specialCases = [7919, 14715, 17337, 19273, 19357, 21039, 22113, 22145, 34355, 37194, 37217, 63776, 63925, 63928, 64754, 65326, 65391, 69412, 69434, 74594, 74731, 89081, 97896];
      if (!specialCases.includes(plz)) {
        if (
          (plz >= 63928 && plz <= 63928) ||
          (plz >= 64754 && plz <= 64754) ||
          (plz >= 68001 && plz <= 68312) ||
          (plz >= 68520 && plz <= 68549) ||
          (plz >= 68701 && plz <= 69234) ||
          (plz >= 69240 && plz <= 69429) ||
          (plz >= 69434 && plz <= 69434) ||
          (plz >= 69435 && plz <= 69469) ||
          (plz >= 69489 && plz <= 69502) ||
          (plz >= 69510 && plz <= 69514) ||
          (plz >= 70001 && plz <= 74592) ||
          (plz >= 74594 && plz <= 76709) ||
          (plz >= 77601 && plz <= 79879) ||
          (plz >= 88001 && plz <= 88099) ||
          (plz >= 88147 && plz <= 88147) ||
          (plz >= 88181 && plz <= 89079) ||
          (plz >= 89081 && plz <= 89085) ||
          (plz >= 89090 && plz <= 89198) ||
          (plz >= 89501 && plz <= 89619) ||
          (plz >= 97861 && plz <= 97877) ||
          (plz >= 97893 && plz <= 97896) ||
          (plz >= 97897 && plz <= 97900) ||
          (plz >= 97911 && plz <= 97999)
        ) {
          // Baden-Württemberg - 644a5e6dd411b0cdfb77795a
          setFormData({
            ...formData,
            state: "644a5e6dd411b0cdfb77795a",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 63701 && plz <= 63774) ||
          (plz >= 63776 && plz <= 63928) ||
          (plz >= 63930 && plz <= 63939) ||
          (plz >= 74594 && plz <= 74594) ||
          (plz >= 80001 && plz <= 87490) ||
          (plz >= 87493 && plz <= 87561) ||
          (plz >= 87571 && plz <= 87789) ||
          (plz >= 88101 && plz <= 88146) ||
          (plz >= 88147 && plz <= 88179) ||
          (plz >= 89081 && plz <= 89081) ||
          (plz >= 89087 && plz <= 89087) ||
          (plz >= 89201 && plz <= 89449) ||
          (plz >= 90001 && plz <= 96489) ||
          (plz >= 97001 && plz <= 97859) ||
          (plz >= 97888 && plz <= 97892) ||
          (plz >= 97896 && plz <= 97896) ||
          (plz >= 97901 && plz <= 97909)
        ) {
          // Bayern - 644a5e7cd411b0cdfb77795b
          setFormData({
            ...formData,
            state: "644a5e7cd411b0cdfb77795b",
          });
          cleanStateError = true;
        }
        if (plz >= 10001 && plz <= 14330) {
          // Berlin - 644a5ee7d411b0cdfb77795d
          setFormData({
            ...formData,
            state: "644a5ee7d411b0cdfb77795d",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 1941 && plz <= 1998) ||
          (plz >= 3001 && plz <= 3253) ||
          (plz >= 4891 && plz <= 4938) ||
          (plz >= 14401 && plz <= 14715) ||
          (plz >= 14723 && plz <= 16949) ||
          (plz >= 17258 && plz <= 17258) ||
          (plz >= 17261 && plz <= 17291) ||
          (plz >= 17309 && plz <= 17309) ||
          (plz >= 17321 && plz <= 17321) ||
          (plz >= 17326 && plz <= 17326) ||
          (plz >= 17335 && plz <= 17335) ||
          (plz >= 17337 && plz <= 17337) ||
          (plz >= 19307 && plz <= 19357)
        ) {
          // Brandenburg - 644a5ef4d411b0cdfb77795e
          setFormData({
            ...formData,
            state: "644a5ef4d411b0cdfb77795e",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 27501 && plz <= 27580) ||
          (plz >= 28001 && plz <= 28779)
        ) {
          // Bremen - 644a5e30d411b0cdfb777956
          setFormData({
            ...formData,
            state: "644a5e30d411b0cdfb777956",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 20001 && plz <= 21037) ||
          (plz >= 21039 && plz <= 21170) ||
          (plz >= 22001 && plz <= 22113) ||
          (plz >= 22115 && plz <= 22143) ||
          (plz >= 22145 && plz <= 22145) ||
          (plz >= 22147 && plz <= 22786) ||
          (plz >= 27499 && plz <= 27499)
        ) {
          // Hamburg - 644a5e05d411b0cdfb777954
          setFormData({
            ...formData,
            state: "644a5e05d411b0cdfb777954",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 34001 && plz <= 34329) ||
          (plz >= 34355 && plz <= 34355) ||
          (plz >= 34356 && plz <= 34399) ||
          (plz >= 34441 && plz <= 36399) ||
          (plz >= 37194 && plz <= 37195) ||
          (plz >= 37201 && plz <= 37299) ||
          (plz >= 55240 && plz <= 55252) ||
          (plz >= 59969 && plz <= 59969) ||
          (plz >= 60001 && plz <= 63699) ||
          (plz >= 63776 && plz <= 63776) ||
          (plz >= 64201 && plz <= 64753) ||
          (plz >= 64754 && plz <= 65326) ||
          (plz >= 65327 && plz <= 65391) ||
          (plz >= 65392 && plz <= 65556) ||
          (plz >= 65583 && plz <= 65620) ||
          (plz >= 65627 && plz <= 65627) ||
          (plz >= 65701 && plz <= 65936) ||
          (plz >= 68501 && plz <= 68519) ||
          (plz >= 68601 && plz <= 68649) ||
          (plz >= 69235 && plz <= 69239) ||
          (plz >= 69430 && plz <= 69431) ||
          (plz >= 69434 && plz <= 69434) ||
          (plz >= 69479 && plz <= 69488) ||
          (plz >= 69503 && plz <= 69509) ||
          (plz >= 69515 && plz <= 69518)
        ) {
          // Hessen - 644a5e4dd411b0cdfb777958
          setFormData({
            ...formData,
            state: "644a5e4dd411b0cdfb777958",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 17001 && plz <= 17256) ||
          (plz >= 17258 && plz <= 17259) ||
          (plz >= 17301 && plz <= 17309) ||
          (plz >= 17309 && plz <= 17321) ||
          (plz >= 17321 && plz <= 17322) ||
          (plz >= 17328 && plz <= 17331) ||
          (plz >= 17335 && plz <= 17335) ||
          (plz >= 17337 && plz <= 19260) ||
          (plz >= 19273 && plz <= 19273) ||
          (plz >= 19273 && plz <= 19306) ||
          (plz >= 19357 && plz <= 19417) ||
          (plz >= 23921 && plz <= 23999)
        ) {
          // Mecklenburg-Vorpommern - 644a5f02d411b0cdfb77795f
          setFormData({
            ...formData,
            state: "644a5f02d411b0cdfb77795f",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 19271 && plz <= 19273) ||
          (plz >= 21202 && plz <= 21449) ||
          (plz >= 21522 && plz <= 21522) ||
          (plz >= 21601 && plz <= 21789) ||
          (plz >= 26001 && plz <= 27478) ||
          (plz >= 27607 && plz <= 27809) ||
          (plz >= 28784 && plz <= 29399) ||
          (plz >= 29431 && plz <= 31868) ||
          (plz >= 34331 && plz <= 34353) ||
          (plz >= 34355 && plz <= 34355) ||
          (plz >= 37001 && plz <= 37194) ||
          (plz >= 37197 && plz <= 37199) ||
          (plz >= 37401 && plz <= 37649) ||
          (plz >= 37689 && plz <= 37691) ||
          (plz >= 37697 && plz <= 38479) ||
          (plz >= 38501 && plz <= 38729) ||
          (plz >= 48442 && plz <= 48465) ||
          (plz >= 48478 && plz <= 48480) ||
          (plz >= 48486 && plz <= 48488) ||
          (plz >= 48497 && plz <= 48531) ||
          (plz >= 49001 && plz <= 49459) ||
          (plz >= 49551 && plz <= 49849)
        ) {
          // Niedersachsen - 644a5e22d411b0cdfb777955
          setFormData({
            ...formData,
            state: "644a5e22d411b0cdfb777955",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 32001 && plz <= 33829) ||
          (plz >= 34401 && plz <= 34439) ||
          (plz >= 37651 && plz <= 37688) ||
          (plz >= 37692 && plz <= 37696) ||
          (plz >= 40001 && plz <= 48432) ||
          (plz >= 48466 && plz <= 48477) ||
          (plz >= 48481 && plz <= 48485) ||
          (plz >= 48489 && plz <= 48496) ||
          (plz >= 48541 && plz <= 48739) ||
          (plz >= 49461 && plz <= 49549) ||
          (plz >= 50101 && plz <= 51597) ||
          (plz >= 51601 && plz <= 53359) ||
          (plz >= 53581 && plz <= 53604) ||
          (plz >= 53621 && plz <= 53949) ||
          (plz >= 57001 && plz <= 57489) ||
          (plz >= 58001 && plz <= 59966) ||
          (plz >= 59969 && plz <= 59969)
        ) {
          // Nordrhein-Westfalen - 644a5e3ed411b0cdfb777957
          setFormData({
            ...formData,
            state: "644a5e3ed411b0cdfb777957",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 51598 && plz <= 51598) ||
          (plz >= 53401 && plz <= 53579) ||
          (plz >= 53614 && plz <= 53619) ||
          (plz >= 54181 && plz <= 55239) ||
          (plz >= 55253 && plz <= 56869) ||
          (plz >= 57501 && plz <= 57648) ||
          (plz >= 65326 && plz <= 65326) ||
          (plz >= 65391 && plz <= 65391) ||
          (plz >= 65558 && plz <= 65582) ||
          (plz >= 65621 && plz <= 65626) ||
          (plz >= 65629 && plz <= 65629) ||
          (plz >= 66461 && plz <= 66509) ||
          (plz >= 66841 && plz <= 67829) ||
          (plz >= 76711 && plz <= 76891)
        ) {
          // Rheinland-Pfalz - 644a5e5bd411b0cdfb777959
          setFormData({
            ...formData,
            state: "644a5e5bd411b0cdfb777959",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 66001 && plz <= 66459) ||
          (plz >= 66511 && plz <= 66839)
        ) {
          // Saarland - 644a5ea0d411b0cdfb77795c
          setFormData({
            ...formData,
            state: "644a5ea0d411b0cdfb77795c",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 1001 && plz <= 1936) ||
          (plz >= 2601 && plz <= 2999) ||
          (plz >= 4001 && plz <= 4579) ||
          (plz >= 4641 && plz <= 4889) ||
          (plz >= 7919 && plz <= 7919) ||
          (plz >= 7919 && plz <= 7919) ||
          (plz >= 7951 && plz <= 7951) ||
          (plz >= 7952 && plz <= 7952) ||
          (plz >= 7982 && plz <= 7982) ||
          (plz >= 7985 && plz <= 7985) ||
          (plz >= 8001 && plz <= 9669)
        ) {
          // Sachsen - 644a5f10d411b0cdfb777960
          setFormData({
            ...formData,
            state: "644a5f10d411b0cdfb777960",
          });
          cleanStateError = true;
        }
          
        if (
          (plz >= 6001 && plz <= 6548) ||
          (plz >= 6601 && plz <= 6928) ||
          (plz >= 14715 && plz <= 14715) ||
          (plz >= 29401 && plz <= 29416) ||
          (plz >= 38481 && plz <= 38489) ||
          (plz >= 38801 && plz <= 39649)
        ) {
          // Sachsen-Anhalt - 644a5f1ed411b0cdfb777961
          setFormData({
            ...formData,
            state: "644a5f1ed411b0cdfb777961",
          });
          cleanStateError = true;
        }
          
        if (
          (plz >= 21039 && plz <= 21039) ||
          (plz >= 21451 && plz <= 21521) ||
          (plz >= 21524 && plz <= 21529) ||
          (plz >= 22113 && plz <= 22113) ||
          (plz >= 22145 && plz <= 22145) ||
          (plz >= 22145 && plz <= 22145) ||
          (plz >= 22801 && plz <= 23919) ||
          (plz >= 24001 && plz <= 25999) ||
          (plz >= 27483 && plz <= 27498)
        ) {
          // Schleswig-Holstein - 644a5de8d411b0cdfb777953
          setFormData({
            ...formData,
            state: "644a5de8d411b0cdfb777953",
          });
          cleanStateError = true;
        }
        if (
          (plz >= 4581 && plz <= 4639) ||
          (plz >= 6551 && plz <= 6578) ||
          (plz >= 7301 && plz <= 7919) ||
          (plz >= 7919 && plz <= 7919) ||
          (plz >= 7920 && plz <= 7950) ||
          (plz >= 7952 && plz <= 7952) ||
          (plz >= 7953 && plz <= 7980) ||
          (plz >= 7985 && plz <= 7985) ||
          (plz >= 7985 && plz <= 7989) ||
          (plz >= 36401 && plz <= 36469) ||
          (plz >= 37301 && plz <= 37359) ||
          (plz >= 96501 && plz <= 96529) ||
          (plz >= 98501 && plz <= 99998)
        ) {
          // Thüringen - 644a5f2dd411b0cdfb777962
          setFormData({
            ...formData,
            state: "644a5f2dd411b0cdfb777962",
          });
          cleanStateError = true;
        }
      }

    }
    setErrors({
      ...errors,
      zip: "",
      state: cleanStateError ? "" : errors.state,
      address: "",
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    setErrors({
      ...errors,
      name: name === "name" ? "" : errors.name,
      zip: name === "zip" ? "" : errors.zip,
      address: name === "address" ? "" : errors.address,
      area: name === "area" ? "" : errors.area,
      rooms: name === "rooms" ? "" : errors.rooms,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (name === "estateType") {
      newErrors.estateType = "";
      // make sure to reset the detail type to prevent invalid configurations
      setFormData({ ...formData, 
        "estateTypeDetailName": "", 
        "estateType": value,
      });
      if (value === "644a4d95d411b0cdfb777942") {
        //estateType -> house
        setEstateType(EstateType.House);
      } else if (value === "644a50aad411b0cdfb777943") {
        //estateType -> apartment
        setEstateType(EstateType.Apartment);
      }
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === "state") {
        newErrors.state = "";
      }
      if (name === "estateTypeDetailName") {
        newErrors.estateTypeDetailName = "";
      }
    }
    setErrors(newErrors);
  };

  const handleButtonClick = (key: keyof FormData) => {
    setFormData((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  const handleCreateRental = (
    setLoading: Dispatch<React.SetStateAction<boolean>>
  ) => {
    // make API request to create rental with formData
    setLoading(true);
    const request = Object.assign({}, formData);
    createRental(request)
      .then((response) => {
        setRentalId(response.id);
        setEstimatedRent(response.estimatedRent);
        setIsRentControl(response.rentControl);
        setFormData(response);
        setRent(response.estimatedRent.toString());
        setLoading(false);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
        setLoading(false);
      })
      .catch((err) => {
        Alert.error((err && err.message) || t("error"));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (rentalId !== "") {
      const updatedFormData = { ...formData, id: rentalId };
      setFormData(updatedFormData);
    }
  // if the dependecy array were to be used correctly, it would create an infinite loop. So leave it as is :-(
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalId]);

  const handleUpdateRental = (
    setLoading: Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true);

    const request = Object.assign({}, formData);
    updateRental(request)
      .then((response) => {
        setEstimatedRent(response.estimatedRent);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error((err && err.message) || t("error"));
      })
      .catch((err) => {
        setLoading(false);
        Alert.error((err && err.message) || t("error"));
      });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleSaveExpose = () => {
    setIsApiChangesLoading(true);
    const request = Object.assign({}, formData);
    saveExpose(request)
      .then(() => {
        setIsApiChangesLoading(false);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        setIsApiChangesLoading(false);
        Alert.error((err && err.message) || t("error"));
      })
      .catch((err) => {
        setIsApiChangesLoading(false);
        Alert.error((err && err.message) || t("error"));
      });
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setFormData({
        name: "",
        address: "",
        state: "",
        zip: "",
        placeId: "",
        latitude: "",
        longitude: "",
        estateType: "",
        estateTypeDetailName: "",
        area: "",
        plotArea: "",
        constructionYear: "",
        lastRefurbished: "",
        rooms: "",
        bedRooms: "",
        bathRooms: "",
        floor: "",
        numberOfFloors: "",
        kitchen: false,
        balconyTerrace: false,
        garden: false,
        guestWashRoom: false,
        parking: false,
        basementLoft: false,
        canBeWg: false,
        lift: false,
        stepLessAccess: false,
        subsidized: false,
        energyUsage: "",
        preservation: false,
        commonCharge: "",
        firstTimeUse: false,
        parkingOutside: false,
        parkingInside: false,
        garage: false,
        priceParking: "",
        furnished: false,
        barrierFree: false,
        temporary: false,

        additionalCosts: "",
        estimatedRent: 0,
        suggestionRentals: "",
        rentControl: false,
        rent: "",
        sideCost: "",
        heatingCosts: "",
        totalRent: "",
        deposit: "",
        availableFrom: "",
        availableTo: "",
        quality: "",
        isPetAllowed: "",
        typeOfHeating: "",
        essentialEnergySource: "",
        energyClass: "",
        areaCharacteristics: [],
        reachability: [],
        rentalPropertyCharacters: [],
        specialFeaturesAndServices: [],
        floorCovering: "",
        conditionOfTheObject: "",

        hasSelfDisclosure: false,
        proofOfIdentity: false,
        proofOfCreditCheck: false,
        previousRentPaymentProof: false,
        incomeConfirmation: false,
        jobCenter: false,
        rentGuarantee: false,

        titleSuggestion: "",
        titleSuggestionAttempts: "",
        descriptionSuggestion: "",
        descriptionSuggestionAttempts: "",
        equipmentsSuggestion: "",
        equipmentsSuggestionAttempts: "",
        areaDescriptionSuggestion: "",
        areaDescriptionSuggestionAttempts: "",
        miscellaneous: "",
      });
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid container sx={{ paddingBottom: "16px" }}>
      <Grid item xs={12} className={[classes.card, classes.stepperContainer].join(" ")}>
        <HorizontalStepper activeStep={activeStep} />
      </Grid>

      {activeStep !== 3 && (
        <Grid item xs={12} className={[classes.card, classes.topCard, (activeStep === 1 || activeStep === 2 ? classes.cardVariant : "") ].join(" ")}>
          <InfoText
            labelText={t("info.label.rental.data")}
            bodyText={t("info.label.rental.data.body")}
          />
        </Grid>
      )}

      {activeStep === 0 && (
        <>
          <Grid item container xs={12} className={[classes.card, classes.bottomCard].join(" ")}>
            <PropertyDetails
              activeStep={activeStep}
              formData={formData}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleButtonClick={handleButtonClick}
              handleRental={handleCreateRental}
              submitText={t("button.calculate.rent")}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              estateType={estateType}
              realTimeZip={realTimeZip}
            />
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Grid item xs={12} className={[classes.card, classes.bottomCard, classes.cardVariant].join(" ")}>
            <PropertyDetails
              activeStep={activeStep}
              formData={formData}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleButtonClick={handleButtonClick}
              handleRental={handleUpdateRental}
              submitText={t("button.calculate.rent")}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              estateType={estateType}
              realTimeZip={realTimeZip}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 4, width: "100%" }} className={classes.card}>
            <RecommendedRent
              rent={estimatedRent}
              isRentControl={isRentControl}
            />
          </Grid>
        </>
      )}
      {activeStep === 2 && (
        <Grid item container xs={12}>
          <Grid item xs={12} className={[classes.card, classes.bottomCard, classes.cardVariant].join(" ")}>
            <PropertyDetails
              activeStep={activeStep}
              formData={formData}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleButtonClick={handleButtonClick}
              handleRental={handleUpdateRental}
              submitText={t("button.calculate.rent")}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              estateType={estateType}
              realTimeZip={realTimeZip}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, width: "100%" }} className={[classes.card, classes.cardVariant].join(" ")}>
            <RecommendedRent
              rent={estimatedRent}
              isRentControl={isRentControl}
            />
          </Grid>
          <Grid item mt={4} className={[classes.card, classes.topCard].join(" ")}>
            <InfoText
              labelText={t("create.expose")}
              bodyText={t("create.expose.text")}
            />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <CostCalculator formData={formData} setFormData={setFormData} />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <QualityAndPets formData={formData} setFormData={setFormData} />
          </Grid>
          <Grid container item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <Heating formData={formData} setFormData={setFormData} />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <DetailedInformation formData={formData} setFormData={setFormData} />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <Description formData={formData} setFormData={setFormData} objectId={rentalId} />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
            <ImageAccordion objectId={formData?.id ? formData.id : ""} />
          </Grid>
          <Grid item xs={12} className={[classes.card, classes.bottomCard].join(" ")}>
            <DesiredDocuments formData={formData} setFormData={setFormData} />
          </Grid>
        </Grid>
      )}
      {activeStep === 3 && (
        <Grid item xs={12}>
          <ExposePreview
            rentalId={rentalId}
            setActiveStep={setActiveStep}
            formData={formData}
            setFormData={setFormData}
          />
        </Grid>
      )}
      {activeStep !== 3 && activeStep !== 0 && (
        <Grid item xs={12} className={classes.card} mt={2}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            {activeStep !== 0 && activeStep !== 3 && (
              <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={handleBack}>
                <RestartAltIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                {t("button.start.over")}
              </Button>
            )}
            {activeStep === 1 && (
              <Button className={classes.button} onClick={handleNext}>
                {t("button.next.step.create")}
                <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
              </Button>
            )}
            {activeStep === 2 && (
              <>
                {isApiChangesLoading ? (
                  <CircularProgress color="success" />
                ) : (
                  <Button className={classes.button} onClick={handleSaveExpose}>
                    {t("button.next.step.preview")}
                    <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      {activeStep === 1 && (
        <>
          <Grid item xs={12} mt={6} className={[classes.card, classes.cardVariant].join(" ")}>
            <ObjectsRenderer />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ExposeRenderer;
