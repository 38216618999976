import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { getEntity, getRental, updateRentalStatus } from "../../../services/rentalHandler";
import Alert from "react-s-alert";
import i18next from "i18next";
import immoScout from "../../../assets/icons/immoscout.png";
import immoWelt from "../../../assets/icons/immoWelt.png";
import ebay from "../../../assets/icons/ebay.png";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import generateImgUrl from "../ImageHandler/util";
import { PetAllowance, Quality } from "../QualityAndPets/QualityAndPets";
import { useParams } from "react-router-dom";
import "./image-gallery.css";
import {
  numericFormatter,
} from "react-number-format";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/de";
import "dayjs/locale/en";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import {
  OBJECTS_PAGE,
  OBJECT_DASHBOARD,
} from "../../../routes/ROUTES";

interface Props {
  rentalId: string;
  setActiveStep: Dispatch<SetStateAction<number>>;
  formData: any;
  setFormData: Dispatch<SetStateAction<any>>;
}
const ExposePreview = ({
  rentalId,
  setActiveStep,
  formData,
  setFormData,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;
  const { id } = useParams();

  const [currentId, setCurrentId] = useState(rentalId);
  const htmlElement = useRef<HTMLDivElement>(null);
  const [pdfVersion, setPdfVersion] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [objectData, setObjectData] = useState<Record<string, any>>({});
  const [estateType, setEstateType] = useState<string>("");
  const [conditionOfTheObject, setConditionOfTheObject] = useState<string>("");
  const [typeOfHeating, setTypeOfHeating] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flooring, setFlooring] = useState<string>("");
  const [energyClass, setEnergyClass] = useState<string>("");
  const [quality, setQuality] = useState<string>("");
  const [petAllowance, setPetAllowance] = useState<string>("");
  const [essentialEnergySource, setEssentialEnergySource] =
    useState<string>("");
  const [booleanValues, setBooleanValues] = useState<string[]>([]);
  const [images, setImages] = useState<Record<string, string>[]>([]);
  const [galleryImages, setGalleryImages] = useState<
    { original: string; thumbnail: string }[]
  >([]);
  const [floorPlan, setFloorPlan] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentId && id) {
      setCurrentId(id);
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  const onStepBack = () => {
    setActiveStep(2);
    setFormData(formData);
  };

  dayjs.extend(isLeapYear);
  dayjs.extend(LocalizedFormat)
  dayjs.locale(`${t("calendar.locale")}`)

  const generateDate = (array: string[]) => {
    return dayjs().set("year", parseInt(array[0])).set("month", parseInt(array[1])).set("date", parseInt(array[2])).format('LL');
  };

  const [checkboxes, setCheckboxes] = useState({
    immoScout: false,
    immoWelt: false,
    ebay: false,
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  };

  const deriveQuality = (quality: Quality) => {
    switch (quality) {
      case "na":
        return "estate.quality.KA";
      case "simple":
        return "estate.quality.simple";
      case "normal":
        return "estate.quality.normal";
      case "high":
        return "estate.quality.high";
      case "luxury":
        return "estate.quality.luxury";
      default:
        return "";
    }
  };

  const derivePetAllowance = (petAllowance: PetAllowance) => {
    switch (petAllowance) {
      case "yes":
        return "estate.pet.yes";
      case "no":
        return "estate.pet.no";
      case "agreement":
        return "estate.pet.aggrement";
      default:
        return "estate.pet.noInfo";
    }
  };

  const calculateBooleanValues = (object: Record<string, any>) => {
    const values = [];
    if (object["kitchen"]) values.push("estate.kitchen");
    if (object["balconyTerrace"]) values.push("estate.balconyTerrace");
    if (object["garden"]) values.push("estate.garden");
    if (object["guestWashRoom"]) values.push("estate.guestWashRoom");
    if (object["garage"]) values.push("estate.garage");
    if (object["parking"]) values.push("estate.parking");
    if (object["basementLoft"]) values.push("estate.basementLoft");
    if (object["stepLessAccess"]) values.push("estate.parking");
    if (object["canBeWg"]) values.push("estate.canBeWg");
    if (object["lift"]) values.push("estate.lift");
    if (object["stepLessAccess"]) values.push("estate.stepLessAccess");
    if (object["subsidized"]) values.push("estate.subsidized");
    if (object["preservation"]) values.push("estate.preservation");
    if (object["firstTimeUse"]) values.push("estate.firstTimeUse");
    if (object["parkingInside"]) values.push("estate.parking.inside");
    if (object["parkingOutside"]) values.push("estate.parking.outside");
    if (object["furnished"]) values.push("estate.furnished");
    if (object["barrierFree"]) values.push("estate.barrier.free");
    if (object["temporary"]) values.push("estate.temporary");
    return values;
  };

  const setToPublished = () => {
    updateRentalStatus(currentId, "publish_expose")
    .then(() => {
      Alert.success( `${t("expose.preview.success")}` );
      navigate(`${OBJECT_DASHBOARD}/${currentId}`);
    })
    .catch((err) => {
      Alert.error((err && err.message) || t("error"));
    });
  };

  useEffect(() => {
    if (currentId) {
      getRental(currentId)
        .then(async (response) => {
          setObjectData(response);
          const photos = response["pictures"];
          if (photos) {
            setImages(
              photos.map(
                (item: {
                  title: string;
                  image: { data: string; type: number };
                }) => {
                  return {
                    image: item["image"]["data"],
                    title: item["title"],
                  };
                }
              )
            );
            setGalleryImages(
              photos.map((item: any) => {
                return {
                  original: generateImgUrl(item["image"]["data"]),
                  thumbnail: generateImgUrl(item["image"]["data"]),
                  description: item["title"].substring(0, item["title"].lastIndexOf('.')) || item["title"],
                };
              })
            );
          }

          const floorPlan = response["floorPlan"];
          setFloorPlan(
            floorPlan && floorPlan[0] && floorPlan[0]["image"]["data"]
          );

          // get entities
          if (response["estateType"]) {
            const res = await getEntity(response["estateType"]);
            setEstateType(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          if (response["conditionOfTheObject"]) {
            const res = await getEntity(response["conditionOfTheObject"]);
            setConditionOfTheObject(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          if (response["typeOfHeating"]) {
            const res = await getEntity(response["typeOfHeating"]);
            setTypeOfHeating(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          if (response["essentialEnergySource"]) {
            const res = await getEntity(response["essentialEnergySource"]);
            setEssentialEnergySource(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          if (response["floorCovering"]) {
            const res = await getEntity(response["floorCovering"]);
            setFlooring(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          if (response["energyClass"]) {
            const res = await getEntity(response["energyClass"]);
            setEnergyClass(
              lang === "en" ? res.name_en : lang === "de" ? res.name_de : ""
            );
          }
          setBooleanValues(calculateBooleanValues(response));
          if (response["quality"]) {
            setQuality(deriveQuality(response["quality"]));
          }
          if (response["isPetAllowed"]) {
            setPetAllowance(derivePetAllowance(response["isPetAllowed"]));
          }
          window.scrollTo(0, 0);
          setLoading(false);
        })
        .catch((err) => {
          Alert.error((err && err.message) || t("error"));
          setLoading(false);
        });
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  const handlePrint = () => {
    setPdfVersion(true);
  };
  const handlePDFPrint = useReactToPrint({
    content: () => htmlElement.current,
  });

  useEffect(() => {
    if (pdfVersion) {
      handlePDFPrint();
      setPdfVersion(false);
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfVersion]);

  return (
    <>
      {isLoading ? (
        <Grid
          container={true}
          flexDirection="row"
          display="flex"
          sx={{
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress color="success" size={100} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className={classes.pdfContainer}
            container={true}
            display="flex"
            flexDirection="row"
            id="pdf-content"
            ref={htmlElement}
            item
            xs={12}
          >
            {/*object title*/}
            <Grid item={true} xs={12}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                sx={{ maxWidth: "inherit !important" }}
              >
                <Grid item={true} xs={10}>
                  <Typography variant="h4">
                    {objectData["titleSuggestion"]}
                  </Typography>
                </Grid>
                <Grid item={true} xs={2} className={classes.objectIDBOX}>
                  <Typography variant="body1" className={classes.objectIdLabel}>
                    {t("expose.preview.object.id")}
                  </Typography>
                  <Typography className={classes.objectId}>
                    {objectData["displayId"]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {pdfVersion && images.length > 0 ? (
              <>
                {/*main image*/}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    marginTop: 3,
                    mb: 2,
                    maxHeight: "500px",
                    objectFit: "scale-down",
                  }}
                  flexDirection="column"
                  alignItems="center"
                >
                  <img
                    alt={images[0]["title"]}
                    className={classes.mainImage}
                    src={`data:image/jpeg;base64,${images[0]["image"]}`}
                  />
                </Grid>
              </>
            ) : (
              <Grid item={true} xs={12}>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  sx={{ maxWidth: "inherit !important", marginTop: 4 }}
                >
                  <Grid item={true} xs={12} position="relative">
                    {images.length > 0 && (
                      <ImageGallery
                        items={galleryImages}
                        showPlayButton={false}
                        showFullscreenButton={true}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*address container*/}
            <Grid
              item={true}
              paddingTop={pdfVersion ? 0 : 4}
              display="flex"
              flexDirection="row"
              xs={12}
            >
              <Grid
                container
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.addressTitle}>
                  {t("expose.preview.address")}
                </Grid>
                <Grid item className={classes.address}>
                  {objectData["address"]}
                </Grid>
              </Grid>
            </Grid>

            {/*cost container*/}
            <Grid item={true} className={classes.addressBox} xs={12}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                className={classes.addressContainerBox}
              >
                <Grid item={true}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item className={classes.currencyTitle}>
                    {numericFormatter(
                        objectData["rent"].toString(), {
                          thousandSeparator: `${t("numbers.thousandSeparator")}`,
                          decimalSeparator: `${t("numbers.decimalSeparator")}`,
                          suffix: " €",
                          decimalScale: 0,
                        }
                      )}
                    </Grid>
                    <Grid item className={classes.addressSubTitle}>
                      {t("expose.preview.cold.rent")}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item className={classes.currencyTitle}>
                    {numericFormatter(
                        objectData["totalRent"].toString(), {
                          thousandSeparator: `${t("numbers.thousandSeparator")}`,
                          decimalSeparator: `${t("numbers.decimalSeparator")}`,
                          suffix: " €",
                          decimalScale: 0,
                        }
                      )}
                    </Grid>
                    <Grid item className={classes.addressSubTitle}>
                      {t("expose.preview.warm.rent")}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item className={classes.currencyTitle}>
                      {objectData["rooms"]}
                    </Grid>
                    <Grid item className={classes.addressSubTitle}>
                      {t("expose.preview.rooms")}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item className={classes.currencyTitle}>
                      {numericFormatter(
                          objectData["area"].toString(), {
                            thousandSeparator: `${t("numbers.thousandSeparator")}`,
                            decimalSeparator: `${t("numbers.decimalSeparator")}`,
                            suffix: `${" " + t("form.adornment.area")}`,
                            decimalScale: 0,
                          }
                        )}
                    </Grid>
                    <Grid item className={classes.addressSubTitle}>
                      {t("expose.preview.living.space")}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*boolean container*/}
            <Grid
              item
              sx={{ maxWidth: "inherit !important" }}
              xs={12}
            >
              <Grid container>
                {booleanValues.map((item, index) => (
                  <Grid item className={classes.booleanContainer} key={index}>
                    {t(item)}
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/*room data container*/}
            <Grid
              item
              xs={12}
              sx={{ paddingTop: pdfVersion ? 3 : 6, maxWidth: "inherit !important" }}
            >
              <Grid container sx={{ maxWidth: "inherit !important" }}>
                <Grid item className={classes.rowContainer}>
                  <Grid container display="flex" justifyContent="space-evenly">
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {t("expose.preview.Type")}
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {estateType}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {t("expose.preview.bedroom")}
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {objectData["bedRooms"]}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.rowContainer}>
                  <Grid container display="flex" justifyContent="space-evenly">
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {objectData["estateType"] === "644a4d95d411b0cdfb777942" ? t("expose.preview.totalFloors") : t("expose.preview.floor") }
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {objectData["estateType"] === "644a4d95d411b0cdfb777942" ? 
                              objectData["numberOfFloors"]
                            : 
                              objectData["floor"] + t("expose.preview.floor.of") + objectData["numberOfFloors"]
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {t("expose.preview.bathroom")}
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {objectData["bathRooms"]}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.rowContainer}>
                  <Grid container display="flex" justifyContent="space-evenly">
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {t("expose.preview.available.from")}
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {objectData["availableFrom"] ?
                              generateDate(objectData["availableFrom"])
                            :
                              t("expose.preview.available.from.arrangement")
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={6} className={classes.tableTitle}>
                          {t("expose.preview.pets")}
                        </Grid>
                        <Grid item xs={6} className={classes.tableSubTitle}>
                          {t(petAllowance)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*object description*/}
            {objectData["descriptionSuggestion"] && (
              <Grid
                item
                xs={12}
                sx={{ paddingTop: pdfVersion ? 3 : 8, maxWidth: "inherit !important", pageBreakInside: "avoid" }}
              >
                <Grid container direction="column">
                  <Grid item className={classes.objectDescriptionTitle}>
                    {t("expose.preview.object.description")}
                  </Grid>
                  <Grid item className={classes.objectDescription}>
                    <Typography>
                      {objectData["descriptionSuggestion"]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*cost calculator*/}
            <Grid
              item
              xs={12}
              sx={{ paddingTop: pdfVersion ? 3 : 4, maxWidth: "inherit !important", pageBreakInside: "avoid" }}
            >
              <Grid container direction="column">
                <Grid
                  item
                  className={classes.objectDescriptionTitle}
                  sx={{ paddingBottom: 2 }}
                >
                  {t("expose.preview.cost")}
                </Grid>
                <Grid container>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.cost.cold")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["rent"]} €
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.cost.deposit")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["deposit"]} €
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.cost.extra")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            +{objectData["sideCost"]} €
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.cost.heat")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["heatingCosts"]
                              ? `${objectData["heatingCosts"]} €`
                              : "in Nebenkosten enthalten"}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.totalRowContainer} mb={pdfVersion ? 3 : 4}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.cost.total")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["totalRent"]} € (inklusive Heizkosten)
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*furnishing description*/}
            {objectData["equipmentsSuggestion"] && (
              <Grid item xs={12} sx={{ maxWidth: "inherit !important", pageBreakInside: "avoid" }}>
                <Grid container direction="column">
                  <Grid item className={classes.objectDescriptionTitle}>
                    {t("expose.preview.furnishing.title")}
                  </Grid>
                  <Grid item className={classes.objectDescription}>
                    <Typography>
                      {objectData["equipmentsSuggestion"]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*heating details*/}
            <Grid
              item
              xs={12}
              sx={{ paddingTop: pdfVersion ? 3 : 4, maxWidth: "inherit !important", pageBreakInside: "avoid" }}
            >
              <Grid container direction="column">
                <Grid
                  item
                  className={classes.objectDescriptionTitle}
                >
                  {t("expose.preview.energy.title")}
                </Grid>
                <Grid container mt={2}>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.construction.year")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["constructionYear"]}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.energy.certificate")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["energyCertificates"] &&
                            objectData["energyCertificates"].length > 0
                              ? t("expose.preview.energy.available")
                              : t("expose.preview.energy.not.available")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.object.state")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {conditionOfTheObject}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.energy.source")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {essentialEnergySource}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.furnishing")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {t(quality)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.energy.usage")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {objectData["energyUsage"]} kWh/(m²*a)
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContainer}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                    >
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.type.of.heating")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {typeOfHeating}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={6} className={classes.tableTitle}>
                            {t("expose.preview.energy.class")}
                          </Grid>
                          <Grid item xs={6} className={classes.tableSubTitle}>
                            {energyClass}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*flor plan*/}
            {floorPlan && floorPlan.length > 0 && (
              <Grid item xs={12} sx={{ maxWidth: "inherit !important", marginTop: pdfVersion ? 3 : 4 }}>
                <Grid container direction="column" item xs={12}>
                  <Grid item className={classes.objectDescriptionTitle}>
                    { floorPlan.length === 1 ? t("expose.preview.floor.plan") : t("expose.preview.floor.plans") }
                  </Grid>
                  <Grid container item xs={12} display="flex" flexDirection="row" spacing={2}>
                    {objectData["floorPlan"].map((plan: any, index: any) => (
                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-start"
                        sx={{ marginTop: 2 }}
                        key={index}
                      >
                        <img
                          alt={plan.title}
                          className={classes.mainImage}
                          src={`data:image/jpeg;base64,${plan.image.data}`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*area description*/}
            {objectData["areaDescriptionSuggestion"] && (
              <Grid
                item
                xs={12}
                sx={{ maxWidth: "inherit !important", marginTop: pdfVersion ? 3 : 4, pageBreakInside: "avoid" }}
              >
                <Grid container direction="column">
                  <Grid item className={classes.objectDescriptionTitle}>
                    {t("expose.preview.area")}
                  </Grid>
                  <Grid item className={classes.objectDescription}>
                    <Typography>
                      {objectData["areaDescriptionSuggestion"]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*other description*/}
            {objectData["miscellaneous"] && (
              <Grid
                item
                xs={12}
                sx={{ maxWidth: "inherit !important", marginTop: pdfVersion ? 3 : 4, pageBreakInside: "avoid" }}
              >
                <Grid container direction="column">
                  <Grid item className={classes.objectDescriptionTitle}>
                    {t("expose.preview.other")}
                  </Grid>
                  <Grid item className={classes.objectDescription}>
                    <Typography>{objectData["miscellaneous"]}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*contact*/}
            <Grid
              item
              xs={12}
              sx={{ maxWidth: "inherit !important", marginTop: pdfVersion ? 3 : 4, pageBreakInside: "avoid" }}
            >
              <Grid
                container
                direction="row"
                item
                xs={12}
                className={classes.contactContainer}
              >
                <Grid item xs={12} className={classes.contactDescriptionTitle}>
                  {t("expose.preview.contact.information")}
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Typography>
                    {t("expose.preview.contact.detail.1")}
                    &nbsp;
                    <Link
                      target="_blank"
                      href={`https://www.vermietsmart.de/application/${objectData["displayId"]}`}
                    >
                      {`https://www.vermietsmart.de/application/${objectData["displayId"]}`}
                    </Link>
                    &nbsp; {t("expose.preview.contact.detail.2")}
                  </Typography>
                  {pdfVersion && (
                    <Grid item container xs={12} mt={3} mb={1} sx={{ justifyContent: "center" }}>
                      <QRCode
                        size={120}
                        value={`https://www.vermietsmart.de/application/${objectData["displayId"]}`}
                      />
                      </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {pdfVersion && (
              <Grid
                item
                xs={12}
                sx={{ maxWidth: "inherit !important", marginTop: 3, pageBreakBefore: "always" }}
              >
                <Grid container direction="column">
                  <Grid item className={classes.objectDescriptionTitle} mb={2}>
                    {t("expose.preview.additionalimages")}
                  </Grid>
                  <Grid item container>
                    <Grid
                      container
                      spacing={2}
                      xs={12}
                      item
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {images.slice(1, images.length).map((image, index) => (
                        <Grid item xs={3} sx={{ pageBreakInside: "avoid" }} key={index}>
                          <Card variant="outlined">
                            <CardMedia
                              component="img"
                              alt={image.title}
                              height="120"
                              image={`data:image/jpeg;base64,${image["image"]}`}
                            />
                            <CardActions
                              sx={{ display: "flex", justifyContent: "center", padding: "4px" }}
                            >
                              <Typography variant="body1" align="center" sx={{ fontSize: "10px" }}>
                                {image.title.substring(0, image.title.lastIndexOf('.')) || image.title}
                              </Typography>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item className={[classes.card, classes.cardVariant].join(" ")} mt={4}>
            <Grid item container sx={{ justifyContent: "space-between" }}>
              <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={onStepBack}>
                <EditIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                {t("expose.preview.change.data")}
              </Button>
              <Button className={classes.button} onClick={handlePrint}>
                <DownloadIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                {t("expose.preview.download.pdf")}
              </Button>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Typography color="white">
                {t("expose.preview.share.data")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.card} mt={4}>
            <Grid item container>
              <Grid item className={classes.objectDescriptionTitle} sx={{ color: "#ffffff !important" }}>
                {t("expose.preview.share.title")}
              </Grid>
              <Grid item xs={12} className={classes.shareDescriptionTitle}>
                <Typography color="white">
                  {t("expose.preview.share.title.description")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} pt={1} pb={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={checkboxes.immoScout}
                      onChange={handleCheckboxChange}
                      name="immoScout"
                      className={classes.checkbox}
                    />
                  }
                  label={
                    <img
                      height={24}
                      width={120}
                      src={immoScout}
                      alt="immoscout"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={checkboxes.immoWelt}
                      onChange={handleCheckboxChange}
                      name="immoWelt"
                      className={classes.checkbox}
                    />
                  }
                  label={
                    <img height={24} width={120} src={immoWelt} alt="immoWelt" />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={checkboxes.ebay}
                      onChange={handleCheckboxChange}
                      name="ebay"
                      className={classes.checkbox}
                    />
                  }
                  label={<img height={24} width={120} src={ebay} alt="ebay" />}
                />
              </Grid>
            </Grid>
            <Grid item container sx={{ justifyContent: "space-between" }} pt={2}>
              <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={() => navigate(OBJECTS_PAGE)}>
                <DashboardIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                {t("expose.preview.go.to.dashboard")}
              </Button>
              <Button className={classes.button} onClick={setToPublished}>
                {t("expose.preview.share")}
                <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ExposePreview;
