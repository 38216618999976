import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "259px",
    maxHeight: "360px",
    borderRadius: "4px",
  },
  cardMedia: {
    height: "200px",
    width: "100%",
    objectFit: "cover",
    backgroundBlendMode: "multiply",
  },
  rowLabelContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  locationLabelDistance: {
    lineHeight: 1,
    fontSize: colors.fontSizeSmall,
    fontWeight: colors.fontWeightBold,
    color: colors.darkTextSecondary,
  },
  locationLabelAddress: {
    lineHeight: 1,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
    color: colors.darkTextPrimary,
  },
  bottomBox: { 
    display: "flex", 
    flexDirection: "row", 
    pt: 2
  },
}));
