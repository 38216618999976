import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.common.white,
    boxShadow:
      "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    borderRadius: colors.cardBorderRadius,
  },
  titleContainer: {
    padding: "16px 0 12px 16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
  },
  subTitle: {
    paddingTop: "3px",
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextSecondary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
  },
  imgContainer: {
    height: 152,
    width: 368,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#9E9E9E",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  priceContainer: {
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
    width: "140px",
  },
  priceFont: {
    paddingBottom: "4px",
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    wordWrap: "break-word",
  },
  secondaryInfos: {
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightRegular,
    wordWrap: "break-word",
  },
  statusContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  activeStatus: {
    padding: "8px 0 8px 16px",
    fontSize: colors.fontSizeSmall,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    wordWrap: "break-word",
    display: "flex",
  },
  currentStatus: {
    padding: "12px 0 4px 8px",
    backgroundColor: "#101529",
    borderBottomRightRadius: colors.cardBorderRadius,
  },
  nextStatusFont: {
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightRegular,
    wordWrap: "break-word",
    color: colors.lightTextSecondary,
  },
  statusFont: {
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  createCard: {
    backgroundColor: colors.smartAgentGreen,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    width: "100%",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "primary.main",
      transition: "background-color 0.3s",
    },
    minHeight: 24,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    borderRadius: colors.cardBorderRadius,
    padding: "20px 0",
  },
  createCardFont: {
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    wordWrap: "break-word",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  createCardInner: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  createCardNudge: {
    width: "100%",
    fontWeight: colors.fontWeightRegular,
    fontSize: colors.fontSizeIntermediate,
    textAlign: "center",
    color: colors.darkTextSecondary,
    maskImage: "linear-gradient( -75deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 1) 70%);",
    maskSize: "200%",
  },
  createCardNudgeIcon: {
    fontSize: "36px",
  },
  createObjectTable: {
    backgroundColor: "rgba(154, 244, 48, 0.5)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "primary.main",
      transition: "background-color 0.3s",
    },
    paddingTop: 16,
    paddingBottom: 16,
  },
  actionCell: {
    padding: 0,
  },
  objectAction: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  currentStatusCell: {
    backgroundColor: "#101529",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  tableRow: {
    borderBottom: "1px solid #e0e0e0",
    "&:last-child": {
      borderBottom: "unset",
    },
    "& td": {
      borderBottom: "unset",
    },
  },
  statusChip: {
    fontSize: colors.fontSizeSmall,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
  },
}));
