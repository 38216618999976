import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import CustomTextField from "../../Customs/CustomTextField/CustomTextField";
import AdultDetails from "./AdultDetails";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Button,
  InputLabel,
} from "@mui/material";
import {
  CustomerContext,
  CustomerState,
} from "../../../context/CustomerContext";
import TenantImage from "./TenantImage";
import { getBooleanCategories } from "../../../services/entitiyHandler";
import { Entities } from "../../Expose/Form/types";
import i18next from "i18next";
import { suggestMessage } from "../../../services/textSuggestion/textSuggestionHandler";
import { adultSuggestion } from "../../../services/textSuggestion/types";
import Alert from "react-s-alert";

const Details = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const { customerState, updateCustomerState } = useContext(CustomerContext);

  const [booleanCategories, setBooleanCategories] = useState<Entities[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getBooleanCategories().then((response) => {
      setBooleanCategories(response);
    });
  }, []);

  const selectionItemsAdults: React.ReactNode[] = [];
  for (let i = 1; i <= 10; i++) {
    selectionItemsAdults.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const selectionItemsChildren: React.ReactNode[] = [];
  for (let i = 0; i <= 10; i++) {
    selectionItemsChildren.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = event.target;

    if (["adultCount", "childCount"].includes(name)) {
      noInfiniteLoop(name, parseInt(value));
      return;
    }

    const updatedCustomerState: CustomerState = {
      ...customerState,
      customer: {
        ...customerState.customer,
      },
    };

    updatedCustomerState.customer = {
      ...updatedCustomerState.customer,
      [name]: value,
    };

    updateCustomerState(updatedCustomerState);
  };

  const handleGenerateMessageSuggestion = () => {
    setLoading(true);

    const newAdultsData: adultSuggestion[] =
      customerState.customer.adultsData.map((adult) => {
        const { firstName, nationality, profession, relationship } = adult;
        return {
          firstName,
          nationality,
          profession,
          relationship,
        };
      });

    const messageData = {
      howManyAdultsMovingIn: customerState.customer.adultCount,
      adultsData: newAdultsData,
      howManyKidsMovingIn: customerState.customer.childCount,
      pets: customerState.customer.petTypes,
      hobbies: customerState.customer.hobbies,
      language: lang,
    };

    suggestMessage(messageData)
      .then((response) => {
        const updatedCustomerState: CustomerState = {
          ...customerState,
          customer: {
            ...customerState.customer,
            messages: response.message,
          },
        };
        updateCustomerState(updatedCustomerState);
        setLoading(false);
      })
      .catch((err) => {
        Alert.error(
          (err && err.message) ||
            t("error")
        );
        setLoading(false);
      });
  };

  const noInfiniteLoop = (updateWhat: String, howMany: number) => {

    if (updateWhat === "adultCount") {
      const updatedAdultsData = [...customerState.customer.adultsData];

      if (howMany > updatedAdultsData.length) {
        for (let i = updatedAdultsData.length; i < howMany; i++) {
          updatedAdultsData.push({
            firstName: "",
            lastName: "",
            birthday: "",
            nationality: "",
            profession: "",
            employmentType: "",
            employer: "",
            maritalStatus: "",
            relationship: "",
          });
        }
      } else if (howMany < updatedAdultsData.length) {
        updatedAdultsData.splice(howMany);
      }

      updateCustomerState({
        ...customerState,
        customer: {
          ...customerState.customer,
          adultsData: updatedAdultsData,
          adultCount: howMany.toString(),
        },
      });
    } 

    if (updateWhat === "childCount") {
      const updatedChildData = [...customerState.customer.childData];

      if (howMany > updatedChildData.length) {
        for (let i = updatedChildData.length; i < howMany; i++) {
          updatedChildData.push("");
        }
      } else if (howMany < updatedChildData.length) {
        updatedChildData.splice(howMany);
      }

      updateCustomerState({
        ...customerState,
        customer: {
          ...customerState.customer,
          childData: updatedChildData,
          childCount: howMany.toString(),
        },
      });
    }
  };

  const handleChildDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { value } = event.target;
    const updatedChildData = [...customerState.customer.childData];
    updatedChildData[index] = value;

    updateCustomerState({
      ...customerState,
      customer: {
        ...customerState.customer,
        childData: updatedChildData,
      },
    });
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion
        label={t("customer.application.form.personal.data.personal.data")}
      >
        <Grid container className={classes.columnContainer} spacing={2}>
          {/** */}
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("customer.application.form.personal.data.how.many.people")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Grid item md={6}>
                <FormControl fullWidth className={classes.select}>
                  <InputLabel>{t("customer.application.form.personal.data.adults")}</InputLabel>
                  <Select
                    value={customerState.customer.adultCount}
                    name="adultCount"
                    onChange={handleChange}
                    displayEmpty
                    label={t("customer.application.form.personal.data.adults")}
                  >
                    {selectionItemsAdults}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl fullWidth className={classes.select}>
                  <InputLabel>{t("customer.application.form.personal.data.children")}</InputLabel>
                  <Select
                    value={customerState.customer.childCount}
                    name="childCount"
                    onChange={handleChange}
                    displayEmpty
                    label={t("customer.application.form.personal.data.children")}
                  >
                    {selectionItemsChildren}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {parseInt(customerState.customer.adultCount, 10) >= 0 && (
            <Grid item container spacing={2} xs={12}>
              {customerState.customer.adultsData
                .map((adult, index) => (
                  <Grid item xs={12} key={index}>
                    {(index === 0 ||
                      customerState.customer.adultCount !== "") && (
                      <AdultDetails
                        key={index}
                        id={index}
                        firstName={adult.firstName}
                        lastName={adult.lastName}
                        birthday={adult.birthday}
                        nationality={adult.nationality}
                        profession={adult.profession}
                        employmentType={adult.employmentType}
                        employer={adult.employer}
                        maritalStatus={adult.maritalStatus}
                        relationship={adult.relationship}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
          )}

          {/*Children data*/}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            sx={{
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {parseInt(customerState.customer.childCount, 10) > 0 && (
              <Grid item container spacing={2} xs={12} mt={1}>
                {customerState.customer.childData
                  .map((child, index) => (
                    <Grid item md={3} key={index}>
                      <Grid item key={index} className={classes.childBox}>
                        <Typography variant="body1" className={classes.bodyText} sx={{ fontWeight: "700", color: "rgba(16, 21, 41, 0.6)" }}>
                          {t("customer.application.form.personal.data.child")}{" "}{`${index + 1}`}{":"}
                        </Typography>
                        <TextField
                          label={t("customer.application.form.personal.data.age.of.the.child")}
                          value={child}
                          onChange={(event) =>
                            handleChildDataChange(event, index)
                          }
                          name="childData"
                          size="small"
                          fullWidth
                          sx={{ marginTop: "8px" }}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>

          <Grid item container spacing={1} mt={1}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("customer.application.form.personal.data.other") + ":"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Grid item md={3}>
                <FormControl fullWidth className={classes.select}>
                  <InputLabel>{t("customer.application.form.personal.data.smoker")}</InputLabel>
                  <Select
                    label={t("customer.application.form.personal.data.smoker")}
                    value={customerState.customer.smoker}
                    name="smoker"
                    onChange={handleChange}
                    displayEmpty
                  >
                    {booleanCategories.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {lang === "en"
                            ? item.name_en
                            : lang === "de"
                            ? item.name_de
                            : ""}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl fullWidth className={classes.select}>
                  <InputLabel>{t("customer.application.form.personal.data.pets")}</InputLabel>
                  <Select
                    label={t("customer.application.form.personal.data.pets")}
                    value={customerState.customer.pets}
                    name="pets"
                    onChange={handleChange}
                    displayEmpty
                  >
                    {booleanCategories.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {lang === "en"
                            ? item.name_en
                            : lang === "de"
                            ? item.name_de
                            : ""}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {customerState.customer.pets === "64788ed4b6e4e9a7a99b2573" && (
                <Grid item md={6}>
                  <CustomTextField
                    label={t("customer.application.form.personal.data.which.pets")}
                    value={customerState.customer.petTypes}
                    name="petTypes"
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item container spacing={1} className={classes.columnContainer}>
            <Grid item>
              <TextField
                fullWidth
                multiline
                rows={4}
                label={t("customer.application.form.personal.data.hobbies")}
                value={customerState.customer.hobbies}
                variant="outlined"
                name="hobbies"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={1} className={classes.columnContainer}>
            <Grid item>
              <Typography variant="body1" className={classes.bodyText}>
                {t("customer.application.form.personal.data.message.to.landlord") + ":"}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: false }}
                value={customerState.customer.messages}
                placeholder="…"
                variant="outlined"
                name="messages"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{ justifyContent: "end" }}>
                {isLoading ? (
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.info}>
                      {t("generate.text.spinner")}
                    </Typography>
                    <CircularProgress color="success" sx={{ marginLeft: "16px" }}/>
                  </Box>
                ) : (
                  <Button className={classes.button} onClick={handleGenerateMessageSuggestion}>
                    {t("generate.text")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={1} className={classes.imageContainer}>
            <Grid item xs={6}>
              <TenantImage />
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default Details;
