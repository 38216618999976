import { API_BASE_URL } from "./CONSTANTS";
import { request } from "./authHandler";

export function getEstateType() {
  return request({
    url: API_BASE_URL + "/entity/Estate_Type",
    method: "GET",
  });
}

export function getEstateTypeDetailName() {
  return request({
    url: API_BASE_URL + "/entity/Estate_Type_Detail_Name",
    method: "GET",
  });
}

export function getGermanState() {
  return request({
    url: API_BASE_URL + "/entity/German_State",
    method: "GET",
  });
}

export function getEnergyClass() {
  return request({
    url: API_BASE_URL + "/entity/Energy_Class",
    method: "GET",
  });
}

export function getTypeOfHeating() {
  return request({
    url: API_BASE_URL + "/entity/Type_of_Heating",
    method: "GET",
  });
}

export function getEssentialEnergySource() {
  return request({
    url: API_BASE_URL + "/entity/Essential_Energy_Source",
    method: "GET",
  });
}

export function getFlooring() {
  return request({
    url: API_BASE_URL + "/entity/Living_Room_Flooring_Type",
    method: "GET",
  });
}

export function getObjectCondition() {
  return request({
    url: API_BASE_URL + "/entity/Object_Conditions",
    method: "GET",
  });
}

export function getFamilyCategories() {
  return request({
    url: API_BASE_URL + "/entity/Family_Categories",
    method: "GET",
  });
}

export function getEmploymentCategories() {
  return request({
    url: API_BASE_URL + "/entity/Employment_Categories",
    method: "GET",
  });
}

export function getIncomeCategories() {
  return request({
    url: API_BASE_URL + "/entity/Income_Categories",
    method: "GET",
  });
}

export function getBooleanCategories() {
  return request({
    url: API_BASE_URL + "/entity/Boolean_Categories",
    method: "GET",
  });
}

export function getApplicationStatuses() {
  return request({
    url: API_BASE_URL + "/entity/Application_Statuses",
    method: "GET",
  });
}
