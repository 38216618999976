export const HOST_URL = "http://vermietsmart.de";

// a bit ugly, but I don't want to fully switch to .env files because I'm not sure about all the side effects in the existing build
var this_api_base_url = "http://vermietsmart.de:8080"
if (typeof process.env.REACT_APP_API_BASE_URL !== 'undefined') {
  this_api_base_url = process.env.REACT_APP_API_BASE_URL
}
export const API_BASE_URL = this_api_base_url;
export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI = "http://vermietsmart.de/oauth2/redirect";
export const GOOGLE_AUTH_URL =
  API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  API_BASE_URL +
  "/oauth2/authorize/facebook?redirect_uri=" +
  OAUTH2_REDIRECT_URI;

export const SEARCH_AUTO_COMPLETE =
  API_BASE_URL + "/rental/address/autocomplete";

export const UPLOAD_PHOTOS = API_BASE_URL + "/rental/upload/images";
export const UPLOAD_ENERGY_CERTIFICATES =
  API_BASE_URL + "/rental/upload/energyCertificates";
export const UPLOAD_FLOOR_PLAN = API_BASE_URL + "/rental/upload/floorPlan";

export const UPLOAD_CUSTOMER_PHOTO = API_BASE_URL + "/customer/upload/photo";
export const UPLOAD_PROOF_OF_INCOME =
  API_BASE_URL + "/customer/upload/incomeProof";
export const UPLOAD_INCOME_TAX = API_BASE_URL + "/customer/upload/incomeTax";
export const UPLOAD_RENTAL_COST_TAKE =
  API_BASE_URL + "/customer/upload/costTake";
export const UPLOAD_RENTAL_GUARANTEE =
  API_BASE_URL + "/customer/upload/rentalGuarantee";
export const UPLOAD_SCHUFA = API_BASE_URL + "/customer/upload/schufa";
export const UPLOAD_RENT_PAYMENTS =
  API_BASE_URL + "/customer/upload/rentPayments";
