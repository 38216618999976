import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { getAddressSuggestions, Place } from "../../../services/rentalHandler";
import AutoCompleteDropDown from "./AutoCompleteDropDown";
import { ErrorState } from "../../Expose/Form/FormComponent";

const renderSuggestion = (suggestion: Place) => {
  return `${suggestion.description}`;
};

interface AddressFieldProps {
  address: string;
  setAddress: Dispatch<SetStateAction<any>>;
  errors: ErrorState;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
  realTimeZip: (zip: string) => void;
}

const AddressField = (props: AddressFieldProps) => {
  const { address, setAddress, errors, setErrors, realTimeZip } = props;

  const onSelect = (suggestion: Place) => {
    // can you spell ugly hack? ;-)
    let strPlz = "";
    const iCanHazPlz = suggestion.description.match(/\b\d{5}\b/);
    if (iCanHazPlz && iCanHazPlz.length === 1) {
      const plz = parseInt(iCanHazPlz[0]);
      if (!isNaN(plz) && plz > 1000 && plz < 99999) {
        strPlz = plz.toString().padStart(5, "0");
        realTimeZip(strPlz);
      }
    }
    if (strPlz === "") {
      setErrors({
        ...errors,
        address: ""
      });
    }
    setAddress((prevData: any) => ({
      ...prevData,
      address: suggestion.description,
      placeId: suggestion.place_id,
      zip: strPlz !== "" ? strPlz : prevData.zip,
    }));
  };

  return (
    <>
      <AutoCompleteDropDown
        address={address}
        getSuggestions={getAddressSuggestions}
        renderSuggestion={renderSuggestion}
        onSelect={(suggestion) => onSelect(suggestion)}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
};

export default AddressField;
