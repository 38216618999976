import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    backgroundColor: "#E7E7E7",
  },
  main: {
    marginTop: "50px",
    marginLeft: "50px",
  },
  icon: {
    width: 104,
    height: 86,
  },
}));
