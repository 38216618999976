import React from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";

const ContactPage = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" className={classes.root}>
      <Grid item className={classes.main}>
        <Typography variant="h4">Kontakt</Typography>
        <br />
        <Typography variant="body1">
          Sie haben Fragen, Anregungen oder ein Problem?
        </Typography>
        <br />
        <Typography variant="body1">
          Kontaktieren Sie uns unter: <strong>info@smart-agent.eu</strong>
        </Typography>
        <br />
      </Grid>
    </Grid>
  );
};

export default ContactPage;
