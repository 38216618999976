import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "./styles";

interface AccordionProps {
  children?: React.ReactNode;
  id?: string;
  label: string;
  isExpand?: boolean;
}

export const VerticalAccordion = (props: AccordionProps) => {
  //TODO
  const classes = useStyles();
  return (
    <Grid>
      <Accordion
        square
        className={classes.accordion}
        defaultExpanded={props.isExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
          id={props.id}
          className={classes.expandMoreIcon}
        >
          <Typography className={classes.accordionHead}>
            {props.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails> {props.children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};
