import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    background: "#FECDD2",
    color: theme.palette.error.dark,
    marginTop: "6px",
    fontWeight: colors.fontWeightBold,
    fontSize: colors.fontSizeSmall,
    padding: "0 12px",
  },
}));
