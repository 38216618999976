import { useTranslation } from "react-i18next";
import i18next from "i18next";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./styles";
import { useState, useEffect } from "react";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import {
  Grid,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  getFlooring,
  getObjectCondition,
} from "../../../services/entitiyHandler";
import { FormData } from "../Form/types";

interface FlooringAndConditionProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

interface Entities {
  id: string;
  value_1: string;
  name_de: string;
  name_en: string;
  type: string;
}

const FlooringAndCondition = (props: FlooringAndConditionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const [floorCooveringSelect, setFloorCooveringSelect] = useState<Entities[]>(
    []
  );
  const [conditionSelect, setConditionSelect] = useState<Entities[]>([]);

  const [floorCovering, setFloorCovering] = useState(
    props.formData.floorCovering
  );
  const [conditionOfTheObject, setConditionOfTheObject] = useState(
    props.formData.conditionOfTheObject
  );

  const [hoveredObject, setHoveredObject] = useState("");
  const handleMouseOver = (obj: string) => {
    setHoveredObject(obj);
  };

  const handleMouseOut = () => {
    setHoveredObject("");
  };

  useEffect(() => {
    getFlooring().then((response) => {
      setFloorCooveringSelect(response);
    });
    getObjectCondition().then((response) => {
      setConditionSelect(response);
    });
  }, []);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name === "floorCovering") {
      setFloorCovering(value);
      props.setFormData({ ...props.formData, floorCovering: value });
    }
    if (name === "conditionOfTheObject") {
      setConditionOfTheObject(value);
      props.setFormData({ ...props.formData, conditionOfTheObject: value });
    }
  };

  const langCompare = (a: any, b: any, lang: string) => {
    const aValue = lang === "en" ? a.name_en : a.name_de;
    const bValue = lang === "en" ? b.name_en : b.name_de;
    if (aValue.charAt(0) === ".") {
      return 1;
    } else if (bValue.charAt(0) === ".") {
      return -1;
    } else {
      return aValue.localeCompare(bValue);
    }
  };

  const langSlice = (item: any, lang: string) => {
    let itemValue = lang === "en" ? item.name_en : item.name_de;
    if (itemValue.charAt(0) === ".") {
      itemValue = itemValue.substring(1);
    }
    return itemValue;
  };

  return (
    <Grid item container className={classes.columnContainer} spacing={2}>
      <Grid
        item
        container
        className={classes.rowContainer}
        spacing={2}
        onMouseOver={() => handleMouseOver("floorCovering")}
        onMouseOut={handleMouseOut}
      >
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.select}>
            <InputLabel>{t("extra.info.flooring")}</InputLabel>
            <Select
              id="floorCovering"
              value={floorCooveringSelect.length ? floorCovering : ""}
              name="floorCovering"
              label={t("extra.info.flooring")}
              onChange={handleSelectChange}
              displayEmpty
            >
              {floorCooveringSelect.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {langSlice(item, lang)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {hoveredObject === "floorCovering" && (
          <Grid item xs={6}>
            <InfoAlert>{t("extra.info.flooring.alert")}</InfoAlert>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        className={classes.rowContainer}
        spacing={2}
        onMouseOver={() => handleMouseOver("conditionOfTheObject")}
        onMouseOut={handleMouseOut}
      >
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.select}>
            <InputLabel>{t("extra.info.object.condition")}</InputLabel>
            <Select
              id="conditionOfTheObject"
              value={conditionSelect.length ? conditionOfTheObject : ""}
              name="conditionOfTheObject"
              onChange={handleSelectChange}
              displayEmpty
              label={t("extra.info.object.condition")}
            >
              {conditionSelect.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {langSlice(item, lang)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {hoveredObject === "conditionOfTheObject" && (
          <Grid item xs={6} className={classes.infoContainer}>
            <div className={classes.info}>
              <InfoAlert>{t("extra.info.object.condition.alert")}</InfoAlert>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FlooringAndCondition;
