import { RelativeObject } from "../../../context/ObjectContext";
import { useState } from "react";
import { ObjectContext } from "../../../context/ObjectContext";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardDeck from "./CardDeck/CardDeck";
import InfoText from "../../Customs/InfoText";

const ObjectsRenderer = () => {
  const { t } = useTranslation();
  const [relativeObjects, setRelativeObjects] = useState<RelativeObject[]>([
    {
      rent: 1300,
      address: "ca. 0,4 km",
      distance: "Schlossstrasse 45, 87455 München",
      size: "22qm",
      rooms: 2,
      tintColor: "#b0c2a7",
    },
    {
      rent: 1340,
      address: "ca. 0,5 km",
      distance: "Paul Hindemith Allee 45, 87455 München",
      size: "44qm",
      rooms: 3,
      tintColor: "#7078be",
    },
    {
      rent: 1500,
      address: "ca. 0,5 km",
      distance: "Maximillianstrasse 2a, 87455 München",
      size: "65qm",
      rooms: 3,
      tintColor: "#b6be70",
    },
    {
      rent: 1630,
      address: "ca. 0,5 km",
      distance: "Maximillianstrasse 2a, 87455 München",
      size: "75qm",
      rooms: 3,
      tintColor: "#be8a70",
    },
  ]);
  const updateRelativeObjects = (objects: RelativeObject[]) => {
    setRelativeObjects(objects);
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <InfoText
          labelText={t("property.example.label")}
          bodyText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
            rebum."
        />
      </Grid>

      <Grid item container mt={4}>
        <ObjectContext.Provider
          value={{ relativeObjects, updateRelativeObjects }}
        >
          <CardDeck />
        </ObjectContext.Provider>
      </Grid>
    </Grid>
  );
};
export default ObjectsRenderer;
