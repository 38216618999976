import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    //borderRadius: 0,
    boxShadow: colors.cardShadow,
    minHeight: "100%",
  },

  dot: {
    color: "gray",
  },
}));
