import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface Props {
  activeStep: number;
}

const HorizontalStepper: React.FC<Props> = ({ activeStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const steps = [
    t("customer.application.step.1"),
    t("customer.application.step.2"),
  ];
  return (
    <Grid
      container
      className={classes.stepperContainer}
      item
      sx={{
        display: { xs: "none", md: "block" },
      }}
    >
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        sx={{ width: "100%" }}
      >
        {steps.map((label, index) => (
          <Step key={`${index}`}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default HorizontalStepper;
