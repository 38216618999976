import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Alert from "react-s-alert";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { getCurrentUser } from "../../services/authHandler";
import { DASHBOARD, ROOT } from "../../routes/ROUTES";
import { ACCESS_TOKEN } from "../../services/CONSTANTS";
import logo from "../../assets/img/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageMenu from "../Common/LanguageMenu/LanguageMenu";
import * as colors from "../../style/colors";

export const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authStatus: { authenticated, currentUser },
    updateAuthStatus,
  } = useContext(AuthContext);
  const [userImage, setImgUrl] = useState<string>(
    currentUser?.imageUrl ? currentUser.imageUrl : ""
  );
  const [userName, setUserName] = useState<string>(
    currentUser?.name ? currentUser.name : ""
  );

  /*useEffect(() => {
    setContent(<SideBar />);
  }, [setContent]);*/

  useEffect(() => {
    if (authenticated) {
      getCurrentUser()
        .then((user: any) => {
          updateAuthStatus({
            loading: false,
            authenticated: true,
            currentUser: user,
          });
          setImgUrl(user?.imageUrl ? user.imageUrl : "");
          setUserName(user?.name ? user.name : "");
        })
        .catch((err) => {
          updateAuthStatus({ loading: false, authenticated: true });
          Alert.error(
            (err && err.message) ||
              "Oops! Something went wrong. Please try again!"
          );
        });
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const goToHome = () => {
    navigate(authenticated ? DASHBOARD : ROOT);
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    updateAuthStatus({
      currentUser: undefined,
      authenticated: false,
      loading: false,
    });
    Alert.success("You're safely logged out!");
  };

  /*const handleMenuIconClick = () => {
    setVisibility(true);
  };*/

  return (
    <Paper elevation={4}>
      <Box className={classes.header}>
        <Box className={classes.left}>
          <Box sx={{ flexGrow: 1 }}>
            <Box className={classes.logo}>
              <img
                className={classes.logoImg}
                onClick={goToHome}
                src={logo}
                alt="logo"
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.right}>
          <Grid sx={{ marginRight: 2 }}>
            <LanguageMenu />
          </Grid>
          <Box sx={{ mr: 4 }}>
            {authenticated ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  {userName ? (
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar 
                        alt={userName} 
                        src={userImage} 
                        sx={{ bgcolor: colors.smartAgentGreen }}
                      />
                    </IconButton>
                  ) : (
                    <MenuIcon />
                  )}
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser !== null)}
                  onClose={handleCloseUserMenu}
                >
                  {/**<MenuItem onClick={goToProfile}>
                    <Typography textAlign="center">
                      {t("profile.menu.profile")}
                    </Typography>
                  </MenuItem> */}
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">
                      {t("profile.menu.logout")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : null}
          </Box>
          {/**<Grid sx={{ marginRight: 16 }}>
            <IconButton>
              <MenuIcon />
            </IconButton>
          </Grid> */}
        </Box>
      </Box>
    </Paper>
  );
};

export default Header;
