import React, { useContext, useState } from "react";
import { useStyles } from "./styles";
import { Button, Grid, Typography } from "@mui/material";
import { CustomerContext } from "../../../context/CustomerContext";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";

export type LocalFile = {
  id: string;
  src: string;
  file: File | null;
};

export type FileType =
  | "PROOF_OF_INCOME"
  | "INCOME_TAX"
  | "RENTAL_COST_TAKE"
  | "RENTAL_GUARANTEE"
  | "SCHUFA"
  | "RENT_PAYMENTS"
  | "";

const customizeFileUploader = (
  type: FileType
): {
  buttonLabel: string;
  buttonLabelAgain: string;
  attribute: string;
} => {
  switch (type) {
    case "PROOF_OF_INCOME":
      return {
        buttonLabel: t("customer.application.form.personal.data.proofOfIncome"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "proofOfIncome",
      };

    case "INCOME_TAX":
      return {
        buttonLabel: t("customer.application.form.personal.data.incomeTaxStatement"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "incomeTaxStatement",
      };

    case "RENTAL_COST_TAKE":
      return {
        buttonLabel: t("customer.application.form.personal.data.rentalCostTakeOver"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "rentalCostTakeOver",
      };

    case "RENTAL_GUARANTEE":
      return {
        buttonLabel: t("customer.application.form.personal.data.rentalGuarantee"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "rentalGuarantee",
      };

    case "SCHUFA":
      return {
        buttonLabel: t("customer.application.form.personal.data.schufa"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "schufa",
      };

    case "RENT_PAYMENTS":
      return {
        buttonLabel: t("customer.application.form.personal.data.rentPaymentConfirmations"),
        buttonLabelAgain: t("customer.application.form.personal.data.files.again"),
        attribute: "rentPaymentConfirmations",
      };

    default:
      return {
        buttonLabel: "",
        buttonLabelAgain: "",
        attribute: "",
      };
  }
};

interface Props {
  type: FileType;
}

const FileUploader = ({ type }: Props) => {
  const classes = useStyles();
  const { buttonLabel, buttonLabelAgain, attribute } = customizeFileUploader(type);
  const { customerState, updateCustomerState } = useContext(CustomerContext);

  const [selectedFiles, setSelectedFiles] = useState(
    customerState.customer[attribute]
  );

  const handleDownloadFile = (file: File) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setSelectedFiles(files);
    updateCustomerState({
      customer: { ...customerState.customer, [attribute]: files },
    });
  };

  return (
    <Grid container sx={{ width: "100%", paddingTop: "9px" }}>
      {selectedFiles instanceof FileList && (
        <>
          {Array.from(selectedFiles).map((file, index, {length}) => (
            <Grid 
              container 
              key={index} 
              onClick={() => handleDownloadFile(file)} 
              sx={{
                marginBottom: index + 1 === length ? "8px" : "",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Grid item xs={1}>
                <InsertDriveFileIcon className={classes.icon} />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.fileName}>
                  {file.name}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </>
      )}
      <Grid sx={{ width: "100%" }}>
        <Button
          variant="outlined"
          component="label"
          className={classes.fileButton}
          fullWidth
        >
          {selectedFiles instanceof FileList && selectedFiles.length > 0 && (
            <RestartAltIcon className={classes.icon} />
          )}
          {!(selectedFiles instanceof FileList) && (
            <UploadFileIcon className={classes.icon} />
          )}
          {(selectedFiles instanceof FileList && selectedFiles.length > 0) ? buttonLabelAgain : buttonLabel}
          <input
            type="file"
            name="file"
            multiple={true}
            hidden
            accept="*"
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default FileUploader;
