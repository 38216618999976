import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { ESTATE } from "../../../routes/ROUTES";
import { useTranslation } from "react-i18next";
import AddBoxIcon from '@mui/icons-material/AddBox';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import { keyframes } from '@mui/system';

interface NewObjectCardProps {
  objectCount: number;
}

const NewObjectCard = ({ objectCount }: NewObjectCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const shine = keyframes`
    from {
      mask-position: 150%;
    }
    to {
      mask-position: -50;
    }
  `;

  return (
    <Box className={classes.createCard} onClick={() => navigate(ESTATE)}>
      <Box className={classes.createCardFont}>
        <Box className={classes.createCardInner}>
          <AddBoxIcon sx={{ paddingRight: "4px", paddingTop: "1px", fontSize: 20 }} />
          {t("customer.dashboard.object.create.new.rental")}
        </Box>
        {objectCount === 0 && (
          <Box className={classes.createCardNudge} sx={{animation: `${shine} 2s linear infinite`}}>
            <TurnSlightRightIcon className={classes.createCardNudgeIcon} />
            {t("customer.dashboard.object.create.new.rental.info")}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NewObjectCard;
