import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./styles";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  InputLabel,
  FormControlLabel,
} from "@mui/material";
import {
  CustomerContext,
  CustomerState,
} from "../../../context/CustomerContext";
import FileUploader, { FileType } from "./FileUploader";
import { getIncomeCategories } from "../../../services/entitiyHandler";
import { Entities } from "../../Expose/Form/types";
import i18next from "i18next";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";

const FinancialDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const { customerState, updateCustomerState } = useContext(CustomerContext);

  const [incomeCategories, setIncomeCategories] = useState<Entities[]>([]);

  useEffect(() => {
    getIncomeCategories().then((response) => {
      setIncomeCategories(response);
    });
  }, []);

  const initialState = [
    {
      id: "canProvideProofOfIncome",
      checked: customerState.customer.canProvideProofOfIncome,
      type: "PROOF_OF_INCOME" as FileType,
    },
    {
      id: "canProvideIncomeTaxStatement",

      checked: customerState.customer.canProvideIncomeTaxStatement,
      type: "INCOME_TAX" as FileType,
    },
    {
      id: "canProvideRentalCostTakeOver",

      checked: customerState.customer.canProvideRentalCostTakeOver,
      type: "RENTAL_COST_TAKE" as FileType,
    },
    {
      id: "canProvideRentalGuarantee",

      checked: customerState.customer.canProvideRentalGuarantee,
      type: "RENTAL_GUARANTEE" as FileType,
    },
    {
      id: "canProvideSCHUFA",

      checked: customerState.customer.canProvideSCHUFA,
      type: "SCHUFA" as FileType,
    },
    {
      id: "canProvideRentPaymentConfirmations",

      checked: customerState.customer.canProvideRentPaymentConfirmations,
      type: "RENT_PAYMENTS" as FileType,
    },
  ];

  const insureInitialState = [
    {
      id: "nonEvictionForLastYears",
      checked: customerState.customer.nonEvictionForLastYears,
    },
    {
      id: "nonRentClaimProcess",
      checked: customerState.customer.nonRentClaimProcess,
    },
    {
      id: "nonSalaryGarnishment",
      checked: customerState.customer.nonSalaryGarnishment,
    },
    {
      id: "nonAffidavit",
      checked: customerState.customer.nonAffidavit,
    },
    {
      id: "nonBankruptcy",
      checked: customerState.customer.nonBankruptcy,
    },
  ];

  const [items, setItems] = useState(initialState);
  const [insureStatements, setInsureStatements] = useState(insureInitialState);

  const handleToggle = (index: number) => {
    const updatedItems = [...items];
    updatedItems[index].checked = !updatedItems[index].checked;
    setItems(updatedItems);

    // Update the customerState
    const updatedCustomerState = {
      ...customerState,
      customer: {
        ...customerState.customer,
        [updatedItems[index].id]: updatedItems[index].checked,
      },
    };
    updateCustomerState(updatedCustomerState);
  };

  const handleToggleInsure = (index: number) => {
    const updatedItems = [...insureStatements];
    updatedItems[index].checked = !updatedItems[index].checked;
    setInsureStatements(updatedItems);

    // Update the customerState
    const updatedCustomerState = {
      ...customerState,
      customer: {
        ...customerState.customer,
        [updatedItems[index].id]: updatedItems[index].checked,
      },
    };
    updateCustomerState(updatedCustomerState);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    const updatedCustomerState: CustomerState = {
      ...customerState,
      customer: {
        ...customerState.customer,
      },
    };

    updatedCustomerState.customer = {
      ...updatedCustomerState.customer,
      [name]: value,
    };

    updateCustomerState(updatedCustomerState);
  };

  const langSlice = (item: any, lang: string) => {
    let itemValue = lang === "en" ? item.name_en : item.name_de;
    if (itemValue.charAt(0) === ".") {
      itemValue = itemValue.substring(1);
    }
    return itemValue;
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion
        label={t("customer.application.form.personal.data.financial.data")}
      >
        <Grid container className={classes.columnContainer} spacing={2}>
          <Grid item md={6}>
            <FormControl fullWidth className={classes.select}>
                <InputLabel>{t("customer.application.form.personal.data.net.income")}</InputLabel>
              <Select
                value={customerState.customer.netHouseHoldIncome}
                name="netHouseHoldIncome"
                onChange={handleSelectChange}
                displayEmpty
                label={t("customer.application.form.personal.data.net.income")}
              >
                {incomeCategories.sort((a, b) => a.id.localeCompare(b.id)).map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {langSlice(item, lang)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item md={7}>
              <Typography variant="body1" className={classes.bodyText} sx={{fontWeight: "700"}}>
                {t("customer.application.form.personal.data.finacial.text")}{":"}
              </Typography>
            </Grid>

            <Grid item md={5}>
              <InfoAlert>{t("customer.application.form.personal.data.finacial.info")}</InfoAlert>
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            spacing={2}
            xs={12}
          >
            {items.map((item, index) => {
              return (
                <Grid item container key={index}>
                  <Grid
                    item
                    container
                    className={classes.rowContainer}
                    xs={12}
                    spacing={2}
                  >
                    <Grid item md={7} className={classes.gridContainer} sx={{alignItems:"flex-start"}}>
                      <FormControlLabel 
                        control={<Checkbox checked={item.checked} onChange={() => handleToggle(index)} />}
                        label={t(`customer.application.form.personal.data.${item.id}`)}
                      />
                    </Grid>

                    <Grid item md={5} sx={{alignItems:"flex-start"}}>
                      {item.type !== "" && (
                        <FileUploader key={item.id} type={item.type} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} mt={4}>
            <Typography variant="body1" className={classes.bodyText} sx={{fontWeight: "700"}}>
              {t("customer.application.form.personal.data.insure")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            className={classes.columnContainer}
            xs={12}
          >
            {insureStatements.map((item, index) => {
              return (
                <Grid item container key={index}>
                  <Grid
                    item
                    container
                    className={classes.rowContainer}
                    xs={12}
                  >
                    <Grid item md={12} className={classes.gridContainer}>
                      <FormControlLabel 
                        control={<Checkbox checked={item.checked} onChange={() => handleToggleInsure(index)} />}
                        label={t(`customer.application.form.personal.data.${item.id}`)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default FinancialDetails;
