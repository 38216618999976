import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ImagePreview } from "./ImagePreview";
import { LocalFile } from "./ImageUploader";
import { savePhotos } from "../../../services/rentalHandler";
import Alert from "react-s-alert";
import { CircularProgress, Typography } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  images: Array<LocalFile>;
  setSelectedImages: Dispatch<SetStateAction<LocalFile[]>>;
  setCurrentImages: Dispatch<SetStateAction<LocalFile[]>>;
  endPoint: string;
}

const ImageEditDialog = ({
  open,
  handleClose,
  title,
  images,
  setSelectedImages,
  endPoint,
  setCurrentImages,
}: Props) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setUploading(false);
  }, []);
  const handleUpload = () => {
    if (images && images.length > 0) {
      setUploading(true);
      savePhotos(images, endPoint)
        .then(() => {
          setUploading(false);
          setCurrentImages(images);
          handleClose();
        })
        .catch((err) => {
          setUploading(false);
          Alert.error((err && err.message) || t("error"));
        });
    } else {
      setUploading(false);
      Alert.error(`${t("accordion.pictures.upload.energy.upload.error")}`);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        {t(title)}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" className={classes.dialogInfo}>
          {t("accordion.label.pictures.dialog.info")}
        </Typography>
        <ImagePreview
          images={images}
          setSelectedImages={setSelectedImages}
          showDeleteBtn={true}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleClose}
          className={classes.buttonSecondary}
          variant="outlined"
        >
          {t("dialog.close")}
        </Button>
        {uploading ? (
          <CircularProgress size={24} sx={{ marginLeft: 4 }} color="success" />
        ) : (
          <Button onClick={handleUpload} autoFocus className={classes.button} >
            {t("dialog.save")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImageEditDialog;
