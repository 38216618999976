import { Grid, TextField, InputAdornment } from "@mui/material";
import ErrorText from "../../Common/ErrorText/ErrorText";
import { useStyles } from "./styles";

interface TextFieldProps {
  label: string;
  value: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  adornment?: string;
  err?: string;
  disabled?: boolean;
}

const CustomTextField = ({
  label,
  value,
  onChange,
  name,
  adornment,
  err,
  disabled = false,
}: TextFieldProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <TextField
        fullWidth
        //InputLabelProps={{ shrink: false }}
        label={label}
        value={value}
        variant="outlined"
        name={name}
        onChange={onChange}
        className={classes.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
        error={!!err}
        disabled={disabled}
      />
      {err && <ErrorText>{err}</ErrorText>}
    </Grid>
  );
};

export default CustomTextField;
