import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { 
  Box, 
  Grid, 
  Link, 
  TextField, 
  Typography,
  Button,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import {
  Adult,
  CustomerContext,
  CustomerState,
  defaultCustomerState,
} from "../../../context/CustomerContext";
import i18next from "i18next";
import {
  getCustomer,
  getDropDownValues,
} from "../../../services/aplicationHandler";
import AdultsData from "./PersonalData";
import Documents from "./Documents";
import { Loader } from "./Loader";
import Alert from "react-s-alert";
import { useParams } from "react-router-dom";
import CheckBoxGroup from "./CheckBoxGroup";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorText from "../../Common/ErrorText/ErrorText";

interface Props {
  setActiveStep?: Dispatch<SetStateAction<number>>;
  objectName?: string;
}

const ApplicationPreview = ({ setActiveStep, objectName }: Props) => {
  const classes = useStyles();
  const lang = i18next.language;
  const containerElement = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const params = useParams();
  const { customerId } = params;
  const isCustomerVersion: boolean = !!setActiveStep;

  const [customerState, setCustomerState] =
    useState<CustomerState>(defaultCustomerState);
  const customerContext = useContext(CustomerContext);
  const [invalidPage, setInvalidPage] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [photo, setPhoto] = useState<string | null>();
  const [pdfVersion, setPdfVersion] = useState<boolean>(false);

  // entity data
  const [adultsData, setAdultsData] = useState<Adult[]>([]);
  const [isSmoking, setIsSmoking] = useState<boolean>(false);
  const [netIncome, setNetIncome] = useState<string>("");

  const hasLengthyMsg = customerState.customer.messages.length > 700;
  const mainTitle = "mainTitle2";
  const title = "title";
  const subTitle = "subTitle";
  const body = "body";

  const commingSoon = () => {
    Alert.info( `${t("error.notYetAvailable")}` );
  };

  const successMessage = () => {
    setShowError(true);
    if (notYetChecked || dataPrivacyError) {
      Alert.error( `${t("customer.application.error")}` );
    } else {
      Alert.success( `${t("customer.application.success")}` );
    }
  };

  const generateBirthDay = (array: string[]) => {
    const year = array[0];
    const month = String(array[1]).padStart(2, "0");
    const day = String(array[2]).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const setData = () => {
    if (customerState.customer.photo) {
      if (!isCustomerVersion) {
        const photo = customerState.customer.photo as any;
        setPhoto(`data:image/jpeg;base64,${photo["image"]["data"]}`);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhoto(reader.result as string);
        };
        reader.readAsDataURL(customerState.customer.photo);
      }
    }

    // generate drop-down values
    const adultsArray: Adult[] = [];
    const fetchEntities = async () => {
      const adultsData = customerState.customer.adultsData;
      if (adultsData.length > 0) {
        for (const adult of adultsData) {
          let employmentTypeResponse = { name_en: "", name_de: "" };
          let materialStatusResponse = { name_en: "", name_de: "" };

          if (adult.employmentType) {
            employmentTypeResponse = await getDropDownValues(
              adult.employmentType,
              setLoading
            );
          }

          if (adult.maritalStatus) {
            materialStatusResponse = await getDropDownValues(
              adult.maritalStatus,
              setLoading
            );
          }

          if (!isCustomerVersion && adult.birthday) {
            const date = adult.birthday as any;
            adult.birthday = generateBirthDay(date);
          }
          adultsArray.push({
            ...adult,
            employmentType:
              lang === "de"
                ? employmentTypeResponse?.name_de
                : employmentTypeResponse?.name_en,
            maritalStatus:
              lang === "de"
                ? materialStatusResponse?.name_de
                : materialStatusResponse?.name_en,
          });
        }
      }

      if (customerState.customer.smoker) {
        const isSmoking = await getDropDownValues(
          customerState.customer.smoker,
          setLoading
        );
        setIsSmoking(isSmoking === "yes");
      }

      if (customerState.customer.netHouseHoldIncome) {
        const income = await getDropDownValues(
          customerState.customer.netHouseHoldIncome,
          setLoading
        );
        setNetIncome(income.value_1);
      }
    };
    fetchEntities().then(() => {
      setAdultsData(adultsArray);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (isCustomerVersion) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!isCustomerVersion && adultsData.length > 0) {
      setLoading(false);
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultsData, customerState]);

  useEffect(() => {
    if (customerState) {
      setData();
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState]);

  useEffect(() => {
    if (isCustomerVersion) {
      const { customerState } = customerContext;
      setCustomerState(customerState);
    } else {
      if (customerId) {
        getCustomer(customerId)
          .then((res) => {
            const customerState = { ...res };
            setCustomerState({
              id: customerState.id,
              customer: { ...customerState },
            });
            setLoading(false);
          })
          .catch((err) => {
            Alert.error((err && err.message) || t("error"));
            setInvalidPage(true);
            setLoading(false);
          });
      } else {
        setInvalidPage(true);
        setLoading(false);
        Alert.error(`${t("customer.application.preview.error.on.load")}`);
      }
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStepBack = () => {
    setActiveStep?.(0);
  };

  const handlePDFPrint = useReactToPrint({
    content: () => containerElement.current,
  });
  const printPdf = () => {
    setPdfVersion(true);
  };

  useEffect(() => {
    if (pdfVersion) {
      setTimeout(() => {
        handlePDFPrint();
        setPdfVersion(false);
      }, 1000);
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfVersion]);

  // a bunch of convoluted code to make sure the errors are first shown only after the first submit attempt
  // and always updated after the external controller submits a new change
  const [showError, setShowError] = useState<boolean>(false);
  const [notYetChecked, setNotYetChecked] = useState<boolean>(true);
  const [dataPrivacyError, setDataPrivacyError] = useState<boolean>(false);
  const handleCheckboxChange = (checked: boolean[]) => {
    setDataPrivacyError(!checked.every((value) => value));
    setNotYetChecked(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {invalidPage ? (
            <h1>Invalid Page</h1>
          ) : (
            <Grid container>
              <Grid
                container
                className={classes.mainContainer}
                ref={containerElement}
                item
                xs={12}
              >
                <Grid item>
                  <Typography variant={mainTitle}>
                    {t("customer.application.preview.title")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  style={{ pageBreakAfter: "always" }}
                  spacing={8}
                >
                  <Grid item xs={9}>
                    {/*landlord message*/}
                    <Grid
                      item
                      className={classes.msgContainer}
                      style={hasLengthyMsg ? { pageBreakAfter: "always" } : {}}
                    >
                      {customerState.customer["messages"]
                        .split("\n")
                        .map((line, index) => (
                          <Box component="span" key={index}>
                            <Typography variant={body} key={index}>
                              {line}
                            </Typography>
                            <br />
                          </Box>
                        ))}
                    </Grid>

                    {/*personal information*/}
                    <Grid
                      item
                      className={classes.personalDataContainer}
                      sx={{ mt: 8 }}
                    >
                      <Grid
                        sx={{
                          padding: 2,
                        }}
                        className={classes.headerBox}
                      >
                        <Typography variant={title}>
                          {t(
                            "customer.application.preview.personal.data.title"
                          )}
                        </Typography>
                      </Grid>

                      <Grid container sx={{ mt: 4 }}>
                        {adultsData.map((adult, index) => (
                          <AdultsData
                            adult={adult}
                            subTitle={subTitle}
                            body={body}
                            adultNum={index}
                            key={index}
                          />
                        ))}

                        {customerState.customer["childData"] && (
                          <Grid container sx={{ mb: 4 }}>
                            {customerState.customer["childData"].map(
                              (child, index) => (
                                <Grid container className={classes.tableRow}>
                                  <Grid item xs={4}>
                                    <Typography variant={subTitle}>
                                      {`${t("customer.application.preview.personal.data.child.title")} ${index + 1}:`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography variant={body}>
                                      {child !== "" ? 
                                        child + " " + (child === "1" ? 
                                        t("customer.application.preview.personal.data.child.age") :
                                        t("customer.application.preview.personal.data.child.ages")
                                        ) : 
                                        t("customer.application.preview.personal.data.child.noAge")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        )}

                        {customerState.customer.petTypes && (
                          <Grid container className={classes.tableRow}>
                            <Grid item xs={4}>
                              <Typography variant={subTitle}>
                                {t(
                                  "customer.application.preview.personal.data.pets"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant={body}>
                                {customerState.customer["petTypes"]}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        {customerState.customer.hobbies && (
                          <Grid container className={classes.tableRow}>
                            <Grid item xs={4}>
                              <Typography variant={subTitle}>
                                {t(
                                  "customer.application.preview.personal.data.hobbies"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant={body}>
                                {customerState.customer.hobbies}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    {photo && (
                      <Grid item className={classes.photo}>
                        <img
                          className={classes.img}
                          src={photo}
                          alt="This should show the renter"
                        />
                      </Grid>
                    )}

                    <Grid item className={classes.contact}>
                      <Box className={classes.contactLine}>
                        <Typography variant={subTitle} className={classes.contactField}>
                          {t("customer.application.preview.personal.data.address")}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {`${customerState.customer["street"]}, `}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {`${customerState.customer["postalCode"]} ${customerState.customer["city"]}`}
                        </Typography>
                      </Box>

                      {customerState.customer["phoneNumber"] && (
                        <Box className={classes.contactLine}>
                          <Typography variant={subTitle} className={classes.contactField}>
                            {t("customer.application.preview.personal.data.tp")}
                          </Typography>
                          <br />
                          <Typography variant={body} className={classes.contactValue}>
                            {`${customerState.customer["phoneNumber"]}`}
                          </Typography>
                        </Box>
                      )}

                      <Box className={classes.contactLine}>
                        <Typography variant={subTitle} className={classes.contactField}>
                          {t("customer.application.preview.personal.data.email")}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {`${customerState.customer["email"]}`}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={subTitle} className={classes.contactField}>
                          {t("customer.application.preview.personal.data.characteristics")}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {customerState.customer["adultCount"] ? customerState.customer["adultCount"] : "1"}
                          {" "}
                          {(customerState.customer["adultCount"] && parseInt(customerState.customer["adultCount"]) > 1) ? t("customer.application.preview.personal.data.adults") : t("customer.application.preview.personal.data.adult")}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {customerState.customer["childCount"] ? customerState.customer["childCount"] : t("customer.application.preview.personal.data.no")}
                          {" "}
                          {(customerState.customer["childCount"] && parseInt(customerState.customer["childCount"]) === 1) ? t("customer.application.preview.personal.data.child") : t("customer.application.preview.personal.data.children")}
                        </Typography>
                        <br />
                        <Typography variant={body} className={classes.contactValue}>
                          {isSmoking
                            ? t("customer.application.preview.personal.data.smoker")
                            : t("customer.application.preview.personal.data.non.smoker")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ pageBreakInside: "avoid" }}
                  spacing={8}
                >
                  <Grid item xs={9}>
                    <Grid
                      item
                      className={classes.personalDataContainer}
                      sx={{ mt: 8 }}
                    >
                      <Grid
                        item
                        sx={{
                          padding: 2,
                        }}
                        className={classes.headerBox}
                      >
                        <Typography variant={title}>
                          {t("customer.application.preview.income")}
                        </Typography>
                      </Grid>
                      <Grid container className={classes.tableRow}>
                        <Grid item xs={4}>
                          <Typography variant={subTitle}>
                            {t("customer.application.preview.net.income")}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant={body}>
                            {netIncome ? netIncome : t("customer.application.preview.personal.data.na")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={9} sx={{ paddingTop: "16px !important" }}>
                    <Documents
                      customerState={customerState}
                      subTitle={subTitle}
                      pdfVersion={pdfVersion}
                      isBlob={!isCustomerVersion}
                    />
                  </Grid>
                </Grid>


                {pdfVersion && (
                  <Grid container sx={{ mt: 8 }}>
                    {customerState.customer.adultCount && (
                      <>
                        <Typography variant={body}>
                          {t("customer.application.preview.self.agreement.1")}
                        </Typography>
                        <br />
                        <Typography variant={body}>
                          {t("customer.application.preview.self.agreement.2")}
                        </Typography>
                      </>
                    )}
                    {customerState.customer.adultCount &&
                      Array.from(
                        { length: parseInt(customerState.customer.adultCount) },
                        (_, index) => index + 1
                      ).map((item) => (
                        <Grid container sx={{ mt: 8 }} key={item}>
                          <Grid
                            item
                            display="flex"
                            flexDirection="column"
                            xs={4}
                          >
                            <TextField variant="standard" />
                            <Typography>
                              {t(
                                "customer.application.preview.application.sign.place"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            flexDirection="column"
                            xs={1}
                          />
                          <Grid
                            item
                            display="flex"
                            flexDirection="column"
                            xs={4}
                          >
                            <TextField variant="standard" />
                            <Typography>{`${t(
                              "customer.application.preview.application.sign.signature"
                            )} ${item} - ${t(
                              "customer.application.preview.application.sign.person"
                            )}`}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Grid>

              {/*end of application*/}
              {!isCustomerVersion ? (
                <></>
              ) : (
                <>
                  <Grid container item className={[classes.card, classes.cardVariant].join(" ")} mt={4}>
                    <Grid item container sx={{ justifyContent: "space-between" }}>
                      <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={onStepBack}>
                        <EditIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                        {t("customer.application.preview.back")}
                      </Button>
                      <Button className={classes.button} onClick={printPdf}>
                        <DownloadIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                        {t("customer.application.preview.download")}
                      </Button>
                    </Grid>
                    <Grid container item xs={12} mt={2}>
                      <Typography color="white">
                        {t("customer.application.preview.download.msg") + ": "}
                        <Link
                          sx={{color: "rgba(91, 164, 58, 1.0)", fontWeight: "700", textDecoration: "none"}}
                          target="_blank"
                          href={`https://www.vermietsmart.de/application/preview/${customerState.id}`}
                        >{`https://www.vermietsmart.de/application/preview/${customerState.id}`}</Link>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} className={classes.card} mt={4}>
                    <Grid item container>
                      <Grid item className={classes.objectDescriptionTitle} sx={{ color: "#ffffff !important" }}>
                        {`${t("customer.application.preview.application.msg")} ${objectName ? objectName : customerState.customer["object"]["displayId"]}`}
                      </Grid>
                      <Grid item xs={12} className={classes.shareDescriptionTitle}>
                        <Typography color="white">
                          {t("customer.application.preview.application.share.msg")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} pt={1} pb={3}>
                      <CheckBoxGroup onChange={handleCheckboxChange} />
                        {(showError && dataPrivacyError && !notYetChecked) && (
                          <ErrorText>
                            {t("customer.application.error")}
                          </ErrorText>
                        )}
                    </Grid>
                    <Grid item container sx={{ justifyContent: "space-between" }} pt={2}>
                      <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={commingSoon}>
                        <DashboardIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                        {t("customer.application.preview.application.go.dashboard.button")}
                      </Button>
                      <Button className={classes.button} onClick={successMessage}>
                        {t("customer.application.preview.application.share.button")}
                        <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ApplicationPreview;
