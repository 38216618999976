import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: {
    display: "flex",
    flexDirection: "column"
  },
  columnContainer: {
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  bodyText: {
  },
}));
