import { Button, CardMedia, Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Cancel } from "@mui/icons-material";
import { CustomerContext } from "../../../context/CustomerContext";
import UploadIcon from '@mui/icons-material/Upload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const TenantImage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { customerState, updateCustomerState } = useContext(CustomerContext);
  const existingPhoto = customerState.customer?.photo;
  const [selectedImage, setSelectedImage] = useState<File | null>(
    existingPhoto ? existingPhoto : null
  );
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    if (existingPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(existingPhoto);
    }
  }, [existingPhoto]);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file);
        updateCustomerState({
          customer: { ...customerState.customer, photo: file },
        });
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    updateCustomerState({
      customer: { ...customerState.customer, photo: null },
    });
  };

  return (
    <Grid container>
      {previewImage && (
        <Grid item sx={{ position: "relative" }}>
          {selectedImage && (
            <IconButton
              className={classes.removeButton}
              onClick={handleRemoveImage}
            >
              <Cancel />
            </IconButton>
          )}
          <CardMedia
            component="img"
            alt="Preview"
            className={classes.previewImage}
            height="auto"
            image={previewImage}
          />
        </Grid>
      )}
      <Button
        variant="outlined"
        component="label"
        className={classes.fileButton}
        fullWidth
      >
        {previewImage && (
          <RestartAltIcon className={classes.icon} />
        )}
        {!previewImage && (
          <UploadIcon className={classes.icon} />
        )}
        {t("customer.application.detail.photo" + (previewImage ? ".again" : ""))}
        <input
          type="file"
          name="file"
          hidden
          accept="image/*"
          onChange={handleImageChange}
        />
      </Button>
    </Grid>
  );
};

export default TenantImage;
