import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainGrid: {
    display: "flex",
    flexDirection: "column",
  },

  accordionGrid: {
    marginLeft: "0",
    marginRight: "0",
  },
  accordion: {
    width: "100%",
    background: colors.white,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    borderRadius: colors.cardBorderRadius,
  },
  accordionHead: {
    fontWeight: colors.fontWeightBold,
  },
  accordionSummary: {
    //color: theme.palette.primary.contrastText,
  },
  expandMoreIcon: {
    //color: theme.palette.primary.contrastText,
  },
  select: {
    height: "56px",
    padding: "0px, 12px, 0px, 12px",
  },
  bodyText: { 
    //color: theme.palette.common.white
  },
  container: {
    position: "relative", // Set position to relative
  },
  info: {
    position: "absolute", // Set position to absolute
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  loaddingText: {
    color: colors.smartAgentGreen,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "16px",
  },
  errorBelow: {
    "& li": {
      fontWeight: colors.fontWeightRegular,
      marginLeft: "16px",
    },
    padding: "6px 0",
  }
}));
