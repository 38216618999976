import { CustomerState } from "../context/CustomerContext";
import { User } from "./User";

export interface Photo {
  title: string;
  image: any;
}

export type RentalStatus =
  | "initial_object"
  | "create_object"
  | "create_expose"
  | "publish_expose"
  | "review_and_invite_candidates"
  | "prepare_contract"
  | "decline_other_candidates"
  | "disabled";

export const activeStatuses: RentalStatus[] = [
  "publish_expose",
  "review_and_invite_candidates",
  "prepare_contract",
  "decline_other_candidates",
];

export interface RentalObject {
  id: string;
  displayId: string;
  name: string;
  owner: User | null;
  address: string;
  state: string;
  zip: number;
  placeId: string;
  latitude: number;
  longitude: number;
  estateType: string;
  estateTypeDetailName: string;
  area: number;
  plotArea: number;
  constructionYear: number;
  lastRefurbished: number;
  rooms: number;
  bedRooms: number;
  bathRooms: number;
  floor: number;
  numberOfFloors: number;
  kitchen: boolean;
  balconyTerrace: boolean;
  garden: boolean;
  guestWashRoom: boolean;
  garage: boolean;
  parking: boolean;
  basementLoft: boolean;
  canBeWg: boolean;
  lift: boolean;
  stepLessAccess: boolean;
  subsidized: boolean;
  energyUsage: number;
  preservation: boolean;
  commonCharge: number;
  firstTimeUse: boolean;
  parkingOutside: boolean;
  parkingInside: boolean;
  priceParking: number;
  furnished: boolean;
  barrierFree: boolean;
  temporary: boolean;
  additionalCosts: number;
  estimatedRent: number;
  suggestionRentals: string;
  rentControl: boolean;
  pictures: Photo[];
  energyCertificates: Photo[];
  floorPlan: Photo[];
  rent: number;
  sideCost: number;
  heatingCosts: number;
  totalRent: number;
  deposit: number;
  availableFrom: Date;
  availableTo: Date;
  quality: string;
  isPetAllowed: string;
  typeOfHeating: string;
  energyClass: string;
  essentialEnergySource: string;
  areaCharacteristics: string[];
  reachability: string[];
  rentalPropertyCharacters: string[];
  specialFeaturesAndServices: string[];
  floorCovering: string;
  conditionOfTheObject: string;
  hasSelfDisclosure: boolean;
  proofOfIdentity: boolean;
  proofOfCreditCheck: boolean;
  previousRentPaymentProof: boolean;
  incomeConfirmation: boolean;
  jobCenter: boolean;
  rentGuarantee: boolean;
  titleSuggestion: string;
  titleSuggestionAttempts: number;
  descriptionSuggestion: string;
  descriptionSuggestionAttempts: number;
  equipmentsSuggestion: string;
  equipmentsSuggestionAttempts: number;
  areaDescriptionSuggestion: string;
  areaDescriptionSuggestionAttempts: number;
  miscellaneous: string;
  applications: CustomerState["customer"][];
  status: RentalStatus;
}

export const defaultRentalObject: RentalObject = {
  id: "",
  displayId: "",
  name: "",
  owner: null,
  address: "",
  state: "",
  zip: 0,
  placeId: "",
  latitude: 0,
  longitude: 0,
  estateType: "",
  estateTypeDetailName: "",
  area: 0,
  plotArea: 0,
  constructionYear: 0,
  lastRefurbished: 0,
  rooms: 0,
  bedRooms: 0,
  bathRooms: 0,
  floor: 0,
  numberOfFloors: 0,
  kitchen: false,
  balconyTerrace: false,
  garden: false,
  guestWashRoom: false,
  garage: false,
  parking: false,
  basementLoft: false,
  canBeWg: false,
  lift: false,
  stepLessAccess: false,
  subsidized: false,
  energyUsage: 0,
  preservation: false,
  commonCharge: 0,
  firstTimeUse: false,
  parkingOutside: false,
  parkingInside: false,
  priceParking: 0,
  furnished: false,
  barrierFree: false,
  temporary: false,
  additionalCosts: 0,
  estimatedRent: 0,
  suggestionRentals: "",
  rentControl: false,
  pictures: [],
  energyCertificates: [],
  floorPlan: [],
  rent: 0,
  sideCost: 0,
  heatingCosts: 0,
  totalRent: 0,
  deposit: 0,
  availableFrom: new Date(),
  availableTo: new Date(),
  quality: "",
  isPetAllowed: "",
  typeOfHeating: "",
  energyClass: "",
  essentialEnergySource: "",
  areaCharacteristics: [],
  reachability: [],
  rentalPropertyCharacters: [],
  specialFeaturesAndServices: [],
  floorCovering: "",
  conditionOfTheObject: "",
  hasSelfDisclosure: false,
  proofOfIdentity: false,
  proofOfCreditCheck: false,
  previousRentPaymentProof: false,
  incomeConfirmation: false,
  jobCenter: false,
  rentGuarantee: false,
  titleSuggestion: "",
  titleSuggestionAttempts: 0,
  descriptionSuggestion: "",
  descriptionSuggestionAttempts: 0,
  equipmentsSuggestion: "",
  equipmentsSuggestionAttempts: 0,
  areaDescriptionSuggestion: "",
  areaDescriptionSuggestionAttempts: 0,
  miscellaneous: "",
  applications: [],
  status: "initial_object",
};
