import { useContext, useEffect, useMemo, useState } from "react";
import { SideBarContext } from "../../context/SideBarContext";
import {
  Card,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import { useStyles } from "./styles";
import { styled } from "@mui/material/styles";
import {
  SideBar,
} from "../../components/CustomerDashboard/Components";
import { ObjectCard } from "../../components/CustomerDashboard/ObjectCard/ObjectCard";
import { ObjectStatus } from "../../components/CustomerDashboard/ObjectStatus/ObjectStatus";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateNotification,
  DateNotificationProps,
} from "../../components/CustomerDashboard/DateNotification/DateNotification";
import CustomerData from "../../components/CustomerDashboard/CustomerData/CustomerData";
import { getAllApplications } from "../../services/rentalHandler";
import Alert from "react-s-alert";
import { useTranslation } from "react-i18next";
import { CustomerDashboardContext } from "../../context/CustomerDashboardContext";
import { defaultRentalObject, RentalObject } from "../../types/RentalObject";
import { useNavigate, useParams } from "react-router-dom";
import { DASHBOARD } from "../../routes/ROUTES";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { t } from "i18next";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";

export const upcomingEvents: DateNotificationProps[] = [
  // long way to get two dates that are always respectivley one and two months in the future
  {
    date: "11",
    month: t("months.short." + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString()),
    time: "10:00 - 11:00",
    name: "Max Mustermann",
    ymd: (new Date(Date.now() + 30 * 24 * 3600 * 1000).getFullYear()).toString() + "-" + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString().padStart(2, "0") + "-11",
  },
  {
    date: "23",
    month: t("months.short." + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString()),
    time: "14:00 - 15:00",
    name: "John Mann",
    ymd: (new Date(Date.now() + 30 * 24 * 3600 * 1000).getFullYear()).toString() + "-" + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString().padStart(2, "0") + "-23",
  },
  {
    date: "2",
    month: t("months.short." + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString()),
    time: "12:00 - 13:00",
    name: "Maximilian Muster",
    ymd: (new Date(Date.now() + 30 * 24 * 3600 * 1000).getFullYear()).toString() + "-" + (new Date(Date.now() + 30 * 24 * 3600 * 1000).getMonth() + 1).toString().padStart(2, "0") + "-02",
  },
];

const CustomerDashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setVisibility, setContent } = useContext(SideBarContext);
  const [rentalObject, setRentalObject] =
    useState<RentalObject>(defaultRentalObject);
  const [loading, setLoading] = useState<boolean>(true);
  const { objectId } = useParams();
  const navigate = useNavigate();

  const contextValues = useMemo(() => {
    return { rentalObject, setRentalObject };
  }, [rentalObject, setRentalObject]);

  useEffect(() => {
    if (objectId) {
      getAllApplications(objectId)
        .then((res) => {
          setRentalObject(res);
          setLoading(false);
        })
        .catch((err) => {
          Alert.error((err && err.message) || t("error"));
          setLoading(false);
        });
    }
  }, [objectId, t]);

  useEffect(() => {
    setContent(<SideBar />);
    setVisibility(true);
  }, [setVisibility, setContent]);

  const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "rgba(91, 164, 58, 1.0)",
      color: "#ffffff",
      fontWeight: "700",
    },
  }));

  const highlightDay = (props: any) => {
    const { upcomingEvents = [], day, outsideCurrentMonth, ...other } = props;

    const dates = upcomingEvents.map(({ ymd }: any) => ymd);
    const isSelected = !props.outsideCurrentMonth && dates.includes(day.format("YYYY-MM-DD"));
  
    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };

  return (
    <>
      {loading ? (
        <Grid container className={classes.container} sx={{alignContent: "center"}}>
          <Grid item xs={12} sx={{textAlign: "center"}}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <CustomerDashboardContext.Provider value={contextValues}>
          <Grid container className={classes.container} spacing={3}>
            <Grid item xs={12}>
              <Button className={[classes.button, classes.buttonVariantDark].join(" ")} onClick={() => navigate(DASHBOARD)}>
                <DashboardIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                 {t("customer.dashboard.back.to.dashboard")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                spacing={3}
                sx={{ paddingRight: 4 }}
              >
                <Grid item xs={9}>
                  <ObjectCard />
                </Grid>
                <Grid item xs={3}>
                  <ObjectStatus />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  spacing={3}
                  sx={{ paddingRight: 4, marginBottom: "24px" }}
                >
                  <Grid item xs={9}>
                    <CustomerData />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      display="flex"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Card className={classes.card}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={`${t("calendar.locale")}`}
                          >
                            <DateCalendar
                              slots={{
                                day: highlightDay,
                              }}
                              slotProps={{
                                day: {
                                  upcomingEvents,
                                } as any,
                              }}
                            />
                          </LocalizationProvider>
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card className={classes.card} sx={{padding: "8px 0"}}>
                          {upcomingEvents.map(({ date, month, name, time, ymd }, index) => (
                            <DateNotification
                              date={date}
                              month={month}
                              name={name}
                              time={time}
                              key={index}
                              ymd={ymd}
                            />
                          ))}
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomerDashboardContext.Provider>
      )}
    </>
  );
};

export default CustomerDashboard;
