import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import CustomerRenderer from "../../components/Customer/CustomerRenderer/CustomerRenderer";
import { useContext, useEffect } from "react";
import { SideBarContext } from "../../context/SideBarContext";

const CustomerApplication = () => {
  const classes = useStyles();

  const { setVisibility } = useContext(SideBarContext);
  useEffect(() => {
    setVisibility(false);
  }, [setVisibility]);

  return (
    <Grid item container className={classes.mainFrame}>
      <Grid item container className={classes.container}>
        <Grid item xs={12} lg={8} className={classes.content}>
          <CustomerRenderer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerApplication;
