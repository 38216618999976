import { Alert } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  children?: React.ReactNode;
}

const ErrorText = (props: Props) => {
  const classes = useStyles();
  return (
    <Alert
      severity="error"
      variant="standard"
      className={classes.errorText}
      icon={false}
    >
      {props.children}
    </Alert>
  );
};

export default ErrorText;
