import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import SignUp from "../components/Auth/SignUp/SignUp";
import HomePage from "../pages/Home/HomePage";
import NoMatch from "../pages/NoMatch";
import NotImplemented from "../pages/NotImplemented/notImplemented";
import Cookies from "../pages/InformationPages/Cookies";
import DataPolicy from "../pages/InformationPages/DataPolicy";
import Disclaimer from "../pages/InformationPages/Disclaimer";
import Imprint from "../pages/InformationPages/Imprint";

import {
  CUSTOMER_APPLICATION,
  CUSTOMER_APPLICATION_PREVIEW,
  DASHBOARD,
  ESTATE,
  LOGIN,
  OAUTH2_REDIRECT,
  OBJECT_DASHBOARD,
  OBJECTS_PAGE,
  PROFILE,
  ROOT,
  SIGN_UP,
  CALENDER,
  DOCUMENTS,
  NEWS,
  SPECIAL_OFFERS,
  CONTACT,
  CONTRACT_DETAILS,
  DATA_POLICY,
  IMPRINT,
  COOKIES,
  DISCLAIMER,
} from "./ROUTES";
import Login from "../components/Auth/Login/Login";
import OAuth2RedirectHandler from "./Oauth2RedirectHandler";
import { ProtectedRoute } from "./ProtectedRoute";
import Dashboard from "../pages/DashBoard/DashBoard";
import Estate from "../pages/Estate/Estate";
import CustomerApplication from "../pages/Customer/CustomerApplication";
import { AuthContext } from "../context/AuthContext";
import ApplicationPreview from "../components/Customer/ApplicationPreview/ApplicationPreview";
import Layout from "../pages/Layout/Layout";
import CustomerDashboard from "../pages/CustomerDashboard/CustomerDashboard";
import ObjectsDashboard from "../pages/ObjectsDashboard/ObjectsDashboard";
import ContactPage from "../pages/ContactPage/ContactPage";

const AppRoutes = () => {
  const {
    authStatus: { authenticated },
  } = useContext(AuthContext);
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={ROOT} element={<HomePage />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<SignUp />} />
        <Route path="*" element={<NoMatch />} />
        <Route path={OAUTH2_REDIRECT} element={<OAuth2RedirectHandler />} />

        <Route
          path={DASHBOARD}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={PROFILE}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ESTATE}/:id?`}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <Estate />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${CUSTOMER_APPLICATION}/:displayId?`}
          element={<CustomerApplication />}
        />
        <Route
          path={`${CUSTOMER_APPLICATION_PREVIEW}/:customerId?`}
          element={<ApplicationPreview />}
        />
        <Route
          path={`${OBJECT_DASHBOARD}/:objectId`}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <CustomerDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${OBJECTS_PAGE}`}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <ObjectsDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={CALENDER}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route
          path={DOCUMENTS}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route
          path={NEWS}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route
          path={SPECIAL_OFFERS}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route path={CONTACT} element={<ContactPage />} />
        <Route
          path={CONTRACT_DETAILS}
          element={
            <ProtectedRoute authenticated={authenticated}>
              <NotImplemented />
            </ProtectedRoute>
          }
        />
        <Route path={DATA_POLICY} element={<DataPolicy />} />
        <Route path={IMPRINT} element={<Imprint />} />
        <Route path={COOKIES} element={<Cookies />} />
        <Route path={DISCLAIMER} element={<Disclaimer />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
