import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    //borderRadius: 0,
    boxShadow: colors.cardShadow,
  },

  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  columnContainerLeft: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E7E7E7",
  },

  columnContainerRight: {
    display: "flex",
    flexDirection: "column",
  },

  starColor: {
    color: colors.smartAgentGreen,
    "& .MuiRating-iconEmpty": {
      color: "#E7E7E7",
    },
    marginTop: "4px",
  },
  textField: {
    //background: "#FFFFFF",
    [`& fieldset`]: {
      //borderRadius: 0,
    },
  },
  select: {
    //height: "36px",
    "& fieldset": {
      //borderRadius: 0,
      //border: `1px solid ${theme.palette.secondary.main}`,
    },
    //color: theme.palette.secondary.main,
    "& .MuiSelect-icon": {
      //color: theme.palette.secondary.main,
    },
  },
  paper: {
    color: "#9AF430",
    border: `1px solid #9AF430`,
    //borderRadius: 0,
  },
  icon: { display: "flex", alignItems: "flex-end" },
  media: {
    width: 161,
    height: 262,
  },
  leftContainer: {
    backgroundColor: "#E7E7E7",
    flex: 1,
  },
  rightContainer: {
    backgroundColor: theme.palette.primary.dark,
    flex: 1,
  },

  tenantInfoContainer: {
    height: 262,
    paddingTop: 11,
    paddingLeft: 16,
    paddingBottom: 16,
  },
  buttonContainer: {
    marginTop: 8,
  },
  objectOwnerContainer: {
    //height: 262,
    //paddingTop: 11,
    //paddingLeft: 16,
    //paddingBottom: 16,
    //paddingRight: 16,
    justifyContent: "space-between",
  },
  textColorWhite: {
    //color: "white",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    paddingRight: "12px",
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
  buttonVariantDark: {
    backgroundColor: colors.smartAgentDarkBlue,
    color: colors.lightTextPrimary,
  },
  buttonShowApplicant: {
    marginTop: "8px",
    fontWeight: colors.fontWeightRegular,
    fontSize: colors.fontSizeSmall,
    borderRadius: "999px",
    paddingLeft: "10px",
    boxShadow: "none",
    width: "128px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
  },
  subTitle: {
    paddingTop: "3px",
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextSecondary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
  },
  detailInfos: {
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextSecondary,
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightRegular,
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "4px",
    },
    margin: "3px 0",
  },
  statusChip: {
    fontSize: colors.fontSizeSmall,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
  },
  actionStuff: {
    fontSize: colors.fontSizeIntermediate,
    "& .MuiInputLabel-root": {
      fontSize: colors.fontSizeIntermediate,
    },
  },
}));
