import {
  Typography,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Drawer,
  FormControl,
  FormControlLabel,
  TablePagination,
  Button,
  RadioGroup,
  Radio,
  Rating,
  IconButton,
  TextField,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import Alert from "react-s-alert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { getIncomeCategories } from "../../../services/entitiyHandler";
import { Entities } from "../../Expose/Form/types";
import { t } from "i18next";
import { useStyles } from "./styles";
import React, { useState, useEffect } from "react";
import CustomerCard from "../CustomerCard/CustomerCard";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { CustomerDashboardContext } from "../../../context/CustomerDashboardContext";
import { CustomerState } from "../../../context/CustomerContext";
import { updateCustomer } from "../../../services/aplicationHandler";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

interface RatingLabelProps {
  rating: number;
}

const RatingLabel = ({ rating }: RatingLabelProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid>
        <Rating className={classes.starColor} value={rating} readOnly />
      </Grid>

      <Grid>
        <Typography ml="4px"> {t("customer.dashboard.more")}</Typography>
      </Grid>
    </Grid>
  );
};

const CustomerData = () => {
  const classes = useStyles();
  const { rentalObject, setRentalObject } = useContext(
    CustomerDashboardContext
  );
  const customers = rentalObject.applications;

  const [manipulatedCustomers, setManipulatedCustomers] = useState<
    CustomerState["customer"][]
  >(() => {
    return customers.filter((customer) => customer.id !== "");
  });

  const [incomeCategories, setIncomeCategories] = useState<Entities[]>([]);

  const [page, setPage] = useState(0);
  const startIndex = page * 5;
  const endIndex = startIndex + 5;
  const paginatedCustomers = manipulatedCustomers.slice(startIndex, endIndex);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [sort, setSort] = React.useState<string | null>(null);
  const [ratingFilter, setRatingFilter] = React.useState<string | null>(null);
  const [incomeFilter, setIncomeFilter] = React.useState<string | null>(null);
  const [adultCountFilter, setAdultCountFilter] = useState(2);
  const [smokerFilter, setSmokerFilter] = React.useState<string | null>(null);
  const [kidsFilter, setKidsFilter] = React.useState<string | null>(null);

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getIncomeCategories().then((response) => {
      setIncomeCategories(response);
    });
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const paginationLabel = ({ from, to, count }:any) => {
    if (count === 0) {
      return `${t("customer.dashboard.pagination.no")} ${t("customer.dashboard.pagination.applicants")}`;
    }
    if (count === 1) {
      return `${count} ${t("customer.dashboard.pagination.applicant")}`;
    }
    // update here for pagination changes
    if (count <= 5) {
      return `${count} ${t("customer.dashboard.pagination.applicants")}`;
    }
    return `${t("customer.dashboard.pagination.applicants")} ${from} - ${to} ${t("customer.dashboard.pagination.of")} ${count !== -1 ? count : `${t("customer.dashboard.pagination.more")} ${to}`}`;
  };

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;

    if (target.value === sort) {
      setSort(null);
    } else {
      setSort(target.value);
    }
  };

  const handleRatingFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === ratingFilter) {
      setRatingFilter(null);
    } else {
      setRatingFilter(target.value);
    }
  };

  const handleIncomeFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === incomeFilter) {
      setIncomeFilter(null);
    } else {
      setIncomeFilter(target.value);
    }
  };

  const handleAdultCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const cleanValue = value.replace(/\D/g, "");
    const parsedValue = parseInt(cleanValue, 10);
    if (!isNaN(parsedValue)) {
      setAdultCountFilter(parsedValue);
    } else {
      setAdultCountFilter(0);
    }
  };

  const handleSmokerFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;

    if (target.value === smokerFilter) {
      setSmokerFilter(null);
    } else {
      setSmokerFilter(target.value);
    }
  };

  const handleKidsFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === kidsFilter) {
      setKidsFilter(null);
    } else {
      setKidsFilter(target.value);
    }
  };

  const applyFilters = () => {
    Alert.info(`${t("error.notYetAvailable")}`);
    /*
    let sortedCustomers: CustomerState["customer"][] = [...customers];

    if (sort) {
      if (sort === "newerFirst") {
        sortedCustomers = [...sortedCustomers].reverse();
      } else if (sort === "olderFirst") {
        sortedCustomers = [...sortedCustomers];
      } else if (sort === "bestRatingFirst") {
        sortedCustomers.sort((a, b) => {
          const ratingA = parseFloat(a.rating);
          const ratingB = parseFloat(b.rating);
          return ratingB - ratingA;
        });
      }
    }

    if (ratingFilter) {
      const minRating = parseFloat(ratingFilter);
      sortedCustomers = sortedCustomers.filter(
        (customer) => parseFloat(customer.rating) >= minRating
      );
    }

    if (incomeFilter) {
      sortedCustomers = sortedCustomers.filter(
        (customer) => customer.netHouseHoldIncome >= incomeFilter
      );
    }

    if (adultCountFilter) {
      sortedCustomers = sortedCustomers.filter(
        (customer) => parseFloat(customer.adultCount) <= adultCountFilter
      );
    }

    if (smokerFilter) {
      if (smokerFilter === "non-smoker") {
        sortedCustomers = sortedCustomers.filter(
          (customer) => customer.smoker === "64788f0db6e4e9a7a99b2574" //id for smoker
        );
      }
    }

    if (kidsFilter) {
      if (kidsFilter === "no-kids") {
        sortedCustomers = sortedCustomers.filter(
          (customer) => parseFloat(customer.childCount) === 0
        );
      }
    }

    setManipulatedCustomers(sortedCustomers);*/
  };

  const revertFilters = () => {
    setSort(null);
    setRatingFilter(null);
    setIncomeFilter(null);
    setAdultCountFilter(2);
    setSmokerFilter(null);
    setKidsFilter(null);

    setManipulatedCustomers(customers);
  };

  const handleManipulateRating = (customerId: string, newValue: string) => {
    const updatedCustomers = rentalObject.applications.map((customer) => {
      if (customer.id === customerId) {
        return {
          ...customer,
          rating: newValue,
        };
      }
      return customer;
    });

    setRentalObject({
      ...rentalObject,
      applications: updatedCustomers,
    });
  };

  const handleManipulateNotes = (customerId: string, newValue: string) => {
    const updatedCustomers = rentalObject.applications.map((customer) => {
      if (customer.id === customerId) {
        return {
          ...customer,
          notes: newValue,
        };
      }
      return customer;
    });

    setRentalObject({
      ...rentalObject,
      applications: updatedCustomers,
    });
  };

  const handleManipulateCheckbox = (customerId: string, newValue: boolean) => {
    const updatedCustomers = rentalObject.applications.map((customer) => {
      if (customer.id === customerId) {
        return {
          ...customer,
          hasChecked: newValue,
        };
      }
      return customer;
    });

    setRentalObject({
      ...rentalObject,
      applications: updatedCustomers,
    });
  };

  const handleUpdateCustomers = async () => {
    try {
      setLoading(true);
      for (const customer of rentalObject.applications) {
        const request = Object.assign({}, customer);
        //request.photo = null;
        //console.log(request);

        await updateCustomer(request);
        setLoading(false);
      }
    } catch (err) {
      Alert.error("Error updating customers:");
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Card className={classes.card}>
          <CardHeader
            title={
              <Typography variant="h5">
                {t("customer.dashboard.applicants")}:
              </Typography>
            }
          />

          <Grid container spacing={2} direction="column">
            <>
              <Grid item>
                <Drawer
                  open={drawerOpen}
                  onClose={handleToggleDrawer}
                  className={classes.drawer}
                  //variant="persistent"
                  anchor="left"
                >
                  <DrawerHeader>
                    <IconButton onClick={handleToggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </DrawerHeader>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    className={classes.drawerContent}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{ marginBottom: "15px" }}
                    >
                      <Grid item>
                        <Typography variant="subTitle">
                          {t("customer.dashboard.sort.by")}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="sort-radio-button"
                            name="sorting"
                            value={sort}
                          >
                            <FormControlLabel
                              value="newerFirst"
                              control={<Radio onClick={handleSort} />}
                              label={t("customer.dashboard.newest.first")}
                              style={{ marginBottom: "-15px" }}
                            />
                            <FormControlLabel
                              value="olderFirst"
                              control={<Radio onClick={handleSort} />}
                              label={t("customer.dashboard.oldest.first")}
                              style={{ marginBottom: "-15px" }}
                            />
                            <FormControlLabel
                              value="bestRatingFirst"
                              control={<Radio onClick={handleSort} />}
                              label={t("customer.dashboard.best.rating.first")}
                              style={{ marginBottom: "-15px" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="column"
                      sx={{ marginBottom: "15px" }}
                    >
                      <Grid item>
                        <Grid item>
                          <Typography variant="subTitle">
                            {t("customer.dashboard.filter") + ":"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="smoker-radio-button"
                            name="smoker"
                            value={smokerFilter}
                          >
                            <FormControlLabel
                              value="non-smoker"
                              control={<Checkbox onClick={handleSmokerFilter} />}
                              label={t("customer.dashboard.non.smoker")}
                              style={{ marginBottom: "-15px" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="have-kids-radio-button"
                            name="kids"
                            value={kidsFilter}
                          >
                            <FormControlLabel
                              value="no-kids"
                              control={<Checkbox onClick={handleKidsFilter} />}
                              label={t("customer.dashboard.no.children")}
                              style={{ marginBottom: "-15px" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="column"
                      sx={{ marginBottom: "15px" }}
                    >
                      <Grid item>
                        <Typography variant="subTitle">
                          {t("customer.dashboard.number.of.adults")}
                        </Typography>
                      </Grid>
                      <Grid item container alignItems="center" spacing={2} pt={1}>
                        <Grid item>
                          <Typography>
                            1 &nbsp; {t("customer.dashboard.up.to")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes.countTextField}
                            type="text"
                            value={adultCountFilter}
                            onChange={handleAdultCountChange}
                            inputProps={{ pattern: "[0-9]*" }}
                          />
                        </Grid>
                        {/*
                        <Grid item>
                          <Button
                            onClick={handleAdultCountFilterInccrease}
                            variant="contained"
                            endIcon={<ChevronRighIcon />}
                          />
                        </Grid>
                        */}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{ marginBottom: "15px" }}
                    >
                      <Grid item>
                        <Typography variant="subTitle">
                          {t("customer.dashboard.rating")}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="rating-radio-button"
                            name="rating"
                            value={ratingFilter}
                          >
                            <FormControlLabel
                              value="4"
                              control={<Radio onClick={handleRatingFilter} />}
                              label={<RatingLabel rating={4} />}
                              style={{ marginBottom: "-15px" }}
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio onClick={handleRatingFilter} />}
                              label={<RatingLabel rating={3} />}
                              style={{ marginBottom: "-15px" }}
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio onClick={handleRatingFilter} />}
                              label={<RatingLabel rating={2} />}
                              style={{ marginBottom: "-15px" }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio onClick={handleRatingFilter} />}
                              label={<RatingLabel rating={1} />}
                              style={{ marginBottom: "-15px" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="column"
                      sx={{ marginBottom: "15px" }}
                    >
                      <Grid item>
                        <Typography variant="subTitle">
                          {t("customer.dashboard.net.household.income")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="income-radio-button"
                            name="income"
                            value={incomeFilter}
                          >
                            {incomeCategories.map((category) => (
                              <FormControlLabel
                                key={category.id}
                                value={category.id}
                                control={<Radio onClick={handleIncomeFilter} />}
                                label={
                                  <label>
                                    {category.value_1}{" "}
                                    {t("customer.dashboard.more")}
                                  </label>
                                }
                                style={{ marginBottom: "-15px" }}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item sx={{ marginRight: 4 }} mt={1}>
                      <Button fullWidth className={[classes.button].join(" ")} onClick={applyFilters}>
                        <FilterAltIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                        {t("customer.dashboard.show.selection")}
                      </Button>
                    </Grid>
                    <Grid item sx={{ marginRight: 4 }} mt={1}>
                      <Button fullWidth className={[classes.button, classes.buttonVariant].join(" ")} onClick={revertFilters}>
                        <FilterAltOffIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                        {t("customer.dashboard.reset.filter")}
                      </Button>
                    </Grid>
                  </Grid>
                </Drawer>
              </Grid>
              <Grid item container direction="row">
                <Grid item container xs={4}>
                  <Typography sx={{ fontWeight: "700", display: "inline-block", fontSize: "20px", paddingLeft: "16px", paddingTop: "5px" }}>
                    {manipulatedCustomers.length}{" "}
                    {manipulatedCustomers.length === 1 ? t("customer.dashboard.applicant") : t("customer.dashboard.applicants")}
                  </Typography>
                  <Button className={[classes.button, classes.buttonVariant, classes.buttonFilter].join(" ")} onClick={handleToggleDrawer}>
                    <FilterAltIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                    {t("customer.dashboard.filter")}
                  </Button>
                </Grid>
                <Grid item container xs={8} sx={{justifyContent: "flex-end", padding: "0 16px"}}>
                  {isLoading ? (
                    <CircularProgress />
                    ) : (
                      <InfoAlert sx={{
                        backgroundColor: "unset !important",
                        "& .MuiAlert-message": {paddingBottom: "0px !important"},
                        height: "fit-content",
                      }}>{t("customer.dashboard.save.your.selections")}</InfoAlert>
                    )}
                  <Button className={[classes.button].join(" ")} onClick={handleUpdateCustomers} sx={{ marginLeft: "16px" }}>
                    <SaveIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                    {t("customer.dashboard.save")}
                  </Button>
                </Grid>
              </Grid>
            </>
            <Grid item>
              <TableContainer className={classes.table}>
                <Table>
                  <TableBody>
                    {paginatedCustomers.map((customer, index) => (
                      <CustomerCard
                        key={index}
                        id={customer.id}
                        photo={customer.photo}
                        firstName={customer.firstName}
                        lastName={customer.lastName}
                        adultCount={customer.adultCount}
                        childCount={customer.childCount}
                        smoker={customer.smoker}
                        netHouseHoldIncome={customer.netHouseHoldIncome}
                        phoneNumber={customer.phoneNumber}
                        email={customer.email}
                        rating={customer.rating}
                        notes={customer.notes}
                        hasChecked={customer.hasChecked}
                        selection={""}
                        manipulateRating={(newValue) =>
                          handleManipulateRating(customer.id, newValue)
                        }
                        manipulateNotes={(newValue) =>
                          handleManipulateNotes(customer.id, newValue)
                        }
                        manipulateCheckbox={(newValue) =>
                          handleManipulateCheckbox(customer.id, newValue)
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={customers.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={5} // also change paginationLabel() when changing this value
                rowsPerPageOptions={[5]}
                onRowsPerPageChange={() => {}}
                labelDisplayedRows={paginationLabel}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "rgba(16, 21, 41, 0.6)",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default CustomerData;
