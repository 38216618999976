import { makeStyles } from "@mui/styles";

export const FooterHeight = "100px";
export const useStyles = makeStyles(() => ({
  mainHomeContainer: {
    minHeight: "100%",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E7E7E7B2",
  },
  outerContainer: { display: "flex", flexDirection: "column", width: "100%" },
  container: {
    paddingLeft: 92,
    paddingTop: 67,
  },
  card: {
    background: "transparent",
    boxShadow: "none",
  },
  cardMedia: {
    height: "100%",
  },
}));
