import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  mainFrame: {
    backgroundColor: colors.lightGrayBackground,
    minHeight: "100%",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
  },
  component: {
    width: "100%",
  },
}));
