import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    //borderRadius: 0,
    boxShadow: colors.cardShadow,
  },
  table: {
    //borderRadius: 0,
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  tableRow: {
    marginBottom: theme.spacing(2),
  },
  tableLegend: {
    marginLeft: theme.spacing(2),
  },
  primaryButton: {
    borderRadius: 0,
    backgroundColor: "#9AF430",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",

    color: "#101529",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      paddingTop: "86px",
      paddingRight: "16px",
    },
    //width: 260,
    /*flexShrink: 0,
    "& .MuiBackdrop-root": {
      display: "none",
    },

    "& .MuiDrawer-paper": {
      width: 260,
      height: 1009,
      position: "absolute",
      boxShadow:
        "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
    },*/
  },
  drawerContent: {
    marginLeft: 16,
  },
  starColor: {
    color: colors.smartAgentGreen,
  },
  selectButton: {
    height: "36px",
    color: "white",
    background: theme.palette.primary.main,
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    padding: "8px 22px",
    borderRadius: 0,
  },
  clearButton: {
    height: "36px",

    backgroundColor: "#E0E0E0",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    padding: "8px 22px",
    borderRadius: 0,
  },
  countTextField: {
    width: "64px",
    //marginTop: 15,
    "& .MuiInputBase-input": {
      padding: "8px 14px",
    },
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    paddingRight: "12px",
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
  buttonVariantDark: {
    backgroundColor: colors.smartAgentDarkBlue,
    color: colors.lightTextPrimary,
  },
  buttonFilter: {
    display: "inline-flex",
    //paddingTop: "2px",
    //paddingBottom: "2px",
    marginLeft: "32px",
    //marginTop: "-8px",
    height: "fit-content",
  },
}));
