import React from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";

const Cookies = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item className={classes.main}>
        <Typography variant="h4">Cookie-Hinweise</Typography>
        <br />
        <Typography variant="body1">
          <strong>Cookie Hinweise</strong> der Smart Agent GmbH (nachfolgend{" "}
          <strong>Cookie Hinweise</strong>, <strong>„uns“</strong>
          oder <strong>„wir“</strong>) für die Internetseiten
          www.vermietsmart.de sowie alle ihrer Unterseiten (nachfolgend zusammen
          die <strong>„Webseite“</strong>) und www-smart-agent.eu sowie alle
          ihrer Unterseiten (nachfolgend zusammen
          <strong>„Webseite“</strong> ) gültig ab dem 01. Juni 2023.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>Das Wichtigste zunächst vorab: </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>
            Sie können Ihre Einwilligung und Ihre Einstellungen, die Sie beim
            Besuch der Webseite nebst aller ihrer Unterseiten über die von uns
            eingesetzte Consent Management Plattform jederzeit mit Wirkung für
            die Zukunft ganz oder teilweise widerrufen.
          </strong>
        </Typography>
        <br />
        <Typography variant="h6">
          I. Welche Daten verarbeitet Smart Agent bei Nutzung der Webseite?
        </Typography>
        <br />
        <Typography variant="body1">
          Grundsätzlich können Sie die Internetseiten www.vermietsmart.de und
          www.smart-agent.eu nebst aller ihrer Unterseiten (nachfolgend zusammen
          die <strong>„Webseite“</strong>) nutzen, ohne sich zu registrieren
          oder aktiv personenbezogene Daten mitzuteilen. Entnehmen Sie bitte
          weitere Informationen unseren Datenschutzhinweisen, die Sie auf
          unserer Webseite finden, oder den Datenschutzhinweisen der
          Unternehmensseiten unserer jeweiligen Lizenzpartner.{" "}
        </Typography>
        <br />
        <Typography variant="body1">
          Im Fall des Besuchs der Webseite erheben wir oder unsere Lizenzpartner
          in begrenztem Umfang Daten über Ihr verwendetes Endgerät und Ihr
          Nutzungsverhalten auf unseren Angeboten. Diese Daten können von uns
          oder unseren Lizenzpartnern keiner individuellen Person zugeordnet
          werden. Hierzu zählen beim Aufruf einer Website etwa die folgenden
          Daten:{" "}
        </Typography>
        <Typography component="li">
          personenbezogene Daten, die vom Internet-Browser der Nutzer jedes Mal
          übertragen werden, wenn sie auf die Webseite Zugriff nehmen und im
          Rahmen von Protokolldateien, die auch als Server-Protokolldateien
          bekannt sind, gespeichert werden. Diese Daten umfassen: IP-Adresse
          (Internetprotokolladresse) des zugreifenden Rechners; Name der
          zugegriffenen Seite; Datum und Uhrzeit des Zugangs; URL, von der der
          Nutzer auf die Seite zugreift; Anzahl der übertragenen Daten;
          Statusmeldung für erfolgreiches Zugreifen; Sitzungs-ID-Nummer;{" "}
        </Typography>
        <Typography component="li">
          personenbezogene Daten in der Form von Standortdaten (GPS-Daten, sog.
          „Locate Me“ Funktion), die von Ihrem Internet-Browser jedes Mal dann
          übertragen werden, wenn Sie diese Einstellung browserseitig
          grundsätzlich oder anwendungsbasiert aktiviert haben.{" "}
        </Typography>
        <br />
        <Typography variant="h6">
          II. Verwendung von Cookies und anderen Trackingtechnologien
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>1. Was sind Cookies?</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Wie alle anderen kommerziellen Internetseiten verwendet die Webseite
          auch sog. Cookies und andere Trackingtechnologien (nachfolgend
          zusammen als <strong>„Cookies“</strong> bezeichnet). Cookies sind
          kleine Textdateien, die lokal im Arbeitsspeicher oder auf der
          Festplatte ihres Computers oder Mobilgerätes (nachfolgend „Endgerät“)
          gespeichert werden und die ganz unterschiedliche Funktionen erfüllen
          und zu verschiedenen Zwecken Daten verarbeiten können. Wird die
          entsprechende Internetseite erneut aufgerufen, sendet der Browser des
          Nutzers den Inhalt der Cookies zurück und ermöglicht so eine
          Wiedererkennung des Nutzers. Auch Technologien von Drittparteien wie
          Skripte, Pixel und Tags, die Smart Agent zu Werbezwecken einbindet,
          setzen Cookies auf Ihrem Endgerät. Bestimmte Cookies werden nach
          Beendigung der Browser-Sitzung automatisch gelöscht (sogenannte
          Session Cookies), andere werden für eine vorgegebene Zeit bzw.
          dauerhaft im Browser des Nutzers gespeichert und löschen sich danach
          selbständig (sogenannte temporäre oder permanente Cookies). Viele
          Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
          eindeutige Kennung des Cookies, durch welche Internetseiten und Server
          dem konkreten Internetbrowser zugeordnet werden können, in dem das
          Cookie gespeichert wurde. Dies ermöglicht es den besuchten
          Internetseiten, den Browser des Nutzers von anderen Internetbrowsern
          zu unterscheiden, als Person können Cookies Sie grundsätzlich nicht
          identifizieren. Allerdings ermöglicht das Cookie die Identifizierung
          Ihres Endgeräts.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>a. Arten von Cookies </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Es gibt verschiedene Arten von Cookies, die wir einsetzen:
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>(i) Sog. technisch erforderliche Cookies </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Zunächst verwenden wir technisch erforderliche Cookies zum Betrieb der
          Webseite. Technisch erforderliche Cookies sind für die Funktionalität
          der Webseite unerlässlich, ohne sie wäre die Webseite nicht oder nur
          eingeschränkt nutzbar. Die durch technisch erforderliche Cookies
          erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen
          verwendet. Hierzu gehören auch temporäre Cookies (Session-Cookies).
          Sie sammeln grundsätzlich keine Daten von Ihrem Rechner und werden
          automatisch gelöscht, sobald Sie Ihren Browser schließen.
        </Typography>
        <br />
        <Typography variant="body1">
          Auch für den Einsatz unserer Consent Management Plattform zur
          Einholung Ihrer Einwilligung in das Setzen von Cookies und die Nutzung
          anderer Trackingtechnologien werden technisch erforderliche Cookies
          verwendet.
        </Typography>
        <br />
        <Typography variant="body1">
          Technisch erforderliche Cookies sind „unbedingt erforderliche“ Cookies
          i.S.d. ePrivacy Richtlinie 2002/58 EG und benötigen keine
          Einwilligung. Für den Anwendungsbereich der Bundesrepublik Deutschland
          ist Rechtsgrundlage für deren Einsatz § 25 II TTDSG. Werden in diesem
          Zusammenhang personenbezogene Daten verarbeitet, ist Rechtsgrundlage
          Art. 6 Abs. 1 S. 1 lit. f DSGVO (berechtigtes Interesse).
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>(ii) Sog. Nicht technisch erforderliche Cookies </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Daneben gibt es nicht technisch erforderliche Cookies. Solche Cookies
          dienen ganz unterschiedlichen Zwecken wie der Optimierung der
          Webseite, Erstellung von Nutzerprofilen sowie zur Anzeige von
          interessenbezogener Werbung. Grundsätzlich kann man sagen, dass es
          sich um Analyse- oder Marketingzwecke handelt.
        </Typography>
        <br />
        <Typography variant="body1">
          Soweit wir solche Cookies für Analyse- oder Marketingzwecke einsetzen,
          verarbeiten wir Ihre Daten auf Basis Ihrer Einwilligung gem. Art. 6
          Absatz 1 Satz 1 lit. a) DSGVO. Diese erteilen Sie uns mittels des
          CMP-Tools. Alternativ kann auch Art. 6 Absatz 1 Satz 1 lit. f) DSGVO
          (berechtigtes Interesse) Rechtsgrundlage für solche Cookies für
          Analyse- oder Marketingzwecke sein. Bitte entnehmen Sie weitere
          Angaben unserem CMP-Tool. Hier haben wir im Einzelnen aufgeführt, für
          welche Zwecke wir im Rahmen welcher Rechtsgrundlage Cookies für
          Analyse- oder Marketingzwecke verwenden. Auch finden Sie im Folgenden
          noch weitere Erläuterungen.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>b. Die Verwaltung von Cookies in unserem CMP-Tool </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Welche Cookies im Fall der Nutzung der Webseite verwendet werden, von
          welchem Dienstleister / Partner (sog. „Vendoren“) sie ggf. konkret
          angeboten werden, welche Daten von Ihnen dabei verarbeitet werden und
          ob Sie darin einwilligen möchten, darüber können Sie sich selbst
          informieren und selbst bestimmen.
        </Typography>
        <br />
        <Typography variant="body1">
          Um Ihnen diese informierte Entscheidung zu ermöglichen, setzen wir ein
          Verfahren zum Cookie-Einwilligungs-Management (das oben beschriebene
          CMP-Tool) ein, in dessen Rahmen Ihre Einwilligungen in den Einsatz von
          Cookies, bzw. der im Rahmen des
          Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
          und Vendoren eingeholt werden, von Ihnen verwaltet und – wenn
          gewünscht – auch widerrufen werden können. Hierbei wird die
          Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
          wiederholen zu müssen und Ihre Einwilligung entsprechend der
          gesetzlichen Verpflichtungen nachweisen zu können. Die Speicherung
          kann serverseitig und/oder in einem Cookie (sogenanntes
          „Opt-In-Cookie“, bzw. mithilfe vergleichbarer Technologien) erfolgen,
          um die Einwilligung einem Nutzer, bzw. dessen Endgerät zuordnen zu
          können. Vorbehaltlich individueller Angaben zu den Anbietern von
          Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer
          der Speicherung der Einwilligung kann bis zu 12 Monaten betragen.
          Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem
          Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung
          (z. B. welche Kategorien von Cookies und/oder Diensteanbieter).
        </Typography>
        <br />
        <Typography variant="body1">
          Das CMP-Tool hilft Ihnen, verschiedene Cookies, Tracker und
          Analyse-Tools eingesetzt von uns und ggf. unseren Vendoren nach dem
          Verwender für eine Übersicht zu filtern, nach ihrem Verarbeitungszweck
          auszuwählen und – wenn gewünscht – allgemein oder individuell zu
          deaktivieren. Technisch erforderliche Cookies, Tracker und
          Analyse-Tools können Sie aus den oben geschilderten Gründen jedoch
          nicht deaktivieren.
        </Typography>
        <br />
        <Typography variant="body1">
          Wie oben bereits ausgeführt, können Sie die Webseite mithin bereits
          anfangs aber auch nachträglich auch frei von nicht technisch
          erforderlichen Cookies und anderen Trackingmechanismen nutzen. In
          diesem Fall bleiben lediglich die technisch erforderlichen Cookies,
          Tracker und Analyse-Tools bestehen, die für die Nutzung der Webseite
          erforderlich sind.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>Hinweis zur Einwilligung:</strong> Einige der im CMP-Tool
          aufgeführten Dienste werden von unseren Vendoren gesetzt oder wir
          übermitteln Ihre Daten an diese Vendoren. Nähere Angaben finden Sie in
          unserem CMP-Tool.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>
            Hinweis zur Einwilligung:Einige der im CMP-Tool aufgeführten Dienste
            übermitteln Daten in die USA. Die Datenverarbeitung und der Schutz
            Ihrer Daten in den USA entsprechen nicht dem Standard der DSGVO. Die
            Dienste / Vendoren unterliegen dem Recht der USA und sind daher ggf.
            verpflichtet, Daten bei Vorliegen gesetzlicher Voraussetzungen an
            US-Behörden oder US-Geheimdienste herauszugeben. Risiken für Sie
            bestehen insbesondere durch die erschwerte Rechtsdurchsetzung, die
            fehlende Kontrolle bei Weiterverarbeitung oder Weitergabe Ihrer
            Daten und den erwähnten Zugriff staatlicher Stellen. Nähere Angaben
            finden Sie ebenfalls in unserem CMP-Tool.
          </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Um Ihre diesbezügliche Cookie-Einstellungen/ -Widerspruchsmöglichkeit
          auszuüben, klicken Sie bitte auf „Cookie Einstellungen verwalten“
          (erreichbar über den Menüpunkt am Ende der Webseite).{" "}
        </Typography>
        <br />

        <Typography variant="body1">
          <strong>c. Verwaltung von Cookies über Ihren Browser</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Sie können die Speicherung von Cookies auch über Ihre
          Browsereinstellungen deaktivieren und bereits gespeicherte Cookies
          jederzeit in Ihrem Browser löschen. Bitte beachten Sie jedoch, dass
          die Webseite, bzw. das Online-Angebot ohne Cookies möglicherweise
          nicht oder nur noch eingeschränkt funktioniert. Wir empfehlen Ihnen
          auch die Webseite von www.YourOnlineChoices.com zu besuchen, über die
          Sie Informationen und Hilfestellungen im Zusammenhang mit
          Webtrackingmechanismen erhalten. Eine zentrale Widerspruchsmöglichkeit
          für verschiedene Tools insbesondere US-amerikanischer Anbieter ist
          auch unter folgendem Link erreichbar: optout.networkadvertising.org.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>
            2. Zusammenfassung der Zwecke, für die wir Cookies setzen oder auch
            im Rahmen des Webtrackings personenbezogene Daten verarbeiten
          </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>
            a. Zur Erstellung individueller Angebote unserer Dienstleistungen
            oder der Dienstleistungen unserer Lizenzpartner{" "}
          </strong>
        </Typography>
        <br />

        <Typography variant="body1">
          Sofern Sie gemäß Art. 6 Abs. 1 a DSGVO eingewilligt haben, setzen wir
          Cookies und / oder verarbeiten ggf. personenbezogene Daten wie Ihre
          IP-Adresse oder arbeiten mit Unique Identifiern (ein Unique
          Identifier, kurz UID, ist ein eindeutiger Bezeichner, bestehend aus
          Codes in Form von Zeichenketten aus Zahlen und Buchstaben) um Sie über
          unsere Dienstleistungen zu informieren. Hierbei berücksichtigen wir
          auch Ihre Präferenzen, die sich aus Ihrem Nutzungsverhalten auf
          unserer Webseite ergeben.
        </Typography>
        <br />

        <Typography variant="body1">
          In unserem CMP-Tool finden Sie diese Cookies unter den folgenden
          Rubriken:{" "}
        </Typography>
        <Typography component="li">
          Personalisierte Anzeigen auswählen
        </Typography>
        <Typography component="li">
          Personalisierte Inhalte auswählen
        </Typography>
        <Typography component="li">
          Speicherung von und Zugriff auf Standortdaten für gezielte Werbung
        </Typography>

        <br />

        <Typography variant="body1">
          <strong>
            b. Nutzungsprofile auf Basis von statistischen Auswertungen zur
            bedarfsgerechten Gestaltung der Webseite
          </strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Wir benötigen statistische Informationen über die Nutzung des
          Online-Angebots auf der Webseite, um diese oder deren Inhalte
          nutzerfreundlicher zu gestalten und um Marktforschung bzw. eine
          Reichweitenanalyse zu betreiben. Zu diesem Zweck setzen wir
          Webanalyse-Tools („Tools“) ein. Mithilfe von Analyse-Cookies oder
          durch Auswertung Ihrer Log-Dateien werden Nutzungsprofile erstellt.
          Die Anbieter dieser Tools verarbeiten Ihre Daten grundsätzlich als
          Auftragsverarbeiter gemäß unseren Weisungen und nicht zu eigenen
          Zwecken. Die Tools verwenden IP-Adressen der Nutzer entweder überhaupt
          nicht oder kürzen diese sofort nach der Erhebung.
        </Typography>
        <br />
        <Typography variant="body1">
          Rechtsgrundlage kann zum einen Ihre Einwilligung sein (verarbeiten wir
          Ihre personenbezogene Daten, ist Art. 6 Abs. 1 a) DSGVO
          Rechtsgrundlage; setzen wir Cookies und verarbeiten keine
          personenbezogenen Daten, ist die ePrivacy Richtlinie 2002/58 EG bzw.
          im Anwendungsbereich der Bundesrepublik Deutschland § 25 II TTDSG
          Rechtsgrundlage). Hierneben kann Smart Agent auch ein überwiegendes
          berechtigtes Interesse an einer bedarfsgerechten, den
          Nutzungsgewohnheiten ausgerichteten Gestaltung sowie der statistischen
          Auswertung der Webseite haben. Rechtsgrundlage für die
          Datenverarbeitung ist dann Art. 6 Abs. 1 f) DSGVO.
        </Typography>
        <br />
        <Typography variant="body1">
          Für die genannten Zwecke verwenden wir für die Webseite nebst
          Unterseiten Cookies, und zwar eigene Cookies wie auch solche von
          Drittanbietern, die wir mittels Ihres Web-Browsers an Ihren Computer
          übermitteln. Sie ermöglichen unseren Systemen, Ihren Browser von
          anderen zu unterscheiden. Zum einen übersenden wir Ihnen sog.
          persistente Cookies. Zum anderen verwenden wir sog. Session Cookies,
          mit deren Hilfe Sie für die Dauer eines Besuchs auf unseren Seiten
          eine Session-ID erhalten, die es uns ermöglicht, Sie von anderen
          Besuchern der Website zu unterscheiden.
        </Typography>
        <br />

        <Typography variant="body1">
          In unserem CMP-Tool finden Sie diese Cookies unter den folgenden
          Rubriken:
        </Typography>
        <br />
        <Typography component="li">
          Informationen auf einem Gerät speichern und / oder abrufen
        </Typography>
        <Typography component="li">Inhalte-Leistungen messen</Typography>
        <Typography component="li">
          Produkte entwickeln und verbessern
        </Typography>
        <Typography component="li">
          Marktforschung einsetzen, um Erkenntnisse über Zielgruppe zu gewinnen
        </Typography>
        <Typography component="li">Anzeigen-Leistungen messen</Typography>
        <Typography component="li">Auswahl einfacher Anzeigen</Typography>
        <Typography component="li">Genaue Standortdaten verwenden</Typography>
        <Typography component="li">
          Geräteeigenschaften zur Identifikation aktiv abfragen
        </Typography>
        <br />

        <Typography variant="body1">
          Sie finden in unserem CMP-Tool alphabetisch sortiert auch die Partner,
          mit denen wir zusammenarbeiten.
        </Typography>
        <br />

        <Typography variant="body1">
          Einige der im CMP-Tool aufgeführten Dienste übermitteln Daten in die
          USA. Die Datenverarbeitung und der Schutz der Daten in den USA
          entsprechen nicht dem Standard der DSGVO. Die Dienste unterliegen dem
          Recht der USA und sind daher ggf. verpflichtet, Daten bei Vorliegen
          gesetzlicher Voraussetzungen, an US-Behörden oder US-Geheimdienste
          herauszugeben. Risiken für Sie bestehen insbesondere durch die
          erschwerte Rechtsdurchsetzung, die fehlende Kontrolle bei
          Weiterverarbeitung oder Weitergabe der Daten und den erwähnten Zugriff
          staatlicher Stellen. Bei einigen Anbietern wie Google kann es auch zu
          einer Datenverarbeitung zu eigenen Zwecken kommen. Die
          Datenverarbeitung erfolgt in diesen Fällen auf der Grundlage Ihrer
          Einwilligung, vgl. Art. 6 I lit. a DSGVO.
        </Typography>
        <br />

        <Typography variant="body1">
          Nähere Angaben finden Sie ebenfalls in unserem CMP-Tool.
        </Typography>
        <br />

        <Typography variant="body1">
          <strong>3. Verlinkung auf Social Media Seiten</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Unsere Webseite enthält teilweise Verlinkungen auf die wichtigsten
          Social Media Netzwerkseiten (z. B. Facebook und Twitter). Es handelt
          sich insoweit nicht um die Einbettung von Social Media Plug-Ins,
          sondern um eine Verlinkung, bei der personenbezogene Daten nicht an
          die Social Media Betreiber übermittelt werden.
        </Typography>
        <br />

        <Typography variant="h6">
          III. Weitergabe Ihrer personenbezogenen Daten und Weitergabe ggf. in
          Nicht-EWR Länder
        </Typography>
        <br />

        <Typography variant="body1">
          Bitte entnehmen Sie den Angaben innerhalb unseres CMP-Tools, inwiefern
          wir personenbezogene Daten an unsere Partner geben und ob diese
          Partner ihren Sitz in Drittländern haben. Die Empfänger von
          personenbezogenen Daten können sich außerhalb des EWR/UK befinden.
          Wenn personenbezogene Daten an Standorte außerhalb des EWR/UK
          übermittelt werden, werden wir, wie gesetzlich vorgeschrieben,
          sicherstellen, dass Ihre Datenschutzrechte angemessen geschützt sind,
          entweder weil die Europäische Kommission entschieden hat, dass das
          Land, in das personenbezogene Daten übermittelt werden, ein
          angemessenes Schutzniveau gewährleistet (Art. 45 DSGVO) oder die
          Übermittlung unterliegt angemessenen Garantien (z. B.
          Standardvertragsklauseln) der Europäischen Union, die mit dem
          Empfänger vereinbart wurden (Art. 46 DSGVO), sofern nicht die DSGVO
          eine Ausnahme vorsieht (Art. 49 DSGVO). Darüber hinaus beabsichtigen
          wir, soweit erforderlich, zusätzliche Maßnahmen mit den Empfängern zu
          vereinbaren, um ein angemessenes Datenschutzniveau zu gewährleisten.
          Kopien der angemessenen Schutzmaßnahmen (soweit wir uns auf diese
          verlassen) und eine Liste der Empfänger außerhalb des EWR/des
          Vereinigten Königreichs können angefordert werden. Bitte beachten Sie,
          dass diese Kopien in dem Maße geschwärzt werden können, wie es zum
          Schutz von Geschäftsgeheimnissen oder anderen vertraulichen
          Informationen erforderlich ist.
        </Typography>
        <br />

        <Typography variant="h6">
          IV. Speicherung und Löschung von Daten
        </Typography>
        <br />

        <Typography variant="body1">
          Smart Agent speichert Ihre personenbezogenen Daten für die Zeit, die
          erforderlich ist, um die Zwecke zu erlangen, für die Daten erhoben und
          weiterverarbeitet werden, einschließlich jeglicher Aufbewahrungsfrist,
          die nach den geltenden Gesetzen erforderlich ist (z. B. Beibehaltung
          der Buchhaltungsunterlagen). Weitere Angaben zur Speicherdauer
          entnehmen Sie bitte unserem CMP-Tool.
        </Typography>
        <br />
        <Typography variant="h6">V. Datenschutzhinweise</Typography>
        <br />

        <Typography variant="body1">
          Unseren Datenschutzhinweisen können Sie u.a. Angaben zu den
          Verantwortlichkeiten, unseren Kontaktdaten und Ihren Rechten im Rahmen
          der Datenverarbeitung entnehmen.
        </Typography>
        <br />
      </Grid>
    </Grid>
  );
};

export default Cookies;
