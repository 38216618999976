import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  fontStyle: {
    color: colors.lightTextPrimary,
  },
  boxContainer: {
    backgroundColor: colors.smartAgentGreen,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "305px",
    height: "80px",
    borderRadius: colors.cardBorderRadius,
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
  },

  isRentControlContainer: {
  },
  isRentControlFontStyle: {
    color: "red",
  },

  listItem: {
    display: "list-item",
    fontSize: colors.fontSizeIntermediate,
    paddingLeft: "24px ",
    textIndent: "-19px",
  },
  textItem: {
    fontSize: colors.fontSizeIntermediate,
    alignItems: "flex-start",
  },
  alert: {
    background: colors.smartAgentBlue20pctNoAlpha,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
    "& .MuiAlert-message": {
      width: "100%"
    },
    paddingBottom: "0",
  },
  clickableText: {
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightBold,
    textDecoration: "underline",
    display: "inline-block",
    float: "right",
    paddingTop: "2px",
  },
}));
