import React, {useContext} from 'react';
import {Navigate, useLocation} from 'react-router-dom'
import {DASHBOARD, LOGIN} from "./ROUTES";
import {ACCESS_TOKEN} from "../services/CONSTANTS";
import Alert from "react-s-alert";
import {AuthContext} from "../context/AuthContext";

const OAuth2RedirectHandler = () => {
    const location = useLocation();
    const {updateAuthStatus} = useContext(AuthContext);
    const getUrlParameter = (name: string) => {
        // the linter can't read regex :-D
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const token = getUrlParameter('token');
    const error = getUrlParameter('error');
    if (error) {
        Alert.error('Oops! Something went wrong. Please try again!');
    }

    if (token) {
        localStorage.setItem(ACCESS_TOKEN, token);
        updateAuthStatus({loading: false, authenticated: true});
        return <Navigate to={{pathname: DASHBOARD}} state={{from: location}}/>;
    } else {
        return <Navigate to={{pathname: LOGIN}} state={{from: location}}/>;
    }
}

export default OAuth2RedirectHandler;
