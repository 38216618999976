import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  textField: {
    background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      //borderRadius: 0,
    },
  },
}));
