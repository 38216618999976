import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Entities, FormData } from "../Form/types";
import {
  suggestArea,
  suggestDescription,
  suggestEquipment,
  suggestTitle,
} from "../../../services/textSuggestion/textSuggestionHandler";
import Alert from "react-s-alert";

import {
  getEssentialEnergySource,
  getEstateType,
  getEstateTypeDetailName,
  getFlooring,
  getObjectCondition,
  getTypeOfHeating,
} from "../../../services/entitiyHandler";
import i18next from "i18next";

interface DescriptionProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
  objectId: string;
}

interface pairTypes {
  id: string;
  name: string;
}

const Description = (props: DescriptionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const [estateTypes, setEstateTypes] = useState([]);
  const [estateTypeDetailNames, setEstateTypeDetailNames] = useState([]);
  const [typesOfHeating, setTypesOfHeating] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [essentialEnergySources, setEssentialEnergySources] = useState([]);
  const [condition, setCondition] = useState([]);
  const [flooring, setFlooring] = useState([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getTypeOfHeating().then((response) => {
      const heatingTypes = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setTypesOfHeating(heatingTypes);
    });
    getEssentialEnergySource().then((response) => {
      const energySources = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setEssentialEnergySources(energySources);
    });
    getEstateTypeDetailName().then((response) => {
      const detailedNames = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setEstateTypeDetailNames(detailedNames);
    });
    getEstateType().then((response) => {
      const typeEstates = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setEstateTypes(typeEstates);
    });
    getFlooring().then((response) => {
      const items = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setFlooring(items);
    });
    getObjectCondition().then((response) => {
      const items = response.map((types: Entities) => ({
        id: types.id,
        name: types.name_en,
      }));
      setCondition(items);
    });
  }, []);

  const handleGenerateSuggestions = () => {
    if (props.formData.id) {
      setLoading(true);
      const titleData = {
        objectID: props.formData.id,
        address: props.formData.address,
        city: "",
        buildingType:
          (estateTypes as pairTypes[]).find(
            (type) => type.id === props.formData.estateType
          )?.name || "",
        numberOfRooms: props.formData.rooms,
        hasBalcony: props.formData.balconyTerrace,
        hasGardenAccess: false,
        hasGarden: props.formData.garden,
        newBuilding: false,
        furnished: false,
        quality: props.formData.quality,
        character: "",
        language: lang,
      };

      const descriptionData = {
        objectID: props.formData.id,
        buildingType:
          (estateTypeDetailNames as pairTypes[]).find(
            (type) => type.id === props.formData.estateTypeDetailName
          )?.name || "",
        plotArea: props.formData.plotArea,
        constructionYear: props.formData.constructionYear,
        lastRefurbished: props.formData.lastRefurbished,
        numberOfRooms: props.formData.rooms,
        balconyTerrace: props.formData.balconyTerrace,
        hasGarden: props.formData.garden,
        newBuilding: props.formData.firstTimeUse,
        furnished: props.formData.furnished,
        quality: props.formData.quality,
        heating:
          (typesOfHeating as pairTypes[]).find(
            (type) => type.id === props.formData.typeOfHeating
          )?.name || "",
        characteristics: props.formData.rentalPropertyCharacters,
        condition:
          (condition as pairTypes[]).find(
            (type) => type.id === props.formData.conditionOfTheObject
          )?.name || "",
        language: lang,
      };

      const equipmentsData = {
        objectID: props.formData.id,
        flooring:
          (flooring as pairTypes[]).find(
            (type) => type.id === props.formData.floorCovering
          )?.name || "",
        services: props.formData.specialFeaturesAndServices,
        language: lang,
      };

      const areaData = {
        objectID: props.formData.id,
        city: props.formData.address,
        environmentInfo: props.formData.areaCharacteristics,
        walkingDistance: props.formData.reachability,
        language: lang,
      };

      Promise.all([
        suggestTitle(titleData),
        suggestDescription(descriptionData),
        suggestEquipment(equipmentsData),
        suggestArea(areaData),
      ])
        .then(
          ([
            titleResponse,
            descriptionResponse,
            equipmentResponse,
            areaResponse,
          ]) => {
            props.setFormData((prevData: FormData) => ({
              ...prevData,
              titleSuggestion: titleResponse.titleSuggestion,
              descriptionSuggestion: descriptionResponse.descriptionSuggestion,
              equipmentsSuggestion: equipmentResponse.equipmentsSuggestion,
              areaDescriptionSuggestion: areaResponse.areaDescriptionSuggestion,
            }));
            setLoading(false);
          }
        )
        .catch((err: any) => {
          Alert.error(
            (err && err.message) ||
              t("error")
          );
          setLoading(false);
        });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion label={"5. " + t("extra.info.description.label")}>
        <Grid container className={classes.columnContainer}>
          <Grid item>
            <Typography variant="body1" className={classes.bodyText}>
              {t("extra.info.description.text")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{ justifyContent: "center" }} mt={3} mb={3}>
              {isLoading ? (
                <Box display="flex" alignItems="center" flexDirection="column">
                  <CircularProgress color="primary" />
                  <Typography className={classes.info}>
                    {t("generate.text.spinner")}
                  </Typography>
                </Box>
              ) : (
                <Button onClick={handleGenerateSuggestions} className={classes.button}>
                  {t("generate.text")}
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            key="titleSuggestion"
          >
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.typography}>
                {t("extra.info.description.title")}
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <TextField
                fullWidth
                multiline
                rows={1}
                placeholder={`${t("extra.info.description.adjust.text")}`}
                value={props.formData.titleSuggestion}
                variant="outlined"
                name="titleSuggestion"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            key="descriptionSuggestion"
          >
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.typography}>
                {t("extra.info.description.description")}
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={`${t("extra.info.description.adjust.text")}`}
                value={props.formData.descriptionSuggestion}
                variant="outlined"
                name="descriptionSuggestion"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            key="equipmentsSuggestion"
          >
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.typography}>
                {t("extra.info.description.equipments")}
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={`${t("extra.info.description.adjust.text")}`}
                value={props.formData.equipmentsSuggestion}
                variant="outlined"
                name="equipmentsSuggestion"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            key="areaDescriptionSuggestion"
          >
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.typography}>
                {t("extra.info.description.area")}
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={`${t("extra.info.description.adjust.text")}`}
                value={props.formData.areaDescriptionSuggestion}
                variant="outlined"
                name="areaDescriptionSuggestion"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.columnContainer}
            key="miscellaneous"
          >
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.typography}>
                {t("extra.info.description.miscellaneous")}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={`${t("extra.info.description.adjust.text")}`}
                value={props.formData.miscellaneous}
                variant="outlined"
                name="miscellaneous"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default Description;
