import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    marginTop: 24,
    width: "100%",
  },
  pdfContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "24px",
    borderRadius: colors.cardBorderRadius,
    boxShadow: colors.cardShadow,
    "@media print": {
      boxShadow: "none",
      paddingBottom: "0",
    },
  },
  addressTitle: {
    fontWeight: colors.fontWeightBold,
    fontSize: colors.fontSizeBigger,
    fontFamily: theme.typography.fontFamily,
    display: "flex",
    marginRight: 8,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "4px",
  },
  address: {
    fontWeight: colors.fontWeightRegular,
    fontSize: colors.fontSizeBigger,
    fontFamily: theme.typography.fontFamily,
  },
  addressBox: {
    backgroundColor: colors.smartAgentDarkBlue,
    color: theme.palette.common.white,
    padding: "16px 36px",
    marginTop: "8px",
    maxWidth: "inherit !important",
    borderRadius: colors.cardBorderRadius,
  },
  addressContainerBox: {
    justifyContent: "space-between",
  },
  currencyTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightRegular,
    fontSize: 48,
    letterSpacing: 0.15,
    color: theme.palette.common.white,
  },
  addressSubTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 200,
    fontSize: 18,
    letterSpacing: 0.15,
    color: theme.palette.common.white,
  },
  booleanContainer: {
    fontFamily: theme.typography.fontFamily,
    fontSize: colors.fontSizeIntermediate,
    boxSizing: "border-box",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    padding: "6px 8px",
    marginTop: "8px",
    marginRight: "8px",
    lineHeight: "1.75",
    background: colors.smartAgentGreen,
    color: colors.white,
    borderRadius: colors.cardBorderRadius,
  },
  rowContainer: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    height: 56,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    alignItems: "center",
    "@media print": {
      "&:last-child": {
        borderBottom: "none",
        pageBreakAfter: "always",
      },
      "&:first-child": {
        borderTop: "none",
      },
    },
  },
  rowBeforeTotalContainer: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    height: 56,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    alignItems: "center",
  },
  tableTitle: {
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 20,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "center",
  },
  tableSubTitle: {
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 16,
    fontWeight: colors.fontWeightRegular,
    display: "flex",
    alignItems: "center",
  },
  objectDescriptionTitle: {
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 28,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "center",
  },
  objectDescription: {
    marginTop: 8,
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 28,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "center",
  },
  totalRowContainer: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    height: 56,
    borderTop: "3px solid #171E3B",
    alignItems: "center",
    borderImage: `linear-gradient(to right, #000 50%, transparent 50%) 100% 1`,
  },
  objectIDBOX: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "8px 20px",
    wordWrap: "break-word",
    borderRadius: colors.cardBorderRadius,
  },
  objectId: {
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
    wordWrap: "break-word",
    marginTop: "-4px",
  },
  objectIdLabel: {
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightRegular,
    color: colors.lightTextSecondary,
    wordWrap: "break-word",
  },
  mainImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  contactDescriptionTitle: {
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 20,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "flex-start",
  },
  contactContainer: {
    backgroundColor: " #E7E7E7",
    padding: 16,
    borderRadius: colors.cardBorderRadius,
  },
  updateButton: {
    backgroundColor: "#E0E0E0",
    boxShadow:
      "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    padding: 8,
    borderRadius: 0,
    "&:hover": {
      background: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
  shareDescriptionTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "flex-start",
  },
  checkbox: {
    color: "white",
    "&.Mui-checked": {
      color: "white",
    },
  },
  checkboxLabel: {
    color: "white",
  },
  cardMedia: {
    boxShadow: "none",
    borderRadius: 0,
  },
  card: {
    padding: "16px",
    borderRadius: colors.cardBorderRadius,
    backgroundColor: colors.smartAgentDarkBlue,
    boxShadow: colors.cardShadow,
  },
  topCard: {
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  bottomCard: {
    borderTopLeftRadius: "0 !important",
    borderTopRightRadius: "0 !important",
  },
  middleCard: {
    borderRadius: "0",
    paddingBottom: "0",
  },
  cardVariant: {
    backgroundColor: colors.smartAgentChatbotBlue,
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
}));
