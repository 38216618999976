import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  columnContainer: {
    flexDirection: "row",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  infoContainer: {
    position: "relative", // Set position to relative
    flexGrow: 1,
  },
  info: {
    position: "absolute", // Set position to absolute
    flexGrow: 1,
  },
  select: {
    /*background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",*/
  },
}));
