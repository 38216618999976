import React from "react";
import { useStyles } from "./styles";
import { Adult } from "../../../context/CustomerContext";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/de";
import "dayjs/locale/en";

interface Props {
  adult: Adult;
  subTitle: "subTitle" | "h5";
  body: "body" | "h6";
  adultNum: number;
}
const AdultsData = ({ adult, subTitle, body, adultNum }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  dayjs.extend(isLeapYear);
  dayjs.extend(LocalizedFormat)
  dayjs.locale(`${t("calendar.locale")}`)

  const formatDate = (inputDate: string) => {
    if (inputDate) {
      return dayjs(inputDate).format('LL');
    }
    return t("customer.application.preview.personal.data.na");
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }} style={{ pageBreakInside: "avoid" }}>
        {/*name*/}
        <Grid container className={classes.tableRow}>
          <Grid item xs={4}>
            <Typography variant={subTitle}>
              {t("customer.application.preview.personal.data.name")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant={subTitle}>
              {
                (
                  adult["firstName"] || adult["lastName"]) ? 
                  adult["firstName"] + " " + adult["lastName"] : 
                  t("customer.application.preview.personal.data.adult") + " " + (adultNum + 1)
              }
              {adultNum === 0 ? " (" + t("customer.application.form.personal.data.main.tenant") + ")" : ""}
            </Typography>
          </Grid>
        </Grid>

        {/*birthday*/}
        {adult["birthday"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.birthday")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>
                {formatDate(adult["birthday"])}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/*relationship*/}
        {adult.relationship && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.relationship")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>
                {adult.relationship}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/*nationality*/}
        {adult["nationality"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.nationality")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>
                {adult["nationality"]}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/*profession*/}
        {adult["profession"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.profession")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>{adult["profession"]}</Typography>
            </Grid>
          </Grid>
        )}

        {/*employment type*/}
        {adult["employmentType"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.employment.type")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>{adult["employmentType"]}</Typography>
            </Grid>
          </Grid>
        )}

        {/*employer*/}
        {adult["employer"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.employer")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>{adult["employer"]}</Typography>
            </Grid>
          </Grid>
        )}

        {/*marital status*/}
        {adult["maritalStatus"] && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
              <Typography variant={subTitle} className={classes.smaller}>
                {t("customer.application.preview.personal.data.married.status")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>{adult["maritalStatus"]}</Typography>
            </Grid>
          </Grid>
        )}

        {/*marital status*/}
        {(
          !adult["birthday"] &&
          !adult.relationship &&
          !adult["nationality"] &&
          !adult["profession"] &&
          !adult["employmentType"] &&
          !adult["employer"] &&
          !adult["maritalStatus"]
        ) && (
          <Grid container className={classes.tableRow}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={body} className={classes.smaller}>{t("customer.application.preview.personal.data.na.atAll")}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AdultsData;
