import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useState, ChangeEvent, useEffect } from "react";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import { 
  Typography,
  Grid,
  Switch,
  Divider,
  InputAdornment,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { FormData } from "../Form/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import {
  NumericFormat,
  SourceInfo,
  NumberFormatValues,
  numericFormatter,
 } from 'react-number-format';

//TODO: DATE TIME

interface CostCalculatoProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

const CostCalculator = (props: CostCalculatoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [switchState, setSwitchState] = useState(false);
  const [rent, setRent] = useState(props.formData.rent);
  const [sideCosts, setSideCosts] = useState(props.formData.sideCost);
  const [heatingCosts, setHeatingCosts] = useState(props.formData.heatingCosts);
  const [deposit, setDeposit] = useState(props.formData.deposit);
  const [availableFrom, setAvailableFrom] = useState<Date | null>(null);
  const [availableTo, setAvailableTo] = useState<Date | null>(null);
  const [totalCost, setTotalCost] = useState<number | string>(
    props.formData.totalRent
  );

  const handleDateChangeFrom = (date: Date | null) => {
    setAvailableFrom(date);
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      props.setFormData({ ...props.formData, availableFrom: formattedDate });
    }
  };

  const handleDateChangeTo = (date: Date | null) => {
    setAvailableTo(date);
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      props.setFormData({ ...props.formData, availableTo: formattedDate });
    }
  };

  useEffect(() => {
    const rentValue = parseFloat(rent) || 0;
    const sideCostsValue = parseFloat(sideCosts) || 0;
    const heatingCostsValue = parseFloat(heatingCosts) || 0;

    const newTotalCost = !switchState
      ? rentValue + sideCostsValue + heatingCostsValue
      : rentValue + sideCostsValue;
    setTotalCost(newTotalCost);
    props.setFormData({ ...props.formData, totalRent: newTotalCost });
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rent, sideCosts, heatingCosts, switchState]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "rent":
        setRent(value);
        props.setFormData({ ...props.formData, rent: value });
        break;
      case "sideCosts":
        setSideCosts(value);
        props.setFormData({ ...props.formData, sideCost: value });
        break;
      case "heatingCosts":
        setHeatingCosts(value);
        props.setFormData({ ...props.formData, heatingCosts: value });
        break;
      case "deposit":
        setDeposit(value);
        props.setFormData({ ...props.formData, deposit: value });
        break;
      default:
        break;
    }
  };
  const numericValueChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (sourceInfo.event) {
      const changeEvent = {
        target: {
          name: (sourceInfo.event.target as HTMLInputElement).name,
          value: values.value,
        }
      } as React.ChangeEvent<HTMLInputElement>;
      handleChange(changeEvent);
    }
  };
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion label={"1. " + t("extra.info.costs.label")}>
        <Grid item container className={classes.inputContainer} spacing={2}>
          <Grid item container className={classes.contentContainer} spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.typography}>
                {t("extra.info.costs.rent")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <NumericFormat
                decimalSeparator={`${t("numbers.decimalSeparator")}`}
                allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                thousandSeparator={`${t("numbers.thousandSeparator")}`}
                thousandsGroupStyle="thousand"
                allowNegative={false}
                decimalScale={2}
                valueIsNumericString={true}
                customInput={TextField}
                fullWidth
                label=""
                value={rent}
                name="rent"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{"€"}</InputAdornment>
                  ),
                  
                }}
                inputProps={{
                  style: { textAlign: "right" },
                }}
                onValueChange={numericValueChange}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.contentContainer} spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.typography}>
                {t("extra.info.costs.side.costs")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <NumericFormat
                decimalSeparator={`${t("numbers.decimalSeparator")}`}
                allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                thousandSeparator={`${t("numbers.thousandSeparator")}`}
                thousandsGroupStyle="thousand"
                allowNegative={false}
                decimalScale={2}
                valueIsNumericString={true}
                customInput={TextField}
                fullWidth
                label=""
                value={sideCosts}
                name="sideCosts"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{"€"}</InputAdornment>
                  ),
                  
                }}
                inputProps={{
                  style: { textAlign: "right" },
                }}
                onValueChange={numericValueChange}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.contentContainer} spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.typography}>
                {t("extra.info.costs.heating")}
              </Typography>
              <FormGroup>
                <FormControlLabel 
                  control={<Switch checked={switchState} onChange={handleSwitchChange} />} 
                  label={t("extra.info.costs.included.in.rent")}
                  sx={{ paddingLeft: "4px" }}
                />
              </FormGroup>
            </Grid>
            <Grid item container className={classes.contentContainer} xs={6}>
              <Grid item xs={12}>
                {!switchState && (
                  <NumericFormat
                    decimalSeparator={`${t("numbers.decimalSeparator")}`}
                    allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                    thousandSeparator={`${t("numbers.thousandSeparator")}`}
                    thousandsGroupStyle="thousand"
                    allowNegative={false}
                    decimalScale={2}
                    valueIsNumericString={true}
                    customInput={TextField}
                    fullWidth
                    label=""
                    value={heatingCosts}
                    name="heatingCosts"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"€"}</InputAdornment>
                      ),
                      
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    onValueChange={numericValueChange}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid
            item
            container
            className={classes.contentContainerWithSpace}
            xs={12}
          >
            <Grid item>
              <Typography className={classes.typographyCosts}>
                {t("extra.info.costs.total.rent")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typographyCosts}>
                {numericFormatter(
                  totalCost.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalSeparator: `${t("numbers.decimalSeparator")}`,
                    suffix: " €",
                    decimalScale: 2,
                  }
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container className={classes.contentContainer} spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.typography}>
                {t("extra.info.costs.deposit")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <NumericFormat
                decimalSeparator={`${t("numbers.decimalSeparator")}`}
                allowedDecimalSeparators={[`${t("numbers.decimalSeparator")}`]}
                thousandSeparator={`${t("numbers.thousandSeparator")}`}
                thousandsGroupStyle="thousand"
                allowNegative={false}
                decimalScale={2}
                valueIsNumericString={true}
                customInput={TextField}
                fullWidth
                label=""
                value={deposit}
                name="deposit"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{"€"}</InputAdornment>
                  ),
                  
                }}
                inputProps={{
                  style: { textAlign: "right" },
                }}
                onValueChange={numericValueChange}
              />
            </Grid>
          </Grid>

          <Grid item container className={classes.contentContainer} spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={`${t("calendar.locale")}`}
              >
                <DatePicker
                  className={classes.datePicker}
                  label={
                    availableFrom === null
                      ? t("extra.info.costs.available.from")
                      : ""
                  }
                  value={availableFrom}
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={6}
              container
              spacing={2}
              direction="column"
            >
              <Grid item>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={`${t("calendar.locale")}`}
                >
                  <DatePicker
                    className={classes.datePicker}
                    label={
                      availableTo === null
                        ? t("extra.info.costs.available.to")
                        : ""
                    }
                    value={availableTo}
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <InfoAlert>
                  {t("extra.info.costs.available.to.info")}
                </InfoAlert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default CostCalculator;
