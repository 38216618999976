import React from "react";
import { RentalObject } from "../types/RentalObject";

export interface DashboardContextValues {
  setObjects: (param: RentalObject[]) => void;
  objects: RentalObject[];
}

export const DashboardContext = React.createContext<DashboardContextValues>({
  setObjects: () => {},
  objects: [],
});
