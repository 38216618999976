import ContactData from "../ContactData/ContactData";
import FinancialDetails from "../Financial/FinancialDetails";
import Details from "../Details/Details";
import { Grid, Typography } from "@mui/material";
import InfoText from "../../Customs/InfoText";
import React from "react";
import { t } from "i18next";
import { useStyles } from "./styles";

const CustomerRenderer = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={[classes.card, classes.topCard].join(" ")}>
        <InfoText
          labelText={t("customer.application.form.create")}
          bodyText={t("customer.application.form.info.we.are.glad")}
        />
      </Grid>

      <Grid item xs={12} className={[classes.card, classes.middleCard].join(" ")}>
        <Typography variant="body1" color="#F3F4F6">
          {t("customer.application.form.to.increase.chance")}
        </Typography>
      </Grid>

      <Grid item xs={12} className={[classes.card, classes.bottomCard].join(" ")}>
        <ContactData isDefaultExpand={true} />
      </Grid>

      <Grid item xs={12} mt={4} className={[classes.card, classes.cardVariant, classes.topCard].join(" ")}>
        <Typography variant="body1" color="#F3F4F6">
          {t("customer.application.form.you.now.have.opportunity")}
        </Typography>
      </Grid>

      <Grid item xs={12} className={[classes.card, classes.middleCard, classes.cardVariant].join(" ")}>
        <Details />
      </Grid>

      <Grid item xs={12} className={[classes.card, classes.bottomCard, classes.cardVariant].join(" ")}>
        <FinancialDetails />
      </Grid>
    </>
  );
};

export default CustomerRenderer;
