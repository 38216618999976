import React from "react";
import { defaultRentalObject, RentalObject } from "../types/RentalObject";

export interface CustomerDashboardContextValues {
  setRentalObject: (param: RentalObject) => void;
  rentalObject: RentalObject;
}

export const CustomerDashboardContext =
  React.createContext<CustomerDashboardContextValues>({
    setRentalObject: () => {},
    rentalObject: defaultRentalObject,
  });
