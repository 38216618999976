import React, { ReactNode } from "react";

export interface SideBarContextValues {
  setVisibility: (parpam: boolean) => void;
  setContent: (param: ReactNode) => void;
}

export const SideBarContext = React.createContext<SideBarContextValues>({
  setVisibility: () => {},
  setContent: () => {},
});
