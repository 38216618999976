import React, { ReactNode, useEffect, useState } from "react";
import { Box, Button, Grid, Icon, IconButton } from "@mui/material";
import { useStyles } from "./styles";
import {
  activeStatuses,
  RentalObject,
  RentalStatus,
} from "../../../types/RentalObject";
import { Home, MoreVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ESTATE, OBJECT_DASHBOARD } from "../../../routes/ROUTES";
import ObjectActions from "./ObjectActions";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { numericFormatter } from "react-number-format";
import * as colors from "../../../style/colors";

interface Props {
  object: RentalObject;
  reload: () => void;
}

const PlaceHolderComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#BDBDBD",
        height: "100%",
        width: "100%",
      }}
    >
      <Icon sx={{ color: "#9E9E9E", height: 64, width: 64 }}>
        <Home sx={{ width: "100%", height: "100%" }} />
      </Icon>
    </Box>
  );
};

export const statuses: {
  label: string;
  status: RentalStatus;
  index: number;
}[] = [
  {
    index: 0,
    status: "create_object",
    label: "customer.dashboard.inactive.object.status.1",
  },
  {
    index: 1,
    status: "create_expose",
    label: "customer.dashboard.inactive.object.status.2",
  },
  {
    index: 2,
    status: "publish_expose",
    label: "customer.dashboard.inactive.object.status.3",
  },
  {
    index: 3,
    status: "review_and_invite_candidates",
    label: "customer.dashboard.inactive.object.status.4",
  },
  {
    index: 4,
    status: "prepare_contract",
    label: "customer.dashboard.inactive.object.status.5",
  },
  {
    index: 5,
    status: "decline_other_candidates",
    label: "customer.dashboard.inactive.object.status.6",
  },
  {
    index: 5,
    status: "disabled",
    label: "customer.dashboard.inactive.object.status.7",
  },
];

const ObjectCard = ({ object, reload }: Props) => {
  const [img, setImg] = useState<ReactNode>();
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getNextStatus = (status: RentalStatus | undefined): string => {
    if (status) {
      const currentIndex = statuses.findIndex((item) => item.status === status);
      const nextIndex = (currentIndex + 1) % statuses.length;
      return statuses[nextIndex]?.label;
    }
    return "";
  };

  useEffect(() => {
    setImg(
      object.pictures && object.pictures.length > 0 ? (
        <img
          alt=""
          className={classes.img}
          src={`data:image/jpeg;base64,${object.pictures[0]["image"]["data"]}`}
        />
      ) : (
        <PlaceHolderComponent />
      )
    );
  }, [object, classes.img]);

  const handleNextStatus = () => {
    switch (object.status) {
      case "create_object":
        navigate(`${ESTATE}/${object.id}`, {
          state: { object, step: 2 },
        });
        break;
      case "create_expose":
        navigate(`${ESTATE}/${object.id}`, {
          state: { object, step: 3 },
        });
        break;
      case "publish_expose":
        navigate(`${OBJECT_DASHBOARD}/${object.id}`);
        break;
    }
  };

  const handleCardClick = () => {
    navigate(`${OBJECT_DASHBOARD}/${object.id}`);
  };

  return (
    <>
      <Box className={classes.card}>
        <Grid container sx={{ position: "relative !important" }}>
          <Grid item xs={12} display="flex">
            <Grid
              item
              xs={11}
              className={classes.titleContainer}
              onClick={handleCardClick}
            >
              <Box className={classes.title}>{object.name}</Box>
              <Box className={classes.subTitle}>{object.address}</Box>
            </Grid>
            <Grid item xs={1} sx={{ margin: "10px" }}>
              <IconButton sx={{ color: "#101529" }} onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.imgContainer}
            onClick={handleCardClick}
          >
            {img}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              className={classes.priceContainer}
              onClick={handleCardClick}
            >
              <Box className={classes.priceFont}>{
                numericFormatter(
                  object.totalRent.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    suffix: " €",
                    decimalScale: 0,
                  }
                )
              }</Box>
              <Box className={classes.secondaryInfos}>{
                numericFormatter(
                  object.rooms.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalSeparator: `${t("numbers.decimalSeparator")}`,
                  }
                ) + 
                " " + (object.rooms === 1 ? t("customer.dashboard.room") : t("customer.dashboard.rooms")) }
              </Box>
              <Box className={classes.secondaryInfos}>{
                numericFormatter(
                  object.area.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalScale: 0,
                  }
                ) + " " + t("customer.dashboard.area")
              }</Box>
            </Grid>
            <Grid item xs className={classes.statusContainer}>
              <Grid
                item
                className={classes.activeStatus}
                onClick={handleCardClick}
                sx={{
                  flex: "1 1 auto",
                  backgroundColor: activeStatuses.includes(object.status)
                    ? colors.smartAgentGreen
                    : "#E0E0E0",
                  color: activeStatuses.includes(object.status)
                    ? colors.lightTextPrimary
                    : colors.darkTextPrimary,
                }}
              >
                {activeStatuses.includes(object.status)
                  ? t("customer.dashboard.object.active.expose")
                  : t("customer.dashboard.object.inactive.expose")}
              </Grid>
              <Grid
                item
                className={classes.currentStatus}
                sx={{ flex: "1 1 auto" }}
              >
                <Button
                  sx={{
                    display: "flex",
                    flexDirection: "column !important",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                  onClick={handleNextStatus}
                >
                  <Box className={classes.nextStatusFont} sx={{ marginBottom: "-6px" }}>
                    {t("customer.dashboard.object.next.step")}
                  </Box>
                  <Box className={classes.statusFont}>
                    {t(getNextStatus(object.status))}
                    <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ObjectActions
        object={object}
        reload={reload}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default ObjectCard;
