import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  columnContainer: {
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  bodyText: { },
  typography: {
    //color: theme.palette.common.white,
    fontSize: "16px",
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
  },
  fileButton: {
    display: "flex",
    //color: theme.palette.common.white,
    justifyContent: "flex-start",
    textTransform: "none",
    //fontSize: "16px",
    height: "56px",
    //border: "1px white solid",
    //borderRadius: 0,
    "&:hover": {
      //backgroundColor: theme.palette.common.black,
      //color: theme.palette.common.white,
    },
  },
  icon: {
    marginRight: "8px",
  },
  select: {
    /*background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",*/
  },
  filePreview: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    marginRight: 10,
    //color: "#90CAF9",
    fontSize: 16,
    textDecoration: "underline",
  },
}));
