import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./styles";

interface Props {
  children?: React.ReactNode;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToggleButton = (props: Props) => {
  const classes = useStyles();
  const [active, setActive] = useState(props.active ?? false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActive(!active);
    if (props.onClick) {
      props.onClick(event);
    }
    //console.log("state changed");
  };

  return (
    <Grid item>
      <Button
        className={`${classes.button} ${active ? "active" : ""}`}
        onClick={handleClick}
      >
        {props.children}
      </Button>
    </Grid>
  );
};

export default ToggleButton;
