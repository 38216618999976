import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { CustomerState } from "../../../context/CustomerContext";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

interface LinkProps {
  files: FileProp[];
}

interface FileProp {
  title: string;
  image: {
    type: number;
    data: string;
  };
}

const BlobDownloadLinks = ({ files }: LinkProps) => {
  const classes = useStyles();

  const handleDownloadFile = ({ title, image: { data } }: FileProp) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = title;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {Array.from(files).map((file, index, {length}) => (
        <Grid 
          container 
          key={index} 
          onClick={() => handleDownloadFile(file)} 
          sx={{
            marginBottom: index + 1 === length ? "8px" : "",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          >
          <Grid item xs={1}>
            <InsertDriveFileIcon className={classes.icon} />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.fileName}>
              {file.title}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const convertImageDataToFileList = (imageData: ImageData[]): FileList => {
  const dataTransfer = new DataTransfer();

  if (imageData && imageData.length > 0) {
    imageData.forEach((item) => {
      const { title, image } = item;
      const file = new File([image.data], title, {
        type: image.type.toString(),
        lastModified: Date.now(),
      });
      dataTransfer.items.add(file);
    });
  }

  return dataTransfer.files;
};

interface linkProps {
  data: FileList;
}

interface ImageData {
  title: string;
  image: {
    type: number;
    data: Blob;
  };
}

const DownloadLinks = ({ data }: linkProps) => {
  const classes = useStyles();
  const handleDownloadFile = (file: File) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {Array.from(data).map((file, index, {length}) => (
        <Grid 
          item 
          key={index} 
          onClick={() => handleDownloadFile(file)} 
          sx={{
            marginBottom: index + 1 === length ? "8px" : "",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography className={classes.fileName} sx={{fontSize: "14px !important"}}>
            <InsertDriveFileIcon sx={{fontSize: "16px !important", verticalAlign: "text-bottom"}}/>
            {file.name}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

interface DocumentProps {
  label: string;
  canProvide: boolean;
  files: FileList | null | ImageData[];
  notPDF: boolean;
  isBlob: boolean;
}

const Document = ({
  label,
  canProvide,
  files,
  notPDF,
  isBlob,
}: DocumentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileList, setFiles] = useState<FileList | null>(null);
  const [fileBlobList, setBlobFiles] = useState<FileProp[] | null>(null);

  useEffect(() => {
    if (isBlob && files && files?.length > 0) {
      const fileData = files as ImageData[];
      const blobData = files as unknown as FileProp[];
      setBlobFiles(blobData);
      setFiles(convertImageDataToFileList(fileData));
    } else {
      const fileList = files as FileList;
      setFiles(fileList);
    }
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlob]);

  return (
    <Grid container className={classes.tableRow}>
      <Grid item container xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={"body"}>
              {t(label) + ":"}
            </Typography>
            {notPDF && fileList && (
              <Typography className={classes.uploaded}>
                {t("customer.application.preview.application.file.submitted")}
              </Typography>
            )}
            {notPDF && canProvide && !fileList && (
              <Typography className={classes.uploaded}>
                {t("customer.application.preview.application.file.on.appointment")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
          {notPDF && fileList && (
            <Grid item container xs={12} mt={1} mb={2}>
              {fileBlobList ? (
                <BlobDownloadLinks files={fileBlobList} />
              ) : (
                <DownloadLinks data={fileList} />
              )}
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

interface Props {
  customerState: CustomerState;
  subTitle: "subTitle" | "h5";
  pdfVersion: boolean;
  isBlob: boolean;
}
const Documents = ({ customerState, subTitle, pdfVersion, isBlob }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container>

      {/* Document Block */}
      <Grid container>
        {/* Headline */}
        <Grid item container xs={9}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12}>
              <Typography variant={"subTitle"}>
                {t("customer.application.preview.document") + ": "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* no docs supplied*/}
        {(!(customerState.customer.canProvideProofOfIncome || customerState.customer.proofOfIncome) &&
          !(customerState.customer.canProvideIncomeTaxStatement || customerState.customer.incomeTaxStatement) &&
          !(customerState.customer.canProvideRentalCostTakeOver || customerState.customer.rentalCostTakeOver) &&
          !(customerState.customer.canProvideRentalGuarantee || customerState.customer.rentalGuarantee) &&
          !(customerState.customer.canProvideSCHUFA || customerState.customer.schufa) &&
          !(customerState.customer.canProvideRentPaymentConfirmations || customerState.customer.rentPaymentConfirmations)) && (
          <Grid container className={classes.tableRow}>
            <Grid item container xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={"body"}>
                    {t("customer.application.preview.noDocuments")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*proof of income*/}
        {(customerState.customer.canProvideProofOfIncome ||
          customerState.customer.proofOfIncome) && (
          <Document
            label={"customer.application.preview.proof.of.income"}
            canProvide={customerState.customer.canProvideProofOfIncome}
            files={customerState.customer.proofOfIncome}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}

        {/*tax statement*/}
        {(customerState.customer.canProvideIncomeTaxStatement ||
          customerState.customer.incomeTaxStatement) && (
          <Document
            label={"customer.application.preview.income.tax.statement"}
            canProvide={customerState.customer.canProvideIncomeTaxStatement}
            files={customerState.customer.incomeTaxStatement}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}

        {/*rental cost take over*/}
        {(customerState.customer.canProvideRentalCostTakeOver ||
          customerState.customer.rentalCostTakeOver) && (
          <Document
            label={"customer.application.preview.rental.tax.take.over"}
            canProvide={customerState.customer.canProvideRentalCostTakeOver}
            files={customerState.customer.rentalCostTakeOver}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}

        {/*rental guarantee*/}
        {(customerState.customer.canProvideRentalGuarantee ||
          customerState.customer.rentalGuarantee) && (
          <Document
            label={"customer.application.preview.rental.guarantee"}
            canProvide={customerState.customer.canProvideRentalGuarantee}
            files={customerState.customer.rentalGuarantee}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}

        {/*schufa*/}
        {(customerState.customer.canProvideSCHUFA ||
          customerState.customer.schufa) && (
          <Document
            label={"customer.application.preview.schufa"}
            canProvide={customerState.customer.canProvideSCHUFA}
            files={customerState.customer.schufa}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}

        {/*payment confirmations*/}
        {(customerState.customer.canProvideRentPaymentConfirmations ||
          customerState.customer.rentPaymentConfirmations) && (
          <Document
            label={"customer.application.preview.rental.payment.confirmation"}
            canProvide={customerState.customer.canProvideRentPaymentConfirmations}
            files={customerState.customer.rentPaymentConfirmations}
            notPDF={!pdfVersion}
            isBlob={isBlob}
          />
        )}
      </Grid>


      {/* Checkbox Block */}
      <Grid container mt={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customerState.customer.nonEvictionForLastYears}
                readOnly={true}
                className={classes.checkbox}
              />
            }
            label={t("customer.application.preview.nonEvictionForLastYears")}
            sx={{
              alignItems: "flex-start",
              "& span:first-of-type": {
                paddingTop: "2px",
                paddingLeft: "16px",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customerState.customer.nonRentClaimProcess}
                readOnly={true}
                className={classes.checkbox}
              />
            }
            label={t("customer.application.preview.nonRentClaimProcess")}
            sx={{
              alignItems: "flex-start",
              "& span:first-of-type": {
                paddingTop: "2px",
                paddingLeft: "16px",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customerState.customer.nonSalaryGarnishment}
                readOnly={true}
                className={classes.checkbox}
              />
            }
            label={t("customer.application.preview.nonSalaryGarnishment")}
            sx={{
              alignItems: "flex-start",
              "& span:first-of-type": {
                paddingTop: "2px",
                paddingLeft: "16px",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customerState.customer.nonAffidavit}
                readOnly={true}
                className={classes.checkbox}
              />
            }
            label={t("customer.application.preview.nonAffidavit")}
            sx={{
              alignItems: "flex-start",
              "& span:first-of-type": {
                paddingTop: "2px",
                paddingLeft: "16px",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customerState.customer.nonBankruptcy}
                readOnly={true}
                className={classes.checkbox}
              />
            }
            label={t("customer.application.preview.nonBankruptcy")}
            sx={{
              alignItems: "flex-start",
              "& span:first-of-type": {
                paddingTop: "2px",
                paddingLeft: "16px",
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Documents;
