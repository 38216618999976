import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: { display: "flex", flexDirection: "column" },
  accordion: {
    //background: theme.palette.info.light,
    //boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
  },
  accordionSummary: {
    // color: theme.palette.primary.contrastText,
  },
  expandMoreIcon: {
    // color: theme.palette.primary.contrastText,
  },
  select: {
    //background: "#FFFFFF",
    height: "56px",
    [`& fieldset`]: {
      //borderRadius: 0,
    },
    padding: "0px, 12px, 0px, 12px",
  },
  bodyText: {
    //color: theme.palette.common.white
  },
  icon: {
    marginRight: "8px",
  },
  fileButton: {
    display: "flex",
    //color: theme.palette.common.white,
    justifyContent: "flex-start",
    textTransform: "none",
    //fontSize: "16px",
    height: "56px",
    //border: "1px white solid",
    //borderRadius: 0,
    "&:hover": {
      //backgroundColor: theme.palette.common.black,
      //color: theme.palette.common.white,
    },
  },
  infoContainer: {
    position: "relative", // Set position to relative
    flexGrow: 1,
  },
  info: {
    position: "absolute", // Set position to absolute
    flexGrow: 1,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dialogTitle: {
    backgroundColor: colors.smartAgentGreen,
    padding: "8px 16px",
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeBig,
    fontWeight: colors.fontWeightBold,
  },
  dialogContent: {
    padding: "16px !important",
  },
  dialogInfo: {
    fontSize: colors.fontSizeIntermediate,
    color: colors.darkTextSecondary,
  },
  dialogActions: {
    padding: "16px",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    padding: "6px 16px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
    marginLeft: "16px !important",
  },
  buttonSecondary: {
    color: colors.smartAgentGreen,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightRegular,
    borderColor: colors.smartAgentGreen,
    padding: "5px 16px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    //boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -3px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
}));
