import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: colors.lightGrayBackground,
    padding: "24px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,
    marginTop: 0,
    overflowY: "scroll",
  },
}));
