import React, { ReactNode } from "react";

interface Props {
  isShowing: boolean;
  content: ReactNode;
}
const SideBar = ({ isShowing, content }: Props) => {
  return <>{isShowing && content}</>;
};

export default SideBar;
