import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    borderRadius: colors.cardBorderRadius,
    boxShadow:
      "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
    minHeight: "100%",
    backgroundColor: colors.white,
  },
  icon: {
    padding: "24px 16px 16px 16px",
    display: "flex",
    justifyContent: "center",
  },
  subTitle: {
    padding: "8px 16px 0 16px",
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontWeight: colors.fontWeightRegular,
  },
  additionalInfo: {
    padding: "8px 16px 16px 16px",
    fontFamily: theme.typography.fontFamily,
    color: colors.darkTextSecondary,
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightRegular,
  },
}));
