import { ACCESS_TOKEN, API_BASE_URL } from "./CONSTANTS";
import { CURRENT_USER } from "../routes/ROUTES";

interface RequestOptions {
  url: string;
  method: string;
  body?: string;
}

interface SignUpRequest {
  name: string;
  password: string;
  email: string;
  signupcode: string;
  lang: string;
}

interface LoginRequest {
  password: string;
  email: string;
}

export const request = (options: RequestOptions) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  const data = Object.assign({}, defaults, options);

  return fetch(options.url, data).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + CURRENT_USER,
    method: "GET",
  });
}

export function signup(signupRequest: SignUpRequest) {
  return request({
    url: API_BASE_URL + "/auth/register",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function login(loginRequest: LoginRequest) {
  return request({
    url: API_BASE_URL + "/auth/login",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}
