import { BehaviorSubject, Observable } from "rxjs";
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Place } from "../../../services/rentalHandler";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ErrorState } from "../../Expose/Form/FormComponent";

interface AutoCompleteDropDownProps {
  address: string;
  getSuggestions: (subject: BehaviorSubject<string>) => Observable<Place[]>;
  renderSuggestion?: (suggestion: Place) => JSX.Element | string;
  onSelect?: (suggestion: Place) => void;
  errors: ErrorState;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
}

const subject$ = new BehaviorSubject("");

const AutoCompleteDropDown = (props: AutoCompleteDropDownProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    address,
    renderSuggestion,
    onSelect,
    getSuggestions,
    errors,
    setErrors,
  } = props;
  const [value, setValue] = useState(address);
  const [suggestions, setSuggestions] = useState<Place[]>([]);
  const [highlightedIdx, setHighlightedIdx] = useState(0);
  const [isUpdate, setIsUpdate] = useState(address !== "");

  useEffect(() => {
    const subscription = getSuggestions(subject$).subscribe(
      (suggestions) => {
        setSuggestions(suggestions);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => subscription.unsubscribe();
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdate(false);
    setValue(e.target.value);
    subject$.next(e.target.value);
    if (e.target.value.length === 0) {
      setErrors({ ...errors, address: t("estate.address.validation") });
    }
  };

  const handleSelect = (idx: number) => {
    if (onSelect) {
      const place = suggestions[idx].description;
      if (!/\d/.test(place)) {
        onSelect(suggestions[idx]);
        setValue(place);
        setSuggestions([]);
        setErrors({ ...errors, address: t("estate.city.error.msg") });
      } else {
        onSelect(suggestions[idx]);
        setValue(place);
        setSuggestions([]);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const UP = 38;
    const DOWN = 40;
    const ENTER = 13;
    const INITIAL_IDX = 0;

    if (e.keyCode === DOWN) {
      e.preventDefault();
      const idx = highlightedIdx;
      const nextIdx = idx !== undefined ? idx + 1 : INITIAL_IDX;

      if (nextIdx < suggestions.length) {
        setHighlightedIdx(nextIdx);
      } else {
        setHighlightedIdx(INITIAL_IDX);
      }
    }

    if (e.keyCode === UP) {
      e.preventDefault();
      const lastIdx = suggestions.length - 1;
      const idx = highlightedIdx;
      const prevIdx = idx !== undefined ? idx - 1 : lastIdx;

      if (prevIdx >= 0) {
        setHighlightedIdx(prevIdx);
      } else {
        setHighlightedIdx(lastIdx);
      }
    }

    if (e.keyCode === ENTER && highlightedIdx !== undefined) {
      handleSelect(highlightedIdx);
    }
  };

  const shouldShowSuggestions =
    suggestions.length > 0 && value.length > 0 && !isUpdate;

  return (
    <Grid item>
      <TextField
        fullWidth
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        placeholder={`${t("estate.address")}`}
        label={`${t("estate.address")}`}
        className={classes.textField}
        inputProps={{
          sx: {
            "&::placeholder": {
              opacity: 0.8,
            },
            "& .MuiFormHelperText-root.Mui-error": {
              color: "white",
            },
          },
        }}
        error={!!errors.address}
      />
      {shouldShowSuggestions && (
        <Paper>
          {suggestions.map((suggestion, idx) => (
            <MenuItem
              key={`suggestion-${idx}`}
              onClick={() => handleSelect(idx)}
              selected={highlightedIdx === idx}
            >
              <Box
                flexWrap="wrap"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography>
                  {renderSuggestion ? renderSuggestion(suggestion) : ""}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Paper>
      )}
    </Grid>
  );
};

export default AutoCompleteDropDown;
