import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { 
  Box, 
  Button,
  CircularProgress, 
  Grid
} from "@mui/material";
import {
  CustomerContext,
  CustomerState,
  defaultCustomerState,
  ErrorState,
  InitialErrorsState,
} from "../../../context/CustomerContext";
import React, { useState, useEffect } from "react";
import HorizontalStepper from "../HorizontalStepper/HorizontalStepper";
import Application from "../Application/Application";
import ApplicationPreview from "../ApplicationPreview/ApplicationPreview";
import {
  createCustomer,
  saveAllFiles,
  updateCustomer,
} from "../../../services/aplicationHandler";
import Alert from "react-s-alert";
import { useParams } from "react-router-dom";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const handleFileSave = (request: any) => {
  delete request?.photo;
  delete request?.proofOfIncome;
  delete request?.incomeTaxStatement;
  delete request?.rentalCostTakeOver;
  delete request?.rentalGuarantee;
  delete request?.schufa;
  delete request?.rentPaymentConfirmations;
};

const CustomerRenderer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [customerState, setCustomerState] =
    useState<CustomerState>(defaultCustomerState);
  const updateCustomerState = (updatedState: CustomerState) => {
    setCustomerState(updatedState);
  };
  const [errors, setErrors] = useState<ErrorState>(InitialErrorsState);
  const updateErrors = (updatedState: ErrorState) => {
    setErrors(updatedState);
  };

  const { displayId } = useParams();

  const [objectName, setObjectName] = useState<string>("");

  const setDisplayID = () => {
    if (displayId && customerState.customer.object.displayId === "") {
      if (objectName === "") {
        setObjectName(displayId);
      }
      const stateToUpdate: CustomerState = {
        ...customerState,
        customer: {
          ...customerState.customer,
          object: {
            ...customerState.customer.object,
            displayId: displayId
          }
        },
      }
      updateCustomerState(stateToUpdate);
    }
  };

  useEffect(() => {
    setDisplayID();
    // especially quick and a bit dirty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayId]);

  const handleCreateCustomer = (event: React.MouseEvent<HTMLButtonElement>) => {
    let hasError = false;
    event.preventDefault();
    const newErrors = { ...errors };

    if (customerState.customer.object.displayId === "") {
      newErrors.displayId = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.firstName === "") {
      newErrors.firstName = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.lastName === "") {
      newErrors.lastName = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.street === "") {
      newErrors.street = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.postalCode === "") {
      newErrors.postalCode = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.city === "") {
      newErrors.city = t("customer.application.form.mandatory.error");
      hasError = true;
    }

    if (customerState.customer.email === "") {
      newErrors.email = t("customer.application.form.mandatory.error");
      hasError = true;
    }
    updateErrors(newErrors);
    if (!hasError) {
      setLoading(true);

      if (!displayId) {
        Alert.error("URL must contain the displayId");
        setLoading(false);
        return;
      }

      if (!customerState.id) {
        const request = Object.assign({}, customerState.customer) as any;
        handleFileSave(request);
        createCustomer(request, displayId)
          .then(async (response) => {
            setObjectName(response.object.name);
            const updatedCustomerState: CustomerState = {
              ...customerState,
              id: response.id, // Assuming the response object contains the id
              customer: {
                ...customerState.customer,
                id: response.id,
              },
            };
            updateCustomerState(updatedCustomerState);
            await saveAllFiles(customerState.customer, response.id, setLoading);
            setTimeout(() => {
              setActiveStep(1);
              //Alert.success("Application submitted successfully!");
              setLoading(false);
            }, 2000);
          })
          .catch((err) => {
            Alert.error((err && err.message) || t("error"));
            setLoading(false);
          })
          .catch((err) => {
            Alert.error((err && err.message) || t("error"));
            setLoading(false);
          });
      } else {
        const request = Object.assign({}, customerState.customer, {
          id: customerState.id,
        }) as any;
        handleFileSave(request);
        updateCustomer(request)
          .then(async () => {
            await saveAllFiles(customerState.customer, request.id, setLoading);
            //Alert.success("Application updated successfully!");
            setLoading(false);
            setActiveStep(1);
          })
          .catch((err) => {
            Alert.error((err && err.message) || t("error"));
            setLoading(false);
          })
          .catch((err) => {
            Alert.error((err && err.message) || t("error"));
            setLoading(false);
          });
      }
    }
  };

  return (
    <CustomerContext.Provider value={{ customerState, updateCustomerState, errors, updateErrors }}>

      <Grid container sx={{ paddingBottom: "16px" }}>
        <Grid item xs={12} className={[classes.card, classes.stepperContainer].join(" ")}>
          <HorizontalStepper activeStep={activeStep} />
        </Grid>

        {activeStep === 0 && (
          <Grid container>
          {/*<Grid container className={classes.outerContainer} spacing={4}>*/}
            <Application />


            <Grid container item xs={12} className={classes.card} mt={4} sx={{ justifyContent: "end"}}>
              {isLoading ? (
                <Box display="flex" sx={{paddingRight: "16px"}}>
                  <CircularProgress color="success" />
                </Box>
              ) : null}
              <Button className={classes.button} onClick={handleCreateCustomer}>
                {t("customer.application.next")}
                <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
              </Button>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && <ApplicationPreview setActiveStep={setActiveStep} objectName={objectName} />}
      </Grid>

    </CustomerContext.Provider>
  );
};

export default CustomerRenderer;
