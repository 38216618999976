import { Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import ImageUploader from "./ImageUploader";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";

interface Props {
  objectId: string;
}

const ImageAccordion = ({ objectId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion label={"6. " + t("accordion.label.pictures")}>
        <Grid item flexDirection="column" mb={2} xs={12}>
          <Typography variant="body1" className={classes.bodyText} mb={1}>
            {t("accordion.label.pictures.info")}
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            {t("accordion.label.pictures.info2")}
          </Typography>
        </Grid>
        <Grid item container flexDirection="column" spacing={2} xs={12}>
          <Grid item container className={classes.rowContainer} spacing={2}>
            <Grid item xs={12} mb={4}>
              <ImageUploader type="FLOOR" objectId={objectId} />
            </Grid>
          </Grid>
          <Grid item container className={classes.rowContainer} spacing={2} xs={12}>
          <Grid item xs={12} mb={4}>
              <ImageUploader type="ENERGY" objectId={objectId} />
            </Grid>
          </Grid>
          <Grid item container className={classes.rowContainer} spacing={2} xs={12}>
          <Grid item xs={12}>
              <ImageUploader type="PHOTOS" objectId={objectId} />
              <InfoAlert sx={{ marginTop: "8px" }}>
                {t("accordion.label.pictures.info.main")}
              </InfoAlert>
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default ImageAccordion;
