import {
  API_BASE_URL,
  UPLOAD_CUSTOMER_PHOTO,
  UPLOAD_INCOME_TAX,
  UPLOAD_PROOF_OF_INCOME,
  UPLOAD_RENT_PAYMENTS,
  UPLOAD_RENTAL_COST_TAKE,
  UPLOAD_RENTAL_GUARANTEE,
  UPLOAD_SCHUFA,
} from "./CONSTANTS";
import { request } from "./authHandler";
import axios from "axios";
import { CustomerState } from "../context/CustomerContext";
import { Dispatch, SetStateAction } from "react";

export function createCustomer(customerRequest: any, displayId: string) {
  delete customerRequest?.id;
  return request({
    url: `${API_BASE_URL}/customer/${displayId}`,
    method: "POST",
    body: JSON.stringify(customerRequest),
  });
}

export function updateCustomer(customerRequest: any) {
  // no, I'm not fixing the backend data model
  // enjoy a lovely hack instead:
  delete customerRequest?.photo;
  delete customerRequest?.proofOfIncome;
  delete customerRequest?.incomeTaxStatement;
  delete customerRequest?.rentalCostTakeOver;
  delete customerRequest?.rentalGuarantee;
  delete customerRequest?.rentPaymentConfirmations;
  delete customerRequest?.schufa;
  return request({
    url: API_BASE_URL + "/customer",
    method: "PUT",
    body: JSON.stringify(customerRequest),
  });
}

export function getCustomer(id: string) {
  return request({
    url: API_BASE_URL + `/customer/${id}`,
    method: "GET",
  });
}

export const getDropDownValues = async (
  id: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/entity/${id}`);
    return data;
  } catch (error) {
    setLoading(false);
    console.error("Error fetching data:", error);
  }
};

export const uploadFiles = async (
  files: FileList,
  customerId: string,
  type: string,
  url: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const dataForm = new FormData();
  for (let i = 0; i < files.length; i++) {
    let localFile: File = files[i];
    dataForm.append(type, localFile);
  }

  const config = {
    method: "put",
    url: `${url}/${customerId}`,
    data: dataForm,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const uploadPhoto = async (
  file: File,
  customerId: string,
  type: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const dataForm = new FormData();
  dataForm.append(type, file);

  const config = {
    method: "put",
    url: `${UPLOAD_CUSTOMER_PHOTO}/${customerId}`,
    data: dataForm,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const saveAllFiles = async (
  customerState: CustomerState["customer"],
  id: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  // customer photo
  if (customerState.photo) {
    await uploadPhoto(customerState.photo, id, "photo", setLoading);
  }

  // proof of income
  if (customerState.proofOfIncome) {
    await uploadFiles(
      customerState.proofOfIncome,
      id,
      "proofOfIncome",
      UPLOAD_PROOF_OF_INCOME,
      setLoading
    );
  }

  // incomeTax
  if (customerState.incomeTaxStatement) {
    await uploadFiles(
      customerState.incomeTaxStatement,
      id,
      "incomeTaxStatement",
      UPLOAD_INCOME_TAX,
      setLoading
    );
  }

  // rental cost take over
  if (customerState.rentalCostTakeOver) {
    await uploadFiles(
      customerState.rentalCostTakeOver,
      id,
      "rentalCostTakeOver",
      UPLOAD_RENTAL_COST_TAKE,
      setLoading
    );
  }

  // rental guarantee
  if (customerState.rentalGuarantee) {
    await uploadFiles(
      customerState.rentalGuarantee,
      id,
      "rentalGuarantee",
      UPLOAD_RENTAL_GUARANTEE,
      setLoading
    );
  }

  // schufa
  if (customerState.schufa) {
    await uploadFiles(
      customerState.schufa,
      id,
      "SCHUFA",
      UPLOAD_SCHUFA,
      setLoading
    );
  }

  // rent Payment confirmations
  if (customerState.rentPaymentConfirmations) {
    await uploadFiles(
      customerState.rentPaymentConfirmations,
      id,
      "rentPaymentConfirmations",
      UPLOAD_RENT_PAYMENTS,
      setLoading
    );
  }
};
