import { makeStyles } from "@mui/styles";
import { appBarHeight, appBarPadding } from "../../components/Header/styles";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  mainContentContainer: {
    marginTop: `calc(${appBarHeight}px + ${appBarPadding * 2}px)`,
    width: "100%",
    flexGrow: 1,
    /*overflowY: "auto",*/
  },
  nav: {
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
  },
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "top",
    width: "100%",
    height: "100%",
  },
}));
