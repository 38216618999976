import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import {LOGIN} from "./ROUTES";

type ProtectedRouteType = {
  children: ReactNode;
  authenticated: boolean
};
export const ProtectedRoute: React.FC<ProtectedRouteType> = ({ authenticated, children }) => {
  if (!authenticated) {
    return <Navigate to={LOGIN}/>;
  }
  return <>{children}</>;
};
