import { 
  Grid,
  Badge,
} from "@mui/material";
import generateImgUrl from "./util";
import { ImageType } from "./ImageUploader";
import { useTranslation } from "react-i18next";

interface Props {
  images: { title: string; image: string }[];
  type: ImageType;
}
const ImagePreviewReadOnly = ({ images, type }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} spacing={2} mb={2}>
      {images.map(({ image, title }, index) => (
        <Grid item key={index}>
          <Badge 
            badgeContent={t("accordion.pictures.upload.pictures.main")}
            sx={{
              "& span": {
                transform: "translateX(+10px)",
                marginTop: "10px",
                backgroundColor: "rgba(91, 164, 58, 1.0)",
                color: "#fff",
              },
            }}
            invisible={ (index === 0 && type === "PHOTOS") ? false : true }
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <img
              style={{ 
                borderRadius: "4px", 
                borderStyle: "solid",
                maxWidth: (index === 0 && type === "PHOTOS") ? "144px" : "150px", 
                borderWidth: (index === 0 && type === "PHOTOS") ? "4px" : "1px", 
                borderColor: (index === 0 && type === "PHOTOS") ? "rgba(91, 164, 58, 1.0)" : "rgba(10, 25, 41, 0.3)",
              }}
              src={generateImgUrl(image)}
              alt={title}
            />
          </Badge>
        </Grid>
      ))}
    </Grid>
  );
};

export default ImagePreviewReadOnly;
