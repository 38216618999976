import Card from "@mui/material/Card";
import {
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useStyles } from "./styles";
import placeHolder from "../../../assets/img/placeholder-image.jpeg";
import { DeactivateButton } from "../Components";
import { useContext } from "react";
import { CustomerDashboardContext } from "../../../context/CustomerDashboardContext";
import { useNavigate } from "react-router-dom";
import { ESTATE } from "../../../routes/ROUTES";
import { SideBarContext } from "../../../context/SideBarContext";
import { useTranslation } from "react-i18next";
import {
  numericFormatter,
 } from 'react-number-format';
 import EditIcon from '@mui/icons-material/Edit';

interface ValueContainerProps {
  title: string;
  subTitle: string;
}

const ValueContainer = ({ title, subTitle }: ValueContainerProps) => {
  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography>{subTitle}</Typography>
      </Grid>
    </Grid>
  );
};

export const ObjectCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rentalObject } = useContext(CustomerDashboardContext);
  const img =
    rentalObject.pictures && rentalObject.pictures.length > 0
      ? `data:image/jpeg;base64,${rentalObject.pictures[0]["image"]["data"]}`
      : placeHolder;
  const { setVisibility } = useContext(SideBarContext);

  const goToObject = () => {
    setVisibility(false);
    navigate(`${ESTATE}/${rentalObject.id}`, {
      state: { object: rentalObject, step: 2 },
    });
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant="h5">{`${t("customer.dashboard.object")} ${
            rentalObject.name
          }`}</Typography>
        }
        subheader={`${t("customer.dashboard.address")} ${rentalObject.address}`}
      />
      <CardMedia
        component="img"
        image={img}
        className={classes.img}
        alt="object-image"
      />
      <CardContent sx={{paddingBottom: "16px !important"}}>
        <Grid container flexDirection="row">
          <Grid item xs={6} display="flex" flexDirection="row" alignItems="flex-end">
            <ValueContainer
              title={
                numericFormatter(
                  rentalObject.totalRent.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalSeparator: `${t("numbers.decimalSeparator")}`,
                    suffix: " €",
                    decimalScale: 0,
                  }
                )
              }
              subTitle={t("customer.dashboard.cold.rent")}
            />
            <ValueContainer
              title={
                numericFormatter(
                  rentalObject.rooms.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalSeparator: `${t("numbers.decimalSeparator")}`,
                  }
                )
              }
              subTitle={(rentalObject.rooms === 1 ? t("customer.dashboard.room") : t("customer.dashboard.rooms"))}
            />
            <ValueContainer
              title={
                numericFormatter(
                  rentalObject.area.toString(), {
                    thousandSeparator: `${t("numbers.thousandSeparator")}`,
                    decimalScale: 0,
                  }
                ) + " " + t("customer.dashboard.area")
              }
              subTitle={t("customer.dashboard.cold.floor")}
            />
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent="flex-end"
            display="flex"
            alignItems="flex-end"
          >
            <Box display="flex">
              <Box sx={{ marginRight: 4 }}>
                <Button className={[classes.button, classes.buttonVariant].join(" ")} onClick={goToObject}>
                  <EditIcon sx={{ paddingRight: "4px", paddingTop: "2px", fontSize: 20 }} />
                  {t("customer.dashboard.cold.change.data")}
                </Button>
              </Box>
              <Box>
                <DeactivateButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
