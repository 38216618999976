import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { FormData } from "../Form/types";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";

interface QualityAndPetsProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

export type Quality = null | "simple" | "normal" | "high" | "luxury" | "na";
export type PetAllowance = null | "yes" | "no" | "agreement";
const QualityAndPets = (props: QualityAndPetsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedQuality, setSelectedQuality] = useState<Quality>(
    props.formData.quality as Quality
  );
  const handleQualityChange = (qualityType: Quality) => {
    props.setFormData({ ...props.formData, quality: qualityType });
    setSelectedQuality(qualityType);
  };

  const [isPetAllowed, setIsPetAllowed] = useState<PetAllowance>(
    props.formData.isPetAllowed as PetAllowance
  );
  const handleIsPetAllowed = (allowance: PetAllowance) => {
    props.setFormData({ ...props.formData, isPetAllowed: allowance });
    setIsPetAllowed(allowance);
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion
        label={"2. " + t("accordion.label.facilities.quality.and.pet")}
      >
        <Grid item container className={classes.columnContainer}>
          <Grid item container className={classes.columnContainer}>
            <Grid item mb={1}>
              <Typography variant="body1" className={classes.bodyText}>
                {t("accordion.label.facilities.quality")}
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <ButtonGroup>
                <Button
                  className={classes.selectButton}
                  onClick={() => handleQualityChange("na")}
                  variant={selectedQuality === "na" ? "contained" : "outlined"}
                >
                  {t("estate.quality.KA")}
                </Button>
                <Button
                  className={classes.selectButton}
                  variant={
                    selectedQuality === "simple" ? "contained" : "outlined"
                  }
                  onClick={() => handleQualityChange("simple")}
                >
                  {t("estate.quality.simple")}
                </Button>
                <Button
                  className={classes.selectButton}
                  variant={
                    selectedQuality === "normal" ? "contained" : "outlined"
                  }
                  onClick={() => handleQualityChange("normal")}
                >
                  {t("estate.quality.normal")}
                </Button>
                <Button
                  className={classes.selectButton}
                  variant={
                    selectedQuality === "high" ? "contained" : "outlined"
                  }
                  onClick={() => handleQualityChange("high")}
                >
                  {t("estate.quality.high")}
                </Button>
                <Button
                  className={classes.selectButton}
                  onClick={() => handleQualityChange("luxury")}
                  variant={
                    selectedQuality === "luxury" ? "contained" : "outlined"
                  }
                >
                  {t("estate.quality.luxury")}
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid
              item
              container
              className={classes.columnContainer}
            >
              <Grid item mb={1}>
                <Typography variant="body1" className={classes.bodyText}>
                  {t("accordion.label.facilities.pet")}
                </Typography>
              </Grid>
              <Grid item>
                <ButtonGroup>
                  <Button
                    className={classes.selectButton}
                    variant={isPetAllowed === "yes" ? "contained" : "outlined"}
                    onClick={() => handleIsPetAllowed("yes")}
                  >
                    {t("estate.pet.yes")}
                  </Button>
                  <Button
                    className={classes.selectButton}
                    variant={isPetAllowed === "no" ? "contained" : "outlined"}
                    onClick={() => handleIsPetAllowed("no")}
                  >
                    {t("estate.pet.no")}
                  </Button>
                  <Button
                    className={classes.selectButton}
                    variant={
                      isPetAllowed === "agreement" ? "contained" : "outlined"
                    }
                    onClick={() => handleIsPetAllowed("agreement")}
                  >
                    {t("estate.pet.aggrement")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default QualityAndPets;
