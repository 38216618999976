import {
  Typography,
  Grid,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import petra_mm from "../../../assets/img/petra_mustermann.png";
import max_mm from "../../../assets/img/max_mustermann.png";
import Alert from "react-s-alert";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Avatar from '@mui/material/Avatar';
import { Divider } from '@mui/material';

export const SmartAssistant = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    Alert.info( `${t("error.notYetAvailable")}` );
  };
  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "32px 0 16px 0" }}>
        { t("main.dashboard.smart.assistant") }
      </Typography>
      <Grid container sx={{ position: "relative !important" }} className={classes.card}>
        <Grid item xs={12} display="flex">
          <Grid item xs={12} className={ classes.titleContainer }>
            <Box className={classes.title}>
              { t("main.dashboard.ask.our.experts") }
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={ classes.subTitle }>
          { t("main.dashboard.ask.smartagent") }
        </Grid>
        <Grid item xs={12} sx={{ padding: "16px" }}>
          <TextField
            variant="outlined"
            label={`${t("main.dashboard.ask.question")}`}
            multiline
            minRows={4}
            className={classes.textField}
            color="info"
            fullWidth
            InputProps={{ 
              sx: {
                backgroundColor: "#ffffff",
              },
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{ 
              classes: {
                focused: classes.inputLabel,
              },
              shrink: false,
            }}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="right" sx={{ padding: "16px" }}>
          <Button className={classes.button} onClick={handleClick}>
            { t("main.dashboard.submit") }
            <DoubleArrowIcon sx={{ paddingLeft: "4px", paddingTop: "2px", fontSize: 20 }} />
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ padding: "8px 16px" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={ classes.additionalInfo }>
          { t("main.dashboard.response.within.24.hours") }
        </Grid>
        <Grid item container xs={12} sx={{ padding: "12px 16px 20px 16px", marginLeft: "0" }}>
          <Grid item container xs={6} className={classes.expertCard}>
            <Grid item container xs={12} sx={{ backgroundColor: "#fff", padding: "12px", marginRight: "8px", borderRadius: "8px" }}>
              <Grid item xs={12} justifyContent="center" display="flex">
                <Avatar src={petra_mm} sx={{ width: 64, height: 64 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.expertName}>
                  Petra Mustermann
                </Typography>
                <Typography className={classes.expertInfo}>
                  { t("main.dashboard.ivdmember1") }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6} className={classes.expertCard}>
            <Grid item container xs={12} sx={{ backgroundColor: "#fff", padding: "12px", marginLeft: "8px", borderRadius: "8px" }}>
              <Grid item xs={12} justifyContent="center" display="flex">
                <Avatar src={max_mm} sx={{ width: 64, height: 64 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.expertName}>
                  Max Mustermann
                </Typography>
                <Typography className={classes.expertInfo}>
                  { t("main.dashboard.ivdmember2") }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
