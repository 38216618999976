import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  deckContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    overflow: "hidden",
  },
}));
