import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E7E7E7",
    minHeight: "100vh",
    display: "flex",
  },
  main: {
    marginTop: "50px",
    marginLeft: "50px",
  },
}));
