import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    background: "#FFFFFF",
    height: "56px",
    padding: "0px, 12px, 0px, 12px",
  },
}));
