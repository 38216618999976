import { useTranslation } from "react-i18next";
import i18next from "i18next";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./styles";
import { useState, useEffect } from "react";
import InfoAlert from "../../Common/InfoAlert/InfoAlert";
import { VerticalAccordion } from "../../Common/Accordion/VerticalAccordion";
import {
  Grid,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  getTypeOfHeating,
  getEssentialEnergySource,
  getEnergyClass,
} from "../../../services/entitiyHandler";
import { FormData } from "../Form/types";

interface HeatingProps {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<any>>;
}

interface Entities {
  id: string;
  value_1: string;
  name_de: string;
  name_en: string;
  type: string;
}

const Heating = (props: HeatingProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;

  const [typeOfHeatingSelect, setTypeOfHeatingSelect] = useState<Entities[]>(
    []
  );
  const [essentialEnergySourceSelect, setEssentialEnergySourceSelect] =
    useState<Entities[]>([]);

  const [energyClassSelect, setEnergyClassSelect] = useState<Entities[]>([]);

  const [typeOfHeating, setTypeOfHeating] = useState(
    props.formData.typeOfHeating
  );
  const [essentialEnergySource, setEssentialEnergySource] = useState(
    props.formData.essentialEnergySource
  );
  const [energyClass, setEnergyClass] = useState(props.formData.energyClass);

  const [hoveredObject, setHoveredObject] = useState("");
  const handleMouseOver = (obj: string) => {
    setHoveredObject(obj);
  };

  const handleMouseOut = () => {
    setHoveredObject("");
  };

  useEffect(() => {
    getTypeOfHeating().then((response) => {
      setTypeOfHeatingSelect(response);
    });
    getEssentialEnergySource().then((response) => {
      setEssentialEnergySourceSelect(response);
    });
    getEnergyClass().then((response) => {
      setEnergyClassSelect(response);
    });
  }, []);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name === "heatingType") {
      setTypeOfHeating(value);
      props.setFormData({ ...props.formData, typeOfHeating: value });
    }
    if (name === "essentialEnergySource") {
      setEssentialEnergySource(value);
      props.setFormData({ ...props.formData, essentialEnergySource: value });
    }
    if (name === "energyClass") {
      setEnergyClass(value);
      props.setFormData({ ...props.formData, energyClass: value });
    }
  };

  const langCompare = (a: any, b: any, lang: string) => {
    const aValue = lang === "en" ? a.name_en : a.name_de;
    const bValue = lang === "en" ? b.name_en : b.name_de;
    if (aValue.charAt(0) === ".") {
      return 1;
    } else if (bValue.charAt(0) === ".") {
      return -1;
    } else {
      return aValue.localeCompare(bValue);
    }
  };

  const langSlice = (item: any, lang: string) => {
    let itemValue = lang === "en" ? item.name_en : item.name_de;
    if (itemValue.charAt(0) === ".") {
      itemValue = itemValue.substring(1);
    }
    return itemValue;
  };

  return (
    <Grid container className={classes.accordionContainer}>
      <VerticalAccordion label={"3. " + t("extra.info.heating.label")}>
        <Grid container className={classes.columnContainer}>
          <Grid
            item
            container
            spacing={2}
            className={classes.rowContainer}
            onMouseOver={() => handleMouseOver("heatingType")}
            onMouseOut={handleMouseOut}
            mb={2}
          >
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.select}>
                <InputLabel>{t("extra.info.heating.type")}</InputLabel>
                <Select
                  label={t("extra.info.heating.type")}
                  id="heatingType"
                  value={typeOfHeatingSelect.length ? typeOfHeating : ""}
                  name="heatingType"
                  onChange={handleSelectChange}
                  displayEmpty
                >
                  {typeOfHeatingSelect.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {langSlice(item, lang)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {hoveredObject === "heatingType" && (
                <InfoAlert>{t("extra.info.heating.type.alert")}</InfoAlert>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.rowContainer}
            spacing={2}
            onMouseOver={() => handleMouseOver("essentialEnergySource")}
            onMouseOut={handleMouseOut}
            mb={2}
          >
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.select}>
                <InputLabel>{t("extra.info.heating.source")}</InputLabel>
                <Select
                  label={t("extra.info.heating.source")}
                  id="essentialEnergySource"
                  value={essentialEnergySourceSelect.length ? essentialEnergySource : ""}
                  name="essentialEnergySource"
                  onChange={handleSelectChange}
                  displayEmpty
                >
                  {essentialEnergySourceSelect.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {langSlice(item, lang)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {hoveredObject === "essentialEnergySource" && (
                <InfoAlert>{t("extra.info.heating.source.alert")}</InfoAlert>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.rowContainer}
            spacing={2}
            onMouseOver={() => handleMouseOver("energyClass")}
            onMouseOut={handleMouseOut}
            mb={2}
          >
            <Grid item xs={6} mb={8}>
              <FormControl fullWidth className={classes.select}>
                <InputLabel>{t("estate.energyClass")}</InputLabel>
                <Select
                  label={t("estate.energyClass")}
                  id="energyClass"
                  value={energyClassSelect.length ? energyClass : ""}
                  name="energyClass"
                  onChange={handleSelectChange}
                  displayEmpty
                >
                  {energyClassSelect.sort((a, b) => langCompare(a, b, lang)).map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {langSlice(item, lang)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {hoveredObject === "energyClass" && (
                <InfoAlert>{t("estate.energyClass.validation")}</InfoAlert>
              )}
            </Grid>
          </Grid>
        </Grid>
      </VerticalAccordion>
    </Grid>
  );
};

export default Heating;
