import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: colors.cardBorderRadius,
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
    "@media print": {
      boxShadow: "none",
      paddingBottom: "0",
    },
  },
  msgContainer: {
    //paddingRight: 64,
  },
  photo: {
    height: "auto",
    maxWidth: 486,
    marginBottom: "24px",
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: colors.cardBorderRadius,
    verticalAlign: "top",
  },
  personalDataContainer: {
    //paddingRight: 64,
  },
  headerBox: {
    backgroundColor: colors.smartAgentDarkBlue,
    borderRadius: colors.cardBorderRadius,
    color: colors.white,
  },
  smaller: {
    color: colors.darkTextSecondary,
    //fontWeight: colors.fontWeightRegular,
  },
  tableRow: {
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: 8,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  contact: {
    padding: 16,
    borderRadius: colors.cardBorderRadius,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  contactField: {
    fontSize: colors.fontSizeIntermediate,
    fontWeight: colors.fontWeightRegular,
    color: colors.lightTextSecondary,
  },
  contactValue: {
    fontWeight: colors.fontWeightBold,
    color: theme.palette.common.white,
    wordBreak: "break-all",
  },
  contactLine: {
    paddingBottom: 24,
  },
  footerContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 64,
  },
  filePreview: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0",
  },
  fileName: {
    marginRight: 10,
    color: "##171E3B",
    fontSize: 16,
    textDecoration: "underline",
  },
  provideMsg: {
    marginRight: 10,
    color: "#6BAA21",
    fontSize: 16,
  },
  downloadFiles: {
    border: "2px solid #171E3B",
    padding: 2,
    paddingLeft: 6,
    display: "flex",
    flexDirection: "row",
  },
  uploaded: {
    color: "#6BAA21",
  },
  icon: {
    marginRight: "8px",
  },
  button: {
    backgroundColor: colors.smartAgentGreen,
    color: colors.lightTextPrimary,
    fontSize: colors.fontSizeRegular,
    fontFamily: theme.typography.fontFamily,
    fontWeight: colors.fontWeightBold,
    paddingLeft: "12px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: colors.smartAgentLightGreen,
    },
  },
  buttonVariant: {
    backgroundColor: colors.smartAgentBlue20pctNoAlpha,
    color: colors.darkTextPrimary,
  },
  card: {
    padding: "16px",
    borderRadius: colors.cardBorderRadius,
    backgroundColor: colors.smartAgentDarkBlue,
    boxShadow: colors.cardShadow,
  },
  topCard: {
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  bottomCard: {
    borderTopLeftRadius: "0 !important",
    borderTopRightRadius: "0 !important",
  },
  middleCard: {
    borderRadius: "0",
    paddingBottom: "0",
  },
  cardVariant: {
    backgroundColor: colors.smartAgentChatbotBlue,
  },
  objectDescriptionTitle: {
    fontFamily: theme.typography.fontFamily,
    color: "#171E3B",
    fontSize: 28,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "center",
  },
  shareDescriptionTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: colors.fontWeightBold,
    display: "flex",
    alignItems: "flex-start",
  },
  checkbox: {
    //color: "white",
    //"&.Mui-checked": {
    //  color: "white",
    //},
  },
  checkboxDark: {
    color: "white",
    "&.Mui-checked": {
      color: "white",
    },
  },
  checkboxLabel: {
    color: "white",
    alignItems: "flex-start",
    "& span:first-of-type": {
      paddingTop: "2px",
      paddingLeft: "16px",
    },
    marginBottom: "8px",
  },
}));
