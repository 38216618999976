import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../../style/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: { display: "flex", flexDirection: "column" },
  columnContainer: {
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  infoContainer: {
    position: "relative", // Set position to relative
    flexGrow: 1,
  },
  info: {
    position: "absolute", // Set position to absolute
    flexGrow: 1,
  },
  bodyText: { 
    color: colors.darkTextPrimary,
  },
  selectButton: {
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: colors.smartAgentGreen20pct,
      borderColor: colors.smartAgentGreen20pct,
    },
    "&.MuiButton-contained": {
      background: colors.smartAgentGreen,
      color: colors.white,
      boxShadow: "none",
    },
  },
}));
