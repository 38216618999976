import { createTheme } from "@mui/material/styles";
import * as colors from "./colors";
import { green } from "./colors";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CSSProperties } from "react";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    info: true;
    error: true;
    mainTitle1: true;
    mainTitle2: true;
    title: true;
    subTitle: true;
    body: true;
    homeH1: true;
    homeBody1: true;
    homeBody2: true;
    homeBody3: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  info: CSSProperties;
  error: CSSProperties;
  mainTitle1: CSSProperties;
  mainTitle2: CSSProperties;
  title: CSSProperties;
  subTitle: CSSProperties;
  body: CSSProperties;
  homeH1: CSSProperties;
  homeBody1: CSSProperties;
  homeBody2: CSSProperties;
  homeBody3: CSSProperties;
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: colors.lightNavy,
      main: colors.navy,
      dark: colors.darkNavy,
    },
    secondary: {
      light: colors.lightGreen,
      main: colors.green,
      dark: colors.darkGreen,
    },
    info: {
      light: colors.lightGray,
      main: colors.gray,
      dark: colors.darkGray,
    },
    error: {
      light: colors.darkRed,
      main: colors.darkRed,
      dark: colors.darkRed,
    },
    common: {
      white: colors.white,
      black: colors.black,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: ["Barlow", "sans-serif"].join(","),
    h4: {
      fontSize: 32,
      fontWeight: 800,
    },
    h5: {
      fontWeight: 800,
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 32,
      fontWeight: 800,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      opacity: 0.7,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      color: "#171E3B",
      fontSize: 20,
      fontWeight: 500,
    },
    caption: {
      fontSize: 12,
      fontWeight: 500,
    },
    info: {
      fontFamily: "Barlow",
      color: green,
      fontSize: 16,
      fontWeight: 600,
    },
    error: {
      fontFamily: "Barlow",
      color: "red",
      fontSize: 16,
      fontWeight: 500,
    },
    mainTitle1: {
      fontFamily: "Barlow",
      fontSize: 48,
      fontWeight: 800,
    },
    mainTitle2: {
      fontFamily: "Barlow",
      fontSize: 32,
      fontWeight: 800,
    },
    title: {
      fontFamily: "Barlow",
      fontSize: 24,
      fontWeight: 800,
    },
    subTitle: {
      fontFamily: "Barlow",
      fontWeight: 800,
      fontSize: 16,
    },
    body: {
      fontFamily: "Barlow",
      fontSize: 16,
      fontWeight: 500,
      color: "#101529",
    },
    homeH1: {
      color: "#171E3B",
      fontSize: 36,
      fontFamily: "Barlow",
      fontWeight: "700",
      wordWrap: "break-word",
    },
    homeBody1: {
      color: "#101529",
      fontSize: 20,
      fontFamily: "Barlow",
      fontWeight: "500",
      letterSpacing: 0.15,
      wordWrap: "break-word",
      lineHeight: 1.5,
    },
    homeBody2: {
      color: "#101529",
      fontSize: 20,
      fontFamily: "Barlow",
      fontWeight: "700",
      lineHeight: 2,
    },
    homeBody3: {
      color: "#101529",
      fontSize: 20,
      fontFamily: "Barlow",
      fontWeight: "700",
      lineHeight: 2,
    },
  } as ExtendedTypographyOptions,
});
