import Card from "@mui/material/Card";
import {
  Box,
  CardContent,
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import React, { useContext, useEffect, useState } from "react";
import { RentalStatus } from "../../../types/RentalObject";
import { CustomerDashboardContext } from "../../../context/CustomerDashboardContext";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ESTATE } from "../../../routes/ROUTES";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface STEP {
  label: string;
  status: RentalStatus;
  index: number;
}

export const ObjectStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rentalObject } = useContext(CustomerDashboardContext);

  const steps: STEP[] = [
    {
      index: 0,
      status: "create_object",
      label: t("customer.dashboard.inactive.object.status.1"),
    },
    {
      index: 1,
      status: "create_expose",
      label: t("customer.dashboard.inactive.object.status.2"),
    },
    {
      index: 2,
      status: "publish_expose",
      label: t("customer.dashboard.inactive.object.status.3"),
    },
    {
      index: 3,
      status: "review_and_invite_candidates",
      label: t("customer.dashboard.inactive.object.status.4"),
    },
    {
      index: 4,
      status: "prepare_contract",
      label: t("customer.dashboard.inactive.object.status.5"),
    },
    {
      index: 5,
      status: "decline_other_candidates",
      label: t("customer.dashboard.inactive.object.status.6"),
    },
  ];

  const getCurrentStep = () => {
    const currentStatus = steps.filter(
      (step) => step.status === rentalObject.status
    );
    return currentStatus.length === 0 ? 0 : currentStatus[0].index;
  };

  const [activeStep, setActiveStep] = useState(getCurrentStep());

  useEffect(() => {
    setActiveStep(getCurrentStep());
    // not touching dependency arrays again, as the incorrect use hides errors 
    // that I don't want to accidentally trigger. So leave it as is :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalObject.status]);

  const StepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    return (
      <Box sx={{paddingTop: "1px"}}>
        {(completed || active) && (
          <CheckCircleIcon sx={{ color: "rgba(91, 164, 58, 1.0)" }}/>
        )}
        {(!active && !completed) && (
          <RadioButtonUncheckedIcon sx={{ color: "#757575" }}/>
        )}
      </Box>
    );
  };

  const StepIconNext = (props: StepIconProps) => {
    return (
      <Box sx={{paddingTop: "1px"}}>
        <PlayCircleIcon sx={{ color: "#0A1929" }}/>
      </Box>
    );
  };

  const CustomStepConnector = styled(StepConnector)(() => ({
    "& .MuiStepConnector-line": {
      borderColor: "rgba(91, 164, 58, 1.0)",
      borderLeftWidth: "3px",
      marginLeft: "-2px",
      minHeight: "36px",
      marginBottom: "4px",
    },
    "&.Mui-disabled .MuiStepConnector-line": {
      borderColor: "#757575",
      borderLeftStyle: "dotted",
    },
  }));

  const handleClick = (step: STEP) => {
    switch (step.index) {
      case 0:
        navigate(`${ESTATE}`);
        break;
      case 1:
        navigate(`${ESTATE}/${rentalObject.id}`, {
          state: { object: rentalObject, step: 2 },
        });
        break;
      case 2:
        navigate(`${ESTATE}/${rentalObject.id}`, {
          state: { object: rentalObject, step: 3 },
        });
        break;
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container display="flex" flexDirection="column">
          <Grid item sx={{ mb: 5 }}>
            <Typography variant="h5">Objektstatus:</Typography>
          </Grid>
          <Grid item>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<CustomStepConnector />}
            >
              {steps.map((step) => (
                <Step key={step.label} onClick={() => handleClick(step)}>
                  <StepLabel StepIconComponent={step.index === activeStep + 1 ? StepIconNext : StepIcon} sx={{
                      "& .MuiStepLabel-labelContainer": {
                        marginTop: "-9px",
                      },
                      padding: "0",
                    }}>
                    <Typography
                      variant="body"
                      style={{
                        color: step.index === activeStep ? "rgba(91, 164, 58, 1.0)" : (step.index < activeStep || step.index === activeStep + 1) ? "#101529" : "#757575",
                        cursor: step.index < 3 ? "pointer" : "",
                        fontWeight: step.index !== activeStep ? "400" : "700",
                      }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
